import clsx from 'clsx';
import { useStyles } from './utils';

export default function DeletedServiceIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <g clipPath="url(#clip0_7084_9667)">
                <path
                    d="M1.14282 4.5H14.8571"
                    stroke="#EC373C"
                    strokeWidth="1.14286"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.85718 4.5H13.1429V14.7857C13.1429 15.0888 13.0225 15.3795 12.8082 15.5938C12.5938 15.8082 12.3031 15.9286 12 15.9286H4.00003C3.69693 15.9286 3.40624 15.8082 3.19191 15.5938C2.97759 15.3795 2.85718 15.0888 2.85718 14.7857V4.5Z"
                    stroke="#EC373C"
                    strokeWidth="1.14286"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.14282 4.5001V3.92868C5.14282 3.17091 5.44384 2.44419 5.97966 1.90837C6.51548 1.37255 7.2422 1.07153 7.99997 1.07153C8.75773 1.07153 9.48445 1.37255 10.0203 1.90837C10.5561 2.44419 10.8571 3.17091 10.8571 3.92868V4.5001"
                    stroke="#EC373C"
                    strokeWidth="1.14286"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.28564 6.78589V13.0716"
                    stroke="#EC373C"
                    strokeWidth="1.14286"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.71436 6.78589V13.0716"
                    stroke="#EC373C"
                    strokeWidth="1.14286"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7084_9667">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}
