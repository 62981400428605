import clsx from 'clsx';
import { useStyles } from './utils';

export default function RefreshIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="18" height="18" fill="white" fillOpacity="0.01" />
            <path
                d="M6.75 5.62496H3.81375C4.54126 4.50689 5.61061 3.65387 6.8624 3.19304C8.11419 2.7322 9.48139 2.68825 10.7602 3.06771C12.039 3.44718 13.1609 4.22976 13.9588 5.29878C14.7566 6.36781 15.1876 7.66604 15.1875 8.99996H16.3125C16.3141 7.49555 15.8516 6.02727 14.9881 4.79533C14.1246 3.5634 12.9022 2.62776 11.4875 2.11601C10.0728 1.60426 8.53467 1.5413 7.08288 1.93571C5.63109 2.33013 4.33628 3.16272 3.375 4.31996V2.24996H2.25V6.74996H6.75V5.62496Z"
                fill="#11567F"
            />
            <path
                d="M11.25 12.375H14.1863C13.4587 13.493 12.3894 14.3461 11.1376 14.8069C9.88582 15.2677 8.51862 15.3117 7.23981 14.9322C5.961 14.5527 4.83907 13.7702 4.04124 12.7011C3.24341 11.6321 2.81242 10.3339 2.8125 8.99996H1.6875C1.68595 10.5044 2.14845 11.9727 3.01193 13.2046C3.8754 14.4365 5.09783 15.3722 6.51253 15.8839C7.92723 16.3957 9.46534 16.4586 10.9171 16.0642C12.3689 15.6698 13.6637 14.8372 14.625 13.68V15.75H15.75V11.25H11.25V12.375Z"
                fill="#11567F"
            />
        </svg>
    );
}
