import clsx from 'clsx';
import { useStyles } from './utils';

export default function GoogleIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M41.7995 22.4838C41.7995 21.1198 41.6675 19.7118 41.4475 18.3918H22.3955V26.1798H33.3075C32.8675 28.6878 31.4155 30.8878 29.2595 32.2958L35.7715 37.3558C39.5995 33.7918 41.7995 28.5998 41.7995 22.4838Z"
                fill="#4280EF"
            />
            <path
                d="M22.396 42.196C27.852 42.196 32.428 40.392 35.772 37.312L29.26 32.296C27.456 33.528 25.124 34.232 22.396 34.232C17.116 34.232 12.672 30.668 11.044 25.916L4.35596 31.064C7.78796 37.884 14.74 42.196 22.396 42.196Z"
                fill="#34A353"
            />
            <path
                d="M11.0439 25.872C10.2079 23.364 10.2079 20.636 11.0439 18.128L4.35594 12.936C1.49594 18.656 1.49594 25.388 4.35594 31.064L11.0439 25.872Z"
                fill="#F6B704"
            />
            <path
                d="M22.396 9.81199C25.256 9.76799 28.072 10.868 30.14 12.848L35.904 7.03999C32.252 3.60799 27.412 1.75999 22.396 1.80399C14.74 1.80399 7.78796 6.11599 4.35596 12.936L11.044 18.128C12.672 13.332 17.116 9.81199 22.396 9.81199Z"
                fill="#E54335"
            />
        </svg>
    );
}
