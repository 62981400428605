export const EnvUrls = {
    PerfInsights_001: 'perfinsights001.tsl-terls.cloud',
    UI_Dev: 'uidev009.tsl-terls.cloud',
    Azure_CP_BYOA: 'azurecpbyoa.tsl-barc.cloud',
    newprovisioningflow001: 'newprovisioningflow001.tsl-terls.cloud',
    byokcrosscloudaccount001: 'byokcrosscloudaccount001.tsl-terls.cloud',
    ErrorPropagation_002001: 'errorprop002001.tsl-terls.cloud',
    ErrorPropagation_001: 'errorprop001.tsl-terls.cloud',
    Canary: 'canary.tessell-stage.com',
    timeZone: 'timezone001.tsl-terls.cloud',
    sqltask: 'mysqltask2001.tsl-terls.cloud',
    clone_refresh: 'dbclonerefresh002.tsl-terls.cloud',
};
