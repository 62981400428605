import clsx from 'clsx';
import { useStyles } from './utils';

export default function IDPIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.11228 20.5477C8.83246 23.354 13.7112 23.9656 13.9339 23.9922C13.9905 23.9976 14.0499 24 14.1118 24C15.8768 24 19.7799 21.884 22.7962 20.0735C23.2135 19.8229 23.4998 19.3575 23.5617 18.8282C23.6236 18.2975 23.4538 17.7627 23.1075 17.397C22.4548 16.7094 21.544 16.4234 20.6715 16.6306L17.1862 17.4594C17.1839 17.3109 17.1599 17.178 17.1262 17.0678C16.8754 16.2486 15.8802 15.6326 14.0849 15.1848C13.2499 14.977 12.4187 14.6942 11.613 14.3442L10.4422 13.8357C9.47581 13.4169 8.43072 13.322 7.41921 13.5629L5.82607 13.9416C5.78419 13.3557 5.4142 12.8549 4.88683 12.7201L0 11.4783V21.6989H3.91896C4.51425 21.6989 5.01824 21.2065 5.11228 20.5477ZM7.62161 14.6487C8.44799 14.452 9.30075 14.5295 10.0902 14.8716L11.261 15.3801C12.1118 15.7496 12.9909 16.0484 13.8729 16.2684C15.5559 16.688 16.1262 17.1965 16.1977 17.4306C16.2216 17.5086 16.1814 17.6202 16.0782 17.7624C15.8495 18.077 15.4835 18.2677 15.0734 18.2858C14.0806 18.3294 12.0518 18.24 9.66382 17.1518L9.29643 18.1807C11.5492 19.2074 13.5118 19.4036 14.6816 19.4036C14.8399 19.4036 14.9837 19.4001 15.1118 19.3944C15.6792 19.3694 16.1974 19.1275 16.5923 18.7346L20.8739 17.7164C21.4332 17.5839 22.0183 17.7673 22.437 18.2083C22.5536 18.3313 22.6087 18.5042 22.5876 18.6827C22.567 18.8601 22.4754 19.0097 22.3358 19.0934C18.2092 21.5705 15.0931 22.9893 14.0264 22.8874C13.9751 22.8814 8.88109 22.2437 5.33287 19.3713L5.2293 19.5348L5.7246 15.0997L7.62161 14.6487ZM0.982258 12.8653L4.67052 13.8026C4.78659 13.8324 4.86237 13.9549 4.8475 14.0879L4.14678 20.3628C4.13239 20.4921 4.03455 20.5893 3.91896 20.5893H0.982258V12.8653Z"
                fill="var(--icon-color)"
            />
            <path
                d="M23.6 7C23.6 10.6617 20.7624 13.6 17.2983 13.6C13.8343 13.6 10.9967 10.6617 10.9967 7C10.9967 3.33832 13.8343 0.4 17.2983 0.4C20.7624 0.4 23.6 3.33832 23.6 7Z"
                stroke="var(--icon-color)"
                strokeWidth="0.8"
            />
            <mask
                id="mask0_1509_513"
                maskUnits="userSpaceOnUse"
                x="11"
                y="0"
                width="13"
                height="14"
            >
                <path
                    d="M23.3619 6.77976C23.3619 10.6456 20.9097 13.342 17.3261 13.342C13.7425 13.342 11.0725 10.6456 11.0725 6.77976C11.0725 2.91391 13.7425 0.437744 17.3261 0.437744C20.9097 0.437744 23.3619 2.91391 23.3619 6.77976Z"
                    fill="#C4C4C4"
                />
            </mask>
            <g mask="url(#mask0_1509_513)">
                <path
                    d="M15.2421 7.875H18.9553C20.2212 7.875 22.3245 9.61671 22.3245 11.199C22.3245 12.7322 21.0573 13.962 19.5248 13.9161L14.7706 13.7736C13.3787 13.7318 12.272 12.5915 12.272 11.199C12.272 9.7156 13.7945 7.875 15.2421 7.875Z"
                    stroke="var(--icon-color)"
                    strokeWidth="0.8"
                    strokeLinecap="round"
                />
                <ellipse
                    cx="17.3311"
                    cy="4.61629"
                    rx="2.61824"
                    ry="2.71542"
                    fill="var(--icon-color)"
                />
            </g>
        </svg>
    );
}
