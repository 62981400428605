import MockUtil from 'tools/MockUtil';
import MockRepository from 'api-clients/network-layer/demo-data/repository/MockRepository';

const networksData = MockRepository.getNetworksData();

const Networks = {
    getVpcsDetails() {
        return networksData.getVpcs();
    },
    getByoaMetadata() {
        return networksData.getByoaMetadata();
    },
    getVpcsSubscriptionName(subName) {
        return networksData.getVpcsSubscriptionName(subName);
    },
    getVpcPeering(vpcName) {
        return networksData.getVpcPeering(vpcName);
    },
    getRegisterNetworksSubscriptionName() {
        return networksData.getRegisterNetworksSubscriptionName();
    },
    getSubnetsSubscriptionName(subName) {
        return networksData.getSubnetsSubscriptionName(subName);
    },
    createVirtualNetwork(reqBody) {
        return networksData.createVirtualNetwork(reqBody);
    },
    updateMaturity(_, url) {
        return MockUtil.getResponse(networksData.updateMaturity(_, url));
    },
    deleteVirtualNetwork(_, url) {
        return MockUtil.getResponse(networksData.deleteVirtualNetwork(_, url));
    },
};

export default Networks;
