import React from 'react';
import clsx from 'clsx';
import { useStyles } from './utils';

export default function LampIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="150"
            height="118"
            viewBox="0 0 150 118"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 4C0 1.79086 1.79086 0 4 0H150V118H4C1.79086 118 0 116.209 0 114V4Z"
                fill="#29B5E8"
            />
            <path d="M64.5 42L86.5 34L139.365 112.652H63.9121L64.5 42Z" fill="white" />
            <path
                d="M87 34.3721L64.5 42.186V26.0698L63.9828 24.6047L61.5 25.5814C61.5 25.5814 60.0482 25.9359 59 24.8327L16 50.9768L15 54.3954L38.6493 96.8982C38.7617 96.8887 38.8786 96.8837 39 96.8837C41.4045 96.8837 42 98.8372 42 98.8372V106.651H55.5V113H21.5V106.651H36V98.8372C36 98.8372 36.2248 98.0996 36.9561 97.5309L14.2857 56.8372L14 57.814L11 51.9535L16.5 48.0465V49.0233L58.2963 23.6279C57.7449 21.9237 59 20.6977 59 20.6977L62.1751 19.4843L59.5 11.907C59.5 11.907 60.5 11.4186 66 9.95349C71.5 8.48837 72.5 8 72.5 8L77 22.1628L87 34.3721Z"
                fill="#171720"
            />
        </svg>
    );
}
