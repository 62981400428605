import MockUtil from 'tools/MockUtil';
import MockRepository from 'api-clients/network-layer/demo-data/repository/MockRepository';

const myServicesData = MockRepository.getMyServicesData();
const availabilityMachinesData = MockRepository.getAvailabilityMachinesData();
const dataflixData = MockRepository.getDataflixData();
const scriptLibraryData = MockRepository.getScriptsLibraryData();
const benchmarksData = MockRepository.getBenchmarksData();
const serversData = MockRepository.getServersData();

const DbServices = {
    getEmptyResponseObject() {
        return {};
    },
    getEmptyResponseArray() {
        return [];
    },
    getEmptyResponseWithMetadata() {
        return {
            response: [],
            metadata: null,
        };
    },
    // DB Services
    getMyServicesData() {
        return myServicesData.getMyServices();
    },
    getComputeServicesData() {
        return myServicesData.getComputeServices();
    },
    getFetchComputesMilvusData() {
        return myServicesData.getFetchComputesMilvus();
    },
    getComputeServicesIncludeTrueData() {
        return myServicesData.getComputeServicesIncludeTrue();
    },
    getSoftwareImagesData() {
        return myServicesData.getSoftwareImages();
    },
    getDatabaseServicesData(id) {
        return myServicesData.getDatabaseServices(id);
    },
    getTaskProgressServicesData(id) {
        return myServicesData.getTaskProgressServices(id);
    },
    getTimeLineServicesData(id) {
        return myServicesData.getTimeLineServices(id);
    },
    getStartStopScheduleServicesData(id) {
        return myServicesData.getStartStopScheduleServices(id);
    },
    getEligibleUsersDbServicesData() {
        return myServicesData.getEligibleUsersDbServices();
    },
    getEligibleUsersAmervicesData() {
        return myServicesData.getEligibleUsersAmServices();
    },
    getParameterProfileServicesData() {
        return myServicesData.getParameterProfileServices();
    },
    getOptionsProfileServicesData() {
        return myServicesData.getOptionsProfileServices();
    },
    getFeaturesConfigServicesData() {
        return myServicesData.getFeaturesConfigServices();
    },
    getConnectionPoolsServicesData(serviceId) {
        return myServicesData.getConnectionPoolsServices(serviceId);
    },
    getSampleDatasetsServicesData() {
        return myServicesData.getSampleDatasetsServices();
    },
    getIntegrationTypesServicesData() {
        return myServicesData.getIntegrationTypesServices();
    },
    getGovernanceIntegrationServicesData() {
        return myServicesData.getGovernanceIntegrationServices();
    },
    getLogfilesServicesData(url) {
        return myServicesData.getLogfilesServices(url);
    },
    getStreamServicesData(id) {
        return myServicesData.getStreamServices(id);
    },
    getCollectionListServicesData(serviceId, databaseId) {
        return myServicesData.getCollectionListServices(serviceId, databaseId);
    },
    getCollectionDetailServicesData(collectionId) {
        return myServicesData.getCollectionDetailServices(collectionId);
    },
    getMonitoringServicesData(url, serviceId) {
        return myServicesData.getMonitoringServices(url, serviceId);
    },
    getVpcDetailsMyServiceServicesData(vpcName) {
        return myServicesData.getVpcDetailsMyServiceServices(vpcName);
    },
    getSslCredsServicesData() {
        return myServicesData.getSslCredsServices();
    },
    getMaintainanceWindows() {
        return myServicesData.getMaintainanceWindows();
    },
    postProvisionDatabaseData(requestBody) {
        return myServicesData.postProvisionDatabase(requestBody);
    },
    postFetchAvgDbLoadData(requestBody) {
        return myServicesData.postFetchAvgDbLoad(requestBody);
    },
    postFetchTopDimensionsData(requestBody) {
        return myServicesData.postFetchTopDimensions(requestBody);
    },
    postFetchExtendedPerfMetricsData(requestBody) {
        return myServicesData.postFetchExtendedPerfMetrics(requestBody);
    },
    // Availability Machines
    getAvailabilityMachinesServicesData() {
        return availabilityMachinesData.getAvailabilityMachinesServices();
    },
    getAvailabilityMachinesDmmServicesData(id) {
        return availabilityMachinesData.getAvailabilityMachinesDmmServices(id);
    },
    getAvailabilityMachinesSlasServicesData() {
        return availabilityMachinesData.getAvailabilityMachinesSlasServices();
    },
    getAvailabilityMachinesSnapshotsAutomatedFalseServicesData(id) {
        return availabilityMachinesData.getAvailabilityMachinesSnapshotsAutomatedFalseServices(id);
    },
    getAvailabilityMachinesSnapshotsManualFalseServicesData(id) {
        return availabilityMachinesData.getAvailabilityMachinesSnapshotsManualFalseServices(id);
    },
    getAvailabilityMachinesSlaServicesData(id) {
        return availabilityMachinesData.getAvailabilityMachinesSlaServices(id);
    },
    getAvailabilityMachinesSanitizationSchedulesServicesData(id) {
        return availabilityMachinesData.getAvailabilityMachinesSanitizationSchedulesServices(id);
    },
    getAvailabilityMachinesSanitizatiedSnapshotsServicesData(id) {
        return availabilityMachinesData.getAvailabilityMachinesSanitizatiedSnapshotsServices(id);
    },
    getAvailabilityMachinesAccessPoliciesServicesData(id) {
        return availabilityMachinesData.getAvailabilityMachinesAccessPoliciesServices(id);
    },
    getAvailabilityMachinesAllSnapshotsServicesData(id) {
        return availabilityMachinesData.getAvailabilityMachinesAllSnapshotsServices(id);
    },
    getAvailabilityMachinesBackupsServicesData(id) {
        return availabilityMachinesData.getAvailabilityMachinesBackupsServices(id);
    },
    getTaskUpdateAvailabilityMachinesData() {
        return availabilityMachinesData.getTaskUpdateAvailabilityMachinesServices();
    },
    getTaskUpdateSanitizedCreateData() {
        return availabilityMachinesData.getTaskUpdateSanitizedCreateServices();
    },
    getTaskUpdateBackupsCreateData() {
        return availabilityMachinesData.getTaskUpdateBackupsCreateServices();
    },
    postManualSnapshotsData(requestBody, id) {
        return availabilityMachinesData.postManualSnapshots(requestBody, id);
    },
    postSanitizedSnapshotsData(requestBody, id) {
        return availabilityMachinesData.postSanitizedSnapshots(requestBody, id);
    },
    postBackupsData(requestBody, id) {
        return availabilityMachinesData.postBackup(requestBody, id);
    },
    postAccessPolicyData(requestBody, id) {
        return availabilityMachinesData.postAccessPolicy(requestBody, id);
    },
    // Dataflix
    getDataflixData() {
        return dataflixData.getDataflix();
    },
    getDataflixDetailsData(id) {
        return dataflixData.getDataflixDetails(id);
    },
    getDataflixRecoveryInfoData(id, recoveryTime, snapshotId) {
        return dataflixData.getDataflixRecoveryInfoDetails(id, recoveryTime, snapshotId);
    },
    getDataflixBackupData(id) {
        return dataflixData.getDataflixBackupDetails(id);
    },
    getDataflixCatalogData(id) {
        return dataflixData.getDataflixCatalogDetails(id);
    },
    getDataflixSanitizedData(id) {
        return dataflixData.getDataflixSanitizedDetails(id);
    },
    // Scripts Library
    getScripts() {
        return scriptLibraryData.getScripts();
    },
    getVersions(id) {
        return scriptLibraryData.getVersions(id);
    },
    getScriptById(id) {
        return scriptLibraryData.getScriptById(id);
    },
    // Benchmarks
    getBenchmarksCatalog() {
        return benchmarksData.getBenchmarksCatalog();
    },
    getBenchmarks() {
        return benchmarksData.getBenchmarks();
    },
    // Servers
    getDbServersListData() {
        return serversData.getDbServersListData();
    },
    getDbServerDetailData(id) {
        return serversData.getDbServerDetailData(id);
    },
    getDbServersSharingEnabledData() {
        return serversData.getDbServersSharingEnabledData();
    },
};

export default DbServices;
