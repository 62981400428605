import MockRepository from 'api-clients/network-layer/demo-data/repository/MockRepository';

const ticketsData = MockRepository.getTicketsData();

const Tickets = {
    getTicketsDetails() {
        return ticketsData.getTickets();
    },
    getConversationByTicketIdDetails(id) {
        return ticketsData.getConversationByTicketId(id);
    },
    getTicketByIdDetails(id) {
        return ticketsData.getTicketById(id);
    },
};

export default Tickets;
