export default function WarningErrorIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="16"
                height="16"
                fill="white"
                fillOpacity="0.01"
                // eslint-disable-next-line react/style-prop-object
                // style="mix-blend-mode:multiply"
            />
            <path
                d="M8 11.5C7.85167 11.5 7.70666 11.544 7.58333 11.6264C7.45999 11.7088 7.36386 11.826 7.30709 11.963C7.25033 12.1 7.23548 12.2508 7.26441 12.3963C7.29335 12.5418 7.36478 12.6754 7.46967 12.7803C7.57456 12.8852 7.7082 12.9567 7.85369 12.9856C7.99917 13.0145 8.14997 12.9997 8.28702 12.9429C8.42406 12.8862 8.54119 12.79 8.62361 12.6667C8.70602 12.5433 8.75 12.3983 8.75 12.25C8.75 12.0511 8.67099 11.8603 8.53033 11.7197C8.38968 11.579 8.19892 11.5 8 11.5Z"
                fill="#F29132"
            />
            <path d="M8.5 6.00001H7.5V10.5H8.5V6.00001Z" fill="#F29132" />
            <path
                d="M14.5 15H1.5C1.4141 15 1.32965 14.9779 1.25478 14.9357C1.17992 14.8936 1.11718 14.8329 1.0726 14.7595C1.02802 14.686 1.00311 14.6024 1.00027 14.5165C0.997436 14.4307 1.01677 14.3455 1.0564 14.2693L7.5564 1.76931C7.59862 1.68812 7.66231 1.62008 7.74053 1.5726C7.81875 1.52511 7.9085 1.5 8 1.5C8.09151 1.5 8.18126 1.52511 8.25948 1.5726C8.3377 1.62008 8.40138 1.68812 8.4436 1.76931L14.9436 14.2693C14.9832 14.3455 15.0026 14.4307 14.9997 14.5165C14.9969 14.6024 14.972 14.686 14.9274 14.7595C14.8828 14.8329 14.8201 14.8936 14.7452 14.9357C14.6704 14.9779 14.5859 15 14.5 15ZM2.32535 14H13.6747L13.6757 13.9984L8.001 3.08571H7.999L2.32435 13.9984L2.32535 14Z"
                fill="#F29132"
            />
        </svg>
    );
}
