/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function SharingIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 68 75"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M51.5 43C46.75 43 42.75 45 39.75 48.25L28.25 41.5C28.75 40 29 38.5 29 37C29 35.75 28.75 34.75 28.5 33.75L39.5 27.25C42.5 30.5 46.75 32.5 51.25 32.5C60 32.5 67.25 25.25 67.25 16.5C67.25 7.75 60.25 0 51.5 0C42.75 0 35.5 7.25 35.5 16C35.5 17.5 35.75 18.75 36 20.25L25 26.75C22.25 24 18.75 22.25 14.5 22.25C6.5 22.25 0 28.75 0 36.75C0 44.75 6.5 51.25 14.5 51.25C18.25 51.25 21.5 49.75 24 47.5L36 54.75C35.5 56 35.5 57.5 35.5 59C35.5 67.75 42.75 75 51.5 75C60.25 75 67.5 67.75 67.5 59C67.5 50.25 60.25 43 51.5 43ZM51.5 7.5C56.25 7.5 60 11.25 60 16C60 20.75 56.25 24.5 51.5 24.5C46.75 24.5 43 20.75 43 16C43 11.25 46.75 7.5 51.5 7.5ZM14.5 43.75C10.75 43.75 7.5 40.5 7.5 36.75C7.5 33 10.75 29.75 14.5 29.75C18.25 29.75 21.5 33 21.5 36.75C21.5 40.5 18.5 43.75 14.5 43.75ZM51.5 67.5C46.75 67.5 43 63.75 43 59C43 54.25 46.75 50.5 51.5 50.5C56.25 50.5 60 54.25 60 59C60 63.75 56.25 67.5 51.5 67.5Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
