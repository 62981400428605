import {
    mockGetMap as mockGetMapGeneralApis,
    mockPostMap as mockPostMapGeneralApis,
} from './GeneralApis/generalapis';

import {
    mockGetMap as mockGetMapDbServices,
    mockPostMap as mockPostMapDbServices,
} from './DbServices/dbservices';

import {
    mockGetMap as mockGetMapGovernanceSubscriptions,
    mockPostMap as mockPostMapGovernanceSubscriptions,
    mockPutMap as mockPutMapGovernanceSubscriptions,
    mockPatchMap as mockPatchMapGovernanceSubscriptions,
} from './Governance/subscriptions';
import { mockGetMap as mockGetMapGovernanceComputes } from './Governance/computes';
import {
    mockGetMap as mockGetMapGovernanceDbGovernance,
    mockPostMap as mockPostMapGovernanceDbGovernance,
} from './Governance/dbgovernance';
import {
    mockGetMap as mockGetMapGovernanceIam,
    mockPostMap as mockPostMapGovernanceIam,
} from './Governance/iam';
import {
    mockGetMap as mockGetMapGovernanceSecurity,
    mockPostMap as mockPostMapGovernanceSecurity,
    mockPatchMap as mockPatchMapGovernanceSecurity,
} from './Governance/security';
import {
    mockGetMap as mockGetMapGovernanceNetwork,
    mockPostMap as mockPostMapGovernanceNetwork,
} from './Governance/network';

import { mockGetMap as mockGetMapSupportTickets } from './Support/tickets';

import { mockGetMap as mockGetMapCostBilling } from './Cost/cost';

export const mockGetMap = {
    ...mockGetMapGeneralApis,

    ...mockGetMapGovernanceSubscriptions,
    ...mockGetMapGovernanceComputes,
    ...mockGetMapGovernanceDbGovernance,
    ...mockGetMapGovernanceIam,
    ...mockGetMapGovernanceSecurity,
    ...mockGetMapGovernanceNetwork,

    ...mockGetMapSupportTickets,
    ...mockGetMapCostBilling,

    // Keeping Db Services last as it has maximum regex and overlaps with someone above
    ...mockGetMapDbServices,
};

export const mockPostMap = {
    ...mockPostMapGeneralApis,
    ...mockPostMapDbServices,
    ...mockPostMapGovernanceSubscriptions,
    ...mockPostMapGovernanceDbGovernance,
    ...mockPostMapGovernanceIam,
    ...mockPostMapGovernanceSecurity,
    ...mockPostMapGovernanceNetwork,
};

export const mockPutMap = {
    ...mockPutMapGovernanceSubscriptions,
};

export const mockPatchMap = {
    ...mockPatchMapGovernanceSubscriptions,
    ...mockPatchMapGovernanceSecurity,
};
