import AvailabilityMachines from './availabilitymachines.json';
import AvailabilityMachinesDmm from './availabilitymachinesdmm.json';
import AvailabilityMachinesAllSlas from './availabilitymachinesallslas.json';
import AvailabilityMachinesSla from './availabilitymachinesla.json';
import AvailabilityMachinesSnapshotsAutomatedFalse from './snapshots-automated=false.json';
import AvailabilityMachinesSnapshotsManualFalse from './snapshots-manual=false.json';
import AvailabilityMachinesAccessPolicies from './availabilitymachines-accesspolices.json';
import AvailabilityMachinesSanitizationSchedules from './availabilitymachines-sanitization-schedules.json';
import AvailabilityMachinesSanitizatiedSnapshots from './availabilitymachines-sanitized-snapshots.json';
import AvailabilityMachinesAllSnapshots from './availabilitymachines-snapshots.json';
import AvailabilityMachinesBackups from './availabilitymachine-backups-automated=false.json';
import TaskUpdateAvailabilityMachines from './task-update-snapshotcreate.json';
import TaskUpdateSanitizedCreate from './task-update-sanitizedcreate.json';
import TaskUpdateBackupsCreate from './tas-update-backupscreate.json';

import scriptsJson from '../../scriptlibrary/default/scripts.json';

import SampleAutoSnapshot from './SampleAutoSnapshot.json';
import Cache from 'tools/Cache';
import replaceTimestampWithMockDate from 'tools/MockTime';

import {
    createdManualSnapshot,
    createdSanitizedSnapshot,
    createdBackup,
    createAccessPolicy,
    setInSessionStorage,
    generateSnapshotsData,
    setInCacheStorage,
    checkIfUserExists,
} from '../utils';
import { personasEnum } from 'api-clients/network-layer/demo-data/repository/DbServices/myservices/default/myserviceutil';

// replacing date with Mock Date
AvailabilityMachines = replaceTimestampWithMockDate(AvailabilityMachines);
AvailabilityMachinesDmm = replaceTimestampWithMockDate(AvailabilityMachinesDmm);
AvailabilityMachinesAllSlas = replaceTimestampWithMockDate(AvailabilityMachinesAllSlas);
AvailabilityMachinesSla = replaceTimestampWithMockDate(AvailabilityMachinesSla);
AvailabilityMachinesSnapshotsAutomatedFalse = replaceTimestampWithMockDate(
    AvailabilityMachinesSnapshotsAutomatedFalse,
);
AvailabilityMachinesSnapshotsManualFalse = replaceTimestampWithMockDate(
    AvailabilityMachinesSnapshotsManualFalse,
);
AvailabilityMachinesAccessPolicies = replaceTimestampWithMockDate(
    AvailabilityMachinesAccessPolicies,
);
AvailabilityMachinesSanitizationSchedules = replaceTimestampWithMockDate(
    AvailabilityMachinesSanitizationSchedules,
);
AvailabilityMachinesSanitizatiedSnapshots = replaceTimestampWithMockDate(
    AvailabilityMachinesSanitizatiedSnapshots,
);
AvailabilityMachinesAllSnapshots = replaceTimestampWithMockDate(AvailabilityMachinesAllSnapshots);
AvailabilityMachinesBackups = replaceTimestampWithMockDate(AvailabilityMachinesBackups);
TaskUpdateAvailabilityMachines = replaceTimestampWithMockDate(TaskUpdateAvailabilityMachines);
TaskUpdateSanitizedCreate = replaceTimestampWithMockDate(TaskUpdateSanitizedCreate);
TaskUpdateBackupsCreate = replaceTimestampWithMockDate(TaskUpdateBackupsCreate);

export default class DefaultAvailabilityMachinesLoader {
    // eslint-disable-next-line class-methods-use-this
    initAvailabilityMachinesServices() {
        if (Cache.get('persona') === personasEnum.DATA_OWNER) {
            return {
                ...AvailabilityMachines[personasEnum.DATA_OWNER],
                response: AvailabilityMachines[personasEnum.DATA_OWNER].response.filter(
                    (el) => el.owner === Cache.get('email'),
                ),
            };
        }

        return {
            ...AvailabilityMachines[personasEnum.ACCOUNT_OWNER],
            response: [
                ...AvailabilityMachines[personasEnum.ACCOUNT_OWNER].response,
                ...AvailabilityMachines[personasEnum.DATA_OWNER].response,
            ],
        };
    }

    // eslint-disable-next-line class-methods-use-this
    initAvailabilityMachinesDmmServices(id) {
        const availabilityMachinesDmmData = AvailabilityMachinesDmm[id];

        const accessPoliciesData = AvailabilityMachinesAccessPolicies[id];

        const newAvailabilityMachinesDmm = localStorage.getItem('demo-access-policies')
            ? JSON.parse(localStorage.getItem('demo-access-policies') ?? {})
            : { [id]: [] };

        if (newAvailabilityMachinesDmm[id]) {
            newAvailabilityMachinesDmm[id] = newAvailabilityMachinesDmm[id].filter(
                (avMachine) =>
                    avMachine.owner === Cache.get('email') ||
                    checkIfUserExists(avMachine.sharedWith.users, Cache.get('email')),
            );
        }

        return {
            ...availabilityMachinesDmmData,
            daps: [
                ...new Set([
                    ...availabilityMachinesDmmData.daps,
                    ...accessPoliciesData.response,
                    ...(newAvailabilityMachinesDmm[id] ?? []),
                ]),
            ],
        };
    }

    // eslint-disable-next-line class-methods-use-this
    initAvailabilityMachinesSlasServices() {
        return AvailabilityMachinesAllSlas;
    }

    // eslint-disable-next-line class-methods-use-this
    initAvailabilityMachinesSnapshotsAutomatedFalseServices(id) {
        const manualSnapshots = AvailabilityMachinesSnapshotsAutomatedFalse[id];

        const newManualSnapshots = sessionStorage.getItem('demo-manual-snapshots')
            ? JSON.parse(sessionStorage.getItem('demo-manual-snapshots') ?? {})
            : { [id]: [] };

        return {
            ...manualSnapshots,
            snapshots: [...manualSnapshots.snapshots, ...(newManualSnapshots[id] ?? [])],
        };
    }

    // eslint-disable-next-line class-methods-use-this
    initAvailabilityMachinesSnapshotsManualFalseServices(id) {
        let availabilityMachineSnapshotData = AvailabilityMachinesSnapshotsManualFalse[id];

        // for generating automatic snapshots.
        const generatedSnapshots = generateSnapshotsData(SampleAutoSnapshot);

        if (availabilityMachineSnapshotData && generatedSnapshots) {
            // add generatedSnapshots to response.
            availabilityMachineSnapshotData = {
                ...availabilityMachineSnapshotData,
                snapshots: [...availabilityMachineSnapshotData.snapshots, ...generatedSnapshots],
            };
        }

        return availabilityMachineSnapshotData;
    }

    // eslint-disable-next-line class-methods-use-this
    initAvailabilityMachinesSlaServices(id) {
        return AvailabilityMachinesSla[id];
    }

    // eslint-disable-next-line class-methods-use-this
    initAvailabilityMachinesSanitizationSchedulesServices(id) {
        return AvailabilityMachinesSanitizationSchedules[id];
    }

    // eslint-disable-next-line class-methods-use-this
    initAvailabilityMachinesSanitizatiedSnapshotsServices(id) {
        const sanitizedSnapshots = AvailabilityMachinesSanitizatiedSnapshots[id];

        const newSanitizedSnapshots = sessionStorage.getItem('demo-sanitized-snapshots')
            ? JSON.parse(sessionStorage.getItem('demo-sanitized-snapshots') ?? {})
            : { [id]: [] };

        return {
            ...sanitizedSnapshots,
            snapshots: [
                ...(sanitizedSnapshots?.snapshots ?? []),
                ...(newSanitizedSnapshots[id] ?? []),
            ],
        };
    }

    // eslint-disable-next-line class-methods-use-this
    initAvailabilityMachinesAccessPoliciesServices(id) {
        const accessPoliciesData = AvailabilityMachinesAccessPolicies[id];

        const newAccessPolicies = localStorage.getItem('demo-access-policies')
            ? JSON.parse(localStorage.getItem('demo-access-policies') ?? {})
            : { [id]: [] };

        if (newAccessPolicies[id]) {
            newAccessPolicies[id] = newAccessPolicies[id].filter(
                (avMachine) =>
                    avMachine.owner === Cache.get('email') ||
                    checkIfUserExists(avMachine.sharedWith.users, Cache.get('email')),
            );
        }

        return {
            ...accessPoliciesData,
            response: [...accessPoliciesData.response, ...(newAccessPolicies[id] ?? [])],
        };
    }

    // eslint-disable-next-line class-methods-use-this
    initAvailabilityMachinesAllSnapshotsServices(id) {
        return AvailabilityMachinesAllSnapshots[id];
    }

    // eslint-disable-next-line class-methods-use-this
    initAvailabilityMachinesBackupsServices(id) {
        const backupsData = AvailabilityMachinesBackups[id];

        const newBackups = sessionStorage.getItem('demo-backups')
            ? JSON.parse(sessionStorage.getItem('demo-backups') ?? {})
            : { [id]: [] };

        return {
            ...backupsData,
            backups: [...(backupsData?.backups ?? []), ...(newBackups[id] ?? [])],
        };
    }

    // eslint-disable-next-line class-methods-use-this
    initTaskUpdateAvailabilityMachines() {
        return TaskUpdateAvailabilityMachines;
    }

    // eslint-disable-next-line class-methods-use-this
    initTaskUpdateSanitizedCreate() {
        return TaskUpdateSanitizedCreate;
    }

    // eslint-disable-next-line class-methods-use-this
    initTaskUpdateBackupsCreate() {
        return TaskUpdateBackupsCreate;
    }

    // eslint-disable-next-line class-methods-use-this
    createManualSnapshots(requestBody, id) {
        const newCreatedManualSnapshot = createdManualSnapshot(JSON.parse(requestBody));
        const oldManualSnapshots = sessionStorage.getItem('demo-manual-snapshots')
            ? JSON.parse(sessionStorage.getItem('demo-manual-snapshots'))
            : {};

        if (oldManualSnapshots && oldManualSnapshots[id]) {
            sessionStorage.setItem(
                'demo-manual-snapshots',
                JSON.stringify({
                    ...oldManualSnapshots,
                    [id]: [...oldManualSnapshots[id], newCreatedManualSnapshot],
                }),
            );
        } else {
            sessionStorage.setItem(
                'demo-manual-snapshots',
                JSON.stringify({ [id]: [newCreatedManualSnapshot] }),
            );
        }
        return newCreatedManualSnapshot;
    }

    // eslint-disable-next-line class-methods-use-this
    createSanitizedSnapshots(requestBody, id) {
        return setInSessionStorage(
            requestBody,
            id,
            createdSanitizedSnapshot,
            'demo-sanitized-snapshots',
            scriptsJson,
        );
    }

    // eslint-disable-next-line class-methods-use-this
    createBackups(requestBody, id) {
        return setInSessionStorage(
            requestBody,
            id,
            createdBackup,
            'demo-backups',
            AvailabilityMachinesAllSnapshots,
        );
    }

    // eslint-disable-next-line class-methods-use-this
    createAccessPolicy(requestBody, id) {
        return setInCacheStorage(
            requestBody,
            id,
            createAccessPolicy,
            'demo-access-policies',
            AvailabilityMachines[
                Cache.get('persona') === personasEnum.DATA_OWNER
                    ? personasEnum.DATA_OWNER
                    : personasEnum.ACCOUNT_OWNER
            ],
        );
    }
}
