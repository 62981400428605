import MockUtil from 'tools/MockUtil';
import MockRepository from 'api-clients/network-layer/demo-data/repository/MockRepository';

const billingData = MockRepository.getBillingData();

const Billing = {
    getRatesDetails() {
        return billingData.getRates();
    },

    getTotalCreditsDetails() {
        return billingData.getTotalCredits();
    },

    getMetersResDetails(start, end) {
        return billingData.getMetersRes(start, end);
    },

    getMetersResShortDurationDetails(start, end, dbId, cycle) {
        return MockUtil.getResponse(
            billingData.getMetersResShortDuration(start, end, dbId, cycle),
            0,
        );
    },

    getDefaultPaymentMethodDetails() {
        return billingData.getDefaultPaymentMethod();
    },

    getBillsSystemGeneratedTrueDetails() {
        return billingData.getBillsSystemGeneratedTrue();
    },

    getBillingProfileDetails() {
        return billingData.getBillingProfile();
    },

    getInvoicesDetails() {
        return billingData.getInvoices();
    },
};

export default Billing;
