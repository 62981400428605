/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */
import clsx from 'clsx';
import { useStyles } from './utils';

export default function StartingIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.1183 12.1025C18.9238 12.9306 18.6191 13.7288 18.2125 14.4759"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.5117 16.6924C15.8957 17.279 15.2084 17.7858 14.4658 18.2007"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.8275 0.62207C11.6749 0.697143 12.508 0.888379 13.3033 1.1904"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.74 2.55371C16.4147 3.0718 17.0126 3.68288 17.5158 4.36871"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.8333 6.83301C19.1238 7.63261 19.2962 8.47032 19.345 9.31967"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.875 19.1426C10.6685 19.3888 9.42527 19.395 8.21636 19.1608C7.00745 18.9265 5.85652 18.4565 4.82927 17.7774C2.75465 16.4061 1.30977 14.2668 0.812489 11.8301C0.315209 9.3934 0.806261 6.85898 2.17762 4.78436C3.54898 2.70974 5.68831 1.26486 8.12499 0.767578"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.75128 7.0894C7.67512 7.03456 7.58441 7.00354 7.49062 7.00029C7.39682 6.99703 7.30417 7.02168 7.2244 7.07111C7.14463 7.12055 7.08132 7.19254 7.04249 7.27798C7.00366 7.36342 6.99106 7.45846 7.00628 7.55107V13.3844C6.99126 13.4769 7.00401 13.5719 7.04292 13.6572C7.08183 13.7424 7.14515 13.8143 7.22488 13.8636C7.30461 13.9129 7.39717 13.9375 7.49087 13.9342C7.58456 13.931 7.67518 13.9 7.75128 13.8452L13.7613 10.8402C14.1704 10.6352 14.1704 10.3002 13.7613 10.0952L7.75128 7.0894Z"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
