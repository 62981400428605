/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function HomeIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.7638 10.749L12.2586 0.298729C12.0623 0.107252 11.7983 0 11.5233 0C11.2483 0 10.9843 0.107252 10.7879 0.298729L0.282725 10.749C0.14733 10.8933 0.0557551 11.0727 0.0185955 11.2665C-0.0185641 11.4604 0.000210483 11.6607 0.0727472 11.8444C0.145284 12.0281 0.268618 12.1876 0.428478 12.3044C0.588338 12.4213 0.77819 12.4907 0.976068 12.5047H2.2577V22.955C2.2577 23.2321 2.36838 23.4979 2.56539 23.6939C2.7624 23.8899 3.02961 24 3.30822 24H9.19113V16.9878C9.19113 16.7107 9.30181 16.4449 9.49882 16.2489C9.69583 16.0529 9.96304 15.9428 10.2417 15.9428H12.7839C13.0625 15.9428 13.3297 16.0529 13.5267 16.2489C13.7238 16.4449 13.8344 16.7107 13.8344 16.9878V23.9895H19.7173C19.996 23.9895 20.2632 23.8794 20.4602 23.6835C20.6572 23.4875 20.7679 23.2217 20.7679 22.9445V12.4942H22.102C22.2952 12.4735 22.4788 12.3999 22.6325 12.2816C22.7861 12.1633 22.9038 12.0049 22.9724 11.8241C23.041 11.6432 23.0578 11.447 23.021 11.2572C22.9842 11.0674 22.8951 10.8915 22.7638 10.749Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
