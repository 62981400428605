/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */
import clsx from 'clsx';
import { useStyles } from './utils';

export default function FailedIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.0927 7.34355V2.61129C16.0927 1.9579 15.5631 1.42822 14.9097 1.42822H1.89596C1.24257 1.42822 0.712891 1.9579 0.712891 2.61129V7.34355C0.712891 7.99694 1.24257 8.52661 1.89596 8.52661H9"
                stroke="#EC373C"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.26243 5.5693C4.58913 5.5693 4.85396 5.30446 4.85396 4.97776C4.85396 4.65107 4.58913 4.38623 4.26243 4.38623C3.93574 4.38623 3.6709 4.65107 3.6709 4.97776C3.6709 5.30446 3.93574 5.5693 4.26243 5.5693Z"
                stroke="#EC373C"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.99268 4.97705H13.1334"
                stroke="#EC373C"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.89596 8.52734C1.58219 8.52734 1.28127 8.65199 1.0594 8.87386C0.837535 9.09572 0.712891 9.39664 0.712891 9.71041V14.4427C0.712891 14.7564 0.837535 15.0574 1.0594 15.2792C1.28127 15.5011 1.58219 15.6257 1.89596 15.6257H7"
                stroke="#EC373C"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.26243 12.667C4.58913 12.667 4.85396 12.4021 4.85396 12.0754C4.85396 11.7487 4.58913 11.4839 4.26243 11.4839C3.93574 11.4839 3.6709 11.7487 3.6709 12.0754C3.6709 12.4021 3.93574 12.667 4.26243 12.667Z"
                stroke="#EC373C"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.99268 12.0762H13.1334"
                stroke="#EC373C"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="13.75" cy="13.75" r="6.25" fill="white" />
            <path
                d="M13.75 8.75C10.9886 8.75 8.75 10.9885 8.75 13.75C8.75 16.5114 10.9886 18.75 13.75 18.75C16.5114 18.75 18.75 16.5114 18.75 13.75C18.75 10.9885 16.5114 8.75 13.75 8.75Z"
                stroke="#EC373C"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.75 11.25V13.75"
                stroke="#EC373C"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.75 15.625C13.9226 15.625 14.0625 15.7648 14.0625 15.9375C14.0625 16.1101 13.9226 16.25 13.75 16.25"
                stroke="#EC373C"
            />
            <path
                d="M13.75 15.625C13.5774 15.625 13.4375 15.7648 13.4375 15.9375C13.4375 16.1101 13.5774 16.25 13.75 16.25"
                stroke="#EC373C"
            />
        </svg>
    );
}
