export default function TimelineStopIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1879_2160)">
                <path
                    d="M8.00004 15.0001C11.8661 15.0001 15.0001 11.8661 15.0001 8.00004C15.0001 4.13402 11.8661 1 8.00004 1C4.13402 1 1 4.13402 1 8.00004C1 11.8661 4.13402 15.0001 8.00004 15.0001Z"
                    fill="#F29132"
                    stroke="#F29132"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.61533 5.30762H6.38455C5.78977 5.30762 5.30762 5.78977 5.30762 6.38455V9.61533C5.30762 10.2101 5.78977 10.6923 6.38455 10.6923H9.61533C10.2101 10.6923 10.6923 10.2101 10.6923 9.61533V6.38455C10.6923 5.78977 10.2101 5.30762 9.61533 5.30762Z"
                    fill="white"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1879_2160">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
