import SoftwareImageJson from './software-images.json';
import ParameterProfileJson from './parameter-profile.json';
import ParameterProfileValidationsJson from './parameter-profile-validations.json';
import OptionsProfileJson from './options-profile.json';
import OptionsProfileLoadAclsTrueJson from './options-profiles-load-acls=true.json';
import SLAsJson from './slas.json';
import AdDomainsJson from './ad-domains.json';
import SampleSLA from './SampleSLA.json';
import LicensesJson from './licenses.json';
import replaceTimestampWithMockDate from 'tools/MockTime';

// replacing date with Mock Date
SoftwareImageJson = replaceTimestampWithMockDate(SoftwareImageJson);
ParameterProfileJson = replaceTimestampWithMockDate(ParameterProfileJson);
OptionsProfileJson = replaceTimestampWithMockDate(OptionsProfileJson);
OptionsProfileLoadAclsTrueJson = replaceTimestampWithMockDate(OptionsProfileLoadAclsTrueJson);
SLAsJson = replaceTimestampWithMockDate(SLAsJson);
AdDomainsJson = replaceTimestampWithMockDate(AdDomainsJson);
SampleSLA = replaceTimestampWithMockDate(SampleSLA);
LicensesJson = replaceTimestampWithMockDate(LicensesJson);

const modifySlaRequest = (reqBody, sampleSlaJson) => {
    const newObj = sampleSlaJson;
    newObj.name = reqBody.name;
    newObj.description = reqBody.description;
    newObj.retentionInfo = reqBody.retentionInfo;
    newObj.scope = reqBody.scope;

    return newObj;
};

export default class UBSDbGovernanceLoader {
    // eslint-disable-next-line class-methods-use-this
    initSoftwareImages() {
        return SoftwareImageJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initParameterProfiles() {
        return ParameterProfileJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initParameterProfilesValidations() {
        return ParameterProfileValidationsJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initOptionsProfiles() {
        return OptionsProfileJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initOptionsProfilesLoadAclsTrue() {
        return OptionsProfileLoadAclsTrueJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initSLAs() {
        const SLANewlyProvisioned = JSON.parse(sessionStorage.getItem('demo-SLAs'));

        if (!SLANewlyProvisioned) {
            return SLAsJson;
        }

        return {
            ...SLAsJson,
            response: [...SLAsJson.response, ...SLANewlyProvisioned],
        };
    }

    // eslint-disable-next-line class-methods-use-this
    initAdDomains() {
        return AdDomainsJson;
    }

    // eslint-disable-next-line class-methods-use-this
    createCustomSLA(body) {
        const createdSLA = modifySlaRequest(JSON.parse(body), SampleSLA);

        if (sessionStorage.getItem('demo-SLAs')) {
            const oldSLAs = JSON.parse(sessionStorage.getItem('demo-SLAs'));
            sessionStorage.setItem('demo-SLAs', JSON.stringify([...oldSLAs, createdSLA]));
        } else {
            sessionStorage.setItem('demo-SLAs', JSON.stringify([createdSLA]));
        }
        return SLAsJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initLicenses() {
        return LicensesJson;
    }
}
