import scriptsJson from './scripts.json';
import versionsJson from './versions.json';
import scriptByIdJson from './scriptById.json';
import replaceTimestampWithMockDate from 'tools/MockTime';

// replacing date with Mock Date
scriptsJson = replaceTimestampWithMockDate(scriptsJson);
versionsJson = replaceTimestampWithMockDate(versionsJson);
scriptByIdJson = replaceTimestampWithMockDate(scriptByIdJson);

export default class DefaultScriptLibraryLoader {
    // eslint-disable-next-line class-methods-use-this
    initScripts() {
        return scriptsJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initVersions(id) {
        return versionsJson[id];
    }

    // eslint-disable-next-line class-methods-use-this
    initScriptById(id) {
        return scriptByIdJson[id];
    }
}
