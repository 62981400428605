/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function GovernanceIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 24 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.9569 1.16797L1.48316 7.69545C1.05654 7.96133 1.24493 8.61979 1.74761 8.61979H22.3072C22.8054 8.61979 22.9971 7.97073 22.5788 7.70003L12.493 1.17255C12.3303 1.06724 12.1214 1.06545 11.9569 1.16797Z"
                stroke="var(--icon-color)"
            />
            <path
                d="M22.1775 25L2.44162 25C1.88934 25 1.44162 24.5523 1.44162 24L1.44162 23.6289C1.44162 23.0766 1.88934 22.6289 2.44162 22.6289L22.1775 22.6289C22.7298 22.6289 23.1775 23.0766 23.1775 23.6289L23.1775 24C23.1775 24.5523 22.7298 25 22.1775 25Z"
                stroke="var(--icon-color)"
            />
            <path
                d="M10.8679 19.5V11.5C10.8679 10.9477 11.3156 10.5 11.8679 10.5H12.5849C13.1372 10.5 13.5849 10.9477 13.5849 11.5V19.5C13.5849 20.0523 13.1372 20.5 12.5849 20.5H11.8679C11.3156 20.5 10.8679 20.0523 10.8679 19.5Z"
                stroke="var(--icon-color)"
            />
            <path
                d="M17.2076 19.5V11.5C17.2076 10.9477 17.6553 10.5 18.2076 10.5H18.9245C19.4768 10.5 19.9245 10.9477 19.9245 11.5V19.5C19.9245 20.0523 19.4768 20.5 18.9245 20.5H18.2075C17.6553 20.5 17.2076 20.0523 17.2076 19.5Z"
                stroke="var(--icon-color)"
            />
            <path
                d="M4.52829 19.5V11.5C4.52829 10.9477 4.97601 10.5 5.52829 10.5H6.24527C6.79756 10.5 7.24527 10.9477 7.24527 11.5V19.5C7.24527 20.0523 6.79756 20.5 6.24527 20.5H5.52829C4.976 20.5 4.52829 20.0523 4.52829 19.5Z"
                stroke="var(--icon-color)"
            />
        </svg>
    );
}
