export default function ResizeStorageIcon() {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="40" height="40" rx="20" fill="#F2FAFE" />
            <g clipPath="url(#clip0_2827_4345)">
                <path
                    d="M19.8929 16.9464C26.0469 16.9464 31.0357 15.0276 31.0357 12.6607C31.0357 10.2938 26.0469 8.375 19.8929 8.375C13.7388 8.375 8.75 10.2938 8.75 12.6607C8.75 15.0276 13.7388 16.9464 19.8929 16.9464Z"
                    fill="#3986C1"
                />
                <path
                    d="M31.0358 14.2656C31.0358 16.6326 26.0469 18.5513 19.8929 18.5513C13.7388 18.5513 8.75 16.6326 8.75 14.2656V19.6249C8.75 21.9906 13.7386 23.9106 19.8929 23.9106C26.0472 23.9106 31.0358 21.9906 31.0358 19.6249V14.2656Z"
                    fill="#3986C1"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.75 21.125C8.75176 23.4901 13.7397 25.4095 19.8929 25.4095C26.0461 25.4095 31.034 23.4901 31.0357 21.125V27.2321C31.0357 29.5979 26.0471 31.5179 19.8929 31.5179C13.7386 31.5179 8.75 29.5979 8.75 27.2321V21.125H8.75Z"
                    fill="#3986C1"
                />
            </g>
            <defs>
                <clipPath id="clip0_2827_4345">
                    <rect width="24" height="24" fill="white" transform="translate(8 8)" />
                </clipPath>
            </defs>
        </svg>
    );
}
