import clsx from 'clsx';
import { useStyles } from './utils';

export default function FetchingIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);
    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.3033 4.6967L14.1247 5.87521C13.0692 4.81958 11.6108 4.16667 10 4.16667C6.77834 4.16667 4.16667 6.77834 4.16667 10C4.16667 13.2217 6.77834 15.8333 10 15.8333C13.2217 15.8333 15.8333 13.2217 15.8333 10H17.5C17.5 14.1422 14.1422 17.5 10 17.5C5.85787 17.5 2.5 14.1422 2.5 10C2.5 5.85787 5.85787 2.5 10 2.5C12.0711 2.5 13.9461 3.33947 15.3033 4.6967Z"
                fill="#11567F"
            />
        </svg>
    );
}
