import MockUtil from 'tools/MockUtil';
import MockRepository from 'api-clients/network-layer/demo-data/repository/MockRepository';

const securityData = MockRepository.getSecurityData();

const Security = {
    getEligibleEncryptionKeysDetails() {
        return securityData.getEligibleEncryptionKeys();
    },

    getEncryptionKeysDetails() {
        return securityData.getEncryptionKeys();
    },

    getPasswordPoliciesDetails() {
        return securityData.getPasswordPolicies();
    },

    getKeyVaultSubscriptionDetails() {
        return securityData.getKeyVaultSubscription();
    },

    createPasswordPolicies(body) {
        return securityData.createPasswordPolicies(body);
    },

    createEncryptionKey(body) {
        return securityData.createEncryptionKey(body);
    },

    registerEncryptionKey() {
        return {};
    },
};

export default Security;
