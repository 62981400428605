/* eslint-disable react/react-in-jsx-scope */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function DBOutlinedIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_255_2)">
                <path
                    d="M10.0001 7.85721C15.1284 7.85721 19.2858 6.25823 19.2858 4.28578C19.2858 2.31334 15.1284 0.714355 10.0001 0.714355C4.87171 0.714355 0.714355 2.31334 0.714355 4.28578C0.714355 6.25823 4.87171 7.85721 10.0001 7.85721Z"
                    stroke="#1E7EA1"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M0.714355 4.28589V15.7145C0.714355 17.6859 4.8715 19.2859 10.0001 19.2859C15.1286 19.2859 19.2858 17.6859 19.2858 15.7145V4.28589"
                    stroke="#1E7EA1"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.2858 10C19.2858 11.9714 15.1286 13.5714 10.0001 13.5714C4.8715 13.5714 0.714355 11.9714 0.714355 10"
                    stroke="#1E7EA1"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_255_2">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
