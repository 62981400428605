import clsx from 'clsx';
import { useStyles } from './utils';

export default function RetentionPolicyIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <g clipPath="url(#clip0_910_2969)">
                <path
                    d="M8 12C10.2056 12 12 10.2056 12 8C12 5.79441 10.2056 4 8 4C5.79441 4 4 5.79441 4 8C4 10.2056 5.79441 12 8 12ZM7.84879 6.43038C7.84879 6.39009 7.86479 6.35146 7.89328 6.32297C7.92176 6.29448 7.9604 6.27848 8.00068 6.27848C8.04097 6.27848 8.07961 6.29448 8.10809 6.32297C8.13658 6.35146 8.15258 6.39009 8.15258 6.43038V7.93739L9.24433 9.41876C9.26824 9.45125 9.27826 9.4919 9.27219 9.53178C9.26612 9.57165 9.24446 9.60749 9.21198 9.63139C9.17949 9.6553 9.13883 9.66532 9.09896 9.65925C9.05908 9.65319 9.02325 9.63153 8.99934 9.59904L7.878 8.07752C7.85888 8.05135 7.84864 8.01975 7.84879 7.98734V6.43038Z"
                    fill="#666666"
                />
                <path
                    d="M11.6207 2.07805C10.1188 1.15865 8.32364 0.844317 6.59868 1.1987C4.87373 1.55309 3.34789 2.54971 2.3301 3.98677L2.11469 2.83284C2.11018 2.80862 2.10094 2.78554 2.0875 2.76489C2.07406 2.74425 2.05669 2.72646 2.03638 2.71253C2.01607 2.6986 1.99321 2.68881 1.96911 2.68372C1.94502 2.67862 1.92015 2.67832 1.89594 2.68284C1.87173 2.68735 1.84864 2.69659 1.828 2.71002C1.80736 2.72346 1.78956 2.74083 1.77564 2.76114C1.76171 2.78146 1.75192 2.80431 1.74682 2.82841C1.74172 2.85251 1.74143 2.87737 1.74594 2.90159L2.04569 4.5073C2.05334 4.54895 2.07496 4.58675 2.10696 4.61447C2.13897 4.64219 2.17947 4.65818 2.22178 4.6598L2.22491 4.66005C2.22657 4.66005 2.22822 4.66043 2.22991 4.66043C2.23213 4.66043 2.23432 4.66018 2.23657 4.66009C2.25247 4.65983 2.26828 4.65757 2.28363 4.65337L3.87288 4.33174C3.92162 4.32187 3.96445 4.29305 3.99193 4.25161C4.01942 4.21016 4.02932 4.1595 4.01946 4.11076C4.00959 4.06202 3.98076 4.01919 3.93932 3.9917C3.89788 3.96421 3.84721 3.95431 3.79847 3.96418L2.6391 4.1988C3.60256 2.84095 5.04576 1.89967 6.67679 1.56534C8.30782 1.23101 10.0049 1.52858 11.4249 2.39787C14.5138 4.28868 15.4886 8.33999 13.5978 11.4288C12.8632 12.6285 11.7629 13.5607 10.4588 14.0882C9.15472 14.6156 7.71574 14.7105 6.35369 14.3589C4.99163 14.0072 3.77844 13.2276 2.89269 12.1347C2.00694 11.0419 1.49541 9.69357 1.43341 8.28821C1.43117 8.23853 1.40929 8.19178 1.37258 8.15823C1.33587 8.12469 1.28734 8.1071 1.23766 8.10934C1.18798 8.11157 1.14123 8.13345 1.10768 8.17016C1.07413 8.20687 1.05655 8.25541 1.05878 8.30509C1.11143 9.50226 1.47302 10.6654 2.10838 11.6815C2.74374 12.6975 3.63124 13.5318 4.68457 14.1032C5.73789 14.6747 6.92117 14.9638 8.11931 14.9424C9.31746 14.921 10.4897 14.59 11.522 13.9814C12.5543 13.3727 13.4115 12.5073 14.0102 11.4693C14.6089 10.4313 14.9288 9.25594 14.9388 8.05764C14.9487 6.85934 14.6484 5.67887 14.0669 4.63104C13.4855 3.58322 12.6428 2.7037 11.6207 2.07805Z"
                    fill="#666666"
                    stroke="#666666"
                    strokeWidth="0.5"
                />
            </g>
            <defs>
                <clipPath id="clip0_910_2969">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
