import userflow from 'userflow.js';
import { getAppKey } from 'tools/getAppKey';
import { EXTERNAL_APPS } from 'tools/appKeyConfig';

class GuidanceManagerClass {
    userFlow = userflow;

    initialise(isProd) {
        return this.userFlow.init(getAppKey(EXTERNAL_APPS.USERFLOW_INIT_KEY, isProd));
    }

    identifyUser(userKey, userObject, options) {
        return this.userFlow.identify(userKey, userObject, options);
    }

    identifyGroup(groupKey, groupObject, options) {
        return this.userFlow.group(groupKey, groupObject, options);
    }

    reset() {
        return this.userFlow.reset();
    }

    track(eventName, attributes) {
        return this.userFlow.track(eventName, { attributes });
    }

    // eslint-disable-next-line class-methods-use-this
    setIdKey(isProd) {
        return getAppKey(EXTERNAL_APPS.USERFLOW_ID_KEY, isProd);
    }

    setVisible(param) {
        return this.userFlow.setResourceCenterLauncherHidden(param);
    }
}

const GuidanceManager = new GuidanceManagerClass();
export default GuidanceManager;
