import { PerfMetricEnum } from 'pages/dbServices/my-services/monitoring/components/common/types/PerfMetricEnum';
import { getRandomNumberInRange } from './myserviceutil';

// One each refresh of the app we have a constants value.
const totalAvgDbLoadMetricValue = getRandomNumberInRange(12000, 15000);
const totalAvgCallsMetricValue = getRandomNumberInRange(1000000, 1200000);
const totalRowsReturnedPerCallsMetricValue = getRandomNumberInRange(120000000, 150000000);

export const additionalMetricsName = () => [
    { metricName: 'Avg Db Load/Sec', totalValue: totalAvgDbLoadMetricValue },
    { metricName: 'Avg Calls/Sec', totalValue: totalAvgCallsMetricValue },
    { metricName: 'Avg Rows Returned/Call', totalValue: totalRowsReturnedPerCallsMetricValue },
];

export const topDimensionsTableValuesForWait = () => [
    'LWLock.WALWrite',
    'IO.DataFileRead',
    'IO.WALSync',
    'Client.ClientRead',
    'CPU',
    'Lock.transactionid',
    'IO.DataFileWrite',
    'IO.WALWrite',
    'IO.WALInitSync',
    'IO.DataFileExtend',
];

// For extended perf metrics we have the same format. Only the question mark is replaced by some values.
export const topDimensionsTableValuesForSQL = () => [
    'END;',
    'update production_db_accounts set abalance = abalance + ? where aid = ?',
    'update production_db_branches set bbalance = bbalance + ? where bid = ?',
    'vacuum analyze production_db_accounts',
    'update production_db_tellers set tbalance = tbalance + ? where tid = ?',
    'copy production_db_accounts from stdin',
    'select abalance from production_db_accounts where aid = ?',
    'alter table production_db_accounts',
    'insert into production_db_history(tid, bid, aid, delta, mtime) values (?, ?, ?, ?, current_timestamp())',
    'BEGIN;',
];

export const topDimensionsTableValuesForDb = () => ['production_db'];

export const topDimensionsTableValuesForUser = () => ['master'];

export const topDimensionsTableValuesForHost = () => ['10.101.85.220'];

export const topDimensionsRowGetter = {
    [PerfMetricEnum.WAIT]: topDimensionsTableValuesForWait,
    [PerfMetricEnum.SQL]: topDimensionsTableValuesForSQL,
    [PerfMetricEnum.HOST]: topDimensionsTableValuesForHost,
    [PerfMetricEnum.USER]: topDimensionsTableValuesForUser,
    [PerfMetricEnum.DB]: topDimensionsTableValuesForDb,
};
