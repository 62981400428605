import {
    ReadyServiceIcon,
    StopServiceIcon,
    FailedServiceIcon,
    StartingIcon,
    RefreshIcon,
} from 'icons-v2/index';

export const TABS = {
    RELATIONAL_DATABASES: 'relationalDatabase',
    VECTOR_DATABASES: 'vectorDatabase',
    NOSQl_DATABASES: 'nosqlDatabase',
};

export const STATUS = {
    READY: 'READY',
    UP: 'UP',
    DOWN: 'DOWN',
    STOPPED: 'STOPPED',
    DELETING: 'DELETING',
    STARTING: 'STARTING',
    STOPPING: 'STOPPING',
    CREATING: 'CREATING',
    FAILED: 'FAILED',
    SWITCHING: 'SWITCHOVER',
    REBUILDING: 'REBUILDING',
    DEGRADED: 'DEGRADED',
    ACTIVE: 'ACTIVE',
    UPDATING: 'UPDATING',
    DEGRADED_HEALING: 'HEALING',
    PATCHING: 'PATCHING',
    PATCHING_FAILED: 'PATCH_FAILED',
    REBUILD_FAILED: 'REBUILD_FAILED',
    UPDATING_PARAMETER_PROFILE: 'UPDATING_PARAMETER_PROFILE',
    PARAMETER_PROFILE_UPDATE_FAILED: 'PARAMETER_PROFILE_UPDATE_FAILED',
    REFRESHING: 'REFRESHING',
    // * ---- server status strings ----
    RUNNING: 'RUNNING',
};

export const ENGINE_TYPES = {
    MILVUS: 'MILVUS',
    MONGODB: 'MONGODB',
    ORACLE: 'ORACLE',
    POSTGRESQL: 'POSTGRESQL',
    MYSQL: 'MYSQL',
    MSSQL: 'MSSQL',
};

export const STATUS_LIST = [
    {
        id: 'All',
        name: 'All',
    },
    {
        id: 'READY',
        name: 'Ready',
    },
    {
        id: 'STOPPED',
        name: 'Stopped',
    },
    {
        id: 'CREATING',
        name: 'Creating',
    },
    {
        id: 'FAILED',
        name: 'Failed',
    },
    {
        id: 'REFRESHING',
        name: 'Refreshing',
    },
];

export const StatusMapping = {
    CREATING: {
        name: 'Creating',
        background: '#11567F',
        Icon: <StartingIcon width={16} height={16} />,
    },
    READY: {
        name: 'Ready',
        background: '#4DAC4A',
        Icon: <ReadyServiceIcon width={16} height={16} />,
    },
    STOPPED: {
        name: 'Stopped',
        background: '#F29132',
        Icon: <StopServiceIcon width={16} height={16} />,
    },
    FAILED: {
        name: 'Failed',
        Icon: <FailedServiceIcon width={16} height={16} />,
    },
    REFRESHING: {
        name: 'Refreshing',
        Icon: <RefreshIcon width={16} height={16} />,
    },
};
