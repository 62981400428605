/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './utils';

export default function Download(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlSpace="preserve"
            fill="none"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M0.799805 11.877V12.9846C0.799805 13.5722 1.03321 14.1357 1.44868 14.5512C1.86414 14.9666 2.42763 15.2 3.01519 15.2H12.9844C13.572 15.2 14.1355 14.9666 14.5509 14.5512C14.9664 14.1357 15.1998 13.5722 15.1998 12.9846V11.877"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.3999 7.09961L7.99989 10.7688L11.5999 7.09961"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 10.769V0.799805"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
