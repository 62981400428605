import clsx from 'clsx';
import { useStyles } from './utils';

// Used in Notification details drawer
export default function FailedActivity(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx({ [className]: !!className })}
            style={iconStyle}
        >
            <path
                d="M36 72C55.8823 72 72 55.8822 72 36C72 16.1177 55.8823 0 36 0C16.1178 0 0 16.1177 0 36C0 55.8822 16.1178 72 36 72Z"
                fill="#FF0303"
                fillOpacity="0.13"
            />
            <path
                d="M35.7403 11.2597C31.0212 11.105 26.6115 12.807 23.2848 16.0562C19.9582 19.2281 18.1789 23.5604 18.1015 28.1249L15.0843 36.5574C14.9296 36.7895 15.007 37.0216 15.1617 37.2537C15.3164 37.4084 15.5485 37.5632 15.7806 37.5632H20.4998V45.3768C20.4998 47.0788 21.8149 48.394 23.5169 48.394H26.6115V55.3567C26.6115 55.8209 26.9209 56.1303 27.3851 56.1303H51.3677C51.8318 56.1303 52.1413 55.8209 52.1413 55.3567V28.6664C52.1413 19.3828 44.7918 11.5691 35.7403 11.2597ZM50.594 54.5831H28.1587V47.6204C28.1587 47.1562 27.8493 46.8467 27.3851 46.8467H23.5169C22.7433 46.8467 22.047 46.1505 22.047 45.3768V36.7895C22.047 36.3254 21.7376 36.0159 21.2734 36.0159H16.8637L19.5714 28.5117C19.5714 28.4343 19.6488 28.357 19.6488 28.2796C19.6488 24.0246 21.3508 20.0791 24.3679 17.1393C27.3851 14.1995 31.408 12.6522 35.7403 12.807C43.9408 13.1164 50.594 20.2338 50.594 28.6664V54.5831Z"
                fill="#444647"
            />
            <path
                d="M30.9442 28.6674C31.0989 28.8995 31.331 28.9768 31.5631 28.9768C31.7178 28.9768 31.8725 28.8995 32.0273 28.8221C32.4141 28.59 32.4914 28.1258 32.182 27.739L30.3253 25.1087L32.182 22.4783C32.4141 22.1689 32.3367 21.6273 32.0273 21.3952C31.6405 21.1632 31.1763 21.2405 30.9442 21.55L29.3969 23.7935L27.8497 21.55C27.6176 21.2405 27.1534 21.0858 26.7666 21.3952C26.3798 21.6273 26.3024 22.0915 26.6118 22.4783L28.4686 25.1087L26.6118 27.739C26.3798 28.0485 26.4571 28.59 26.7666 28.8221C26.9213 28.8995 27.076 28.9768 27.2307 28.9768C27.4628 28.9768 27.6949 28.8995 27.8497 28.6674L29.3969 26.4238L30.9442 28.6674Z"
                fill="#444647"
            />
            <path
                d="M43.5536 21.3952C43.1668 21.1632 42.7026 21.2405 42.4705 21.55L40.9233 23.7935L39.376 21.55C39.1439 21.2405 38.6798 21.0858 38.2929 21.3952C37.9061 21.6273 37.8288 22.0915 38.1382 22.4783L39.9949 25.1087L38.1382 27.739C37.9061 28.0485 37.9835 28.59 38.2929 28.8221C38.4477 28.8995 38.6024 28.9768 38.7571 28.9768C38.9892 28.9768 39.2213 28.8995 39.376 28.6674L40.9233 26.4238L42.4705 28.6674C42.6253 28.8995 42.8574 28.9768 43.0895 28.9768C43.2442 28.9768 43.3989 28.8995 43.5536 28.8221C43.9405 28.59 44.0178 28.1258 43.7084 27.739L41.8516 25.1087L43.7084 22.4783C43.9404 22.0915 43.8631 21.6273 43.5536 21.3952Z"
                fill="#444647"
            />
            <path
                d="M30.0153 35.3189C29.7058 35.6284 29.7058 36.0926 30.0153 36.402C30.3247 36.7115 30.7889 36.7115 31.0984 36.402C33.574 33.9264 37.5969 33.9264 40.0725 36.402C40.2272 36.5568 40.4593 36.6341 40.614 36.6341C40.7688 36.6341 41.0009 36.5568 41.1556 36.402C41.465 36.0926 41.465 35.6284 41.1556 35.3189C38.0611 32.2244 33.1098 32.2244 30.0153 35.3189Z"
                fill="#444647"
            />
        </svg>
    );
}
