import DbGovernance from 'api-clients/network-layer/demo-data/REST/Governance/dbgovernance';
import { getDomainUrl } from 'constants/urls';

export const mockGetMap = {
    [`${getDomainUrl()}/software-library/governance/images`]: {
        handler: () => {
            const responseData = DbGovernance.getSoftwareImages();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/databases/profiles/governance/parameter-profiles?load-acls=true&page-size=1000`]:
        {
            handler: () => {
                const responseData = DbGovernance.getParameterProfiles();
                return [200, responseData];
            },
        },
    [`${getDomainUrl()}/governance/parameter-profiles?load-acls=true&page-size=1000`]: {
        handler: () => {
            const responseData = DbGovernance.getParameterProfiles();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/databases/profiles/parameter-profile-types?page-offset=0&page-size=20`]: {
        handler: () => {
            const responseData = DbGovernance.getParameterProfiles();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/governance/parameter-profiles/validations?status=ENABLED`]: {
        handler: () => {
            const responseData = DbGovernance.getParameterProfilesValidations();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/databases/profiles/options-profile-types`]: {
        handler: () => {
            const responseData = DbGovernance.getOptionProfiles();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/databases/profiles/governance/options-profiles?load-acls=true`]: {
        handler: () => {
            const responseData = DbGovernance.getOptionsProfilesLoadAclsTrue();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/ad-domains/governance`]: {
        handler: () => {
            const responseData = DbGovernance.getADDomains();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/license-manager/governance/licenses?licenseCategory=DB&includeAudit=false`]:
        {
            handler: () => {
                const responseData = DbGovernance.getLicenses();
                return [200, responseData];
            },
        },
    [`${getDomainUrl()}/availability-machines/governance/slas?page-size=100&pageOffset=0&load-acls=true`]:
        {
            handler: () => {
                const responseData = DbGovernance.getSLAs();
                return [200, responseData];
            },
        },
};

// Define the mock data mapping for POST requests
export const mockPostMap = {
    [`${getDomainUrl()}/availability-machines/governance/slas`]: {
        handler: (config) => {
            const responseData = DbGovernance.createCustomSLA(config.data);

            return [200, responseData];
        },
    },
};
