import clsx from 'clsx';
import { useStyles } from './utils';

export default function DisableIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.5 9.07553C17.5 13.7358 13.8785 17.5 9.12345 17.5C4.3504 17.5 0.5 13.7179 0.5 9.07553C0.5 4.42233 4.36135 0.5 9.12345 0.5C13.8678 0.5 17.5 4.40419 17.5 9.07553Z"
                stroke="red"
            />
            <path d="M13.4993 2L4.99927 17" stroke="red" />
        </svg>
    );
}
