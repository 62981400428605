import clsx from 'clsx';
import { useStyles } from './utils';

export default function ObjectIdIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);
    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 24 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.2802 7.31212H12.0238V4.31774C12.0238 4.16613 11.9011 4.04346 11.7495 4.04346H8.3536C8.20199 4.04346 8.07931 4.16613 8.07931 4.31774V7.31212H1.82286C1.36912 7.31212 1 7.68124 1 8.13498V19.0762C1 19.53 1.36912 19.8991 1.82286 19.8991H18.28C18.7337 19.8991 19.1029 19.53 19.1029 19.0762V8.13498C19.1029 7.68124 18.7337 7.31212 18.28 7.31212H18.2802ZM8.62813 4.59203H11.4755V8.00425C11.4755 8.78908 10.8369 9.42765 10.0518 9.42765C9.26673 9.42765 8.62816 8.78908 8.62816 8.00425L8.62813 4.59203ZM18.5545 19.076C18.5545 19.2274 18.4316 19.3503 18.2802 19.3503H1.8231C1.67176 19.3503 1.54881 19.2274 1.54881 19.076V8.13477C1.54881 7.98343 1.67176 7.86049 1.8231 7.86049H8.07956V8.00406C8.07956 9.09129 8.9643 9.97603 10.0518 9.97603C11.1392 9.97603 12.024 9.09129 12.024 8.00406V7.86049H18.2804C18.4318 7.86049 18.5547 7.98343 18.5547 8.13477L18.5545 19.076Z"
                fill="#11567F"
                stroke="#11567F"
                strokeWidth="0.3"
            />
            <path
                d="M2.93054 15.8716H7.97259C8.1242 15.8716 8.24688 15.749 8.24688 15.5973V11.1879C8.24688 11.0363 8.1242 10.9136 7.97259 10.9136H2.93054C2.77893 10.9136 2.65625 11.0363 2.65625 11.1879V15.5973C2.65625 15.749 2.77893 15.8716 2.93054 15.8716ZM3.20482 11.4621H7.69831V15.3231H3.20482V11.4621Z"
                fill="#11567F"
                stroke="#11567F"
                strokeWidth="0.3"
            />
            <path
                d="M17.201 17.5278H2.90209C2.75049 17.5278 2.62781 17.6505 2.62781 17.8021C2.62781 17.9537 2.75049 18.0764 2.90209 18.0764H17.201C17.3526 18.0764 17.4752 17.9537 17.4752 17.8021C17.4752 17.6505 17.3526 17.5278 17.201 17.5278Z"
                fill="#11567F"
                stroke="#11567F"
                strokeWidth="0.3"
            />
            <path
                d="M17.2009 10.9136H9.62377C9.47217 10.9136 9.34949 11.0363 9.34949 11.1879C9.34949 11.3395 9.47217 11.4621 9.62377 11.4621H17.2009C17.3525 11.4621 17.4752 11.3395 17.4752 11.1879C17.4752 11.0363 17.3525 10.9136 17.2009 10.9136Z"
                fill="#11567F"
                stroke="#11567F"
                strokeWidth="0.3"
            />
            <path
                d="M17.2009 13.1182H9.62377C9.47217 13.1182 9.34949 13.2408 9.34949 13.3924C9.34949 13.5441 9.47217 13.6667 9.62377 13.6667H17.2009C17.3525 13.6667 17.4752 13.5441 17.4752 13.3924C17.4752 13.2408 17.3525 13.1182 17.2009 13.1182Z"
                fill="#11567F"
                stroke="#11567F"
                strokeWidth="0.3"
            />
            <path
                d="M17.2009 15.323H9.62377C9.47217 15.323 9.34949 15.4457 9.34949 15.5973C9.34949 15.7489 9.47217 15.8716 9.62377 15.8716H17.2009C17.3525 15.8716 17.4752 15.7489 17.4752 15.5973C17.4752 15.4457 17.3525 15.323 17.2009 15.323Z"
                fill="#11567F"
                stroke="#11567F"
                strokeWidth="0.3"
            />
            <path
                d="M10.0516 8.78945C10.5432 8.78945 10.9431 8.38954 10.9431 7.89802C10.9431 7.4065 10.5432 7.00659 10.0516 7.00659C9.56013 7.00659 9.16022 7.4065 9.16022 7.89802C9.16022 8.38954 9.56013 8.78945 10.0516 8.78945ZM10.0516 7.55516C10.2408 7.55516 10.3945 7.70891 10.3945 7.89802C10.3945 8.08713 10.2408 8.24088 10.0516 8.24088C9.86254 8.24088 9.70879 8.08713 9.70879 7.89802C9.70879 7.70891 9.86254 7.55516 10.0516 7.55516Z"
                fill="#11567F"
            />
        </svg>
    );
}
