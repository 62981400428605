/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2022. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     2/23/2022     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function CreditsIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 20 20"
            fill="none"
            strokeWidth={1.2}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#a)" stroke="var(--icon-color)">
                <path d="M13.5 7.5h-12v7a1 1 0 0 0 1 1h5" strokeLinecap="round" />
                <rect x=".5" y="4" width="14" height="3.5" rx="1" />
                <path d="M7.5 4v11.5M9.054 1.307 7.802 3.253a.5.5 0 0 0 .592.74l2.174-.79a1.28 1.28 0 1 0-1.514-1.896Zm-2.86.105 1.07 1.83a.5.5 0 0 1-.617.717L4.68 3.17a1.228 1.228 0 1 1 1.515-1.759Z" />
                <path
                    d="M14.75 12.923v-1.096m-1.096 4.019c0 .548.49.73 1.096.73.607 0 1.096 0 1.096-.73 0-1.096-2.192-1.096-2.192-2.192 0-.73.49-.73 1.096-.73.607 0 1.096.277 1.096.73m-1.096 2.923v1.096"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.75 19.5a4.75 4.75 0 1 0 0-9.5 4.75 4.75 0 0 0 0 9.5Z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="var(--icon-color)" d="M0 0h20v20H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
