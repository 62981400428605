export enum FeatureFlagEnum {
    Servers = 'Servers',
    Provisioning = 'Provisioning',
    MyServices = 'My Services',
    MultiDisk = 'multi-disk',
    Timezone = 'timezone',
    MssqlHpc = 'mssql-hpc-provisioning',
    CustomiseNetworkCreation = 'customize-network-creation',
}

export enum FeatureFlagEngineTypeEnum {
    ORACLE = 'oracle',
    MYSQL = 'mysql',
    POSTGRES = 'postgres',
    MSSQL = 'mssql',
}

export enum EngineFullFormLowercaseEnum {
    'oracle' = 'oracle',
    'mysql' = 'mysql',
    'postgres' = 'postgresql',
    'mssql' = 'sqlserver',
}
