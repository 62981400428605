// eslint-disable-next-line import/no-unresolved
import DefaultIamLoader from './default/iamloader';
import UBSIamLoader from './UBS/iamloader';
import MMCIamLoader from './MMC/iamloader';
import FMRIamLoader from './FMR/iamloader';

const tenantUrl = window.location.hostname;

const getDataLoaders = () => {
    let iamLoader;

    switch (tenantUrl) {
        case 'localhost':
        case 'ubs.tsl-terls.cloud':
        case 'ubs-demo.tessell.com':
        case 'ubs-demo.azure.tessell.com':
        case 'demo.azure.tessell.com':
        case 'msftgsi.azure.tessell.com':
            iamLoader = new UBSIamLoader();
            break;
        case 'mmc-demo.tessell.com':
        case 'mmc.tsl-terls.cloud':
        case 'mmc-demo.aws.tessell.com':
        case 'demo.aws.tessell.com':
            iamLoader = new MMCIamLoader();
            break;
        case 'fmr-demo.tessell.com':
        case 'fmr.demo.tessell.com':
        case 'fmr.tsl-terls.cloud':
            iamLoader = new FMRIamLoader();
            break;
        default:
            iamLoader = new DefaultIamLoader();
            break;
    }

    return {
        iamLoader,
    };
};

const { iamLoader } = getDataLoaders();

export default class IamData {
    // eslint-disable-next-line class-methods-use-this
    getPersonas() {
        return iamLoader.initPersonas();
    }

    // eslint-disable-next-line class-methods-use-this
    getUsers() {
        return iamLoader.initUsers();
    }

    // eslint-disable-next-line class-methods-use-this
    getUpgradablePersonas() {
        return iamLoader.initUpgradablePersonas();
    }

    // eslint-disable-next-line class-methods-use-this
    getSso() {
        return iamLoader.initSso();
    }

    // eslint-disable-next-line class-methods-use-this
    createPersona(reqBody) {
        return iamLoader.createPersona(reqBody);
    }

    // eslint-disable-next-line class-methods-use-this
    createUser(reqBody) {
        return iamLoader.createUser(reqBody);
    }
}
