export default function CircularWarningIcon() {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.53369 14C11.8474 14 14.5337 11.3137 14.5337 8C14.5337 4.68629 11.8474 2 8.53369 2C5.21998 2 2.53369 4.68629 2.53369 8C2.53369 11.3137 5.21998 14 8.53369 14Z"
                fill="#EC373C"
            />
            <path
                d="M8.5335 11.1007C8.42304 11.1007 8.3335 11.0111 8.3335 10.9007C8.3335 10.7902 8.42304 10.7007 8.5335 10.7007"
                fill="#D9D9D9"
            />
            <path
                d="M8.5335 11.1007C8.42304 11.1007 8.3335 11.0111 8.3335 10.9007C8.3335 10.7902 8.42304 10.7007 8.5335 10.7007"
                stroke="white"
                strokeWidth="1.25"
            />
            <path
                d="M8.53369 8.30225V4.70068"
                stroke="white"
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M8.53369 11.1007C8.64414 11.1007 8.73369 11.0111 8.73369 10.9007C8.73369 10.7902 8.64414 10.7007 8.53369 10.7007"
                stroke="white"
                strokeWidth="1.25"
            />
        </svg>
    );
}
