/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { useStyles } from './utils';

export default function GCPLoggingIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 80 80"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect className="gcp-logging-cls-1" x="6" y="11" width="4" height="2" />
            <rect className="gcp-logging-cls-1" x="4" y="18" width="6" height="2" />
            <g id="colored-32_logs" data-name="colored-32/logs">
                <rect id="Container" className="gcp-logging-cls-2" width="24" height="24" />
                <g id="Shape">
                    <polygon
                        id="Fill-3"
                        className="gcp-logging-cls-1"
                        points="4 18 6 18 6 6 4 6 4 18"
                    />
                    <polygon
                        id="Fill-4"
                        className="gcp-logging-cls-3"
                        points="9 7 22 7 22 3 9 3 9 7"
                    />
                    <polygon
                        id="Fill-4-2"
                        data-name="Fill-4"
                        className="gcp-logging-cls-3"
                        points="9 14 22 14 22 10 9 10 9 14"
                    />
                    <polygon
                        id="Fill-4-3"
                        data-name="Fill-4"
                        className="gcp-logging-cls-3"
                        points="9 21 22 21 22 17 9 17 9 21"
                    />
                    <polygon
                        id="Fill-7"
                        className="gcp-logging-cls-4"
                        points="2 8 8 8 8 2 2 2 2 8"
                    />
                </g>
            </g>
        </svg>
    );
}

GCPLoggingIcon.propTypes = {
    className: PropTypes.string.isRequired,
};
