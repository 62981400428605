/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function SearchIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.12 15.1426H17.2075L23.0522 20.999C23.6182 21.5661 23.6177 22.4846 23.0511 23.0511C22.4846 23.6177 21.5661 23.6182 20.999 23.0522L15.1426 17.2075V16.12L14.7709 15.7345C13.2016 17.0836 11.1642 17.8958 8.94789 17.8958C4.0059 17.8958 0 13.8899 0 8.94789C0 4.0059 4.0059 0 8.94789 0C13.8899 0 17.8958 4.0059 17.8958 8.94789C17.8958 11.1642 17.0836 13.2016 15.7345 14.7709L16.12 15.1426ZM2 9C2 12.8733 5.12667 16 9 16C12.8733 16 16 12.8733 16 9C16 5.12667 12.8733 2 9 2C5.12667 2 2 5.12667 2 9Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
