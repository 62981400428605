/* eslint-disable no-unused-vars */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './utils';

export default function AzureIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);
    const random1 = Math.random().toString();
    const random2 = Math.random().toString();
    const random3 = Math.random().toString();
    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 42 39"
            width="42"
            height="29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {/* <svg width="42" height="39" viewBox="0 0 42 39" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
            <path
                d="M14.324 0.139404H26.2243L13.8711 36.7416C13.7441 37.1178 13.5023 37.4447 13.1798 37.6762C12.8572 37.9078 12.4702 38.0323 12.0731 38.0323H2.81196C2.51114 38.0324 2.21465 37.9608 1.947 37.8234C1.67936 37.6861 1.44826 37.4871 1.27282 37.2427C1.09738 36.9984 0.982647 36.7157 0.938113 36.4182C0.89358 36.1207 0.920526 35.8169 1.01672 35.5319L12.526 1.43019C12.6529 1.05396 12.8947 0.72705 13.2173 0.495488C13.5398 0.263926 13.9269 0.139384 14.324 0.139404Z"
                fill={`url(#${random1})`}
            />
            <path
                d="M31.6167 24.6897H12.7459C12.5705 24.6897 12.3991 24.7424 12.2541 24.8411C12.1091 24.9398 11.9972 25.0798 11.9329 25.243C11.8686 25.4062 11.8549 25.585 11.8936 25.756C11.9323 25.9271 12.0215 26.0826 12.1498 26.2022L24.2759 37.5197C24.6289 37.8491 25.0937 38.0323 25.5765 38.0324H36.2615L31.6167 24.6897Z"
                fill="#0078D4"
            />
            <path
                d="M14.3241 0.139417C13.9227 0.137922 13.5314 0.26477 13.2073 0.501441C12.8831 0.738111 12.6431 1.07221 12.5223 1.45495L1.03109 35.501C0.928458 35.787 0.896235 36.0936 0.937146 36.3948C0.978057 36.6959 1.0909 36.9828 1.26612 37.2311C1.44135 37.4794 1.6738 37.6818 1.94382 37.8213C2.21384 37.9608 2.51347 38.0332 2.81738 38.0324H12.3176C12.6715 37.9691 13.0023 37.8131 13.2762 37.5801C13.5501 37.3472 13.7572 37.0457 13.8763 36.7065L16.1674 29.9535L24.353 37.5879C24.696 37.8717 25.1262 38.0286 25.5714 38.0324H36.2168L31.5477 24.6897L17.9369 24.6928L26.2677 0.139417H14.3241Z"
                // fill="url(#paint1_linear_1773_629)"
                fill={`url(#${random2})`}
            />
            <path
                d="M29.5237 1.42812C29.3969 1.05252 29.1555 0.726143 28.8335 0.494954C28.5115 0.263765 28.125 0.139411 27.7286 0.139404H14.4661C14.8625 0.139401 15.249 0.263748 15.571 0.494936C15.8931 0.726124 16.1345 1.05251 16.2613 1.42812L27.7713 35.5317C27.8674 35.8168 27.8944 36.1206 27.8498 36.4181C27.8053 36.7156 27.6906 36.9982 27.5151 37.2426C27.3397 37.487 27.1086 37.686 26.841 37.8234C26.5733 37.9607 26.2768 38.0323 25.976 38.0323H39.2386C39.5394 38.0323 39.8359 37.9607 40.1035 37.8233C40.3711 37.686 40.6022 37.4869 40.7776 37.2425C40.953 36.9982 41.0677 36.7155 41.1122 36.4181C41.1568 36.1206 41.1298 35.8167 41.0336 35.5317L29.5237 1.42812Z"
                // fill="url(#paint2_linear_1773_629)"
                fill={`url(#${random3})`}
            />
            <defs>
                <linearGradient
                    id={random1}
                    x1="18.6614"
                    y1="2.94733"
                    x2="6.30274"
                    y2="39.4579"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#114A8B" />
                    <stop offset="1" stopColor="#0669BC" />
                </linearGradient>
                <linearGradient
                    id={random2}
                    x1="22.5222"
                    y1="19.9621"
                    x2="19.6632"
                    y2="20.9287"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopOpacity="0.3" />
                    <stop offset="0.071" stopOpacity="0.2" />
                    <stop offset="0.321" stopOpacity="0.1" />
                    <stop offset="0.623" stopOpacity="0.05" />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id={random3}
                    x1="20.9499"
                    y1="1.88222"
                    x2="34.5158"
                    y2="38.0251"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3CCBF4" />
                    <stop offset="1" stopColor="#2892DF" />
                </linearGradient>
            </defs>
        </svg>
    );
}
