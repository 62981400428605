// eslint-disable-next-line import/no-unresolved
import DefaultSubscriptionLoader from './default/subscriptionloader';
import UBSSubscriptionLoader from './UBS/subscriptionloader';
import MMCSubscriptionLoader from './MMC/subscriptionloader';
import FMRSubscriptionLoader from './FMR/subscriptionloader';

const tenantUrl = window.location.hostname;

const getDataLoaders = () => {
    let subscriptionLoader;

    switch (tenantUrl) {
        case 'localhost':
        case 'ubs.tsl-terls.cloud':
        case 'ubs-demo.tessell.com':
        case 'ubs-demo.azure.tessell.com':
        case 'demo.azure.tessell.com':
        case 'msftgsi.azure.tessell.com':
            subscriptionLoader = new UBSSubscriptionLoader();
            break;
        case 'mmc-demo.tessell.com':
        case 'mmc.tsl-terls.cloud':
        case 'mmc-demo.aws.tessell.com':
        case 'demo.aws.tessell.com':
            subscriptionLoader = new MMCSubscriptionLoader();
            break;
        case 'fmr-demo.tessell.com':
        case 'fmr.demo.tessell.com':
        case 'fmr.tsl-terls.cloud':
            subscriptionLoader = new FMRSubscriptionLoader();
            break;
        default:
            subscriptionLoader = new DefaultSubscriptionLoader();
            break;
    }

    return {
        subscriptionLoader,
    };
};

const { subscriptionLoader } = getDataLoaders();

export default class SubscriptionsData {
    // eslint-disable-next-line class-methods-use-this
    getSubscriptions() {
        return subscriptionLoader.initSubscriptions();
    }

    // eslint-disable-next-line class-methods-use-this
    getSubscriptionUser() {
        return subscriptionLoader.initSubscriptionUser();
    }

    // eslint-disable-next-line class-methods-use-this
    getSubscriptionRegions() {
        return subscriptionLoader.initSubscriptionRegions();
    }

    // eslint-disable-next-line class-methods-use-this
    getSubscriptionAsConsumer() {
        return subscriptionLoader.initSubscriptionAsConsumer();
    }

    // eslint-disable-next-line class-methods-use-this
    createSubscription(body) {
        return subscriptionLoader.createSubscription(body);
    }

    // eslint-disable-next-line class-methods-use-this
    updateSubscription(subName, cloud, action) {
        return subscriptionLoader.updateSubscription(subName, cloud, action);
    }

    // eslint-disable-next-line class-methods-use-this
    updateSubscriptionToEnable(name, body) {
        return subscriptionLoader.updateSubscriptionToEnable(name, body);
    }

    // eslint-disable-next-line class-methods-use-this
    updateSubscriptionRegions(name, cloud, body) {
        return subscriptionLoader.updateSubscriptionRegions(name, cloud, body);
    }
}
