import BenchmarksCatalogJson from './bencmarks-catalog.json';
import BenchmarksJson from './benchmarks.json';

import replaceTimestampWithMockDate from 'tools/MockTime';

// replacing date with Mock Date
BenchmarksCatalogJson = replaceTimestampWithMockDate(BenchmarksCatalogJson);
BenchmarksJson = replaceTimestampWithMockDate(BenchmarksJson);

export default class DefaultBenchmarksLoader {
    // eslint-disable-next-line class-methods-use-this
    initBenchmarksCatalog() {
        return BenchmarksCatalogJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initBenchmarksJson() {
        return BenchmarksJson;
    }
}
