/* eslint-disable camelcase */
const EMPTY_OBJECT = Object.freeze({});
const EMPTY_ARRAY = Object.freeze([]);
const EMPTY_STRING = '';
const NO_DATA = '-';
const PAGE_SIZE = 1000;

const bytesToGiB = 1024 * 1024 * 1024;

export { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING, NO_DATA, bytesToGiB, PAGE_SIZE };
