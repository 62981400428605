/* eslint-disable max-len */
export default function SqlFileIcon() {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M29.3762 8.11689C29.7274 8.53869 29.9248 9.11057 29.9249 9.70689V31.2784C29.9246 31.7217 29.8515 32.1606 29.7097 32.5701C29.568 32.9795 29.3605 33.3514 29.0989 33.6645C28.8374 33.9777 28.527 34.2259 28.1855 34.3951C27.844 34.5642 27.4781 34.651 27.1087 34.6504H8.20865C7.46382 34.6492 6.74979 34.2935 6.22322 33.6614C5.69666 33.0292 5.40056 32.1722 5.3999 31.2784V4.27839C5.39924 3.38354 5.69475 2.52501 6.22146 1.89156C6.74816 1.2581 7.46295 0.901583 8.20865 0.900391H22.5862C23.0831 0.900518 23.5597 1.13736 23.9112 1.55889L29.3762 8.11689Z"
                fill="#EBF8FE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.2077 0.150393L8.2089 0.150391L22.5864 0.150392C23.3308 0.150582 24.0103 0.506396 24.4874 1.07857L29.9526 7.63675C30.426 8.20526 30.675 8.95136 30.6752 9.70676V31.2784C30.6748 31.8031 30.5883 32.3254 30.4187 32.8154C30.2491 33.3054 29.9981 33.7582 29.6748 34.1453C29.3513 34.5326 28.9599 34.8486 28.5187 35.0671C28.0767 35.2861 27.5968 35.4012 27.1077 35.4004L27.1089 34.6504V35.4004H27.1077H8.2077C7.21564 35.3988 6.29917 34.9241 5.6472 34.1414C4.99852 33.3626 4.65093 32.3317 4.65015 31.279L4.65015 4.27865C4.65015 4.27854 4.65015 4.27875 4.65015 4.27865C4.64949 3.22486 4.99634 2.19218 5.64501 1.41205C6.29709 0.627811 7.21447 0.151981 8.2077 0.150393ZM22.5862 1.65039H8.20955C7.71155 1.65136 7.19958 1.88857 6.79839 2.37106C6.39376 2.85771 6.1496 3.54212 6.15015 4.27783L6.15015 31.2781C6.15075 32.0129 6.39533 32.6958 6.79974 33.1813C7.20089 33.6629 7.71248 33.8996 8.2101 33.9004H27.1101C27.3599 33.9008 27.6118 33.8424 27.8529 33.723C28.0946 33.6033 28.324 33.4227 28.5235 33.1838C28.7233 32.9446 28.8874 32.6536 29.0012 32.3247C29.1151 31.9959 29.1749 31.6398 29.1752 31.2778V9.70702C29.1752 9.70698 29.1752 9.70707 29.1752 9.70702C29.175 9.26993 29.0292 8.87209 28.8002 8.59703C28.8002 8.59697 28.8003 8.59709 28.8002 8.59703L23.3354 2.03921C23.1096 1.7684 22.8356 1.65052 22.5862 1.65039C22.5863 1.65039 22.5861 1.65039 22.5862 1.65039Z"
                fill="#019CE6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.425 0.150391C22.8393 0.150391 23.175 0.486177 23.175 0.900391V7.65039C23.175 8.08795 23.3211 8.48607 23.5504 8.76124C23.7764 9.03242 24.0504 9.15039 24.3 9.15039H29.925C30.3393 9.15039 30.675 9.48618 30.675 9.90039C30.675 10.3146 30.3393 10.6504 29.925 10.6504H24.3C23.5551 10.6504 22.8753 10.2942 22.3981 9.72152C21.9241 9.15278 21.675 8.4063 21.675 7.65039V0.900391C21.675 0.486177 22.0108 0.150391 22.425 0.150391Z"
                fill="#019CE6"
            />
            <rect x="1" y="15" width="33" height="15" rx="2" fill="#019CE6" />
            <path
                d="M11.4042 25.6859C11.1312 25.6859 10.8517 25.6632 10.5657 25.6177C10.2862 25.5787 10.0165 25.5202 9.7565 25.4422C9.503 25.3642 9.2755 25.2667 9.074 25.1497C8.931 25.0717 8.83025 24.9709 8.77175 24.8474C8.71325 24.7174 8.6905 24.5874 8.7035 24.4574C8.723 24.3274 8.77175 24.2137 8.84975 24.1162C8.93425 24.0122 9.03825 23.9472 9.16175 23.9212C9.29175 23.8887 9.43475 23.9114 9.59075 23.9894C9.85725 24.1389 10.1465 24.2494 10.4585 24.3209C10.777 24.3924 11.0922 24.4282 11.4042 24.4282C11.8787 24.4282 12.2167 24.3567 12.4182 24.2137C12.6197 24.0642 12.7205 23.8789 12.7205 23.6579C12.7205 23.4694 12.649 23.3199 12.506 23.2094C12.363 23.0989 12.1127 23.0047 11.7552 22.9267L10.6535 22.6927C10.0295 22.5627 9.56475 22.3417 9.25925 22.0297C8.96025 21.7112 8.81075 21.2952 8.81075 20.7817C8.81075 20.4567 8.879 20.1609 9.0155 19.8944C9.152 19.6279 9.34375 19.3972 9.59075 19.2022C9.83775 19.0072 10.1302 18.8577 10.4682 18.7537C10.8127 18.6497 11.1897 18.5977 11.5992 18.5977C11.9632 18.5977 12.3207 18.6432 12.6717 18.7342C13.0227 18.8187 13.3315 18.9454 13.598 19.1144C13.728 19.1924 13.8157 19.2932 13.8612 19.4167C13.9132 19.5337 13.9295 19.6539 13.91 19.7774C13.897 19.8944 13.8482 19.9984 13.7637 20.0894C13.6857 20.1804 13.585 20.2389 13.4615 20.2649C13.338 20.2909 13.1885 20.2617 13.013 20.1772C12.7985 20.0667 12.571 19.9854 12.3305 19.9334C12.09 19.8814 11.843 19.8554 11.5895 19.8554C11.3165 19.8554 11.0825 19.8912 10.8875 19.9627C10.699 20.0342 10.5527 20.1349 10.4487 20.2649C10.3512 20.3949 10.3025 20.5444 10.3025 20.7134C10.3025 20.9019 10.3707 21.0547 10.5072 21.1717C10.6437 21.2887 10.881 21.3829 11.219 21.4544L12.3207 21.6884C12.9577 21.8249 13.4322 22.0459 13.7442 22.3514C14.0562 22.6504 14.2122 23.0469 14.2122 23.5409C14.2122 23.8659 14.1472 24.1617 14.0172 24.4282C13.8872 24.6882 13.6987 24.9124 13.4517 25.1009C13.2047 25.2894 12.909 25.4357 12.5645 25.5397C12.22 25.6372 11.8332 25.6859 11.4042 25.6859Z"
                fill="white"
            />
            <path
                d="M21.2676 26.5634C21.3716 26.7324 21.4106 26.8852 21.3846 27.0217C21.3586 27.1647 21.2936 27.2817 21.1896 27.3727C21.0856 27.4702 20.9588 27.5319 20.8093 27.5579C20.6663 27.5839 20.5201 27.5677 20.3706 27.5092C20.2276 27.4572 20.1041 27.3532 20.0001 27.1972L19.3078 26.0662C19.2233 25.9362 19.1128 25.8387 18.9763 25.7737C18.8463 25.7152 18.6773 25.6859 18.4693 25.6859L19.6198 25.2472C19.9188 25.2472 20.1626 25.2992 20.3511 25.4032C20.5461 25.5072 20.7216 25.6859 20.8776 25.9394L21.2676 26.5634ZM18.4791 25.6859C17.8031 25.6859 17.2083 25.5397 16.6948 25.2472C16.1878 24.9482 15.7913 24.5322 15.5053 23.9992C15.2258 23.4662 15.0861 22.8454 15.0861 22.1369C15.0861 21.5974 15.1641 21.1132 15.3201 20.6842C15.4826 20.2487 15.7133 19.8782 16.0123 19.5727C16.3113 19.2607 16.6688 19.0202 17.0848 18.8512C17.5073 18.6822 17.9721 18.5977 18.4791 18.5977C19.1681 18.5977 19.7661 18.7439 20.2731 19.0364C20.7801 19.3289 21.1733 19.7417 21.4528 20.2747C21.7388 20.8012 21.8818 21.4187 21.8818 22.1272C21.8818 22.6667 21.8006 23.1542 21.6381 23.5897C21.4756 24.0252 21.2448 24.3989 20.9458 24.7109C20.6468 25.0229 20.2893 25.2634 19.8733 25.4324C19.4573 25.6014 18.9926 25.6859 18.4791 25.6859ZM18.4791 24.3989C18.8626 24.3989 19.1876 24.3112 19.4541 24.1357C19.7271 23.9537 19.9351 23.6937 20.0781 23.3557C20.2276 23.0112 20.3023 22.6049 20.3023 22.1369C20.3023 21.4219 20.1431 20.8694 19.8246 20.4794C19.5061 20.0829 19.0576 19.8847 18.4791 19.8847C18.1021 19.8847 17.7771 19.9724 17.5041 20.1479C17.2311 20.3234 17.0231 20.5802 16.8801 20.9182C16.7371 21.2562 16.6656 21.6624 16.6656 22.1369C16.6656 22.8454 16.8248 23.4012 17.1433 23.8042C17.4618 24.2007 17.9071 24.3989 18.4791 24.3989Z"
                fill="white"
            />
            <path
                d="M23.9186 25.5787C23.6716 25.5787 23.4798 25.5104 23.3433 25.3739C23.2133 25.2374 23.1483 25.0489 23.1483 24.8084V19.4557C23.1483 19.2022 23.2133 19.0104 23.3433 18.8804C23.4733 18.7504 23.6618 18.6854 23.9088 18.6854C24.1494 18.6854 24.3346 18.7504 24.4646 18.8804C24.5946 19.0104 24.6596 19.2022 24.6596 19.4557V24.3014H27.2726C27.4806 24.3014 27.6399 24.3567 27.7504 24.4672C27.8673 24.5777 27.9258 24.7337 27.9258 24.9352C27.9258 25.1432 27.8673 25.3024 27.7504 25.4129C27.6399 25.5234 27.4806 25.5787 27.2726 25.5787H23.9186Z"
                fill="white"
            />
        </svg>
    );
}
