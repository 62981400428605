// eslint-disable-next-line import/no-extraneous-dependencies
import MockAdapter from 'axios-mock-adapter';
import { getDomainUrl } from 'constants/urls';

import { mockGetMap, mockPatchMap, mockPostMap, mockPutMap } from './mock-data-maps';
import { InitializeNewFetchWithAxios } from '../fetchWithAxios';

export const InitializeMockData = (axiosInstance) => {
    // This should be used only in demo site.
    InitializeNewFetchWithAxios(axiosInstance);

    // Create a new MockAdapter instance
    const mock = new MockAdapter(axiosInstance, { delayResponse: 10 });

    // Mock GET requests
    Object.entries(mockGetMap).forEach(([url, { handler }]) => {
        if (url.startsWith(`${getDomainUrl()}`)) {
            // If the URL is a string, not a regular expression
            mock.onGet(url).reply(handler);
        } else {
            // If the URL is a regular expression
            const regexPattern = new RegExp(url.slice(1, -1));
            mock.onGet(regexPattern).reply(handler);
        }
    });

    // Mock POST requests
    Object.entries(mockPostMap).forEach(([url, { handler }]) => {
        if (url.startsWith(`${getDomainUrl()}`)) {
            // If the URL is a string, not a regular expression
            mock.onPost(url).reply(handler);
        } else {
            // If the URL is a regular expression
            const regexPattern = new RegExp(url.slice(1, -1));
            mock.onPost(regexPattern).reply(handler);
        }
    });

    // Mock PUT requests
    Object.entries(mockPutMap).forEach(([url, { handler }]) => {
        if (url.startsWith(`${getDomainUrl()}`)) {
            // If the URL is a string, not a regular expression
            mock.onPut(url).reply(handler);
        } else {
            // If the URL is a regular expression
            const regexPattern = new RegExp(url.slice(1, -1));
            mock.onPut(regexPattern).reply(handler);
        }
    });

    // Mock PATCH requests
    Object.entries(mockPatchMap).forEach(([url, { handler }]) => {
        if (url.startsWith(`${getDomainUrl()}`)) {
            // If the URL is a string, not a regular expression
            mock.onPatch(url).reply(handler);
        } else {
            // If the URL is a regular expression
            const regexPattern = new RegExp(url.slice(1, -1));
            mock.onPatch(regexPattern).reply(handler);
        }
    });

    // return all GET, POST, PUT, PATCH, DELETE calls as 200 with null response
    mock.onAny().reply((config) => [200, null]);
};
