import replaceTimestampWithMockDate from 'tools/MockTime';
import ComputesJson from './computes-include-availability=true.json';

// replacing date with Mock Date
ComputesJson = replaceTimestampWithMockDate(ComputesJson);

export default class DefaultComputeLoader {
    // eslint-disable-next-line class-methods-use-this
    initComputes() {
        return ComputesJson;
    }
}
