/* eslint-disable no-param-reassign */

import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './configureStore';
import { relationalDbObj } from 'customHooks/useFeatureFlags';

export interface FeatureFlags {
    isServersAppAvailable: boolean;
    isMultiDiskProvisioningAvailable?: boolean;
    isTimezoneFeatureAvailable?: { relationalDB: relationalDbObj };
    isMssqlHpcProvisioningAvailable: boolean;
}

const initialState: FeatureFlags = {
    isServersAppAvailable: false,
    isMultiDiskProvisioningAvailable: false,
    isTimezoneFeatureAvailable: {
        relationalDB: {
            oracle: false,
            mysql: false,
            postgresql: false,
            sqlserver: false,
        },
    },
    isMssqlHpcProvisioningAvailable: false,
};

// slice
export const featureFlagsSlice = createSlice({
    name: 'featureFlags',
    initialState,
    reducers: {
        updateIsServerAvailable: (state, action: PayloadAction<any>) => {
            state.isServersAppAvailable = action.payload;
        },
        updateIsMultiDiskProvisioningAvailable: (state, action: PayloadAction<any>) => {
            state.isMultiDiskProvisioningAvailable = action.payload;
        },
        updateIsTimezoneFeatureAvailable: (state, action: PayloadAction<any>) => {
            state.isTimezoneFeatureAvailable = action.payload;
        },
        updateIsMssqlHpcProvisioningAvailable: (state, action: PayloadAction<any>) => {
            state.isMssqlHpcProvisioningAvailable = action.payload;
        },
    },
});

// Actions
export const featureFlagsActions = {
    updateIsServerAvailable: featureFlagsSlice.actions.updateIsServerAvailable,
    updateIsMultiDiskProvisioningAvailable:
        featureFlagsSlice.actions.updateIsMultiDiskProvisioningAvailable,
    updateIsTimezoneFeatureAvailable: featureFlagsSlice.actions.updateIsTimezoneFeatureAvailable,
    updateIsMssqlHpcProvisioningAvailable:
        featureFlagsSlice.actions.updateIsMssqlHpcProvisioningAvailable,
};

// Selectors
export const selectFeatureFlags = (state: RootState) => state.featureFlagsReducer;

// Reducer
export default featureFlagsSlice.reducer;
