import { getDomainUrl } from 'constants/urls';
import { iamUrl, regexToString } from '../../utils';
import DbServices from 'api-clients/network-layer/demo-data/REST/DbServices/dbservices';
import { PAGE_SIZE } from 'constants/app.constants';

// Define the mock data mapping for GET requests
export const mockGetMap = {
    [`${getDomainUrl()}/ad-domains`]: {
        handler: () => {
            const responseData = DbServices.getEmptyResponseObject();
            return [200, responseData];
        },
    },
    [`${iamUrl()}/idps/configured-idps`]: {
        handler: () => {
            const responseData = DbServices.getEmptyResponseWithMetadata();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/services?page-size=${PAGE_SIZE}`]: {
        handler: (config) => {
            const responseData = DbServices.getMyServicesData();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/compute-service/computes?page-size=${PAGE_SIZE}`]: {
        handler: (config) => {
            const responseData = DbServices.getComputeServicesData();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/compute-service/computes?page-size=${PAGE_SIZE}&include-availability=true&use-cases=MILVUS`]:
        {
            handler: (config) => {
                const responseData = DbServices.getFetchComputesMilvusData();
                return [200, responseData];
            },
        },
    [`${getDomainUrl()}/compute-service/computes?page-size=${PAGE_SIZE}&include-availability=true`]:
        {
            handler: (config) => {
                const responseData = DbServices.getComputeServicesIncludeTrueData();
                return [200, responseData];
            },
        },
    // `${getDomainUrl()}/services/${id}/sample-datasets/suggestions`
    [regexToString(/\/services\/[0-9a-f-]+\/sample-datasets\/suggestions/)]: {
        handler: (config) => {
            const responseData = DbServices.getEmptyResponseWithMetadata();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/task-updates?resource-id=${instanceId}&resource-type=TESSELL_SERVICE_INSTANCE`
    [regexToString(
        /\/task-updates\?resource-id=\/[0-9a-f-]+\/&resource-type=TESSELL_SERVICE_INSTANCE/,
    )]: {
        handler: (config) => {
            const responseData = DbServices.getEmptyResponseObject();
            return [200, responseData];
        },
    },
    [regexToString(/\/services\/([0-9a-f-]+)\/maintenance-windows/)]: {
        handler: (config) => {
            const responseData = DbServices.getMaintainanceWindows();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/services/${id}/tags`
    [regexToString(/\/services\/[0-9a-f-]+\/tags/)]: {
        handler: (config) => {
            const responseData = [];
            return [200, responseData];
        },
    },
    // monitoring mock api's
    // /monitoring/metrics?service-id=${serviceId}&service-instance-id=${serviceInstanceId}&start-time=${startTime}&end-time=${endTime}&step=${stepCount}&entity-type=${entityType}&compute-resource-id=${computeResourceId}
    [regexToString(/\/monitoring\/metrics\?[^&]+/)]: {
        handler: (config) => {
            let responseData = [];
            const regexForEntity = /entity-type=([\w-]+)/;
            const match = config.url.match(regexForEntity);
            const entityType = match?.[1];

            if (entityType === 'DB') {
                // `${getDomainUrl()}${dbMetricUrl}`
                responseData = [];
            } else if (entityType === 'NODE') {
                // `${getDomainUrl()}${nodeMetricUrl}`
                const nodeMetricUrl = config.url.replace(getDomainUrl(), '');

                const regexForServiceId = /service-id=([\w-]+)/;
                const match = config.url.match(regexForServiceId);
                const serviceId = match?.[1];

                responseData = DbServices.getMonitoringServicesData(nodeMetricUrl, serviceId);
            }
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/logs/stream?entity-id=${entityId}&service-instance-id=${instance}&file-name=${fl}&start-time=${startTime}&end-time=${endTime}&limit=500&forward=${forward}`
    [regexToString(/\/logs\/stream\?entity-id=([\w-]+)/)]: {
        handler: (config) => {
            const regexForEntity = /entity-id=([\w-]+)/;
            const match = config.url.match(regexForEntity);
            const entityId = match?.[1];

            const responseData = DbServices.getStreamServicesData(entityId);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/services/${dbServiceId}/schedules/delete`
    [regexToString(/\/services\/[0-9a-f-]+\/schedules\/delete/)]: {
        handler: (config) => {
            const responseData = DbServices.getEmptyResponseObject();
            return [200, responseData];
        },
    },
    // '/software-library/images', '/software-library/images?allImages=true'
    [regexToString(/^(?:https?:\/\/[^/]+)?\/software-library\/images(?:\?allImages=true)?$/)]: {
        handler: (config) => {
            const responseData = DbServices.getSoftwareImagesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/services/${id}?load-acls=true`
    [regexToString(/^\/services\/[0-9a-f-]+\?load-acls=true$/)]: {
        handler: (config) => {
            // The actual id can be grabbed from config.url
            const { url } = config;

            // Extract the id from the URL using regex
            const regex = /\/services\/([0-9a-f-]+)\?load-acls=true/;
            const match = url.match(regex);
            const id = match?.[1];

            // Get the mock response data using the id
            const res = DbServices.getDatabaseServicesData(id);

            return [200, res];
        },
    },
    // `${getDomainUrl()}/task-updates?resource-id=${id}&resource-type=TESSELL_SERVICE`
    [regexToString(/^\/task-updates\?resource-id=[0-9a-f-]+&resource-type=TESSELL_SERVICE$/)]: {
        handler: (config) => {
            // The actual id can be grabbed from config.url
            const { url } = config;

            // Extract the id from the URL using regex
            const regex = /resource-id=([\w-]+)/;
            const match = url.match(regex);
            const id = match?.[1];

            const responseData = DbServices.getTaskProgressServicesData(id);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/services/${id}/timeline`
    [regexToString(/^\/services\/[0-9a-f-]+\/timeline$/)]: {
        handler: (config) => {
            // The actual id can be grabbed from config.url
            const { url } = config;

            // Extract the id from the URL using regex
            const regex = /\/services\/([0-9a-f-]+)\/timeline/;
            const match = url.match(regex);
            const id = match?.[1];

            const responseData = DbServices.getTimeLineServicesData(id);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${dmmId}/restore-info?backup-id=96e63504-328e-40eb-ac08-2cf8e4d1e91e&time-zone=UTC`;
    // `${getDomainUrl()}/availability-machines/${dmmId}/restore-info?recovery-timestamp=2024-08-07 10:02:52.894`;
    [regexToString(
        /^\/availability-machines\/[0-9a-fA-F-]+\/restore-info\?(backup-id=[0-9a-fA-F-]+&time-zone=UTC|recovery-timestamp=\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3})$/,
    )]: {
        handler: (config) => {
            const { url } = config;
            const urlObj = new URL(url);

            // Get the search parameters from the URL
            const { searchParams, pathname } = urlObj;
            // Extract path parameters
            const match = pathname.match(/^\/availability-machines\/([0-9a-fA-F-]+)\/restore-info$/);
            const amId = match?.[1];
            const snapshotId = searchParams.get('backup-id');
            const recoveryTimestamp = searchParams.get('recovery-timestamp');

            const responseData = DbServices.getDataflixRecoveryInfoData(
                amId,
                recoveryTimestamp,
                snapshotId,
            );

            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${dmmId}?load-acls=true`
    [regexToString(/^\/availability-machines\/[0-9a-f-]+\?load-acls=true$/)]: {
        handler: (config) => {
            // The actual id can be grabbed from config.url
            const { url } = config;

            // Extract the id from the URL using regex
            const regex = /\/availability-machines\/([0-9a-f-]+)\?load-acls=true/;
            const match = url.match(regex);
            const id = match?.[1];

            // Get the mock response data using the id
            const res = DbServices.getAvailabilityMachinesDmmServicesData(id);

            return [200, res];
        },
    },
    // `${getDomainUrl()}/services/${dbServiceId}/schedules/start-stop?page-size=100`
    [regexToString(/^\/services\/[0-9a-f-]+\/schedules\/start-stop\?page-size=100$/)]: {
        handler: (config) => {
            // The actual id can be grabbed from config.url
            const { url } = config;

            // Extract the id from the URL using regex
            const regex = /\/services\/([0-9a-f-]+)\/schedules\/start-stop\?page-size=100/;
            const match = url.match(regex);
            const id = match?.[1];

            const responseData = DbServices.getStartStopScheduleServicesData(id);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/services/${id}/acls/eligible-users`
    [regexToString(/^\/services\/[0-9a-f-]+\/acls\/eligible-users$/)]: {
        handler: (config) => {
            const responseData = DbServices.getEligibleUsersDbServicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${id}/acls/eligible-users`
    [regexToString(/^\/availability-machines\/[0-9a-f-]+\/acls\/eligible-users$/)]: {
        handler: (config) => {
            const responseData = DbServices.getEligibleUsersDbServicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/dataflix/${dmmId}/catalog?time-zone=UTC&load-acls=true`
    [regexToString(/\/dataflix\/[0-9a-f-]+\/catalog\?time-zone=UTC&load-acls=true/)]: {
        handler: (config) => {
            // The actual id can be grabbed from config.url
            const { url } = config;

            // Extract the id from the URL using regex
            const regex = /\/dataflix\/([0-9a-f-]+)\/catalog\?time-zone=UTC&load-acls=true/;
            const match = url.match(regex);
            const id = match?.[1];

            const responseData = DbServices.getDataflixCatalogData(id);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/dataflix/${dmmId}/catalog/sanitized?time-zone=UTC&load-acls=true`
    [regexToString(/\/dataflix\/[0-9a-f-]+\/catalog\/sanitized\?time-zone=UTC&load-acls=true/)]: {
        handler: (config) => {
            const { url } = config;
            const regex =
                /\/dataflix\/([0-9a-f-]+)\/catalog\/sanitized\?time-zone=UTC&load-acls=true/;
            const match = url.match(regex);
            const id = match?.[1];

            const responseData = DbServices.getDataflixSanitizedData(id);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/dataflix/${amId}/catalog/backups?time-zone=UTC&load-acls=true`
    [regexToString(/\/dataflix\/[0-9a-f-]+\/catalog\/backups\?time-zone=UTC&load-acls=true/)]: {
        handler: (config) => {
            // The actual id can be grabbed from config.url
            const { url } = config;

            // Extract the id from the URL using regex
            const regex =
                /\/dataflix\/([0-9a-f-]+)\/catalog\/backups\?time-zone=UTC&load-acls=true/;
            const match = url.match(regex);
            const id = match?.[1];

            const responseData = DbServices.getDataflixBackupData(id);
            return [200, responseData];
        },
    },

    // `${getDomainUrl()}/dataflix/${dmmId}/acls/eligible-users`
    [regexToString(/^\/dataflix\/[0-9a-f-]+\/acls\/eligible-users$/)]: {
        handler: (config) => {
            const responseData = DbServices.getEligibleUsersAmervicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/databases/profiles/parameter-profiles?load-acls=true&page-size=1000`
    [`${getDomainUrl()}/databases/parameter-profiles?load-acls=true&page-size=1000`]: {
        handler: (config) => {
            const responseData = DbServices.getParameterProfileServicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/databases/profiles/parameter-profiles?page-size=1000`
    [`${getDomainUrl()}/databases/parameter-profiles?page-size=1000`]: {
        handler: (config) => {
            const responseData = DbServices.getParameterProfileServicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/databases/profiles/options-profiles`
    [`${getDomainUrl()}/databases/profiles/options-profiles`]: {
        handler: (config) => {
            const responseData = DbServices.getOptionsProfileServicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/services/feature-config`
    [`${getDomainUrl()}/services/feature-config`]: {
        handler: (config) => {
            const responseData = DbServices.getFeaturesConfigServicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/services/${serviceId}/connection-pools`
    [regexToString(/^\/services\/[0-9a-f-]+\/connection-pools$/)]: {
        handler: (config) => {
            // The actual id can be grabbed from config.url
            const { url } = config;

            // Extract the id from the URL using regex
            const regex = /\/services\/([0-9a-f-]+)\/connection-pools/;
            const match = url.match(regex);
            const id = match?.[1];

            const responseData = DbServices.getConnectionPoolsServicesData(id);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/services/${id}/sample-datasets`
    [regexToString(/^\/services\/[0-9a-f-]+\/sample-datasets$/)]: {
        handler: (config) => {
            const responseData = DbServices.getSampleDatasetsServicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/integration-types`
    [`${getDomainUrl()}/integration-types`]: {
        handler: (config) => {
            const responseData = DbServices.getIntegrationTypesServicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/integrations/governance?include-shared-with-users=true&page-size=1000`
    [`${getDomainUrl()}/integrations/governance?include-shared-with-users=true&page-size=1000`]: {
        handler: (config) => {
            const responseData = DbServices.getGovernanceIntegrationServicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/logs/resources/logfiles?entity-id=${entityId}&service-instance-id=${instance}`
    [regexToString(
        /^\/logs\/resources\/logfiles\?entity-id=[0-9a-f-]+&service-instance-id=[0-9a-f-]+$/,
    )]: {
        handler: (config) => {
            const responseData = DbServices.getLogfilesServicesData(config.url);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/services/${serviceId}/databases/${databaseId}/collections?page-size=100`
    [regexToString(/^\/services\/[0-9a-f-]+\/databases\/[0-9a-f-]+\/collections\?page-size=100$/)]:
        {
            handler: (config) => {
                // The actual id can be grabbed from config.url
                const { url } = config;

                // Extract the id from the URL using regex
                const regex =
                    /\/services\/([0-9a-f-]+)\/databases\/([0-9a-f-]+)\/collections\?page-size=100/;
                const match = url.match(regex);
                const serviceId = match?.[1];
                const databaseId = match?.[2];

                const responseData = DbServices.getCollectionListServicesData(
                    serviceId,
                    databaseId,
                );
                return [200, responseData];
            },
        },
    // `${getDomainUrl()}/services/${serviceId}/databases/${databaseId}/collections/${collectionId}?page-size=100`
    [regexToString(
        /^\/services\/[0-9a-f-]+\/databases\/[0-9a-f-]+\/collections\/[0-9a-f-]+\?page-size=100$/,
    )]: {
        handler: (config) => {
            // The actual id can be grabbed from config.url
            const { url } = config;

            // Extract the id from the URL using regex
            const regex =
                /\/services\/([0-9a-f-]+)\/databases\/([0-9a-f-]+)\/collections\/([0-9a-f-]+)\?page-size=100/;
            const match = url.match(regex);
            const collectionId = match?.[3];

            const responseData = DbServices.getCollectionDetailServicesData(collectionId);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/services/${serviceId}/databases/${databaseId}/collections/${collectionId}/indexes?page-size=100`
    [regexToString(/\/network\/vpcs\/([a-zA-Z0-9-]+)\?subscriptionName=([^&]+)/)]: {
        handler: (config) => {
            const { url } = config;

            const regex = /\/network\/vpcs\/([a-zA-Z0-9-]+)/;
            const matches = url.match(regex);
            const vpcName = matches?.[1];

            const responseData = DbServices.getVpcDetailsMyServiceServicesData(vpcName);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/security/ssl-creds?id=${id}`
    [regexToString(/\/security\/ssl-creds\?id=([^&]+)/)]: {
        handler: (config) => {
            const responseData = DbServices.getSslCredsServicesData();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/availability-machines?page-size=${PAGE_SIZE}&load-acls=true`]: {
        handler: (config) => {
            const responseData = DbServices.getAvailabilityMachinesServicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/slas?page-size=100&pageOffset=0&pitr-enabled=${pitrEnabled}`
    [regexToString(
        /\/availability-machines\/slas\?page-size=100&pageOffset=0&pitr-enabled=([^&]+)/,
    )]: {
        handler: (config) => {
            const responseData = DbServices.getAvailabilityMachinesSlasServicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/slas?page-size=100&pageOffset=0`
    [regexToString(/\/availability-machines\/slas\?page-size=100&pageOffset=0/)]: {
        handler: (config) => {
            const responseData = DbServices.getAvailabilityMachinesSlasServicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/slas?page-size=1000&pageOffset=0`
    [regexToString(/\/availability-machines\/slas\?page-size=1000&pageOffset=0/)]: {
        handler: (config) => {
            const responseData = DbServices.getAvailabilityMachinesSlasServicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/governance/slas?page-size=100&pageOffset=0`
    [regexToString(/\/availability-machines\/governance\/slas\?page-size=100&pageOffset=0/)]: {
        handler: (config) => {
            const responseData = DbServices.getAvailabilityMachinesSlasServicesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${dmmId}/snapshots?automated=false`
    [regexToString(/\/availability-machines\/([^/]+)\/snapshots\?automated=([^&]+)/)]: {
        handler: (config) => {
            const { url } = config;
            const regex = /\/availability-machines\/([^/]+)\/snapshots\?automated=([^&]+)/;
            const matches = url.match(regex);
            const dmmId = matches?.[1];

            const responseData =
                DbServices.getAvailabilityMachinesSnapshotsAutomatedFalseServicesData(dmmId);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${dmmId}/snapshots?manual=false`
    [regexToString(/\/availability-machines\/([^/]+)\/snapshots\?manual=([^&]+)/)]: {
        handler: (config) => {
            const { url } = config;
            const regex = /\/availability-machines\/([^/]+)\/snapshots\?manual=([^&]+)/;
            const matches = url.match(regex);
            const dmmId = matches?.[1];

            const responseData =
                DbServices.getAvailabilityMachinesSnapshotsManualFalseServicesData(dmmId);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${dmmId}/sla`
    [regexToString(/\/availability-machines\/([^/]+)\/sla/)]: {
        handler: (config) => {
            const { url } = config;
            const regex = /\/availability-machines\/([^/]+)\/sla/;
            const matches = url.match(regex);
            const dmmId = matches?.[1];

            const responseData = DbServices.getAvailabilityMachinesSlaServicesData(dmmId);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${dmmId}/sanitization-schedules`
    [regexToString(/\/availability-machines\/([^/]+)\/sanitization-schedules/)]: {
        handler: (config) => {
            const { url } = config;
            const regex = /\/availability-machines\/([^/]+)\/sanitization-schedules/;
            const matches = url.match(regex);
            const dmmId = matches?.[1];

            const responseData =
                DbServices.getAvailabilityMachinesSanitizationSchedulesServicesData(dmmId);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${dmmId}/sanitized-snapshots`
    [regexToString(/\/availability-machines\/([a-zA-Z0-9_-]+)\/sanitized-snapshots/)]: {
        handler: (config) => {
            const { url } = config;
            const regex = /\/availability-machines\/([a-zA-Z0-9_-]+)\/sanitized-snapshots/;
            const matches = url.match(regex);
            const dmmId = matches?.[1];

            const responseData =
                DbServices.getAvailabilityMachinesSanitizatiedSnapshotsServicesData(dmmId);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${dmmId}/access-policies?page-size=100&load-acls=true`
    [regexToString(
        /\/availability-machines\/([a-zA-Z0-9_-]+)\/access-policies\?page-size=100&load-acls=true/,
    )]: {
        handler: (config) => {
            const { url } = config;
            const regex =
                /\/availability-machines\/([a-zA-Z0-9_-]+)\/access-policies\?page-size=100&load-acls=true/;
            const matches = url.match(regex);
            const dmmId = matches?.[1];

            const responseData =
                DbServices.getAvailabilityMachinesAccessPoliciesServicesData(dmmId);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${dmmId}/snapshots`
    [regexToString(/\/availability-machines\/([a-zA-Z0-9_-]+)\/snapshots/)]: {
        handler: (config) => {
            const { url } = config;
            const regex = /\/availability-machines\/([a-zA-Z0-9_-]+)\/snapshots/;
            const matches = url.match(regex);
            const dmmId = matches?.[1];

            const responseData = DbServices.getAvailabilityMachinesAllSnapshotsServicesData(dmmId);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${amId}/backups?automated=false`
    [regexToString(/\/availability-machines\/([a-zA-Z0-9_-]+)\/backups\?automated=false/)]: {
        handler: (config) => {
            const { url } = config;
            const regex = /\/availability-machines\/([a-zA-Z0-9_-]+)\/backups\?automated=false/;
            const matches = url.match(regex);
            const dmmId = matches?.[1];

            const responseData = DbServices.getAvailabilityMachinesBackupsServicesData(dmmId);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/task-updates?resource-id=${snapshotId}&resource-type=DB_SNAPSHOT&availability-machine-id=${dmmId}`
    [regexToString(
        /\/task-updates\?resource-id=([a-zA-Z0-9_-]+)&resource-type=DB_SNAPSHOT&availability-machine-id=([a-zA-Z0-9_-]+)/,
    )]: {
        handler: (config) => {
            const responseData = DbServices.getTaskUpdateAvailabilityMachinesData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/task-updates?resource-id=${snapshotId}&resource-type=DB_SANITIZED_SNAPSHOT&availability-machine-id=${dmmId}`
    [regexToString(
        /\/task-updates\?resource-id=([a-zA-Z0-9_-]+)&resource-type=DB_SANITIZED_SNAPSHOT&availability-machine-id=([a-zA-Z0-9_-]+)/,
    )]: {
        handler: (config) => {
            const responseData = DbServices.getTaskUpdateSanitizedCreateData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/task-updates?resource-id=${id}&resource-type=DB_BACKUP&availability-machine-id=${amId}`
    [regexToString(
        /\/task-updates\?resource-id=([a-zA-Z0-9_-]+)&resource-type=DB_BACKUP&availability-machine-id=([a-zA-Z0-9_-]+)/,
    )]: {
        handler: (config) => {
            const responseData = DbServices.getTaskUpdateBackupsCreateData();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/dataflix?page-size=100`]: {
        handler: (config) => {
            const responseData = DbServices.getDataflixData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/dataflix/${dmmId}`
    [regexToString(/\/dataflix\/([a-zA-Z0-9_-]+)/)]: {
        handler: (config) => {
            const { url } = config;
            const regex = /\/dataflix\/([a-zA-Z0-9_-]+)/;
            const matches = url.match(regex);
            const dmmId = matches?.[1];

            const responseData = DbServices.getDataflixDetailsData(dmmId);
            return [200, responseData];
        },
    },
    // /scripts?load-versions=true&page-size=100
    [`${getDomainUrl()}/scripts?load-versions=true&page-size=100`]: {
        handler: (config) => {
            const responseData = DbServices.getScripts();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/scripts?page-size=100`]: {
        handler: (config) => {
            const responseData = DbServices.getScripts();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/scripts?load-versions=false&page-size=100`]: {
        handler: (config) => {
            const responseData = DbServices.getScripts();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/scripts/${id}/versions?page-size=100`
    [regexToString(/\/scripts\/([a-zA-Z0-9_-]+)\/versions\?page-size=100/)]: {
        handler: (config) => {
            const { url } = config;
            const regex = /\/scripts\/([a-zA-Z0-9_-]+)\/versions\?page-size=100/;
            const matches = url.match(regex);
            const scriptId = matches?.[1];

            const responseData = DbServices.getVersions(scriptId);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/scripts/${id}`
    [regexToString(/\/scripts\/([a-zA-Z0-9_-]+)/)]: {
        handler: (config) => {
            const { url } = config;
            const regex = /\/scripts\/([a-zA-Z0-9_-]+)/;
            const matches = url.match(regex);
            const scriptId = matches?.[1];

            const responseData = DbServices.getScriptById(scriptId);
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/benchmark-catalog`]: {
        handler: (config) => {
            const responseData = DbServices.getBenchmarksCatalog();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/benchmarks?page-size=100`]: {
        handler: (config) => {
            const responseData = DbServices.getBenchmarksCatalog();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/compute-resources/dbservers?page-size=${PAGE_SIZE}`]: {
        handler: (config) => {
            const responseData = DbServices.getDbServersListData();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/compute-resources/dbservers/sharing-enabled?page-size=100`]: {
        handler: (config) => {
            const responseData = DbServices.getDbServersSharingEnabledData();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/compute-resources/dbservers/${id}`
    [regexToString(/\/compute-resources\/dbservers\/([a-zA-Z0-9_-]+)/)]: {
        handler: (config) => {
            const { url } = config;
            const regex = /\/compute-resources\/dbservers\/([a-zA-Z0-9_-]+)/;
            const matches = url.match(regex);
            const dbServerId = matches?.[1];

            const responseData = DbServices.getDbServerDetailData(dbServerId);
            return [200, responseData];
        },
    },
};

export const mockPostMap = {
    // Add more POST request mappings here
    [`${getDomainUrl()}/services`]: {
        // Mock response function
        handler: (config) => {
            // Execute Subscriptions.createSubscription with request body
            const responseData = DbServices.postProvisionDatabaseData(config.data);

            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/monitoring/metrics/avg-db-load`]: {
        handler: (config) => {
            const responseData = DbServices.postFetchAvgDbLoadData(config.data);
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/monitoring/metrics/top-dimensions`]: {
        handler: (config) => {
            const responseData = DbServices.postFetchTopDimensionsData(config.data);
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/monitoring/metrics/extended-perf-metrics`]: {
        handler: (config) => {
            const responseData = DbServices.postFetchExtendedPerfMetricsData(config.data);
            return [200, responseData];
        },
    },

    // `${getDomainUrl()}/availability-machines/${dmmId}/clones`
    [regexToString(/^\/availability-machines\/[0-9a-f-]+\/clones$/)]: {
        // Mock response function
        handler: (config) => {
            // Execute Subscriptions.createSubscription with request body
            const responseData = DbServices.postProvisionDatabaseData(config.data);

            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${dmmId}/access-policies`
    [regexToString(/^\/availability-machines\/[0-9a-f-]+\/access-policies$/)]: {
        // Mock response function
        handler: (config) => {
            const { url } = config;

            const regex = /\/availability-machines\/([0-9a-f-]+)\/access-policies/;
            const match = url.match(regex);

            const id = match?.[1]; // Extract the ID from the first capture group

            const responseData = DbServices.postAccessPolicyData(config.data, id);

            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${dmmId}/snapshots`
    [regexToString(/\/availability-machines\/([a-zA-Z0-9_-]+)\/snapshots/)]: {
        handler: (config) => {
            const { url } = config;

            const regex = /\/availability-machines\/([0-9a-f-]+)\/snapshots/;
            const match = url.match(regex);

            const id = match?.[1]; // Extract the ID from the first capture group

            const responseData = DbServices.postManualSnapshotsData(config.data, id);

            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${dmmId}/sanitized-snapshots`
    [regexToString(/\/availability-machines\/([a-zA-Z0-9_-]+)\/sanitized-snapshots/)]: {
        handler: (config) => {
            const { url } = config;

            const regex = /\/availability-machines\/([0-9a-f-]+)\/sanitized-snapshots/;
            const match = url.match(regex);

            const id = match?.[1]; // Extract the ID from the first capture group

            const responseData = DbServices.postSanitizedSnapshotsData(config.data, id);

            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/availability-machines/${amId}/backups`
    [regexToString(/\/availability-machines\/([a-zA-Z0-9_-]+)\/backups/)]: {
        handler: (config) => {
            const { url } = config;

            const regex = /\/availability-machines\/([0-9a-f-]+)\/backups/;
            const match = url.match(regex);

            const id = match?.[1]; // Extract the ID from the first capture group

            const responseData = DbServices.postBackupsData(config.data, id);

            return [200, responseData];
        },
    },
};
