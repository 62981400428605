import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
// import configureStore from './store';
import userReducer from './userSlice';
import cloneReducer from './cloning';
import restoreDBInfoReducer from './restoreDBInfo';
import creditsReducer from './credits';
import paymentsReducer from './payments';
import benchmarksReducer from './benchmarks';
import billingRatesReducer from './billingRates.slice';
import gettingStartedReducer from './gettingStarted';
import featureFlagsReducer from './featureFlags.slice';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

// const rootReducer = { userReducer, cloneReducer };

const persistConfig = {
    key: 'root',
    storage,
};
const localReducer = combineReducers({
    userReducer,
    cloneReducer,
    restoreDBInfoReducer,
    creditsReducer,
    paymentsReducer,
    benchmarksReducer,
    featureFlagsReducer,
    billingRatesReducer,
});

const persistedReducer = persistReducer(persistConfig, localReducer);

export const store = configureStore({
    reducer: persistedReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);
