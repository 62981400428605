export default function TimelinePlayIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1879_2139)">
                <circle cx="8.0002" cy="8.0002" r="6.8" fill="#4DAC4A" stroke="#4DAC4A" />
                <path
                    d="M6.00001 5.20017C6.00001 5.12675 6.03956 5.06713 6.09461 5.03517C6.16403 4.98766 6.34016 4.98795 6.42821 5.03807L11.1053 7.84602C11.2316 7.92012 11.2316 8.10337 11.1053 8.17603L6.63965 10.8598C6.49951 10.944 6.34253 11.0238 6.18365 10.9852C6.08079 10.9603 5.99926 10.9041 6.00001 10.819V5.20017Z"
                    fill="white"
                    stroke="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1879_2139">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
