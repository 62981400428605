import clsx from 'clsx';
import { useStyles } from './utils';

export default function PersonaIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.0091 12C24.0091 18.4002 18.7304 23.6 12.2045 23.6C5.67868 23.6 0.4 18.4002 0.4 12C0.4 5.59984 5.67868 0.4 12.2045 0.4C18.7304 0.4 24.0091 5.59984 24.0091 12Z"
                stroke="var(--icon-color)"
                strokeWidth="0.8"
            />
            <mask id="mask0_632_2165" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="23">
                <path
                    d="M23.247 11.6223C23.247 18.2494 18.7813 22.8718 12.2551 22.8718C5.72896 22.8718 0.866516 18.2494 0.866516 11.6223C0.866516 4.99511 5.72896 0.750244 12.2551 0.750244C18.7813 0.750244 23.247 4.99511 23.247 11.6223Z"
                    fill="#C4C4C4"
                />
            </mask>
            <g mask="url(#mask0_632_2165)">
                <path
                    d="M8.46001 13.5H15.2221C17.5276 13.5 21.3579 16.4858 21.3579 19.1983V19.8855C21.3579 22.1389 19.4975 23.9475 17.2451 23.8839L6.93826 23.5931C4.77392 23.532 3.05109 21.7601 3.05109 19.5949V19.1983C3.05109 16.6553 5.82369 13.5 8.46001 13.5Z"
                    stroke="var(--icon-color)"
                    strokeWidth="0.8"
                    strokeLinecap="round"
                />
                <ellipse
                    cx="12.2641"
                    cy="7.91379"
                    rx="4.76814"
                    ry="4.655"
                    fill="var(--icon-color)"
                />
            </g>
        </svg>
    );
}
