/*
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     sandeeptessell         Created
 *
 */
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './utils';

export default function ActiveDirectoryDomainIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 24 25"
            width="24"
            height="25"
            xmlSpace="preserve"
            fill="none"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.3936 9.74428C12.6683 9.52172 12.9157 9.32067 13.1331 9.12883L16.6077 13.168C16.5224 13.3851 16.4756 13.6215 16.4756 13.8689C16.4756 14.0341 16.4965 14.1945 16.5358 14.3475L12.7278 16.8761C12.6204 16.8389 12.5087 16.8109 12.3936 16.7931V9.74428ZM13.6815 17.6051C13.893 17.9134 14.0167 18.2865 14.0167 18.6886C14.0167 19.7479 13.158 20.6066 12.0987 20.6066C11.0394 20.6066 10.1806 19.7479 10.1806 18.6886C10.1806 18.204 10.3603 17.7614 10.6568 17.4237L6.81683 15.2714C6.47415 15.5912 6.01417 15.7869 5.50849 15.7869C4.44919 15.7869 3.59045 14.9282 3.59045 13.8689C3.59045 12.8096 4.44919 11.9509 5.50849 11.9509C5.83383 11.9509 6.14026 12.0319 6.4087 12.1748L10.3655 8.403C10.1265 8.0306 9.9839 7.62511 9.9839 7.18043C9.9839 6.01248 10.9307 5.06567 12.0987 5.06567C13.2666 5.06567 14.2134 6.01248 14.2134 7.18043C14.2134 7.62825 14.0977 7.98572 13.8802 8.31728L17.2328 12.3419C17.555 12.0966 17.9573 11.9509 18.3936 11.9509C19.4529 11.9509 20.3117 12.8096 20.3117 13.8689C20.3117 14.9282 19.4529 15.7869 18.3936 15.7869C17.8366 15.7869 17.335 15.5495 16.9846 15.1703L13.6815 17.6051ZM11.3482 16.9229L7.32497 14.4863C7.39081 14.2926 7.42652 14.0849 7.42652 13.8689C7.42652 13.5147 7.33052 13.183 7.16313 12.8983L11.1498 9.27954C11.2643 9.37905 11.3843 9.47587 11.5084 9.57018V16.8631C11.4539 16.8807 11.4005 16.9007 11.3482 16.9229Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
