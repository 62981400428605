import clsx from 'clsx';
import { useStyles } from './utils';

export default function RightArrowIcon(props = {}) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                fill="#fff"
                fillOpacity="0.01"
                style={{ mixBlendMode: 'multiply' }}
                d="M0 0H18V18H0z"
            />
            <path
                fill="#11567F"
                d="M10 6l-.715.697 3.79 3.803H3v1h10.075l-3.79 3.787L10 16l5-5-5-5z"
            />
        </svg>
    );
}
