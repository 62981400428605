import ticketsJson from './tickets.json';
import conversationByTicketId from './conversation-by-ticketId.json';
import ticketById from './ticketById.json';
import replaceTimestampWithMockDate from 'tools/MockTime';

// replacing date with Mock Date
ticketsJson = replaceTimestampWithMockDate(ticketsJson);
conversationByTicketId = replaceTimestampWithMockDate(conversationByTicketId);
ticketById = replaceTimestampWithMockDate(ticketById);

export default class DefaultTicketsLoader {
    // eslint-disable-next-line class-methods-use-this
    initTickets() {
        return ticketsJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initConversationByTicketId(id) {
        return conversationByTicketId[id];
    }

    // eslint-disable-next-line class-methods-use-this
    initTicketById(id) {
        return ticketById[id];
    }
}
