/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function IAMIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            viewBox="0 0 25 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={iconStyle}
        >
            <path
                d="M6.39284 7.51786C8.19269 7.51786 9.65177 6.05878 9.65177 4.25893C9.65177 2.45907 8.19269 1 6.39284 1C4.59299 1 3.13391 2.45907 3.13391 4.25893C3.13391 6.05878 4.59299 7.51786 6.39284 7.51786Z"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M0.526733 15.936C0.526733 16.3263 0.843119 16.6427 1.2334 16.6427H11.5522C11.9425 16.6427 12.2589 16.3263 12.2589 15.936C12.2485 14.9423 11.9865 13.9677 11.4972 13.1027C11.008 12.2379 10.3076 11.5112 9.46132 10.9904C8.61507 10.4696 7.65063 10.1719 6.65811 10.1249C6.56962 10.1207 6.48116 10.1185 6.3928 10.1183C6.30445 10.1185 6.21599 10.1207 6.1275 10.1249C5.13498 10.1719 4.17054 10.4696 3.32429 10.9904C2.47805 11.5112 1.7776 12.2379 1.28837 13.1027C0.79915 13.9677 0.537111 14.9423 0.526733 15.936Z"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.6071 7.51786C13.4069 7.51786 14.866 6.05878 14.866 4.25893C14.866 2.45907 13.4069 1 11.6071 1"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.866 16.6428H16.7665C17.1568 16.6428 17.4731 16.3264 17.4731 15.9361V15.9361C17.4627 14.9424 17.2007 13.9677 16.7115 13.1028C16.2222 12.238 15.5218 11.5112 14.6755 10.9904C14.1286 10.6538 13.5321 10.4103 12.9106 10.2676"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
