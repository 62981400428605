import clsx from 'clsx';
import { useStyles } from './utils';

export default function PrimaryField(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="19" height="20" rx="9.5" fill="#F4F9FA" />
            <path
                d="M9.155 10.405C9.40167 10.405 9.61667 10.375 9.8 10.315C9.98333 10.2517 10.135 10.1633 10.255 10.05C10.3783 9.93333 10.47 9.79333 10.53 9.63C10.59 9.46333 10.62 9.27833 10.62 9.075C10.62 8.88167 10.59 8.70667 10.53 8.55C10.47 8.39333 10.38 8.26 10.26 8.15C10.14 8.04 9.98833 7.95667 9.805 7.9C9.62167 7.84 9.405 7.81 9.155 7.81H8.15V10.405H9.155ZM9.155 6.77C9.63833 6.77 10.0567 6.82667 10.41 6.94C10.7633 7.05333 11.055 7.21167 11.285 7.415C11.515 7.61833 11.685 7.86167 11.795 8.145C11.9083 8.42833 11.965 8.73833 11.965 9.075C11.965 9.425 11.9067 9.74667 11.79 10.04C11.6733 10.33 11.4983 10.58 11.265 10.79C11.0317 11 10.7383 11.1633 10.385 11.28C10.035 11.3967 9.625 11.455 9.155 11.455H8.15V14H6.805V6.77H9.155Z"
                fill="#1E7EA1"
            />
        </svg>
    );
}
