import GeneralApis from 'api-clients/network-layer/demo-data/REST/GeneralApis/generalapis';
import { getDomainUrl } from 'constants/urls';
import { regexToString } from '../../utils';

// Define the mock data mapping for GET requests
export const mockGetMap = {
    [`${window.location.origin}/build.json`]: {
        handler: () => {
            const responseData = GeneralApis.getBuildDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/tenant-config`]: {
        handler: () => {
            const responseData = GeneralApis.getTenantConfigDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/iam/users/whoami`]: {
        handler: (config) => {
            const responseData = GeneralApis.getWhoamiDetails(config.headers);
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/cloud-locations?all=true&page-size=1000`]: {
        handler: (config) => {
            const responseData = GeneralApis.getCloudLocationsDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/cloud-locations?all=true&page-size=100`]: {
        handler: (config) => {
            const responseData = GeneralApis.getCloudLocationsDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/cloud-locations?page-size=1000`]: {
        handler: () => {
            const responseData = GeneralApis.getCloudLocationsDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/iam/api-keys?page-size=1000`]: {
        handler: (config) => {
            const responseData = GeneralApis.getApiKeysDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/apps?load-privileges=true&load-features=true`]: {
        handler: (config) => {
            const responseData = GeneralApis.getAppsDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/iam/roles?page-offset=0&page-size=100`]: {
        handler: (config) => {
            const responseData = GeneralApis.getRolesIamUserDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/tickets/project/TS/components`]: {
        handler: (config) => {
            const responseData = GeneralApis.getComponentsTsDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/byoa/template-metadata`]: {
        handler: (config) => {
            const responseData = GeneralApis.getTemplateMetadataDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/event-service/notifications/batch-acknowledge`]: {
        handler: (config) => {
            const responseData = GeneralApis.getEmptyResponse();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/event-service/notifications?userId=
    [regexToString(/\/event-service\/notifications\?userId=.*&pageSize=1000/)]: {
        handler: (config) => {
            const responseData = GeneralApis.getEventServiceNotificationsDetails();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/event-service/notifications/count?userId=
    [regexToString(/\/event-service\/notifications\/count\?userId=.*/)]: {
        handler: (config) => {
            const responseData = GeneralApis.getCustomCount();
            return [200, responseData];
        },
    },
};

// Define the mock data mapping for POST requests
export const mockPostMap = {
    [`${getDomainUrl()}/iam/users/login`]: {
        handler: (config) => {
            const responseData = GeneralApis.getLoginDetails(config.data);

            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/byoa/template-metadata`]: {
        handler: (config) => {
            const responseData = GeneralApis.getTemplateMetadataDetails();
            return [200, responseData];
        },
    },
};
