import clsx from 'clsx';
import { useStyles } from './utils';

export default function NoKeyAvailable(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width={64}
            height={64}
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52 35.617H12V54.129C11.9999 54.5591 12.1385 54.9779 12.3952 55.323C12.6519 55.6681 13.013 55.9213 13.425 56.045L31.425 61.445C31.8002 61.557 32.1998 61.557 32.575 61.445L50.575 56.045C50.987 55.9213 51.3481 55.6681 51.6048 55.323C51.8615 54.9779 52.0001 54.5591 52 54.129V35.617Z"
                fill="#11567F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.9998 35.617H31.9998C31.9998 35.617 31.8058 61.529 31.9998 61.529C32.1938 61.529 32.3868 61.501 32.5748 61.445L50.5748 56.045C50.9868 55.9213 51.3479 55.6681 51.6046 55.323C51.8613 54.9779 51.9999 54.5591 51.9998 54.129V35.617Z"
                fill="#11567F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.9998 42.106L11.9998 35.617L6.9248 43.141C6.83805 43.2694 6.78233 43.4162 6.76203 43.5698C6.74174 43.7235 6.75742 43.8797 6.80785 44.0262C6.85827 44.1728 6.94206 44.3056 7.0526 44.4142C7.16314 44.5228 7.2974 44.6042 7.4448 44.652L25.4398 50.498C25.646 50.565 25.8684 50.5632 26.0734 50.4929C26.2785 50.4226 26.4552 50.2875 26.5768 50.108L31.9998 42.106Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52 35.617L32 42.106L37.423 50.108C37.5446 50.2875 37.7213 50.4226 37.9264 50.4929C38.1314 50.5632 38.3538 50.565 38.56 50.498L56.555 44.652C56.7024 44.6042 56.8367 44.5228 56.9472 44.4142C57.0577 44.3056 57.1415 44.1728 57.192 44.0262C57.2424 43.8797 57.2581 43.7235 57.2378 43.5698C57.2175 43.4162 57.1617 43.2694 57.075 43.141L52 35.617Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.1589 21.986C27.0371 21.8071 26.8605 21.6725 26.6556 21.6026C26.4508 21.5326 26.2288 21.531 26.0229 21.598L8.02694 27.445C7.87944 27.4925 7.74503 27.5737 7.63436 27.6822C7.52369 27.7907 7.4398 27.9234 7.38935 28.07C7.33889 28.2165 7.32324 28.3727 7.34366 28.5264C7.36407 28.68 7.41997 28.8267 7.50694 28.955L11.9999 35.617L31.9999 29.106L27.1589 21.986Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.493 28.955C56.5795 28.8266 56.6351 28.6799 56.6553 28.5264C56.6755 28.3729 56.6597 28.2168 56.6093 28.0704C56.5589 27.924 56.4752 27.7913 56.3648 27.6828C56.2544 27.5743 56.1202 27.4929 55.973 27.445L37.977 21.598C37.7712 21.531 37.5491 21.5326 37.3443 21.6026C37.1395 21.6725 36.9628 21.8071 36.841 21.986L32 29.106L52 35.617L56.493 28.955Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52 35.617L32 29.106L12 35.617L32 42.106L52 35.617Z"
                fill="#29B5E8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32 42.106V29.106L12 35.617L32 42.106Z"
                fill="#11567F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8659 28.906C24.5817 28.5231 24.3296 28.1174 24.1119 27.693C23.9917 27.4566 23.7824 27.2776 23.5301 27.1955C23.2779 27.1133 23.0034 27.1347 22.7669 27.255C22.5305 27.3753 22.3515 27.5846 22.2694 27.8368C22.1872 28.089 22.2087 28.3636 22.3289 28.6C22.5829 29.098 22.8909 29.597 23.2559 30.092C23.4152 30.3002 23.6498 30.4375 23.9092 30.4745C24.1687 30.5114 24.4323 30.4451 24.6433 30.2896C24.8543 30.1342 24.9959 29.9021 25.0375 29.6433C25.0792 29.3846 25.0175 29.1198 24.8659 28.906Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.4041 24.825C23.4141 24.422 23.4731 24.031 23.5811 23.656C23.6173 23.5297 23.6283 23.3976 23.6134 23.2671C23.5985 23.1366 23.558 23.0103 23.4943 22.8954C23.4306 22.7806 23.3449 22.6794 23.2421 22.5976C23.1393 22.5159 23.0214 22.4552 22.8951 22.419C22.7689 22.3828 22.6367 22.3719 22.5062 22.3868C22.3757 22.4017 22.2494 22.4421 22.1345 22.5058C22.0197 22.5695 21.9185 22.6552 21.8367 22.758C21.755 22.8608 21.6943 22.9787 21.6581 23.105C21.5034 23.6487 21.4181 24.2099 21.4041 24.775C21.3975 25.0402 21.4965 25.2972 21.6793 25.4894C21.8622 25.6817 22.1139 25.7934 22.3791 25.8C22.6443 25.8066 22.9013 25.7076 23.0936 25.5248C23.2858 25.3419 23.3975 25.0902 23.4041 24.825Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.9421 21.485C25.1991 21.247 25.4871 21.027 25.8021 20.828C25.9132 20.7579 26.0095 20.6667 26.0853 20.5594C26.1611 20.4522 26.2151 20.331 26.2441 20.203C26.2731 20.0749 26.2766 19.9423 26.2544 19.8128C26.2322 19.6834 26.1847 19.5596 26.1146 19.4485C26.0446 19.3374 25.9533 19.2412 25.8461 19.1653C25.7388 19.0895 25.6177 19.0355 25.4896 19.0065C25.3615 18.9775 25.229 18.974 25.0995 18.9962C24.9701 19.0184 24.8462 19.0659 24.7351 19.136C24.3248 19.3938 23.9391 19.6891 23.5831 20.018C23.3935 20.1994 23.2828 20.4481 23.2749 20.7104C23.267 20.9727 23.3625 21.2276 23.5408 21.4202C23.7192 21.6127 23.9661 21.7274 24.2282 21.7395C24.4904 21.7516 24.7468 21.6602 24.9421 21.485Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.6612 19.76C29.189 19.6636 29.723 19.6048 30.2592 19.584C30.5179 19.564 30.7588 19.4444 30.931 19.2502C31.1032 19.0561 31.1933 18.8027 31.1823 18.5434C31.1712 18.2842 31.06 18.0393 30.8719 17.8605C30.6838 17.6817 30.4337 17.5829 30.1742 17.585C29.5142 17.613 28.8882 17.684 28.2972 17.793C28.0364 17.8413 27.8054 17.9912 27.6551 18.2097C27.5048 18.4283 27.4474 18.6977 27.4957 18.9585C27.544 19.2193 27.6939 19.4503 27.9125 19.6006C28.131 19.7509 28.4004 19.8083 28.6612 19.76Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.8728 19.701C34.7258 19.711 35.5198 19.681 36.2568 19.616C36.5186 19.5895 36.7593 19.4609 36.9269 19.2581C37.0945 19.0553 37.1754 18.7946 37.1522 18.5326C37.1289 18.2705 37.0033 18.0282 36.8025 17.8581C36.6018 17.6881 36.3422 17.604 36.0798 17.624C35.4048 17.683 34.6778 17.711 33.8958 17.701C33.6306 17.698 33.3751 17.8004 33.1854 17.9858C32.9957 18.1711 32.8874 18.4243 32.8843 18.6895C32.8813 18.9547 32.9837 19.2103 33.1691 19.4C33.3545 19.5897 33.6076 19.698 33.8728 19.701Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.5442 19.003C40.5342 18.7 41.3702 18.312 42.0702 17.867C42.181 17.7964 42.2767 17.7047 42.3521 17.5971C42.4274 17.4895 42.4808 17.3681 42.5092 17.2399C42.5376 17.1117 42.5405 16.9791 42.5177 16.8497C42.4949 16.7204 42.4468 16.5968 42.3762 16.486C42.3056 16.3752 42.2139 16.2795 42.1063 16.2041C41.9987 16.1288 41.8773 16.0754 41.7491 16.047C41.6208 16.0186 41.4882 16.0157 41.3589 16.0385C41.2295 16.0614 41.106 16.1094 40.9952 16.18C40.3605 16.5735 39.6757 16.8796 38.9592 17.09C38.7055 17.1676 38.493 17.3428 38.3685 17.577C38.244 17.8112 38.2176 18.0853 38.2952 18.339C38.3728 18.5927 38.5479 18.8052 38.7822 18.9297C39.0164 19.0542 39.2905 19.0806 39.5442 19.003Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.6341 15.068C45.0841 14.1334 45.2928 13.101 45.2411 12.065C45.235 11.9338 45.2031 11.8051 45.1473 11.6862C45.0914 11.5673 45.0127 11.4606 44.9156 11.3722C44.8186 11.2837 44.705 11.2153 44.5815 11.1707C44.4579 11.1262 44.3268 11.1064 44.1956 11.1125C44.0644 11.1186 43.9357 11.1505 43.8169 11.2063C43.698 11.2622 43.5913 11.3409 43.5028 11.438C43.4144 11.535 43.3459 11.6486 43.3014 11.7721C43.2568 11.8957 43.237 12.0268 43.2431 12.158C43.2791 12.8615 43.1381 13.5629 42.8331 14.198C42.7311 14.4349 42.7244 14.7021 42.8146 14.9438C42.9048 15.1855 43.0848 15.3831 43.3171 15.4953C43.5494 15.6075 43.816 15.6257 44.0614 15.5461C44.3068 15.4665 44.512 15.2952 44.6341 15.068Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.9968 8.52903C43.2678 7.54403 42.2788 6.85803 41.2008 6.63703C40.9449 6.59372 40.6823 6.65146 40.4682 6.79806C40.2541 6.94466 40.1053 7.16868 40.0531 7.42287C40.001 7.67706 40.0496 7.94157 40.1887 8.16061C40.3278 8.37966 40.5465 8.53613 40.7988 8.59703C41.4208 8.72403 41.9688 9.15103 42.3898 9.72003C42.468 9.82555 42.5662 9.91463 42.6788 9.98219C42.7915 10.0497 42.9163 10.0945 43.0462 10.1138C43.1761 10.1331 43.3086 10.1266 43.436 10.0948C43.5634 10.0629 43.6833 10.0062 43.7888 9.92803C43.8943 9.84983 43.9834 9.75161 44.0509 9.63898C44.1185 9.52635 44.1632 9.40152 44.1825 9.27161C44.2018 9.1417 44.1954 9.00926 44.1635 8.88185C44.1316 8.75443 44.075 8.63455 43.9968 8.52903Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.9331 6.83098C32.0151 0.703985 41.3921 1.09998 35.9331 6.83098H30.9331Z"
                fill="#29B5E8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.9331 8.83105C32.0151 14.9571 41.3921 14.5621 35.9331 8.83105H30.9331Z"
                fill="#29B5E8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.843 8.617H37.539C37.8042 8.617 38.0586 8.51165 38.2461 8.32411C38.4336 8.13658 38.539 7.88222 38.539 7.617C38.539 7.35179 38.4336 7.09743 38.2461 6.9099C38.0586 6.72236 37.8042 6.617 37.539 6.617H30.843C30.5855 6.62852 30.3424 6.73891 30.1643 6.92519C29.9861 7.11146 29.8867 7.35927 29.8867 7.617C29.8867 7.87474 29.9861 8.12255 30.1643 8.30882C30.3424 8.4951 30.5855 8.60549 30.843 8.617Z"
                fill="#1E7EA1"
            />
        </svg>
    );
}
