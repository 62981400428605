import clsx from 'clsx';
import { useStyles } from './utils';

export default function VectorInputField(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="19" height="20" rx="9.5" fill="#F4F9FA" />
            <path
                d="M13 6.77L10.08 14H8.865L5.945 6.77H7.025C7.14167 6.77 7.23667 6.79833 7.31 6.855C7.38333 6.91167 7.43833 6.985 7.475 7.075L9.18 11.485C9.23667 11.6283 9.29 11.7867 9.34 11.96C9.39333 12.13 9.44333 12.3083 9.49 12.495C9.53 12.3083 9.57333 12.13 9.62 11.96C9.66667 11.7867 9.71833 11.6283 9.775 11.485L11.47 7.075C11.4967 6.99833 11.5483 6.92833 11.625 6.865C11.705 6.80167 11.8017 6.77 11.915 6.77H13Z"
                fill="#1E7EA1"
            />
        </svg>
    );
}
