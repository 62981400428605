/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function DeleteIconWithCircle(props) {
    const { className, color } = props;
    const iconStyle = useStyles(props);

    if (color === 'red') {
        return (
            <svg
                className={clsx({ [className]: !!className })}
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="18" cy="18" r="17.5" fill="#FAE5E5" stroke="#EC373C" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.632 9.25004H25.5304C26.5477 9.25004 27.375 10.0351 27.375 11C27.375 11.9649 26.5477 12.75 25.5304 12.75H25.4856L24.0338 27.8851C23.9573 28.6625 23.2802 29.25 22.4579 29.25H12.7897C11.9649 29.25 11.2875 28.6625 11.2138 27.8851L9.76443 12.75H9.71958C8.70254 12.75 7.875 11.9651 7.875 11C7.875 10.0351 8.70235 9.25004 9.71958 9.25004H12.6182V8.25011C12.6182 7.42242 13.3272 6.75 14.1994 6.75H21.0508C21.9233 6.75 22.632 7.42264 22.632 8.25011V9.25004ZM14.199 7.75031C13.9092 7.75031 13.672 7.97526 13.672 8.25027L13.6719 9.2502H21.5775V8.25027C21.5775 7.97527 21.3404 7.75031 21.0505 7.75031H14.199ZM22.985 27.795C22.9587 28.055 22.7347 28.2499 22.4606 28.2499H12.7895C12.5154 28.2499 12.2887 28.055 12.265 27.795L10.8237 12.7496H24.4264L22.985 27.795ZM9.71919 11.7498H25.5303C25.965 11.7498 26.3209 11.4124 26.3209 10.9998C26.3209 10.5871 25.965 10.2497 25.5303 10.2497H9.71919C9.28427 10.2497 8.92859 10.5873 8.92859 10.9998C8.92859 11.4124 9.28446 11.7498 9.71919 11.7498Z"
                    fill="#EC373C"
                />
                <path
                    d="M20.5959 24.75H20.6259C20.8885 24.75 21.1088 24.5557 21.1238 24.3047L21.624 16.2508C21.6414 15.9902 21.4289 15.7652 21.1538 15.7509C20.8811 15.7344 20.641 15.9357 20.626 16.1962L20.1258 24.2502C20.1108 24.5108 20.3208 24.7335 20.596 24.75H20.5959Z"
                    fill="#EC373C"
                />
                <path
                    d="M17.4587 24.5369L17.4887 24.5359C17.7511 24.5267 17.9644 24.3248 17.9706 24.0735L18.1893 16.007C18.1977 15.7459 17.9774 15.5285 17.702 15.5239C17.4288 15.5169 17.1959 15.7264 17.19 15.9873L16.9713 24.0538C16.9654 24.3148 17.1831 24.53 17.4587 24.5369Z"
                    fill="#EC373C"
                />
                <path
                    d="M14.8749 24.75H14.9049C15.1801 24.7358 15.3902 24.5107 15.3752 24.2502L14.8749 16.196C14.8599 15.9354 14.6222 15.7365 14.347 15.7507C14.0718 15.7649 13.8617 15.99 13.8767 16.2505L14.377 24.3047C14.3921 24.5559 14.6123 24.75 14.8749 24.75H14.8749Z"
                    fill="#EC373C"
                />
            </svg>
        );
    }

    return (
        <svg
            className={clsx({ [className]: !!className })}
            width="36"
            height="36"
            style={iconStyle}
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="18" cy="18" r="17.5" fill="#F4F9FA" stroke="var(--icon-color)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.632 9.25004H25.5304C26.5477 9.25004 27.375 10.0351 27.375 11C27.375 11.9649 26.5477 12.75 25.5304 12.75H25.4856L24.0338 27.8851C23.9573 28.6625 23.2802 29.25 22.4579 29.25H12.7897C11.9649 29.25 11.2875 28.6625 11.2138 27.8851L9.76443 12.75H9.71958C8.70254 12.75 7.875 11.9651 7.875 11C7.875 10.0351 8.70235 9.25004 9.71958 9.25004H12.6182V8.25011C12.6182 7.42242 13.3272 6.75 14.1994 6.75H21.0508C21.9233 6.75 22.632 7.42264 22.632 8.25011V9.25004ZM14.199 7.75031C13.9092 7.75031 13.672 7.97526 13.672 8.25027L13.6719 9.2502H21.5775V8.25027C21.5775 7.97527 21.3404 7.75031 21.0505 7.75031H14.199ZM22.985 27.795C22.9587 28.055 22.7347 28.2499 22.4606 28.2499H12.7895C12.5154 28.2499 12.2887 28.055 12.265 27.795L10.8237 12.7496H24.4264L22.985 27.795ZM9.71919 11.7498H25.5303C25.965 11.7498 26.3209 11.4124 26.3209 10.9998C26.3209 10.5871 25.965 10.2497 25.5303 10.2497H9.71919C9.28427 10.2497 8.92859 10.5873 8.92859 10.9998C8.92859 11.4124 9.28446 11.7498 9.71919 11.7498Z"
                fill="var(--icon-color)"
            />
            <path
                d="M20.5962 24.75H20.6262C20.8888 24.75 21.1091 24.5557 21.1241 24.3047L21.6243 16.2508C21.6418 15.9902 21.4292 15.7652 21.1541 15.7509C20.8814 15.7344 20.6413 15.9357 20.6263 16.1962L20.1261 24.2502C20.1111 24.5108 20.3211 24.7335 20.5963 24.75H20.5962Z"
                fill="var(--icon-color)"
            />
            <path
                d="M17.4583 24.5369L17.4882 24.5359C17.7507 24.5267 17.964 24.3248 17.9702 24.0735L18.1889 16.007C18.1973 15.7459 17.977 15.5285 17.7015 15.5239C17.4284 15.5169 17.1955 15.7264 17.1896 15.9873L16.9709 24.0538C16.965 24.3148 17.1827 24.53 17.4583 24.5369Z"
                fill="var(--icon-color)"
            />
            <path
                d="M14.8747 24.75H14.9047C15.1799 24.7358 15.39 24.5107 15.375 24.2502L14.8747 16.196C14.8597 15.9354 14.622 15.7365 14.3468 15.7507C14.0716 15.7649 13.8615 15.99 13.8765 16.2505L14.3768 24.3047C14.3918 24.5559 14.612 24.75 14.8747 24.75H14.8747Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
