import EligibleEncryptionKeys from './eligibile-encryption-keys.json';
import SampleEncryptionKey from './SampleEncryptionKey.json';
import SampleEligibleKey from './SampleEligibleKey.json';
import EncryptionKeys from './encryption-keys.json';
import PasswordPolicies from './password-policies.json';
import KeyVaultSubscription from './key-vaults-subscriptions.json';

import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line import/no-cycle
import MockRepository from 'api-clients/network-layer/demo-data/repository/MockRepository';
import replaceTimestampWithMockDate from 'tools/MockTime';

// replacing date with Mock Date
EligibleEncryptionKeys = replaceTimestampWithMockDate(EligibleEncryptionKeys);
SampleEncryptionKey = replaceTimestampWithMockDate(SampleEncryptionKey);
SampleEligibleKey = replaceTimestampWithMockDate(SampleEligibleKey);
EncryptionKeys = replaceTimestampWithMockDate(EncryptionKeys);
PasswordPolicies = replaceTimestampWithMockDate(PasswordPolicies);
KeyVaultSubscription = replaceTimestampWithMockDate(KeyVaultSubscription);

const modifyEncryptionKey = (reqBody, sampleEncryptionKey, newId) => {
    const newObj = sampleEncryptionKey;

    newObj.id = newId;
    newObj.name = reqBody.name;
    newObj.description = reqBody.description;
    newObj.cloud = Object.keys(reqBody.cloudConfig)[0].toUpperCase();
    newObj.dateCreated = new Date().toISOString();
    newObj.dateModified = new Date().toISOString();

    return newObj;
};

const modifyEligibleKey = (reqBody, sampleEligibleKey, newId) => {
    // const subs = await Subscriptions.getSubscriptionsDetails();

    const newObj = MockRepository.getSubscriptionsData()
        .getSubscriptions()
        .response.map((subs) => {
            const sampleObj = { ...sampleEligibleKey };

            sampleObj.id = newId;
            sampleObj.name = reqBody.name;
            sampleObj.description = reqBody.description;
            sampleObj.cloud = Object.keys(reqBody.cloudConfig)[0].toUpperCase();
            sampleObj.subscriptionName = subs.name;

            return sampleObj;
        });

    return newObj;
};

export default class UBSSecurityLoader {
    // eslint-disable-next-line class-methods-use-this
    initEligibleEncryptionKeys() {
        // return only eligible keys which have subscription available.
        // const resp = EligibleEncryptionKeys.response.filter(
        //     (keyData) =>
        //         !!SubscriptionsJson.response.find((sub) => sub.name === keyData.subscriptionName),
        // );

        // EligibleEncryptionKeys.response = resp;
        const keysNewlyProvisioned = JSON.parse(sessionStorage.getItem('demo-eligible-keys'));

        if (!keysNewlyProvisioned) {
            return EligibleEncryptionKeys;
        }

        return {
            ...EligibleEncryptionKeys,
            response: [...EligibleEncryptionKeys.response, ...keysNewlyProvisioned],
        };
    }

    // eslint-disable-next-line class-methods-use-this
    initEncryptionKeys() {
        const keysNewlyProvisioned = JSON.parse(sessionStorage.getItem('demo-encryption-keys'));

        if (!keysNewlyProvisioned) {
            return EncryptionKeys;
        }

        return {
            ...EncryptionKeys,
            response: [...EncryptionKeys.response, ...keysNewlyProvisioned],
        };
        // return EncryptionKeys;
    }

    // eslint-disable-next-line class-methods-use-this
    initPasswordPolicies() {
        const passwordPolicyNewlyProvisioned = JSON.parse(
            sessionStorage.getItem('demo-password-policies'),
        );

        if (!passwordPolicyNewlyProvisioned) {
            return PasswordPolicies;
        }

        return passwordPolicyNewlyProvisioned;
    }

    // eslint-disable-next-line class-methods-use-this
    initKeyVaultSubscription() {
        return KeyVaultSubscription;
    }

    // eslint-disable-next-line class-methods-use-this
    createPasswordPolicies(body) {
        if (body) {
            const parsedBody = JSON.parse(body);

            Object.keys(parsedBody).forEach((key) => {
                if (typeof parsedBody[key] === 'string') {
                    // it was type casting some integers to string from payload.
                    // especially like minLength, maxInput etc.
                    parsedBody[key] = parseInt(parsedBody[key] ?? '0', 10);
                }
            });

            sessionStorage.setItem('demo-password-policies', JSON.stringify(parsedBody));
        }

        return {
            status: 'success',
            message: ['Password Policy is Updated Successfully'],
        };
    }

    // eslint-disable-next-line class-methods-use-this
    createEncryptionKey(reqBody) {
        // IN ORDER TO GET SUBSCRIPTIONS WE NEED encryption keys and eligible keys, hence matched using id and subscriptionName.

        // id should be same in order to match
        const newId = uuidv4();

        const modifiedEncryptionKey = modifyEncryptionKey(
            JSON.parse(reqBody),
            SampleEncryptionKey,
            newId,
        );

        if (sessionStorage.getItem('demo-encryption-keys')) {
            const oldKeys = JSON.parse(sessionStorage.getItem('demo-encryption-keys'));
            sessionStorage.setItem(
                'demo-encryption-keys',
                JSON.stringify([...oldKeys, modifiedEncryptionKey]),
            );
        } else {
            sessionStorage.setItem('demo-encryption-keys', JSON.stringify([modifiedEncryptionKey]));
        }

        const modifiedEligibleKey = modifyEligibleKey(
            JSON.parse(reqBody),
            SampleEligibleKey,
            newId,
        );

        if (sessionStorage.getItem('demo-eligible-keys')) {
            const oldKeys = JSON.parse(sessionStorage.getItem('demo-eligible-keys'));
            sessionStorage.setItem(
                'demo-eligible-keys',
                JSON.stringify([...oldKeys, ...modifiedEligibleKey]),
            );
        } else {
            sessionStorage.setItem('demo-eligible-keys', JSON.stringify([...modifiedEligibleKey]));
        }

        return modifiedEncryptionKey;
    }
}
