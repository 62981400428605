export function getDateBeforeDays(days) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - days);
    return currentDate;
}

function replaceTimestampWithMockDate(jsonString, days) {
    try {
        const timeRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:\d{2})?/g;

        const forceTimeRegex =
            /\d{4}-\d{2}-\d{1,2}T\d{2}:\d{2}:\d{2}(\.\d+)?[+-]\d{2}:\d{2}\/forceTime/;

        // Replace all occurrences of the time format with getDateBeforeDays
        let updatedJsonString = JSON.stringify(jsonString).replace(timeRegex, () =>
            getDateBeforeDays(days ?? 40),
        );

        // Replace all occurrences of the time forceTimeRegex with todays date
        updatedJsonString = JSON.stringify(jsonString).replace(forceTimeRegex, () =>
            new Date().toISOString(),
        );

        return JSON.parse(updatedJsonString ?? {});
    } catch (error) {
        return jsonString; // Return the original string if there's an error
    }
}

export default replaceTimestampWithMockDate;
