export default function ScheduleRepeatIcon(props) {
    const { color } = props;

    if (color === 'black') {
        return (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2.1665 6.5V4.5C2.1665 3.83696 2.4299 3.20107 2.89874 2.73223C3.36758 2.26339 4.00346 2 4.6665 2H12.1665C12.8295 2 13.4654 2.26339 13.9343 2.73223C14.4031 3.20107 14.6665 3.83696 14.6665 4.5V9.5C14.6665 10.163 14.4031 10.7989 13.9343 11.2678C13.4654 11.7366 12.8295 12 12.1665 12H3.24984"
                    stroke="#08080D"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.75 9.5L3.25 12L5.75 14.5"
                    stroke="#08080D"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.16675 7.75111V4.5C2.16675 3.83696 2.43014 3.20107 2.89898 2.73223C3.36782 2.26339 4.00371 2 4.66675 2H12.1667C12.8298 2 13.4657 2.26339 13.9345 2.73223C14.4034 3.20107 14.6667 3.83696 14.6667 4.5V9.5C14.6667 10.163 14.4034 10.7989 13.9345 11.2678C13.4657 11.7366 12.8298 12 12.1667 12H3.25008"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.75 9.5L3.25 12L5.75 14.5"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
