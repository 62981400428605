import clsx from 'clsx';
import { useStyles } from './utils';

export default function ReadyServiceIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.42354 0.599076C5.73826 0.272009 6.26174 0.27201 6.57646 0.599076L11.6246 5.8453C12.1137 6.35354 11.7535 7.2 11.0482 7.2H0.951818C0.24649 7.2 -0.113699 6.35354 0.375358 5.8453L5.42354 0.599076Z"
                fill="#4DAC4A"
            />
        </svg>
    );
}
