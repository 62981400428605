import { PerfMetricEnum } from 'pages/dbServices/my-services/monitoring/components/common/types/PerfMetricEnum';
import { getRandomNumberInRange } from './myserviceutil';

export enum MockChartTypeData {
    ReachMaxima,
    AlmostStagnant,
    ZigZag,
}

export const avgDbLoadEntityNameForWait = () => [
    {
        entityName: 'IO.WALSync',
        initialValue: getRandomNumberInRange(2, 5),
        chartType: MockChartTypeData.ReachMaxima,
    },
    {
        entityName: 'CPU',
        initialValue: getRandomNumberInRange(0, 1),
        chartType: MockChartTypeData.ReachMaxima,
    },
    {
        entityName: 'Client.ClientRead',
        initialValue: getRandomNumberInRange(0, 1),
        chartType: MockChartTypeData.ReachMaxima,
    },
    {
        entityName: 'IO.DataFileRead',
        initialValue: getRandomNumberInRange(0, 2),
        chartType: MockChartTypeData.AlmostStagnant,
    },
    {
        entityName: 'Lock.transactionid',
        initialValue: getRandomNumberInRange(0, 2),
        chartType: MockChartTypeData.AlmostStagnant,
    },
    {
        entityName: 'IO.DataFileWrite',
        initialValue: getRandomNumberInRange(0, 2),
        chartType: MockChartTypeData.AlmostStagnant,
    },
    {
        entityName: 'IO.WALWrite',
        initialValue: getRandomNumberInRange(0, 2),
        chartType: MockChartTypeData.AlmostStagnant,
    },
    {
        entityName: 'LWLock.WALWrite',
        initialValue: getRandomNumberInRange(0, 2),
        chartType: MockChartTypeData.AlmostStagnant,
    },
    {
        entityName: 'IO.WALInitSync',
        initialValue: getRandomNumberInRange(0, 2),
        chartType: MockChartTypeData.AlmostStagnant,
    },
    {
        entityName: 'IO.DataFileExtend',
        initialValue: getRandomNumberInRange(0, 2),
        chartType: MockChartTypeData.AlmostStagnant,
    },
];

export const avgDbLoadEntityNameForSQL = () => [
    {
        entityName:
            'insert into production_db(tid, bid, aid, delta, mtime) values (?, ?, ?, ?, current_timestamp())',
        initialValue: getRandomNumberInRange(2, 5),
        chartType: MockChartTypeData.ReachMaxima,
    },
    {
        entityName: 'vacuum analyze production_db',
        initialValue: getRandomNumberInRange(2, 5),
        chartType: MockChartTypeData.ReachMaxima,
    },
    {
        entityName: 'update production_db_branches set bbalance = bbalance + ? where bid = ?',
        initialValue: getRandomNumberInRange(2, 5),
        chartType: MockChartTypeData.ReachMaxima,
    },
    {
        entityName: 'BEGIN;',
        initialValue: getRandomNumberInRange(0, 2),
        chartType: MockChartTypeData.AlmostStagnant,
    },
    {
        entityName: 'alter table production_db_accounts',
        initialValue: getRandomNumberInRange(0, 2),
        chartType: MockChartTypeData.AlmostStagnant,
    },
    {
        entityName: 'END;',
        initialValue: getRandomNumberInRange(0, 2),
        chartType: MockChartTypeData.AlmostStagnant,
    },
    {
        entityName: 'update production_db_accounts set abalance = abalance + ? where aid = ?',
        initialValue: getRandomNumberInRange(0, 2),
        chartType: MockChartTypeData.AlmostStagnant,
    },
    {
        entityName: 'copy production_db_accounts from stdin',
        initialValue: getRandomNumberInRange(0, 2),
        chartType: MockChartTypeData.AlmostStagnant,
    },
    {
        entityName: 'select abalance from production_db_accounts where aid = ?',
        initialValue: getRandomNumberInRange(0, 2),
        chartType: MockChartTypeData.AlmostStagnant,
    },
    {
        entityName: 'update production_db_tellers set tbalance = tbalance + ? where tid = ?',
        initialValue: getRandomNumberInRange(0, 2),
        chartType: MockChartTypeData.AlmostStagnant,
    },
];

export const avgDbLoadEntityNameForDb = () => [
    {
        entityName: 'production_db',
        initialValue: getRandomNumberInRange(2, 5),
        chartType: MockChartTypeData.ReachMaxima,
    },
];

export const avgDbLoadEntityNameForUser = () => [
    {
        entityName: 'master',
        initialValue: getRandomNumberInRange(2, 5),
        chartType: MockChartTypeData.ReachMaxima,
    },
];

export const avgDbLoadEntityNameForHost = () => [
    {
        entityName: '10.101.85.220',
        initialValue: getRandomNumberInRange(2, 5),
        chartType: MockChartTypeData.ReachMaxima,
    },
];

export const avgDbLoadLegendLabelGetter = {
    [PerfMetricEnum.WAIT]: avgDbLoadEntityNameForWait,
    [PerfMetricEnum.SQL]: avgDbLoadEntityNameForSQL,
    [PerfMetricEnum.HOST]: avgDbLoadEntityNameForHost,
    [PerfMetricEnum.USER]: avgDbLoadEntityNameForUser,
    [PerfMetricEnum.DB]: avgDbLoadEntityNameForDb,
};
