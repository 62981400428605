import Iam from 'api-clients/network-layer/demo-data/REST/Governance/iam';
import { getDomainUrl } from 'constants/urls';
import { iamUrl } from '../../utils';

export const mockGetMap = {
    [`${iamUrl()}/personas?load-apps=true&page-size=100&pageOffset=0`]: {
        handler: () => {
            const responseData = Iam.getPersonasData();
            return [200, responseData];
        },
    },
    [`${iamUrl()}/users?load-personas=true&load-subscriptions=true`]: {
        handler: () => {
            const responseData = Iam.getUsersData();
            return [200, responseData];
        },
    },
    [`${iamUrl()}/personas/upgradable-personas`]: {
        handler: () => {
            const responseData = Iam.getUpgradablePersonasData();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/iam/sso`]: {
        handler: () => {
            const responseData = Iam.getSsoData();
            return [200, responseData];
        },
    },
};

// Define the mock data mapping for POST requests
export const mockPostMap = {
    [`${getDomainUrl()}/iam/personas`]: {
        handler: (config) => {
            const responseData = Iam.createPersona(config.data);

            return [200, responseData];
        },
    },
    [`${iamUrl()}/users/invite-user`]: {
        handler: (config) => {
            const responseData = Iam.createUser(config.data);

            return [200, responseData];
        },
    },
};
