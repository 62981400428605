/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import { FormatListNumbered } from '@mui/icons-material';
import { styles } from 'styles/style-templates';

export default function VersionIcon(props) {
    const { isTabIcon, enabledTabIcon } = props;
    const iconColor =
        isTabIcon && !enabledTabIcon ? styles.color.textBoxLabel : styles.color.darkShade;

    return (
        <FormatListNumbered
            fontSize="medium"
            style={{
                color: iconColor,
            }}
            {...props}
        />
    );
}
