import clsx from 'clsx';
import { useStyles } from './utils';

export default function GCPWithCircleIcon(props = {}) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="none"
            viewBox="0 0 48 48"
        >
            <rect width="47.5" height="47.5" x="0.25" y="0.25" fill="#fff" rx="23.75" />
            <rect
                width="47.5"
                height="47.5"
                x="0.25"
                y="0.25"
                stroke="#CCC"
                strokeWidth="0.5"
                rx="23.75"
            />
            <g clipPath="url(#clip0_2306_486)">
                <path
                    fill="#EA4335"
                    d="M28.297 19.292l2.262-2.268.151-.956c-4.123-3.758-10.678-3.332-14.395.89a10.495 10.495 0 00-2.207 4.144l.81-.114 4.526-.749.35-.358c2.012-2.216 5.416-2.514 7.74-.629l.763.04z"
                />
                <path
                    fill="#4285F4"
                    d="M33.782 21.035a10.223 10.223 0 00-3.072-4.966l-3.176 3.183a5.665 5.665 0 012.072 4.49v.565a2.83 2.83 0 012.827 2.833c0 1.565-1.266 2.802-2.827 2.802h-5.66l-.556.605v3.399l.555.533h5.661a7.334 7.334 0 007.41-7.28 7.376 7.376 0 00-3.233-6.164z"
                />
                <path
                    fill="#34A853"
                    d="M18.293 34.48h5.653v-4.538h-5.653a2.77 2.77 0 01-1.16-.255l-.801.247-2.279 2.269-.199.772a7.324 7.324 0 004.439 1.504z"
                />
                <path
                    fill="#FBBC05"
                    d="M18.293 19.762c-4.06.024-7.333 3.344-7.308 7.414a7.377 7.377 0 002.87 5.799l3.278-3.288a2.836 2.836 0 01-1.412-3.749 2.824 2.824 0 015.152 0l3.279-3.287a7.337 7.337 0 00-5.86-2.89z"
                />
            </g>
            <defs>
                <clipPath id="clip0_2306_486">
                    <path fill="#fff" d="M0 0H26.031V21H0z" transform="translate(10.984 13.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}
