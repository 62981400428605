import clsx from 'clsx';
import { useStyles } from './utils';

export default function DoubleTagIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width={64}
            height={64}
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.285 34.0598C10.9103 33.8848 10.5888 33.6135 10.3534 33.2735C10.118 32.9335 9.97703 32.5371 9.94503 32.1248L8.50003 15.9998L20.79 7.99982L7.18003 16.7698C6.81972 16.9755 6.52056 17.2732 6.31321 17.6326C6.10586 17.9919 5.99778 18.3999 6.00003 18.8148V55.1598C5.99938 55.4673 6.05945 55.7719 6.17681 56.0561C6.29418 56.3403 6.46651 56.5985 6.68394 56.8159C6.90136 57.0333 7.15958 57.2057 7.44378 57.323C7.72798 57.4404 8.03256 57.5005 8.34003 57.4998H33.16C33.4675 57.5005 33.7721 57.4404 34.0563 57.323C34.3405 57.2057 34.5987 57.0333 34.8161 56.8159C35.0336 56.5985 35.2059 56.3403 35.3233 56.0561C35.4406 55.7719 35.5007 55.4673 35.5 55.1598V45.4648L11.285 34.0598Z"
                fill="#29B5E8"
            />
            <path
                d="M56.635 22.6898L51.37 20.1898L23.905 7.26483C23.5293 7.09056 23.1144 7.01822 22.7019 7.05508C22.2894 7.09194 21.8938 7.23671 21.555 7.47483L21 7.85483L34.32 16.7698C34.6796 16.9763 34.9782 17.2742 35.1855 17.6334C35.3927 17.9925 35.5012 18.4002 35.5 18.8148V45.4648L35.635 45.5298L44 49.4998C44.561 49.7649 45.2042 49.7964 45.7884 49.5873C46.3725 49.3782 46.8497 48.9457 47.115 48.3848L57.755 25.7998C57.886 25.5221 57.9609 25.2213 57.9755 24.9146C57.9901 24.608 57.9441 24.3014 57.84 24.0125C57.736 23.7237 57.576 23.4581 57.3692 23.2312C57.1625 23.0042 56.913 22.8202 56.635 22.6898Z"
                fill="#11567F"
            />
            <path
                d="M34.32 16.77L21 7.85498L20.815 7.97498L8.5 16L9.945 32.125C9.977 32.5373 10.1179 32.9337 10.3534 33.2737C10.5888 33.6136 10.9103 33.885 11.285 34.06L35.5 45.465V18.815C35.5012 18.4003 35.3927 17.9927 35.1855 17.6335C34.9782 17.2744 34.6796 16.9764 34.32 16.77ZM20.75 24.5C20.0083 24.5 19.2833 24.28 18.6666 23.868C18.0499 23.4559 17.5693 22.8703 17.2855 22.185C17.0016 21.4998 16.9274 20.7458 17.0721 20.0184C17.2168 19.291 17.5739 18.6228 18.0983 18.0983C18.6228 17.5739 19.291 17.2167 20.0184 17.072C20.7458 16.9273 21.4998 17.0016 22.1851 17.2854C22.8703 17.5693 23.456 18.0499 23.868 18.6666C24.2801 19.2833 24.5 20.0083 24.5 20.75C24.5 21.7445 24.1049 22.6984 23.4017 23.4016C22.6984 24.1049 21.7446 24.5 20.75 24.5Z"
                fill="#1E7EA1"
            />
        </svg>
    );
}
