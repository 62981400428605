/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function DatabaseBackupIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 26 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.98515 0.990799C6.91438 1.02018 5.28902 1.21138 3.88623 1.70051C3.18507 1.94499 2.51026 2.27384 1.98735 2.71762C1.4585 3.16645 1.0656 3.75146 0.993655 4.4891L0.991294 4.51331V4.53764V4.55848C0.990682 4.58109 0.990694 4.60378 0.991295 4.6265L0.991371 11.7866V11.7866L0.991294 15.3796C0.973497 16.0297 1.45057 16.8316 2.50038 17.4595C3.56737 18.0978 5.28736 18.6048 7.9589 18.6772L7.95892 18.678L7.97421 18.6776L7.9859 18.6779L7.98592 18.6773C9.1699 18.6452 10.1694 18.5278 11.0093 18.3516C11.0031 18.2352 11 18.118 11 18C11 17.7707 11.0118 17.5444 11.0347 17.3218C10.2287 17.5095 9.22226 17.6426 7.97414 17.6772C5.41043 17.6064 3.88133 17.1203 3.0137 16.6014C2.12638 16.0706 1.98586 15.5505 1.99098 15.4049L1.99129 15.3961L1.99129 15.3873L1.99137 11.7866V11.7866L1.99131 6.37118C2.15621 6.51399 2.34293 6.65327 2.55252 6.78622C3.63043 7.46995 5.34729 8.01607 7.98567 8.08449L7.99852 7.58905L7.99852 7.58912L7.98608 8.0845L7.98631 8.08451L7.99863 7.59363L8.01111 8.0845L8.01134 8.0845L7.99875 7.58917L7.99875 7.58913L8.01159 8.08449C10.7075 8.01458 12.4416 7.44586 13.5148 6.74103C13.6973 6.62118 13.8619 6.49626 14.0092 6.36831V12.6491C14.3261 12.4732 14.6606 12.3299 15.0092 12.223V4.53773V4.51341L15.0068 4.4892C14.9349 3.75156 14.542 3.16654 14.0131 2.71772C13.4902 2.27394 12.8154 1.94509 12.1143 1.7006C10.7115 1.21147 9.08611 1.02027 8.01535 0.990893L8.00025 1.54118L7.98515 0.990799ZM14.0092 4.58359V4.56389C13.9634 4.16027 13.7471 3.8035 13.3661 3.48016C12.9721 3.14581 12.4232 2.86739 11.785 2.64485C10.5136 2.20151 9.00355 2.01938 7.99859 1.99082C6.99363 2.01968 5.48553 2.20191 4.21547 2.64475C3.57725 2.86729 3.02838 3.14571 2.63441 3.48006C2.25341 3.80341 2.03712 4.16018 1.99129 4.5638V4.56611V4.56612L1.99129 4.60886C2.00198 4.81215 2.20635 5.38243 3.08817 5.94178C3.9654 6.49822 5.48456 7.01765 7.99863 7.08449C10.5681 7.01618 12.098 6.47516 12.9658 5.90519C13.8522 5.323 14.012 4.74538 14.0063 4.58369L14.0092 4.58359Z"
                fill="var(--icon-color)"
            />
            <path
                d="M4.05757 13.137C3.8498 13.062 3.62052 13.1696 3.54548 13.3774C3.47043 13.5851 3.57803 13.8144 3.78581 13.8895L4.05757 13.137ZM8.55635 14.414C8.77493 14.3819 8.92612 14.1787 8.89405 13.9601C8.86197 13.7416 8.65879 13.5904 8.44021 13.6224L8.55635 14.414ZM3.78581 13.8895C4.60836 14.1865 5.28354 14.399 6.01717 14.4942C6.75373 14.5898 7.52851 14.5648 8.55635 14.414L8.44021 13.6224C7.45789 13.7666 6.76101 13.784 6.12012 13.7009C5.47631 13.6173 4.86828 13.4298 4.05757 13.137L3.78581 13.8895Z"
                fill="var(--icon-color)"
            />
            <path
                d="M4.05757 9.90541C3.8498 9.83037 3.62052 9.93797 3.54548 10.1457C3.47043 10.3535 3.57803 10.5828 3.78581 10.6578L4.05757 9.90541ZM8.55635 11.1823C8.77493 11.1503 8.92612 10.9471 8.89405 10.7285C8.86197 10.5099 8.65879 10.3587 8.44021 10.3908L8.55635 11.1823ZM3.78581 10.6578C4.60836 10.9549 5.28354 11.1674 6.01717 11.2626C6.75373 11.3582 7.52851 11.3332 8.55635 11.1823L8.44021 10.3908C7.45789 10.535 6.76101 10.5524 6.12012 10.4692C5.47631 10.3857 4.86828 10.1982 4.05757 9.90541L3.78581 10.6578Z"
                fill="var(--icon-color)"
            />
            <path
                d="M22.9492 21.1582C23.804 19.143 23.6745 17.1266 22.7923 15.219C22.6367 14.9224 22.3595 14.7645 22.0561 14.9213C21.7526 15.078 21.7331 15.5095 21.8886 15.8069C22.6939 17.346 22.7495 19.4295 21.977 21.0014C20.7905 23.7673 17.0582 24.8974 14.3044 23.6081C11.5506 22.3188 10.1635 18.6216 11.6663 15.841C12.9366 13.0519 16.5252 11.8931 19.2611 13.2741L18.0413 14.1082C17.7306 14.2496 17.5532 14.5443 17.6929 14.849C17.8331 15.1532 18.0929 15.2813 18.4038 15.1397L20.8105 13.7532C21.1017 13.6207 21.2503 13.4454 21.1462 13.1492L20.3267 10.5123C20.2714 10.3541 20.1556 10.234 20.0137 10.1675C19.8715 10.101 19.7031 10.0881 19.5422 10.1448C19.2197 10.2581 19.1443 10.5726 19.2554 10.8888L19.6351 12.2743C16.3149 10.8643 12.3443 12.1805 10.7413 15.4417C9.10172 18.7769 10.5061 22.7725 13.8719 24.3486C17.2377 25.9248 21.3098 24.4936 22.9492 21.1582Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
