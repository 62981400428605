import clsx from 'clsx';
import { useStyles } from './utils';

export default function ResizeFeatureIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);
    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.14282 9.85728L1.57139 14.4287"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.57129 14.4287H1.57129V10.4287"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.85704 9.85728L14.4285 14.4287"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.4285 14.4287H14.4285V10.4287"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.14282 6.14258L1.57139 1.57115"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.57129 1.57129H1.57129V5.57129"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.85704 6.14258L14.4285 1.57115"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.4285 1.57129H14.4285V5.57129"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
