import clsx from 'clsx';
import React from 'react';
import { useStyles } from './utils';

export default function PatchingIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);
    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.7158 14.2361C11.4297 14.3819 11.0776 14.269 10.9309 13.982C10.7842 13.6953 10.898 13.3439 11.185 13.1972C13.1476 12.1938 14.367 10.2023 14.367 7.99998C14.367 4.78349 11.7502 2.16666 8.53372 2.16666C5.31723 2.16666 2.7004 4.78349 2.7004 7.99998C2.7004 10.2023 3.91986 12.1938 5.88247 13.1969C6.16947 13.3436 6.28322 13.695 6.13651 13.9818C6.03326 14.1836 5.82881 14.2997 5.61677 14.2997C5.52722 14.2997 5.43681 14.2793 5.35164 14.2358C2.99673 13.0321 1.53374 10.6428 1.53374 7.99998C1.53374 4.14037 4.67381 1 8.53372 1C12.3936 1 15.5337 4.14037 15.5337 7.99998C15.5337 10.6428 14.0707 13.0321 11.7158 14.2361Z"
                fill="var(--icon-color)"
            />
            <path
                d="M6.20016 6.83292C6.05083 6.83292 5.9015 6.77605 5.78775 6.66201C5.55996 6.43422 5.55996 6.06497 5.78775 5.83718L8.12107 3.50385C8.34857 3.27606 8.7184 3.27606 8.9459 3.50385L11.2792 5.83718C11.507 6.06497 11.507 6.43422 11.2792 6.66201C11.0517 6.8898 10.6819 6.8898 10.4544 6.66201L9.11682 5.32443V12.0829C9.11682 12.4049 8.85549 12.6662 8.53349 12.6662C8.21149 12.6662 7.95016 12.4049 7.95016 12.0829V5.32443L6.61258 6.66201C6.49883 6.77605 6.3495 6.83292 6.20016 6.83292Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
