/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-mutable-exports */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     11/29/2021     bakul.banthia         Created
 *
 */

import LogRocket from 'logrocket';
import userflow from 'userflow.js';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import Cache from './Cache';
import MockUtil from './MockUtil';
import AppUtil from './AppUtil';
import GuidanceManager from './userflow';
import { isProdTenant } from 'utils/general';
import { isMilvusProvisioningEnabled } from 'pages/dbServices/dbProvisioning/helpers/dbHelpers';
import { getAppKey } from './getAppKey';
import { EXTERNAL_APPS } from './appKeyConfig';

let isProd;
if (Cache.get('isProd')) {
    isProd = Cache.get('isProd') === 'true';
} else {
    isProd = window.location.hostname.endsWith('tessell.com');
}
if (window.location.hostname.includes('localhost')) {
    isProd = false;
}

const userflowKey = GuidanceManager.setIdKey(isProd);

// Initialize Logrocket
window.LogRocket = LogRocket;

LogRocket.init(getAppKey(EXTERNAL_APPS.LOGROCKET, isProd), {
    network: {
        requestSanitizer: (request) => {
            // if the url contains 'login'
            if (request.url.toLowerCase().indexOf('login') !== -1) {
                // scrub out the body
                request.body = null;
            }

            // scrub header value from request
            if (request.headers.Authorization) {
                request.headers.Authorization = '';
            }
            return request;
        },
    },
    mergeIframes: true,
});

// Initialize Userflow
GuidanceManager.initialise(isProd);

let intercomKey = getAppKey(EXTERNAL_APPS.INTERCOM_KEY, isProd);

// const freshdeskMkpUrl = 'https://marketplace-us.freshworks.com/platform';
export const tessellJiraUrl = 'https://tessell.atlassian.net';

let initialized = false;

let LogrocketURL;
let whoami;

export const Priorities = [
    {
        value: 4,
        label: 'Urgent',
    },
    {
        value: 3,
        label: 'High',
    },
    {
        value: 2,
        label: 'Medium',
    },
    {
        value: 1,
        label: 'Low',
    },
];

export const JiraPriorities = {
    1: 'Low',
    2: 'Medium',
    3: 'High',
    4: 'Highest',
};

export const FreshdeskPriorities = {
    1: 'Low',
    2: 'Medium',
    3: 'High',
    4: 'Urgent',
};

export const FreshdeskStatus = {
    2: 'Open',
    3: 'Pending',
    4: 'Resolved',
    5: 'Closed',
};

const SupportUtil = {
    isExternalCustomer() {
        return isProd;
    },

    reInitialize() {
        // Re-initialize Logrocket
        LogRocket.init(getAppKey(EXTERNAL_APPS.LOGROCKET, isProd), {
            network: {
                requestSanitizer: (request) => {
                    // if the url contains 'login'
                    if (request.url.toLowerCase().indexOf('login') !== -1) {
                        // scrub out the body
                        request.body = null;
                    }

                    // scrub header value from request
                    if (request.headers.Authorization) {
                        request.headers.Authorization = '';
                    }
                    return request;
                },
            },
            mergeIframes: true,
        });
        // Start new LogRocket session
        LogRocket.startNewSession();

        // Intercom
        if (window?.Intercom) {
            window.Intercom('shutdown');
            window.Intercom('update');
        }

        // Reset Userflow
        GuidanceManager.reset();
        // Re-nitialize Userflow
        GuidanceManager.initialise(isProd);

        // Reset keys
        intercomKey = getAppKey(EXTERNAL_APPS.INTERCOM_KEY, isProd);
    },

    reset() {
        initialized = false;
        // End LogRocket session
        LogRocket.startNewSession();
        // Reset Userflow
        GuidanceManager.reset();
        // Show Intercom
        if (window?.Intercom) {
            window.Intercom('update', {
                hide_default_launcher: false,
                vertical_padding: 20,
            });
        }
    },

    whoami() {
        return whoami;
    },

    async updateLogrocketURL() {
        await LogRocket.getSessionURL((sessionURL) => {
            LogrocketURL = sessionURL;
            sessionStorage.setItem('LogrocketURL', LogrocketURL);
        });
        LogRocket.log('localStorage', Cache.toJson());
        return LogrocketURL;
    },

    getLogrocketURL() {
        return LogrocketURL;
    },

    fakeCall(timeout) {
        return MockUtil.getResponse({}, timeout || 5000);
    },

    initAnalytics(data, force) {
        if (force) {
            initialized = false;
        }
        if (initialized) {
            return;
        }
        AppUtil.setClientIP();
        const ip = AppUtil.getClientIP();

        const user = data || {};
        const username = user.emailId || user.tenant;
        const firstName = user.firstName || user.tenantFirstName;
        const lastName = user.lastName || user.tenantLastName;
        const name = `${firstName} ${lastName}`;
        user.email = username;
        user.name = name;
        let persona = '';
        let accountOwner = '';
        let servicePlan = '';
        let isPE = true;
        let tenantDateCreated = '';
        if (user.tenantUserAttributes) {
            const tenancy = user.tenantUserAttributes[0];
            tenantDateCreated = tenancy.tenantDateCreated;
            isPE = tenancy.tenantType === 'PERSONAL' || tenancy.tenantType === 'PROFESSIONAL';
            accountOwner = tenancy.tenant;
            servicePlan = tenancy.tenantServicePlan;
            persona = tenancy.personas[0].name;
        }
        user.persona = persona;
        user.servicePlan = servicePlan;
        user.accountOwner = accountOwner;
        user.tenantDateCreated = tenantDateCreated;
        const ts = user.tenantDateCreated ? moment(user.tenantDateCreated) : moment();

        const url = window.location.hostname;
        const tenant = isPE ? `${url}/${accountOwner}` : url;
        whoami = user;
        Cache.set('whoami', JSON.stringify(user));

        // Check if its actually a dev env hosted on tessell.com
        const isProdFromMetaData = isProdTenant(whoami.customerType);

        if (isProdFromMetaData !== isProd) {
            isProd = isProdFromMetaData;
            this.reInitialize();
        }
        isProd = isProdFromMetaData;
        Cache.set('isProd', isProd);

        const intercomSign = CryptoJS.HmacSHA256(username, intercomKey).toString(CryptoJS.enc.Hex);
        const userflowUserSign = CryptoJS.HmacSHA256(username, userflowKey).toString(
            CryptoJS.enc.Hex,
        );
        const userflowGroupSign = CryptoJS.HmacSHA256(tenant, userflowKey).toString(
            CryptoJS.enc.Hex,
        );

        if (user?.emailId?.length) {
            // Logrocket
            LogRocket.identify(username, {
                name,
                email: username,
                clientIP: ip,
                tenant,
                persona,
                servicePlan,
                accountOwner,
            });

            // Intercom
            if (window?.Intercom) {
                window.Intercom('shutdown');
                window.Intercom('update');
                window.Intercom('boot', {
                    app_id: isProd ? 'qj4l6uz4' : 'cx6vjbwd',
                    name,
                    user_id: username,
                    user_hash: intercomSign,
                    email: username,
                    created_at: ts.unix(),
                    clientIP: ip,
                    tenant,
                    persona,
                    servicePlan,
                    accountOwner,
                    hide_default_launcher: true,
                });
                window.Intercom('update');
            }

            // Userflow
            GuidanceManager.identifyUser(
                username,
                {
                    name,
                    email: username,
                    signed_up_at: ts.toISOString(true),
                    clientIP: ip,
                    tenant,
                    persona,
                    servicePlan,
                    accountOwner,
                    milvus_engine_type: isMilvusProvisioningEnabled(),
                },
                {
                    signature: userflowUserSign,
                },
            );
            GuidanceManager.identifyGroup(
                tenant,
                {
                    name: tenant,
                    signed_up_at: ts.toISOString(true),
                    servicePlan,
                    accountOwner,
                },
                {
                    signature: userflowGroupSign,
                    membership: {
                        persona,
                    },
                },
            );

            // Beamer
            if (window.Beamer) {
                window.Beamer.update({
                    user_firstname: firstName,
                    user_lastname: lastName,
                    user_email: username,
                    user_id: username,
                    user_created_at: ts.unix(),
                });
            }

            // Heap
            window.heap?.identify(username);
            window.heap?.addUserProperties({
                Name: name,
                name,
                email: username,
                clientIP: ip,
                tenant,
                persona,
                servicePlan,
                accountOwner,
            });

            // Pendo
            window.pendo?.initialize({
                visitor: {
                    id: username,
                    name,
                    email: username,
                    clientIP: ip,
                    tenant,
                    persona,
                    servicePlan,
                    accountOwner,
                },
                account: {
                    id: tenant,
                },
            });

            // Integrate LogRocket and Analytics
            LogRocket.getSessionURL((sessionURL) => {
                LogrocketURL = sessionURL;
                sessionStorage.setItem('LogrocketURL', LogrocketURL);
                const eventName = `Session capture for ${username}`;
                const eventNameShort = `Session capture for ${name}`;

                // Intercom
                if (window?.Intercom) {
                    window.Intercom('update', { logrocketURL: sessionURL });
                    window.Intercom('trackEvent', eventNameShort, { sessionURL });
                }
                // Userflow
                GuidanceManager.track(eventNameShort, sessionURL);

                // Pendo
                window.pendo.track(eventName, { sessionURL });

                // Heap
                window.heap.track(eventName, { sessionURL });
            });

            initialized = true;
        }
    },
};

export default SupportUtil;
