import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosInstance from './axiosInterceptor';
import { GET_METHOD, POST_METHODS } from './constants';
import { GetHandlerType } from './types/GetHandlerType';
import { PostHandlerType } from './types/PostHandlerType';

// Functions for different HTTP methods
const getHandler: GetHandlerType = <T, R, D>(url: string, config?: AxiosRequestConfig<D>) =>
    axiosInstance.get<T, R, D>(url, config);

const postHandler: PostHandlerType = <T, R, D>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
) => axiosInstance.post<T, R, D>(url, data, config);

const putHandler: PostHandlerType = <T, R, D>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
) => axiosInstance.put<T, R, D>(url, data, config);

const patchHandler: PostHandlerType = <T, R, D>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
) => axiosInstance.patch<T, R, D>(url, data, config);

const deleteHandler: PostHandlerType = <T, R, D>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
) => axiosInstance.delete<T, R, D>(url, { ...config, data });

type HandlerType = { [key in GET_METHOD]: GetHandlerType } & {
    [key in POST_METHODS]: PostHandlerType;
};

export const httpMethodMapping: HandlerType = {
    [GET_METHOD.GET]: getHandler,
    [POST_METHODS.POST]: postHandler,
    [POST_METHODS.PUT]: putHandler,
    [POST_METHODS.PATCH]: patchHandler,
    [POST_METHODS.DELETE]: deleteHandler,
};
