import clsx from 'clsx';
import { useStyles } from './utils';

export default function ParameterProfileUpdateFailed(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.541 14.0155H1.4583C0.654499 14.0155 0 13.361 0 12.5567V1.47392C0 0.670124 0.654499 0.015625 1.4583 0.015625H12.541C13.3455 0.015625 14 0.670124 14 1.47392V12.5567C14 13.3611 13.3454 14.0155 12.541 14.0155ZM1.4583 0.598799C0.975873 0.598799 0.583299 0.991373 0.583299 1.4738V12.5565C0.583299 13.0391 0.975873 13.4322 1.4583 13.4322H12.541C13.0236 13.4322 13.4167 13.039 13.4167 12.5566V1.4739C13.4167 0.991473 13.0235 0.598899 12.5411 0.598899L1.4583 0.598799Z"
                fill="#EC373C"
            />
            <path
                d="M3.20898 8.47461L3.20898 2.54962C3.20898 2.43916 3.29853 2.34962 3.40898 2.34962L3.59228 2.34962C3.70274 2.34962 3.79228 2.43916 3.79228 2.54962L3.79228 8.47461L3.20898 8.47461Z"
                fill="#EC373C"
            />
            <path
                d="M3.40898 11.6816C3.29853 11.6816 3.20898 11.5921 3.20898 11.4816L3.20898 9.93164L3.79228 9.93164L3.79228 11.4816C3.79228 11.5921 3.70274 11.6816 3.59228 11.6816L3.40898 11.6816Z"
                fill="#EC373C"
            />
            <path
                d="M6.90898 11.6816C6.79853 11.6816 6.70898 11.5921 6.70898 11.4816L6.70898 7.5984L7.29228 7.5984L7.29228 9.64002L7.29228 11.4816C7.29228 11.5921 7.20274 11.6816 7.09228 11.6816L6.90898 11.6816Z"
                fill="#EC373C"
            />
            <path
                d="M6.70898 6.14062L6.70898 2.54888C6.70898 2.43843 6.79853 2.34888 6.90898 2.34888L7.09228 2.34888C7.20274 2.34888 7.29228 2.43843 7.29228 2.54888L7.29228 6.14062L6.70898 6.14062Z"
                fill="#EC373C"
            />
            <path
                d="M4.66738 9.34893C4.66738 9.99238 4.14413 10.5156 3.50068 10.5156C2.85723 10.5156 2.33398 9.99238 2.33398 9.34893C2.33398 8.70548 2.85723 8.18223 3.50068 8.18223C4.14413 8.18233 4.66738 8.70558 4.66738 9.34893ZM2.91738 9.34893C2.91738 9.6704 3.17931 9.93233 3.50068 9.93233C3.82206 9.93233 4.08398 9.6704 4.08398 9.34903C4.08398 9.02765 3.82206 8.76573 3.50068 8.76573C3.17931 8.76563 2.91738 9.02755 2.91738 9.34893Z"
                fill="#EC373C"
            />
            <path
                d="M10.409 11.6816C10.2985 11.6816 10.209 11.5921 10.209 11.4816L10.209 5.2649L10.7923 5.2649L10.7923 11.4816C10.7923 11.5921 10.7027 11.6816 10.5923 11.6816L10.409 11.6816Z"
                fill="#EC373C"
            />
            <path
                d="M10.209 3.80664L10.209 2.54834C10.209 2.43789 10.2985 2.34834 10.409 2.34834L10.5923 2.34834C10.7027 2.34834 10.7923 2.43789 10.7923 2.54834L10.7923 3.80664L10.209 3.80664Z"
                fill="#EC373C"
            />
            <path
                d="M11.6674 4.68291C11.6674 5.32636 11.1441 5.84961 10.5007 5.84961C9.85723 5.84961 9.33398 5.32636 9.33398 4.68291C9.33398 4.03946 9.85723 3.51621 10.5007 3.51621C11.1441 3.51621 11.6674 4.03946 11.6674 4.68291ZM9.91738 4.68291C9.91738 5.00489 10.1787 5.26621 10.5007 5.26621C10.8227 5.26621 11.084 5.00489 11.084 4.68291C11.084 4.36094 10.8227 4.09961 10.5007 4.09961C10.1787 4.09952 9.91738 4.36084 9.91738 4.68291Z"
                fill="#EC373C"
            />
            <path
                d="M8.16738 7.01494C8.16738 7.65839 7.64413 8.18164 7.00068 8.18164C6.35723 8.18164 5.83398 7.65839 5.83398 7.01494C5.83398 6.37149 6.35723 5.84825 7.00068 5.84825C7.64413 5.84825 8.16738 6.37149 8.16738 7.01494ZM6.41738 7.01494C6.41738 7.33632 6.67931 7.59824 7.00068 7.59824C7.32206 7.59824 7.58398 7.33632 7.58398 7.01494C7.58398 6.69357 7.32206 6.43164 7.00068 6.43164C6.67931 6.43164 6.41738 6.69357 6.41738 7.01494Z"
                fill="#EC373C"
            />
            <circle cx="10" cy="9.98438" r="6" fill="white" />
            <path
                d="M10 5C9.01109 5 8.0444 5.29324 7.22215 5.84265C6.39991 6.39206 5.75904 7.17295 5.3806 8.08658C5.00217 9.00021 4.90315 10.0055 5.09608 10.9755C5.289 11.9454 5.76521 12.8363 6.46447 13.5355C7.16373 14.2348 8.05465 14.711 9.02455 14.9039C9.99445 15.0969 10.9998 14.9978 11.9134 14.6194C12.827 14.241 13.6079 13.6001 14.1573 12.7779C14.7068 11.9556 15 10.9889 15 10C15 8.67392 14.4732 7.40215 13.5355 6.46447C12.5979 5.52678 11.3261 5 10 5ZM9.37917 7.5C9.37917 7.33424 9.44502 7.17527 9.56223 7.05806C9.67944 6.94085 9.83841 6.875 10.0042 6.875C10.1699 6.875 10.3289 6.94085 10.4461 7.05806C10.5633 7.17527 10.6292 7.33424 10.6292 7.5V10.3708C10.6292 10.4529 10.613 10.5342 10.5816 10.61C10.5502 10.6858 10.5041 10.7547 10.4461 10.8128C10.3881 10.8708 10.3192 10.9168 10.2433 10.9483C10.1675 10.9797 10.0862 10.9958 10.0042 10.9958C9.92209 10.9958 9.84082 10.9797 9.76499 10.9483C9.68916 10.9168 9.62026 10.8708 9.56223 10.8128C9.50419 10.7547 9.45815 10.6858 9.42674 10.61C9.39533 10.5342 9.37917 10.4529 9.37917 10.3708V7.5ZM10 13.125C9.85826 13.125 9.7197 13.083 9.60184 13.0042C9.48399 12.9255 9.39213 12.8135 9.33789 12.6826C9.28364 12.5516 9.26945 12.4075 9.29711 12.2685C9.32476 12.1295 9.39301 12.0018 9.49324 11.9016C9.59347 11.8013 9.72117 11.7331 9.86019 11.7054C9.99921 11.6778 10.1433 11.692 10.2743 11.7462C10.4052 11.8005 10.5171 11.8923 10.5959 12.0102C10.6746 12.128 10.7167 12.2666 10.7167 12.4083C10.7167 12.5984 10.6412 12.7807 10.5068 12.9151C10.3724 13.0495 10.1901 13.125 10 13.125Z"
                fill="#EC373C"
            />
        </svg>
    );
}
