import clsx from 'clsx';
import { useStyles } from './utils';

export default function CheckOutlineIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 21 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 17.5C13.6944 17.5 17.5 13.6944 17.5 9C17.5 4.30558 13.6944 0.5 9 0.5C4.30558 0.5 0.5 4.30558 0.5 9C0.5 13.6944 4.30558 17.5 9 17.5Z"
                stroke="#4DAC4A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.16536 9.76578L7.42085 12.0794C7.48123 12.1431 7.55505 12.1926 7.63694 12.2242C7.71883 12.2558 7.80674 12.2688 7.89427 12.2621C7.98249 12.2567 8.06849 12.2322 8.14642 12.1905C8.22436 12.1488 8.29239 12.0908 8.3459 12.0205L13.0997 5.78894"
                stroke="#4DAC4A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
