/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { TextField, Typography, Stack } from '@mui/material';
import { styles } from 'styles/style-templates';

export default function TessellInput(props) {
    const {
        sx,
        sxTypography,
        sxTextField,
        label,
        select = false,
        options,
        m,
        mt,
        mb,
        ml,
        mr,
        p,
        pt,
        pb,
        pl,
        pr,
        width = 'auto',
        variant = 'outlined',
        disabled,
        children,
        shouldAutoFocus,
        onlyTextLabel = false,
        customClassNameLabel,
        ...textFieldProps
    } = props;

    function GetComponent() {
        if (children) {
            return children;
        }
        if (select) {
            return (
                <TextField
                    select
                    variant={variant}
                    disabled={disabled}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            cursor: disabled ? 'not-allowed !important' : 'pointer !important',
                        },
                        '& .MuiOutlinedInput-input': {
                            cursor: disabled ? 'not-allowed !important' : 'pointer !important',
                        },
                    }}
                    {...textFieldProps}
                >
                    {options}
                </TextField>
            );
        }

        return (
            <TextField
                variant={variant}
                disabled={disabled}
                autoFocus={shouldAutoFocus}
                sx={{ ...sxTextField }}
                {...textFieldProps}
            />
        );
    }

    function OnlyTextLabel() {
        const { value } = textFieldProps;

        return (
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                height="37.13px"
                minHeight="37.13px"
            >
                {value}
            </Stack>
        );
    }

    return (
        <Stack
            m={m}
            mb={mb}
            mt={mt === 0 ? 0 : mt || 2}
            ml={ml}
            mr={mr}
            p={p}
            pl={pl}
            pb={pb}
            pr={pr}
            pt={pt}
            width={width}
            sx={sx}
        >
            {label && (
                <Typography
                    sx={{
                        ...styles.textBoxLabel,
                        ...(disabled ? { color: styles.color.monotoneDarkLight99 } : {}),
                        ...sxTypography,
                        width: 'fit-content',
                    }}
                    className={customClassNameLabel || ''}
                    mb={1}
                >
                    {label}
                </Typography>
            )}
            {onlyTextLabel ? OnlyTextLabel() : GetComponent()}
        </Stack>
    );
}
