export const InitializeNewFetchWithAxios = (axiosInstance) => {
    // Define a function that mimics the fetch API using axiosInstance
    const fetchWithAxios = async (
        url,
        options = { headers: {}, method: '', params: {}, body: {} },
    ) => {
        try {
            // Determine HTTP method (default to GET if not provided)
            const method = (options.method || 'GET').toLowerCase();

            // Set up axios config
            const config = {
                url,
                method,
                headers: options.headers || {},
                params: options.params || {},
                data: options.body || {},
            };

            // Make the request using axios
            const response = await axiosInstance(config);

            // Return a response object in a similar format to fetch
            return {
                ok: response.status >= 200 && response.status < 300,
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                json: async () => response.data,
                text: async () => JSON.stringify(response.data), // Convert response data to JSON string
            };
        } catch (error) {
            // Handle errors
            return Promise.reject(new Error('Failed to fetch'));
        }
    };

    // To support demo data, replace the global fetch function with fetchWithAxios
    // so that every request goes through axios interceptor and we can replace with dummy response if needed.
    window.fetch = fetchWithAxios as unknown as typeof fetch;
};
