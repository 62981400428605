import clsx from 'clsx';
import { useStyles } from './utils';

export default function ResetIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.0674 6.01859C12.6784 5.12757 12.0378 4.3694 11.2243 3.83699C10.4107 3.30457 9.45956 3.02103 8.48729 3.02112C5.89697 3.02112 3.76627 4.99296 3.51648 7.51733"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.9856 6.01853H13.1837C13.2231 6.01853 13.2621 6.01077 13.2984 5.99571C13.3348 5.98064 13.3678 5.95857 13.3957 5.93073C13.4235 5.9029 13.4456 5.86985 13.4606 5.83349C13.4757 5.79712 13.4835 5.75814 13.4835 5.71878V3.52063M3.93201 10.0152C4.70336 11.7797 6.46387 13.0126 8.51215 13.0126C11.103 13.0126 13.2332 11.0408 13.4835 8.51642"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.01462 10.0153H3.81647C3.73697 10.0153 3.66073 10.0468 3.60452 10.1031C3.5483 10.1593 3.51672 10.2355 3.51672 10.315V12.5132"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

