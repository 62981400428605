import MockRepository from 'api-clients/network-layer/demo-data/repository/MockRepository';

export const subscriptionData = MockRepository.getSubscriptionsData();

const Subscriptions = {
    getSubscriptions() {
        return subscriptionData.getSubscriptions();
    },

    getSubscriptionUsers() {
        return subscriptionData.getSubscriptionUser();
    },

    getSubscriptionRegions() {
        return subscriptionData.getSubscriptionRegions();
    },

    getSubscriptionAsConsumer() {
        return subscriptionData.getSubscriptionAsConsumer();
    },

    createSubscription(body) {
        return subscriptionData.createSubscription(body);
    },

    updateSubscription(subName, cloud, action) {
        return subscriptionData.updateSubscription(subName, cloud, action);
    },

    updateSubscriptionToEnable(name, body) {
        return subscriptionData.updateSubscriptionToEnable(name, body);
    },

    updateSubscriptionRegions(name, cloud, body) {
        return subscriptionData.updateSubscriptionRegions(name, cloud, body);
    },
};

export default Subscriptions;
