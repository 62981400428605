import clsx from 'clsx';
import { useStyles } from './utils';

export default function CorrectTickIcon(props = {}) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.306454 5.58462L3.44957 9.15162C3.64653 9.37515 3.91475 9.50118 4.19344 9.49999C4.47213 9.4988 4.73929 9.37158 4.93626 9.14567L10.6986 2.52414C11.1041 2.05687 11.0999 1.30423 10.6892 0.842901C10.2775 0.38157 9.61321 0.386326 9.2067 0.853602L4.1861 6.62381L1.78791 3.90338C1.3793 3.43848 0.715058 3.43848 0.306454 3.90338C-0.102151 4.36709 -0.102151 5.12091 0.306454 5.58462Z"
                fill="#4DAC4A"
            />
        </svg>
    );
}
