import clsx from 'clsx';
import { useStyles } from './utils';

export default function RefreshWithBackgroundIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="2" y="2" width="32" height="32" rx="16" fill="#11567F" />
            <rect x="2" y="2" width="32" height="32" rx="16" stroke="#F4F9FA" strokeWidth="4" />
            <rect
                width="18"
                height="18"
                transform="translate(9 9)"
                fill="white"
                fillOpacity="0.01"
            />
            <path
                d="M15.75 14.625H12.8138C13.5413 13.507 14.6106 12.6539 15.8624 12.1931C17.1142 11.7323 18.4814 11.6883 19.7602 12.0678C21.039 12.4472 22.1609 13.2298 22.9588 14.2988C23.7566 15.3679 24.1876 16.6661 24.1875 18H25.3125C25.3141 16.4956 24.8516 15.0273 23.9881 13.7954C23.1246 12.5635 21.9022 11.6278 20.4875 11.1161C19.0728 10.6043 17.5347 10.5414 16.0829 10.9358C14.6311 11.3302 13.3363 12.1628 12.375 13.32V11.25H11.25V15.75H15.75V14.625Z"
                fill="white"
            />
            <path
                d="M20.25 21.375H23.1863C22.4587 22.4931 21.3894 23.3461 20.1376 23.8069C18.8858 24.2678 17.5186 24.3117 16.2398 23.9323C14.961 23.5528 13.8391 22.7702 13.0412 21.7012C12.2434 20.6322 11.8124 19.3339 11.8125 18H10.6875C10.686 19.5044 11.1485 20.9727 12.0119 22.2046C12.8754 23.4366 14.0978 24.3722 15.5125 24.884C16.9272 25.3957 18.4653 25.4587 19.9171 25.0643C21.3689 24.6699 22.6637 23.8373 23.625 22.68V24.75H24.75V20.25H20.25V21.375Z"
                fill="white"
            />
        </svg>
    );
}
