import clsx from 'clsx';
import { useStyles } from './utils';

export default function GearsIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="62"
            height="44"
            viewBox="0 0 62 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.25"
                d="M29.2006 31.2206L31.263 28.9279C31.6581 28.4888 31.6959 27.8515 31.35 27.4746L29.4255 25.1896C30.2857 23.6916 30.8566 22.0692 31.1383 20.4099L34.0476 19.9553C34.5486 19.8989 34.9229 19.3879 34.9002 18.7914L34.7811 15.671C34.7584 15.0745 34.3463 14.5966 33.8435 14.5791L30.9095 14.3615C30.5012 12.7294 29.8074 11.1595 28.8338 9.73527L30.5768 7.30074C30.8925 6.8966 30.8055 6.26319 30.3783 5.85905L28.1476 3.73927C27.7204 3.33319 27.1003 3.29434 26.7336 3.6499L24.5085 5.62784C23.051 4.74379 21.4725 4.15701 19.8581 3.86751L19.4157 0.877286C19.3609 0.3624 18.8637 -0.0223098 18.2833 0.00100581L15.2473 0.123414C14.6669 0.146729 14.2019 0.570294 14.1849 1.08712L13.9732 4.10261C12.3852 4.52229 10.8577 5.23536 9.47203 6.23599L7.10332 4.44457C6.71012 4.1201 6.09384 4.20948 5.70063 4.64859L3.63817 6.94129C3.24307 7.3804 3.20526 8.01769 3.55121 8.39463L5.47566 10.6796C4.61552 12.1776 4.04461 13.8 3.76293 15.4593L0.853562 15.9139C0.352598 15.9703 -0.0217065 16.4813 0.000978623 17.0777L0.120075 20.1982C0.14276 20.7946 0.554874 21.2726 1.05773 21.2901L3.99168 21.5077C4.40001 23.1398 5.0938 24.7097 6.06737 26.1339L4.32439 28.5685C4.00869 28.9726 4.09565 29.606 4.52289 30.0101L6.7536 32.1299C7.18083 32.536 7.80089 32.5749 8.16764 32.2193L10.3908 30.2413C11.8483 31.1254 13.4268 31.7122 15.0412 32.0017L15.4855 34.9919C15.5403 35.5068 16.0375 35.8915 16.6179 35.8682L19.6539 35.7458C20.2343 35.7225 20.6993 35.2989 20.7163 34.7821L20.928 31.7666C22.516 31.3469 24.0435 30.6338 25.4292 29.6332L27.7979 31.4246C28.1911 31.7491 28.8074 31.6597 29.2006 31.2206ZM14.1754 21.5738C12.2188 19.7163 12.0978 16.5804 13.907 14.5694C15.7142 12.5584 18.7654 12.4341 20.722 14.2935C22.6786 16.151 22.7996 19.2869 20.9904 21.2979C19.1832 23.3088 16.132 23.4332 14.1754 21.5738Z"
                fill="#1E7EA1"
            />
            <path
                opacity="0.5"
                d="M48.8633 41.0557L50.1677 39.6043C50.4172 39.3265 50.4418 38.9243 50.2225 38.6834L49.0051 37.2358C49.5495 36.2877 49.9106 35.2618 50.0902 34.2106L51.9315 33.9231C52.2491 33.8862 52.4854 33.5636 52.4702 33.1867L52.3946 31.2126C52.3795 30.8338 52.1205 30.5326 51.801 30.5209L49.9446 30.383C49.6856 29.3493 49.2471 28.3565 48.6308 27.455L49.7329 25.9142C49.9333 25.6577 49.8785 25.2575 49.6081 25.001L48.196 23.6603C47.9257 23.4039 47.5343 23.3786 47.2999 23.604L45.8915 24.8553C44.969 24.2957 43.9709 23.9246 42.9481 23.74L42.6684 21.8475C42.6324 21.5211 42.3186 21.2783 41.9519 21.2938L40.0312 21.3715C39.6645 21.3871 39.3696 21.6532 39.3582 21.9816L39.224 23.8896C38.2183 24.1558 37.2523 24.6066 36.3751 25.24L34.876 24.1072C34.6265 23.9013 34.237 23.9595 33.9875 24.2374L32.6831 25.6888C32.4336 25.9666 32.409 26.3688 32.6283 26.6098L33.8457 28.0573C33.3013 29.0054 32.9402 30.0313 32.7606 31.0825L30.9193 31.37C30.6017 31.4069 30.3654 31.7295 30.3806 32.1064L30.4562 34.0805C30.4713 34.4593 30.7303 34.7605 31.0498 34.7722L32.9062 34.9101C33.1652 35.9438 33.6038 36.9366 34.22 37.8382L33.1179 39.3789C32.9175 39.6354 32.9742 40.0357 33.2427 40.2921L34.6548 41.6328C34.9252 41.8893 35.3165 41.9145 35.5509 41.6891L36.9593 40.4379C37.8818 40.9974 38.8799 41.3685 39.9027 41.5531L40.1824 43.4456C40.2184 43.772 40.5322 44.0149 40.8989 43.9993L42.8196 43.9216C43.1882 43.9061 43.4812 43.6399 43.4926 43.3115L43.6268 41.4035C44.6325 41.1373 45.5985 40.6866 46.4757 40.0531L47.9748 41.1859C48.2243 41.3919 48.6138 41.3336 48.8633 41.0557ZM39.3526 34.9509C38.1143 33.7754 38.0387 31.7897 39.1824 30.5171C40.3261 29.2444 42.2581 29.1667 43.4964 30.3422C44.7346 31.5177 44.8102 33.5034 43.6665 34.7761C42.5228 36.0487 40.5908 36.1264 39.3526 34.9509Z"
                fill="#1E7EA1"
            />
            <g opacity="0.5">
                <path
                    d="M44.6846 3.87894L43.2555 5.13993C42.9814 5.38086 42.9133 5.76945 43.1005 6.02786L44.1251 7.57641C43.4899 8.4352 43.0267 9.39114 42.7375 10.3937L40.9151 10.4598C40.6032 10.4598 40.3367 10.7435 40.3102 11.1146L40.1665 13.0439C40.1382 13.4131 40.3593 13.7376 40.6675 13.7842L42.4596 14.1339C42.5976 15.1695 42.9152 16.1876 43.4143 17.1358L42.1723 18.5056C41.9492 18.731 41.9586 19.1273 42.1931 19.4091L43.4199 20.878C43.6544 21.1597 44.0324 21.2296 44.2839 21.0373L45.7905 19.9842C46.6261 20.637 47.5562 21.1131 48.5317 21.4103L48.5959 23.2834C48.5959 23.6039 48.8719 23.8779 49.233 23.9051L51.1102 24.0528C51.4694 24.0819 51.7851 23.8546 51.8305 23.5379L52.1707 21.696C53.1783 21.5541 54.167 21.2277 55.0915 20.7148L56.4242 21.9913C56.6435 22.2206 57.0291 22.2108 57.3014 21.9699L58.7305 20.7089C59.0046 20.468 59.0727 20.0794 58.8855 19.821L57.8609 18.2724C58.4961 17.4137 58.9593 16.4577 59.2485 15.4551L61.0709 15.3891C61.3828 15.3891 61.6494 15.1054 61.6758 14.7343L61.8195 12.8049C61.8479 12.4358 61.6267 12.1113 61.3185 12.0647L59.5264 11.713C59.3884 10.6774 59.0708 9.66121 58.5717 8.7111L59.8138 7.34131C60.0368 7.11593 60.0274 6.71956 59.793 6.43783L58.5661 4.96895C58.3317 4.68722 57.9536 4.61727 57.7021 4.80962L56.1955 5.86272C55.3599 5.20988 54.4298 4.73385 53.4543 4.43657L53.3901 2.56355C53.3901 2.24297 53.1141 1.969 52.753 1.9418L50.8758 1.79414C50.5166 1.765 50.2009 1.99232 50.1555 2.30903L49.8153 4.15096C48.8077 4.29279 47.819 4.61921 46.8946 5.13216L45.5618 3.85563C45.3425 3.62636 44.9569 3.63607 44.6827 3.877L44.6846 3.87894ZM53.2615 10.9261C54.3372 12.2143 54.1916 14.1534 52.9383 15.2589C51.6849 16.3645 49.7983 16.2148 48.7226 14.9267C47.6469 13.6385 47.7925 11.6994 49.0459 10.5938C50.2992 9.48829 52.1859 9.6379 53.2615 10.9261Z"
                    fill="#1E7EA1"
                />
            </g>
        </svg>
    );
}
