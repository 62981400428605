import MockUtil from 'tools/MockUtil';
import MockRepository from 'api-clients/network-layer/demo-data/repository/MockRepository';

const iamData = MockRepository.getIamData();

const Iam = {
    getPersonasData() {
        return iamData.getPersonas();
    },
    getUsersData() {
        return iamData.getUsers();
    },
    getUpgradablePersonasData() {
        return iamData.getUpgradablePersonas();
    },
    getSsoData() {
        return iamData.getSso();
    },
    createPersona(reqBody) {
        return iamData.createPersona(reqBody);
    },
    createUser(reqBody) {
        return iamData.createUser(reqBody);
    },
};

export default Iam;
