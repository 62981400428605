import clsx from 'clsx';
import { useStyles } from './utils';

export default function OktaShortIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            viewBox="0 0 44 44"
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22 2C10.9813 2 2 10.9169 2 22C2 33.0831 10.9175 42 22 42C33.0825 42 42 33.0825 42 22C42 10.9175 33.0187 2 22 2ZM22 32C16.4587 32 12 27.5413 12 22C12 16.4587 16.4587 12 22 12C27.5413 12 32 16.4587 32 22C32 27.5413 27.5413 32 22 32Z"
                fill="#007DC1"
            />
        </svg>
    );
}
