export default function SampleLoaderEmptyIcon() {
    return (
        <svg
            width="104"
            height="104"
            viewBox="0 0 104 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                zoom: 1.2,
            }}
        >
            <circle
                cx="52"
                cy="53"
                r="43"
                stroke="#979797"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeDasharray="0.5 2"
            />
            <g filter="url(#filter0_dd_481_374)">
                <g filter="url(#filter1_dd_481_374)">
                    <circle cx="52" cy="52" r="23" fill="white" />
                </g>
                <g clipPath="url(#clip0_481_374)">
                    <path
                        d="M51.2108 56.1452C51.1267 58.695 51.2313 61.2634 51.5261 61.8879C51.8209 62.5124 52.4511 63.7261 54.619 63.3323C56.43 63.0031 57.0889 62.3663 57.3745 60.9604C57.5796 59.9261 57.9913 57.0532 58.0431 56.4651M48.6122 43.3604C48.6122 43.3604 38.7931 39.9609 39.2546 47.6967C39.3571 49.3416 42.0357 60.143 45.2383 56.8748C46.4084 55.6816 47.4662 54.7459 47.4662 54.7459M53.9191 42.74C53.5791 42.8302 59.3783 40.9433 62.6737 44.5125C63.8376 45.7739 62.4891 50.9259 59.2619 54.9848"
                        stroke="white"
                        strokeWidth="0.701947"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M59.2568 54.9844C59.2568 54.9844 59.4619 55.8612 62.523 55.3269C63.8623 55.0926 63.0563 55.9848 61.6975 56.5195C60.5828 56.9579 58.0837 57.0702 58.0432 56.4647C57.9381 54.9008 59.3584 55.3761 59.2558 54.9844C59.1635 54.6316 58.5277 54.2855 58.1073 53.4221C57.7407 52.6683 53.0772 46.8887 59.4004 47.747C59.6322 47.706 57.7509 42.6483 51.8323 42.5662C45.9137 42.4842 46.1101 48.7345 46.1101 48.7345"
                        stroke="white"
                        strokeWidth="0.701947"
                        strokeLinecap="round"
                        strokeLinejoin="bevel"
                    />
                    <path
                        d="M49.5545 55.5203C48.729 56.3012 48.9721 56.4381 47.3231 56.7262C45.6546 57.0185 46.635 57.5364 47.2749 57.6723C48.0507 57.8363 49.8453 58.0696 51.0579 56.6304C51.4271 56.192 51.0559 55.4921 50.5487 55.3147C50.3037 55.2285 49.976 55.1208 49.554 55.5198L49.5545 55.5203Z"
                        stroke="white"
                        strokeWidth="0.701947"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M49.5006 55.5079C49.4175 55.0484 49.6785 54.5029 49.9585 53.863C50.3789 52.9041 51.349 51.9443 50.5738 48.9011C49.9954 46.6333 46.1144 48.4294 46.1129 48.7371C46.1114 49.0447 46.2882 50.2958 46.0478 51.752C45.734 53.6533 47.4747 55.2607 49.4791 55.0966"
                        stroke="white"
                        strokeWidth="0.701947"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M48.5773 48.6906C48.5599 48.7958 48.8044 49.0752 49.1229 49.1131C49.4413 49.1511 49.7125 48.9316 49.7305 48.827C49.7484 48.7224 49.5038 48.6066 49.1849 48.5686C48.866 48.5307 48.5942 48.586 48.5773 48.6906Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.233812"
                    />
                    <path
                        d="M58.2636 48.4769C58.281 48.582 58.037 48.8614 57.718 48.8994C57.3991 48.9373 57.1273 48.7179 57.1099 48.6122C57.0925 48.5066 57.3371 48.3918 57.6555 48.3538C57.9739 48.3159 58.2451 48.3707 58.2631 48.4759L58.2636 48.4769Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.116906"
                    />
                    <path
                        d="M59.4017 47.751C59.454 48.575 59.1925 49.1354 59.1592 50.0132C59.1105 51.2879 59.876 52.7461 58.7224 54.2075"
                        stroke="white"
                        strokeWidth="0.701947"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
            <circle cx="21" cy="27" r="10" fill="white" />
            <path
                d="M25.5693 21.5996H16.4306C15.9718 21.5996 15.5998 21.9716 15.5998 22.4304V31.5692C15.5998 32.028 15.9718 32.4 16.4306 32.4H25.5693C26.0282 32.4 26.4001 32.028 26.4001 31.5692V22.4304C26.4001 21.9716 26.0282 21.5996 25.5693 21.5996Z"
                stroke="url(#paint0_linear_481_374)"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.7456 29.376V26.1941C19.7479 26.1452 19.7622 26.0976 19.7872 26.0555C19.8122 26.0134 19.8471 25.9781 19.889 25.9527C19.9308 25.9273 19.9782 25.9125 20.0271 25.9097C20.076 25.9069 20.1248 25.9161 20.1693 25.9365L22.9275 27.5234C22.9722 27.5509 23.0091 27.5894 23.0347 27.6352C23.0602 27.681 23.0737 27.7326 23.0737 27.7851C23.0737 27.8375 23.0602 27.8891 23.0347 27.9349C23.0091 27.9807 22.9722 28.0192 22.9275 28.0468L20.1693 29.6419C20.124 29.6605 20.075 29.668 20.0263 29.6639C19.9775 29.6597 19.9305 29.644 19.889 29.618C19.8476 29.592 19.813 29.5564 19.788 29.5144C19.7631 29.4723 19.7485 29.4249 19.7456 29.376V29.376Z"
                stroke="url(#paint1_linear_481_374)"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5998 23.9258H26.4001"
                stroke="url(#paint2_linear_481_374)"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="79" cy="17" r="12" fill="white" />
            <g clipPath="url(#clip1_481_374)">
                <ellipse
                    cx="78.1273"
                    cy="12.143"
                    rx="5.10546"
                    ry="2.21236"
                    stroke="url(#paint3_linear_481_374)"
                    strokeWidth="0.872727"
                />
                <path
                    d="M73.0219 15.7158C73.0219 16.8437 75.3076 17.758 78.1273 17.758C78.3265 17.758 78.5231 17.7534 78.7164 17.7446"
                    stroke="url(#paint4_linear_481_374)"
                    strokeWidth="0.872727"
                    strokeLinecap="round"
                />
                <path
                    d="M73.0219 12.3135V19.3114C73.0219 20.5219 75.3076 21.5033 78.1273 21.5033C78.2594 21.5033 78.3904 21.5011 78.52 21.4969M83.2328 12.3135V14.6437"
                    stroke="url(#paint5_linear_481_374)"
                    strokeWidth="0.872727"
                    strokeLinecap="round"
                />
                <path
                    d="M80.4836 20.142C80.4836 20.841 80.6909 21.5244 81.0793 22.1057C81.4677 22.6869 82.0197 23.1399 82.6656 23.4075C83.3114 23.675 84.0221 23.745 84.7077 23.6086C85.3934 23.4722 86.0232 23.1356 86.5175 22.6413C87.0118 22.147 87.3484 21.5172 87.4848 20.8315C87.6212 20.1459 87.5512 19.4352 87.2837 18.7894C87.0162 18.1435 86.5631 17.5915 85.9819 17.2031C85.4006 16.8147 84.7173 16.6074 84.0182 16.6074C83.0057 16.6063 82.0317 16.9959 81.2993 17.695"
                    stroke="url(#paint6_linear_481_374)"
                    strokeWidth="0.872727"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M81.1987 16.6749L80.9657 17.7714L82.0623 18.0045"
                    stroke="url(#paint7_linear_481_374)"
                    strokeWidth="0.872727"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M84.1269 18.2393V20.4144L85.5407 21.1213"
                    stroke="url(#paint8_linear_481_374)"
                    strokeWidth="0.872727"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <circle cx="87" cy="75" r="12" fill="white" />
            <ellipse
                cx="86.5636"
                cy="70.6597"
                rx="4.96364"
                ry="2.15091"
                stroke="url(#paint9_linear_481_374)"
                strokeWidth="0.872727"
            />
            <path
                d="M81.6 74.1338C81.6 75.2303 83.8223 76.1192 86.5636 76.1192C86.9579 76.1192 87.3414 76.1009 87.7091 76.0661"
                stroke="url(#paint10_linear_481_374)"
                strokeWidth="0.872727"
                strokeLinecap="round"
            />
            <path
                d="M81.6 70.8252V77.6287C81.6 78.8056 83.8223 79.7597 86.5636 79.7597C86.9579 79.7597 87.3414 79.74 87.7091 79.7027M91.5273 70.8252V73.0906"
                stroke="url(#paint11_linear_481_374)"
                strokeWidth="0.872727"
                strokeLinecap="round"
            />
            <path
                d="M90.881 74.6182C90.881 76.865 90.088 77.7902 87.709 77.7902"
                stroke="url(#paint12_linear_481_374)"
                strokeWidth="0.872727"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M90.881 80.9619C90.881 78.5829 90.088 77.7899 87.709 77.7899"
                stroke="url(#paint13_linear_481_374)"
                strokeWidth="0.872727"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M90.881 74.6182C90.881 76.9972 91.674 77.7902 94.053 77.7902"
                stroke="url(#paint14_linear_481_374)"
                strokeWidth="0.872727"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M90.881 80.9619C90.881 78.5829 91.674 77.7899 94.053 77.7899"
                stroke="url(#paint15_linear_481_374)"
                strokeWidth="0.872727"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="93.8727"
                y1="79.6375"
                x2="93.8727"
                y2="81.0557"
                stroke="url(#paint16_linear_481_374)"
                strokeWidth="0.872727"
                strokeLinecap="round"
            />
            <line
                x1="93.109"
                y1="80.2922"
                x2="94.5272"
                y2="80.2922"
                stroke="url(#paint17_linear_481_374)"
                strokeWidth="0.872727"
                strokeLinecap="round"
            />
            <circle
                cx="93.4363"
                cy="74.6181"
                r="0.381818"
                fill="#11567F"
                stroke="url(#paint18_linear_481_374)"
                strokeWidth="0.872727"
            />
            <circle cx="18" cy="82" r="11" fill="white" />
            <g clipPath="url(#clip2_481_374)">
                <path
                    d="M15 85.0011V78.144C15 77.6893 15.1806 77.2533 15.5021 76.9318C15.8236 76.6103 16.2596 76.4297 16.7143 76.4297H22.0714"
                    stroke="url(#paint19_linear_481_374)"
                    strokeWidth="0.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.5714 79.002V85.8591C20.5714 86.3138 20.3908 86.7498 20.0693 87.0713C19.7478 87.3928 19.3118 87.5734 18.8571 87.5734"
                    stroke="url(#paint20_linear_481_374)"
                    strokeWidth="0.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.1428 85.8591V85.4305C17.1428 85.3169 17.0977 85.2079 17.0173 85.1275C16.9369 85.0471 16.8279 85.002 16.7143 85.002H12.8571C12.7435 85.002 12.6345 85.0471 12.5541 85.1275C12.4737 85.2079 12.4286 85.3169 12.4286 85.4305V85.8591C12.4286 86.3138 12.6092 86.7498 12.9307 87.0713C13.2522 87.3928 13.6882 87.5734 14.1428 87.5734H18.8571C18.4025 87.5734 17.9664 87.3928 17.6449 87.0713C17.3235 86.7498 17.1428 86.3138 17.1428 85.8591Z"
                    stroke="url(#paint21_linear_481_374)"
                    strokeWidth="0.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.0714 76.4297C22.4693 76.4297 22.8508 76.5877 23.1321 76.869C23.4134 77.1503 23.5714 77.5319 23.5714 77.9297V78.5725C23.5714 78.6862 23.5263 78.7952 23.4459 78.8756C23.3655 78.956 23.2565 79.0011 23.1429 79.0011H20.5714V77.9297C20.5714 77.5319 20.7295 77.1503 21.0108 76.869C21.2921 76.5877 21.6736 76.4297 22.0714 76.4297V76.4297Z"
                    stroke="url(#paint22_linear_481_374)"
                    strokeWidth="0.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17 80H19"
                    stroke="url(#paint23_linear_481_374)"
                    strokeWidth="0.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17 82H19"
                    stroke="url(#paint24_linear_481_374)"
                    strokeWidth="0.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_dd_481_374"
                    x="0.298487"
                    y="0.298487"
                    width="103.403"
                    height="103.403"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="10"
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect1_dropShadow_481_374"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="9.35076" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.286275 0 0 0 0 0.592157 0 0 0 0 0.858824 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_481_374"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="10"
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect2_dropShadow_481_374"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="7.01307" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.286275 0 0 0 0 0.592157 0 0 0 0 0.858824 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_481_374"
                        result="effect2_dropShadow_481_374"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_481_374"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_dd_481_374"
                    x="25.0571"
                    y="25.7143"
                    width="53.8857"
                    height="53.8857"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="0.657143" />
                    <feGaussianBlur stdDeviation="1.97143" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.215686 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_481_374"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="0.657143" />
                    <feGaussianBlur stdDeviation="1.31429" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.215686 0 0 0 0.06 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_481_374"
                        result="effect2_dropShadow_481_374"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_481_374"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_481_374"
                    x1="15.5998"
                    y1="21.5996"
                    x2="27.7323"
                    y2="23.3944"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_481_374"
                    x1="19.7456"
                    y1="25.9092"
                    x2="23.5014"
                    y2="26.4015"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_481_374"
                    x1="15.5998"
                    y1="23.9258"
                    x2="19.0896"
                    y2="29.5015"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_481_374"
                    x1="73.0219"
                    y1="9.93066"
                    x2="83.5199"
                    y2="13.5144"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_481_374"
                    x1="73.0219"
                    y1="15.7158"
                    x2="78.6083"
                    y2="18.0202"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_481_374"
                    x1="73.0219"
                    y1="12.3135"
                    x2="84.4349"
                    y2="14.1894"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_481_374"
                    x1="80.4836"
                    y1="16.6074"
                    x2="88.4247"
                    y2="17.7821"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_481_374"
                    x1="80.927"
                    y1="16.6846"
                    x2="82.1626"
                    y2="16.7864"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_481_374"
                    x1="85.5407"
                    y1="18.2393"
                    x2="83.9263"
                    y2="18.3564"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_481_374"
                    x1="81.6"
                    y1="68.5088"
                    x2="91.8064"
                    y2="71.993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_481_374"
                    x1="81.6"
                    y1="74.1338"
                    x2="87.4092"
                    y2="76.778"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_481_374"
                    x1="81.6"
                    y1="70.8252"
                    x2="92.696"
                    y2="72.649"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear_481_374"
                    x1="87.709"
                    y1="74.6182"
                    x2="91.2723"
                    y2="75.1453"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint13_linear_481_374"
                    x1="87.709"
                    y1="80.9619"
                    x2="91.2723"
                    y2="80.4348"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint14_linear_481_374"
                    x1="94.053"
                    y1="74.6182"
                    x2="90.4898"
                    y2="75.1453"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint15_linear_481_374"
                    x1="94.053"
                    y1="80.9619"
                    x2="90.4898"
                    y2="80.4348"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint16_linear_481_374"
                    x1="93.4363"
                    y1="79.2012"
                    x2="92.6369"
                    y2="81.5601"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint17_linear_481_374"
                    x1="92.6727"
                    y1="80.7285"
                    x2="95.0316"
                    y2="81.5279"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint18_linear_481_374"
                    x1="93.0545"
                    y1="74.2363"
                    x2="93.9123"
                    y2="74.3632"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint19_linear_481_374"
                    x1="15"
                    y1="76.4297"
                    x2="22.9983"
                    y2="77.4058"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint20_linear_481_374"
                    x1="18.8571"
                    y1="79.002"
                    x2="20.8233"
                    y2="79.0601"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint21_linear_481_374"
                    x1="12.4286"
                    y1="85.002"
                    x2="18.9202"
                    y2="87.4027"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint22_linear_481_374"
                    x1="20.5714"
                    y1="76.4297"
                    x2="23.9156"
                    y2="77.0068"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint23_linear_481_374"
                    x1="17"
                    y1="80"
                    x2="19.1111"
                    y2="80.6246"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <linearGradient
                    id="paint24_linear_481_374"
                    x1="17"
                    y1="82"
                    x2="19.1111"
                    y2="82.6246"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#02AAB0" />
                    <stop offset="1" stopColor="#00CDAC" />
                </linearGradient>
                <clipPath id="clip0_481_374">
                    <rect
                        width="26.7097"
                        height="26.7097"
                        fill="white"
                        transform="translate(37.9032 39.3867)"
                    />
                </clipPath>
                <clipPath id="clip1_481_374">
                    <rect
                        width="15.7091"
                        height="15.7091"
                        fill="white"
                        transform="translate(72.2364 9.14551)"
                    />
                </clipPath>
                <clipPath id="clip2_481_374">
                    <rect width="12" height="12" fill="white" transform="translate(12 76)" />
                </clipPath>
            </defs>
        </svg>
    );
}
