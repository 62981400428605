import MMCServersLoader from './MMC/serversloader';
import UBSServersLoader from './UBS/serversloader';

const tenantUrl = window.location.hostname;

const getDataLoaders = () => {
    let serversloader;

    switch (tenantUrl) {
        case 'localhost':
        case 'mmc-demo.tessell.com':
        case 'mmc.tsl-terls.cloud':
        case 'mmc-demo.aws.tessell.com':
        case 'demo.aws.tessell.com':
            serversloader = new MMCServersLoader();
            break;
        case 'ubs.tsl-terls.cloud':
        case 'ubs-demo.tessell.com':
        case 'ubs-demo.azure.tessell.com':
        case 'demo.azure.tessell.com':
        case 'msftgsi.azure.tessell.com':
            serversloader = new UBSServersLoader();
            break;
        default:
            serversloader = new MMCServersLoader();
            break;
    }

    return {
        serversloader,
    };
};

const { serversloader } = getDataLoaders();

export default class ServersData {
    // eslint-disable-next-line class-methods-use-this
    getDbServersListData() {
        return serversloader.initDbServersList();
    }

    // eslint-disable-next-line class-methods-use-this
    getDbServerDetailData(id) {
        return serversloader.getDbServerDetail(id);
    }

    // eslint-disable-next-line class-methods-use-this
    getDbServersSharingEnabledData() {
        return serversloader.getDbServersSharingEnabled();
    }
}
