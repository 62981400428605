import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export const createdManualSnapshot = (requestBody) => ({
    id: uuidv4(),
    name: requestBody.name,
    description: null,
    snapshotTime: Date.now(),
    status: 'CREATING',
    size: 0,
    manual: true,
    cloudAvailability: [],
    availabilityConfig: [],
    databases: null,
    sharedWith: {
        users: [],
    },
    backupStatus: null,
});

export const findDetailsOfScript = (id, scriptsJson) =>
    scriptsJson.response.filter((script) => script.id === id);

export const findDetailsOfSnapshot = (amId, id, AvailabilityMachinesAllSnapshots) =>
    AvailabilityMachinesAllSnapshots[amId]?.snapshots?.filter((snapshot) => snapshot.id === id);

export const createdSanitizedSnapshot = (requestBody, _, scriptsJson) => ({
    id: uuidv4(),
    name: requestBody.name,
    snapshotTime: Date.now(),
    status: 'CREATING',
    size: 0,
    manual: true,
    cloudAvailability: [
        {
            cloud: 'AWS',
            regions: [
                {
                    region: 'ap-south-1',
                    status: 'NOT_AVAILABLE',
                },
            ],
        },
    ],
    availabilityConfig: [
        {
            availabilityConfiguredManually: true,
            dapId: null,
            cloudAvailabilityConfig: [
                {
                    cloud: 'AWS',
                    regions: [
                        {
                            region: 'ap-south-1',
                            status: 'CREATING',
                        },
                    ],
                },
            ],
        },
    ],
    sanitizationInfo: {
        sourceSnapshotId: requestBody.snapshotId,
        sanitizationScheduleId: null,
        sanitizationSchedule: null,
        sanitizationScriptId: requestBody.scriptId,
        sanitizationScript: `${findDetailsOfScript(requestBody.scriptId, scriptsJson)?.[0].name}`, // find in scriptsjson
        scriptVersion: requestBody.scriptVersion,
    },
    databases: [
        {
            id: '6e68068e-e6bc-4005-8657-58243f45350a',
            name: 'db1',
            status: 'READY',
        },
    ],
    sharedWith: {
        users: [],
    },
    backupStatus: null,
});

export const createdBackup = (requestBody, amId, AvailabilityMachinesAllSnapshots) => ({
    id: uuidv4(),
    name: requestBody.name,
    backupTime: Date.now(),
    status: 'CREATING',
    size: 0,
    manual: true,
    cloudAvailability: [
        {
            cloud: 'AWS',
            regions: [
                {
                    region: 'ap-south-1',
                    availabilityZones: null,
                },
            ],
        },
    ],
    availabilityConfig: [
        {
            availabilityConfiguredManually: true,
            dapId: null,
            cloudAvailabilityConfig: [
                {
                    cloud: 'AWS',
                    regions: [
                        {
                            region: 'ap-south-1',
                            status: 'FAILED',
                        },
                    ],
                },
            ],
        },
    ],
    databases: [
        {
            id: '6e68068e-e6bc-4005-8657-58243f45350a',
            name: 'db1',
            status: 'READY',
        },
    ],
    backupInfo: {
        sourceSnapshotId: requestBody.snapshotId,
        snapshotName: `${
            findDetailsOfSnapshot(
                amId,
                requestBody.snapshotId,
                AvailabilityMachinesAllSnapshots,
            )?.[0].name
        }`,
        snapshotTime: `${
            findDetailsOfSnapshot(
                amId,
                requestBody.snapshotId,
                AvailabilityMachinesAllSnapshots,
            )?.[0].snapshotTime
        }`,
    },
    sharedWith: {
        users: null,
    },
    downloadUrlStatus: null,
});

export const createAccessPolicy = (requestBody, amId, AvailabilityMachines) => ({
    id: uuidv4(),
    name: requestBody.name,
    availabilityMachineId: amId,
    tessellServiceId: '6c2a1d83-0ab7-4819-980c-d1535294541f',
    serviceName: `${AvailabilityMachines.response.filter((am) => am.id === amId)?.[0].serviceName}`,
    engineType: `${AvailabilityMachines.response.filter((am) => am.id === amId)?.[0].engineType}`,
    contentType: requestBody.contentType,
    status: 'ACTIVE',
    contentInfo: requestBody.contentInfo,
    cloudAvailability: [
        {
            cloud: Object.keys(requestBody.targetCloudLocations)?.[0],
            regions: Object.values(requestBody.targetCloudLocations)?.[0].map((reg) => ({
                region: reg,
                availabilityZones: null,
            })),
        },
    ],
    dataAccessConfig: requestBody.dataAccessConfig,
    owner: 'bala@tessell-demo.com',
    loggedInUserRole: 'OWNER',
    sharedWith: {
        users: requestBody.users.map((user) => ({
            emailId: user,
            role: 'DATAFLIX',
        })),
    },
    dateCreated: Date.now(),
    dateModified: Date.now(),
});

export const setInSessionStorage = (requestBody, id, modifyFn, cacheName, ...rest) => {
    const newData = modifyFn(JSON.parse(requestBody), id, ...rest);
    const oldData = sessionStorage.getItem(cacheName)
        ? JSON.parse(sessionStorage.getItem(cacheName))
        : {};

    if (oldData && oldData[id]) {
        sessionStorage.setItem(
            cacheName,
            JSON.stringify({
                ...oldData,
                [id]: [...oldData[id], newData],
            }),
        );
    } else {
        sessionStorage.setItem(cacheName, JSON.stringify({ [id]: [newData] }));
    }

    return newData;
};

export const setInCacheStorage = (requestBody, id, modifyFn, cacheName, ...rest) => {
    const newData = modifyFn(JSON.parse(requestBody), id, ...rest);
    const oldData = localStorage.getItem(cacheName)
        ? JSON.parse(localStorage.getItem(cacheName))
        : {};

    if (oldData) {
        if (oldData[id]) {
            localStorage.setItem(
                cacheName,
                JSON.stringify({
                    ...oldData,
                    [id]: [...oldData[id], newData],
                }),
            );
        } else {
            localStorage.setItem(
                cacheName,
                JSON.stringify({
                    ...oldData,
                    [id]: [newData],
                }),
            );
        }
    } else {
        localStorage.setItem(cacheName, JSON.stringify({ [id]: [newData] }));
    }

    return newData;
};

export const generateSnapshotsData = (SampleAutoSnapshot) => {
    const generatedSnapshots = [];

    // Get the current date
    const currentDate = moment();

    // Loop for each day for 35 days
    for (let i = 0; i < 35; i += 1) {
        const newObj = JSON.parse(JSON.stringify(SampleAutoSnapshot));

        const nameToUseForSnapshot = `${SampleAutoSnapshot.name} ${currentDate.format(
            'YYYY-MM-DD',
        )} ${currentDate.format('HH-mm')} UTC`;

        newObj.id = uuidv4();
        newObj.name = nameToUseForSnapshot;
        newObj.snapshotTime = currentDate.utc().toDate();
        const randomNumber = 9073118412800 + (Math.floor(Math.random() * 100) + 1) * 1073741824; // 8500 gib + randomNumber

        newObj.size = randomNumber;

        generatedSnapshots.push(newObj);

        // Subtract one day from the current date
        currentDate.subtract(1, 'days');
    }

    return generatedSnapshots;
};

export function checkIfUserExists(users, emailId) {
    return users.some((el) => el.emailId === emailId);
}
