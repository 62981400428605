import { AvgServiceLoadResponseType } from 'pages/dbServices/my-services/monitoring/components/avgDbLoad/types/AvgServiceLoadTypes';
import {
    decreaseValueXPercent,
    getRandomNumberInRange,
    getUtcStringDifferenceInMilliseconds,
    graphLineType,
    increaseValueXPercent,
    isWeekdayWorkingHours,
    isWeekend,
    personasEnum,
    randomPlusMinusXPercent,
    spikeInGraphTitles,
    valueRangeForUnitMap,
    zeroValueTitles,
} from './myserviceutil';
import { PerfMetricEnum } from 'pages/dbServices/my-services/monitoring/components/common/types/PerfMetricEnum';
import {
    TopDimensionRowValues,
    TopDimensionsResponseTypes,
} from 'pages/dbServices/my-services/monitoring/components/topDimensions/types/TopDimensionsResponseTypes';
import { additionalMetricsName, topDimensionsRowGetter } from './topDimensionTableInfo';
import { ExtendedPerfMetricsResponseType } from 'pages/dbServices/my-services/monitoring/components/queryDetails/types/ExtendedPerfMetricsResponseType';
import { extendedPerfMetricsTableValuesForSQL } from './getExtendedPerfMetricsInfo';

type GetTopDimensionsDataProps = {
    requestBody: Partial<TopDimensionsResponseTypes>;
};

export const getTopDimensionsData = (
    props: GetTopDimensionsDataProps,
): TopDimensionsResponseTypes => {
    const { requestBody } = props;

    const { startTime, endTime, serviceInstanceId, entityId, sliceBy, metricType, numTopK } =
        requestBody;

    const differenceInMilliseconds = getUtcStringDifferenceInMilliseconds(startTime, endTime);

    const topDimensionsRowValues = topDimensionsRowGetter[metricType]();

    const ratioBucket = getRatioBucket(topDimensionsRowValues.length);
    currentRatioBucket = topDimensionsRowValues?.map((row, ind) => ({
        metricValue: row,
        ratioVal: ratioBucket[ind],
    }));
    let metricDistribution = [];

    metricDistribution = topDimensionsRowValues.map((entityName, indexVal) => {
        const durationInSeconds = differenceInMilliseconds / 1000;

        const additionalMetrics = additionalMetricsName().map((colName) => ({
            metricName: colName.metricName,
            metricValue: (colName.totalValue * ratioBucket[indexVal]) / durationInSeconds,
        }));

        return {
            value: entityName,
            additionalMetrics,
            metricDistribution: [],
        } as TopDimensionRowValues;
    });

    return {
        startTime,
        endTime,
        serviceInstanceId,
        entityId,
        sliceBy,
        metricValues: metricDistribution,
        extendedPerfMetricsAvailable: metricType === PerfMetricEnum.SQL,
        numTopK,
        metricType,
    };
};

const getRatioBucket = (count) => {
    const ratioBucket = [];
    let totalBucketValue = 0;
    for (let i = 0; i < count; i += 1) {
        const randomNum = getRandomNumberInRange(10, 100);
        totalBucketValue += randomNum;
        ratioBucket.push(randomNum);
    }
    const ratioValues = ratioBucket?.map((val) => val / totalBucketValue);
    return ratioValues;
};

let currentRatioBucket = [];

type GetExtendedPerfMetricsDataProps = {
    requestBody: Partial<ExtendedPerfMetricsResponseType>;
};

export const getExtendedPerfMetricsData = (props: GetExtendedPerfMetricsDataProps) => {
    const { requestBody } = props;

    const {
        startTime,
        endTime,
        serviceInstanceId,
        serviceId,
        sliceBy,
        metricType,
        numTopK,
        metricValue,
    } = requestBody;

    const differenceInMilliseconds = getUtcStringDifferenceInMilliseconds(startTime, endTime);

    const extendedPerfMetricsRowValues = extendedPerfMetricsTableValuesForSQL()[metricValue];

    const ratioBucket = getRatioBucket(extendedPerfMetricsRowValues.length);

    const metricValueRatio = currentRatioBucket?.find(
        (ratio) => ratio.metricValue === metricValue,
    )?.ratioVal;

    let metricDistribution = [];

    metricDistribution = extendedPerfMetricsRowValues.map((entityName, indexVal) => {
        const durationInSeconds = differenceInMilliseconds / 1000;

        const additionalMetrics = additionalMetricsName().map((colName) => ({
            metricName: colName.metricName,
            metricValue: Number(
                (
                    (colName.totalValue * metricValueRatio * ratioBucket[indexVal]) /
                    durationInSeconds
                ).toFixed(3),
            ),
        }));

        return {
            value: entityName,
            additionalMetrics,
            metricDistribution: [],
        } as TopDimensionRowValues;
    });

    return {
        startTime,
        endTime,
        serviceInstanceId,
        serviceId,
        sliceBy,
        metricValue,
        extendedMetricValues: metricDistribution,
        numTopK,
        metricType,
    };
};
