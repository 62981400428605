import clsx from 'clsx';
import { useStyles } from './utils';

export default function InfoIcon(props) {
    const { className, type } = props;
    const iconStyle = useStyles(props);

    if (type === 'filled') {
        return (
            <svg
                className={clsx({ [className]: !!className })}
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 14 14"
            >
                <path
                    fill="#11567F"
                    d="M7 .5A6.5 6.5 0 1013.5 7 6.507 6.507 0 007 .5zM8 10a.5.5 0 01-.5.5 1 1 0 01-1-1V7a.5.5 0 010-1 1 1 0 011 1v2.5a.5.5 0 01.5.5zM6 4.25a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
                />
            </svg>
        );
    }

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_288_1265)">
                <path
                    d="M9 17.4375C13.6599 17.4375 17.4375 13.6599 17.4375 9C17.4375 4.3401 13.6599 0.5625 9 0.5625C4.3401 0.5625 0.5625 4.3401 0.5625 9C0.5625 13.6599 4.3401 17.4375 9 17.4375Z"
                    fill="var(--icon-color)"
                />
                <path
                    d="M10.5231 12.3693H9.96058C9.66223 12.3693 9.37604 12.2507 9.16499 12.0398C8.95404 11.8288 8.83555 11.5426 8.83555 11.2443L9.16508 8.27295C9.16508 8.12377 9.10576 7.98067 9.00027 7.87522C8.89481 7.7697 8.75171 7.71045 8.60254 7.71045H8.04004"
                    fill="white"
                />
                <path
                    d="M10.5231 12.3693H9.96058C9.66223 12.3693 9.37604 12.2507 9.16499 12.0398C8.95404 11.8288 8.83555 11.5426 8.83555 11.2443L9.16508 8.27295C9.16508 8.12377 9.10576 7.98067 9.00027 7.87522C8.89481 7.7697 8.75171 7.71045 8.60254 7.71045H8.04004"
                    stroke="white"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.375 5.34375C8.375 5.1539 8.52891 5 8.71875 5C8.90862 5 9.0625 5.15388 9.0625 5.34375C9.0625 5.53362 8.90862 5.6875 8.71875 5.6875C8.52891 5.6875 8.375 5.5336 8.375 5.34375Z"
                    fill="white"
                    stroke="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_288_1265">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
