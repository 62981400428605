import clsx from 'clsx';
import { useStyles } from './utils';

export default function ProfileIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.0625 4.50008C4.0625 5.54433 4.47733 6.5458 5.21572 7.2842C5.95411 8.02259 6.95559 8.43741 7.99984 8.43741C9.04408 8.43741 10.0456 8.02259 10.784 7.2842C11.5223 6.5458 11.9372 5.54433 11.9372 4.50008C11.9372 3.45583 11.5223 2.45436 10.784 1.71596C10.0456 0.977569 9.04408 0.562744 7.99984 0.562744C6.95559 0.562744 5.95411 0.977569 5.21572 1.71596C4.47733 2.45436 4.0625 3.45583 4.0625 4.50008V4.50008Z"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.68774 17.437C1.13546 17.437 0.680705 16.9871 0.755995 16.44C0.97219 14.8689 1.6953 13.4006 2.82944 12.2664C4.20074 10.8951 6.06062 10.1248 7.99994 10.1248C9.93925 10.1248 11.7991 10.8951 13.1704 12.2664C14.3046 13.4006 15.0277 14.8689 15.2439 16.44"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
