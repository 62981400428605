/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */
import clsx from 'clsx';
import { useStyles } from './utils';

export default function StoppingIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.1182 12.1025C18.9237 12.9306 18.6191 13.7288 18.2124 14.4759"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.5117 16.6924C15.8957 17.279 15.2084 17.7858 14.4658 18.2007"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.8276 0.622559C11.6751 0.697632 12.5081 0.888867 13.3035 1.19089"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.7397 2.5542C16.4144 3.07229 17.0123 3.68337 17.5156 4.3692"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.8335 6.8335C19.124 7.6331 19.2964 8.47081 19.3452 9.32016"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.875 19.1436C10.6684 19.3898 9.42524 19.396 8.21633 19.1617C7.00742 18.9275 5.85649 18.4575 4.82924 17.7784C2.75462 16.4071 1.30974 14.2677 0.812459 11.8311C0.315178 9.39438 0.80623 6.85996 2.17759 4.78534C3.54895 2.71072 5.68828 1.26584 8.12496 0.768555"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.88889 6.5H13.1111C13.3259 6.5 13.5 6.67411 13.5 6.88889V13.1111C13.5 13.3259 13.3259 13.5 13.1111 13.5H6.88889C6.67411 13.5 6.5 13.3259 6.5 13.1111V6.88889C6.5 6.67411 6.67411 6.5 6.88889 6.5Z"
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
