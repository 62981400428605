import clsx from 'clsx';
import { useStyles } from './utils';

export default function ResetIconV2(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.66699 6.31706V9.65039H6.00033M13.3337 10.9837C13.0023 7.98372 11.089 5.65039 8.00033 5.65039C6.18033 5.65039 4.04766 7.16239 3.13766 9.31706"
                stroke="#666666"
                strokeWidth="1.5"
            />
        </svg>
    );
}
