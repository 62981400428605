import Computes from 'api-clients/network-layer/demo-data/REST/Governance/computes';
import { getDomainUrl } from 'constants/urls';

export const mockGetMap = {
    [`${getDomainUrl()}/compute-service/computes?page-size=1000&include-availability=true&use-cases=PROVISIONING`]:
        {
            handler: () => {
                const responseData = Computes.getComputesData();
                return [200, responseData];
            },
        },
    [`${getDomainUrl()}/compute-service/computes?page-size=1000&include-availability=true&use-cases=MILVUS`]:
        {
            handler: () => {
                const responseData = Computes.getComputesData();
                return [200, responseData];
            },
        },
    [`${getDomainUrl()}/compute-service/computes?page-size=1000&include-availability=true`]: {
        handler: () => {
            const responseData = Computes.getComputesData();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/compute-service/computes?page-size=1000`]: {
        handler: () => {
            const responseData = Computes.getComputesData();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/compute-service/governance/computes?page-size=1000&include-availability=true`]:
        {
            handler: () => {
                const responseData = Computes.getComputesData();
                return [200, responseData];
            },
        },
};
