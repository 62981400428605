/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */
import clsx from 'clsx';
import { useStyles } from './utils';

export default function SwitchOverIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="14"
            height="20"
            viewBox="0 0 14 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.125 10.625L1.375 19.375"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.125 19.375H1.375V15.625"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.875 9.375L12.625 0.625"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.875 0.625H12.625V4.375"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
