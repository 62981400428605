import { useStyles } from './utils';

export default function DeleteScheduleIcon(props) {
    const iconStyle = useStyles(props);
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1003_109)">
                <path
                    d="M1.44629 4.17676H14.5543"
                    stroke="#11567F"
                    strokeWidth="1.14695"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.08496 4.17676H12.916V14.0078C12.916 14.2975 12.8009 14.5753 12.596 14.7802C12.3912 14.985 12.1134 15.1001 11.8236 15.1001H4.1773C3.88759 15.1001 3.60975 14.985 3.4049 14.7802C3.20005 14.5753 3.08496 14.2975 3.08496 14.0078V4.17676Z"
                    stroke="#11567F"
                    strokeWidth="1.14695"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.26904 4.17691V3.63074C5.26904 2.90648 5.55676 2.21188 6.06889 1.69975C6.58102 1.18761 7.27562 0.899902 7.99988 0.899902C8.72415 0.899902 9.41875 1.18761 9.93088 1.69975C10.443 2.21188 10.7307 2.90648 10.7307 3.63074V4.17691"
                    stroke="#11567F"
                    strokeWidth="1.14695"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.36182 6.36157V12.3694"
                    stroke="#11567F"
                    strokeWidth="1.14695"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.63867 6.36157V12.3694"
                    stroke="#11567F"
                    strokeWidth="1.14695"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1003_109">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
