import Cache from 'tools/Cache';
import SupportUtil from 'tools/SupportUtil';
import HttpStatusCodeEnum from 'types/HttpStatusCodeEnum';

const passwordExpiryErrorCode = 'passwordExpired';
const newPasswordRequiredCode = 'NEW_PASSWORD_REQUIRED';
export const checkIfUnauthorized = (error) => {
    if (
        error?.response?.data &&
        error.response.status === HttpStatusCodeEnum.UNAUTHORIZED &&
        !error.response.data.code?.includes(passwordExpiryErrorCode) &&
        !error.response.data.message?.includes(newPasswordRequiredCode)
    ) {
        // Perform logout or redirect to the login page
        Cache.clear();
        SupportUtil.reset();

        // Redirect to login page
        window.location.href = '/login';
    }
};
