import clsx from 'clsx';
import { useStyles } from './utils';

export default function EnableIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.5 9.07553C17.5 13.7358 13.8785 17.5 9.12345 17.5C4.3504 17.5 0.5 13.7179 0.5 9.07553C0.5 4.42233 4.36135 0.5 9.12345 0.5C13.8678 0.5 17.5 4.40419 17.5 9.07553Z"
                stroke="green"
            />
            <path
                d="M4.31819 9.98931C4.10518 9.81358 3.79005 9.8438 3.61431 10.0568C3.43858 10.2698 3.4688 10.585 3.68181 10.7607L4.31819 9.98931ZM7.18182 13L6.86363 13.3857L7.21876 13.6787L7.53999 13.3489L7.18182 13ZM14.3582 6.34887C14.5509 6.15106 14.5467 5.8345 14.3489 5.64183C14.1511 5.44915 13.8345 5.45331 13.6418 5.65113L14.3582 6.34887ZM3.68181 10.7607L6.86363 13.3857L7.50001 12.6143L4.31819 9.98931L3.68181 10.7607ZM7.53999 13.3489L14.3582 6.34887L13.6418 5.65113L6.82364 12.6511L7.53999 13.3489Z"
                fill="green"
            />
        </svg>
    );
}
