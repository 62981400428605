/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function ComputeIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.40513 16.9995H16.4296C17.2155 16.9995 17.5 16.2351 17.5 15.7351V7.99951C17.5 7.35381 16.7786 6.99951 16.5954 6.99951L8.5 7C7.64113 7 7.5 7.71291 7.5 8V16.0451C7.5 16.4995 7.90513 16.9995 8.40513 16.9995ZM8.40513 8.90437C8.40513 8.35208 8.85284 7.90437 9.40513 7.90437H15.5954C16.1477 7.90437 16.5954 8.35208 16.5954 8.90437V15.0944C16.5954 15.6467 16.1477 16.0944 15.5954 16.0944H9.40513C8.85284 16.0944 8.40513 15.6467 8.40513 15.0944V8.90437Z"
                fill="var(--icon-color)"
            />
            <path
                d="M24.0155 7.86133C24.2829 7.86133 24.4997 7.64453 24.4997 7.3768C24.4997 7.10933 24.2829 6.89253 24.0155 6.89253H20.171V7.61547C20.171 7.61547 20.171 7.39866 20.171 7.13093C20.171 6.86347 20.171 6.6464 20.171 6.6464V4.81333C20.171 4.5456 19.9542 4.3288 19.6865 4.3288H16.8534V0.484267C16.8534 0.2168 16.6366 0 16.3691 0C16.1017 0 15.8846 0.2168 15.8846 0.484267V4.3288H14.6073C14.6073 4.3288 14.407 4.3288 14.1393 4.3288C13.8718 4.3288 13.6385 4.3288 13.6385 4.3288H12.9688V0.484267C12.9688 0.2168 12.752 0 12.4843 0C12.2165 0 11.9997 0.2168 11.9997 0.484267V4.3288H9.11565V0.484267C9.11565 0.2168 8.89885 0 8.63112 0C8.36366 0 8.14686 0.2168 8.14686 0.484267V4.3288H5.31355C5.04582 4.3288 4.82902 4.5456 4.82902 4.81333V6.892H0.984528C0.717064 6.89227 0.5 7.10933 0.5 7.3768C0.5 7.64453 0.717064 7.86133 0.984528 7.86133H4.82902V11.1384H0.984528C0.717064 11.1381 0.5 11.3552 0.5 11.6227C0.5 11.8901 0.717064 12.1072 0.984528 12.1072H4.82902V13.1387C4.82902 13.1387 4.82902 13.3552 4.82902 13.6229C4.82902 13.8907 4.82902 14.1075 4.82902 14.1075V15.3845H0.984528C0.717064 15.3843 0.5 15.6013 0.5 15.8691C0.5 16.1365 0.717064 16.3533 0.984528 16.3533H4.82902V19.1867C4.82902 19.4541 5.04582 19.6709 5.31355 19.6709H8.14686V23.5155C8.14686 23.7829 8.36366 24 8.63139 24C8.89912 24 9.11592 23.7829 9.11592 23.5155V19.6709H10.3927C10.3927 19.6709 10.7323 19.6709 11 19.6709C11.2677 19.6709 11.3617 19.6709 11.3617 19.6709H12V23.5155C12 23.7829 12.2168 24 12.4843 24C12.752 24 12.9688 23.7829 12.9688 23.5155V19.6709H15.8846V23.5155C15.8846 23.7829 16.1014 24 16.3691 24C16.6366 24 16.8534 23.7829 16.8534 23.5155V19.6709H19.6867C19.9542 19.6709 20.1712 19.5 20.1712 19.1867V16.3533H24.0157C24.2832 16.3533 24.5 16.1365 24.5 15.8688C24.5 15.6013 24.2832 15.3845 24.0157 15.3845H20.171V12.1075H24.0155C24.2829 12.1075 24.4997 11.8907 24.4997 11.6229C24.4997 11.3552 24.2829 11.1384 24.0155 11.1384H20.171V7.86133H24.0155ZM19.2019 18.7021H5.79781V5.29787H19.2019V18.7021Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
