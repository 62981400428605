import MockRepository from 'api-clients/network-layer/demo-data/repository/MockRepository';

const dbGovernanceData = MockRepository.getDbGovernanceData();

const DbGovernance = {
    getSoftwareImages() {
        return dbGovernanceData.getSoftwareImages();
    },
    getParameterProfiles() {
        return dbGovernanceData.getParameterProfiles();
    },
    getParameterProfilesValidations() {
        return dbGovernanceData.getParameterProfilesValidations();
    },
    getOptionProfiles() {
        return dbGovernanceData.getOptionProfiles();
    },
    getOptionsProfilesLoadAclsTrue() {
        return dbGovernanceData.getOptionsProfilesLoadAclsTrue();
    },
    getSLAs() {
        return dbGovernanceData.getSLAs();
    },
    getADDomains() {
        return dbGovernanceData.getADDomains();
    },
    getLicenses() {
        return dbGovernanceData.getLicenses();
    },
    createCustomSLA(body) {
        return dbGovernanceData.createCustomSLA(body);
    },
};

export default DbGovernance;
