import clsx from 'clsx';
import { useStyles } from './utils';

export default function CrossWithoutBgIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.54679 1.35964C8.79709 1.10934 8.79709 0.703518 8.54679 0.453214V0.453214C8.29648 0.202911 7.89066 0.202911 7.64036 0.453214L4.5 3.59357L1.35964 0.453214C1.10934 0.202911 0.703518 0.202911 0.453214 0.453214V0.453214C0.202911 0.703518 0.202911 1.10934 0.453214 1.35964L1.79679 2.70321L3.59357 4.5L0.453214 7.64036C0.202911 7.89066 0.202911 8.29648 0.453214 8.54679V8.54679C0.703518 8.79709 1.10934 8.79709 1.35964 8.54679L4.5 5.40643L7.64036 8.54679C7.89066 8.79709 8.29648 8.79709 8.54679 8.54679V8.54679C8.79709 8.29648 8.79709 7.89066 8.54679 7.64036L5.40643 4.5L8.54679 1.35964Z"
                fill="var(--icon-color)"
                stroke="var(--icon-color)"
                strokeWidth="0.5"
            />
        </svg>
    );
}
