import clsx from 'clsx';
import { useStyles } from './utils';

export default function HPComputeIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width={40}
            height={40}
            fill="none"
            {...props}
        >
            <rect width={40} height={40} fill="#fff" rx={20} />
            <g
                stroke="#11567F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                clipPath="url(#a)"
            >
                <path
                    fill="#C3E5F8"
                    d="M27.5 9.201H10.45a1.55 1.55 0 0 0-1.55 1.55v6.2c0 .856.693 1.55 1.55 1.55H27.5a1.55 1.55 0 0 0 1.549-1.55v-6.2a1.55 1.55 0 0 0-1.55-1.55Z"
                />
                <path
                    fill="#11567F"
                    d="M13.548 14.626a.775.775 0 1 0 0-1.55.775.775 0 0 0 0 1.55Z"
                />
                <path d="M19.749 13.85h5.425M29.049 20.05a1.55 1.55 0 0 0-1.55-1.55H10.45a1.55 1.55 0 0 0-1.55 1.55v6.2a1.55 1.55 0 0 0 1.55 1.55h3.74" />
                <path
                    fill="#11567F"
                    d="M13.548 23.927a.775.775 0 1 0 0-1.55.775.775 0 0 0 0 1.55Z"
                />
                <path d="M26.46 23h4.47v4.47" />
                <path d="m30.931 23-5.115 5.115a.56.56 0 0 1-.783 0l-2.57-2.57a.559.559 0 0 0-.783 0l-5.28 5.28" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M8 8h24v24H8z" />
                </clipPath>
            </defs>
        </svg>
    );
}
