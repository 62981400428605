import MockRepository from 'api-clients/network-layer/demo-data/repository/MockRepository';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

const generalApisData = MockRepository.getGeneralApisData();

const GeneralApis = {
    getEmptyResponse() {
        return [];
    },
    getWhoamiDetails(headers) {
        const accessToken = headers.Authorization;
        const decodedToken = accessToken && jwt_decode(accessToken);

        return generalApisData.getWhoami(decodedToken.email);
    },
    getBuildDetails() {
        return generalApisData.getBuildJson();
    },
    getAppsDetails() {
        return generalApisData.getAppsJson();
    },
    getTenantConfigDetails() {
        return generalApisData.getTenantConfigJson();
    },
    getRolesIamUserDetails() {
        return generalApisData.getRolesIamUserJson();
    },
    getCloudLocationsDetails() {
        return generalApisData.getCloudLocationsJson();
    },
    getLoginDetails(body) {
        const parsedBody = JSON.parse(body);
        if (generalApisData.getLoginDetails(parsedBody.emailId)) {
            return generalApisData.getLoginDetails(parsedBody.emailId);
        }
        return generalApisData.getLoginFailedDetails();
    },
    getComponentsTsDetails() {
        return generalApisData.getComponentsTsDetails();
    },
    getApiKeysDetails() {
        return generalApisData.getApiKeysDetails();
    },
    getEventServiceNotificationsDetails() {
        return generalApisData.getEventServiceNotificationsDetails();
    },
    getTemplateMetadataDetails() {
        return generalApisData.getTemplateMetadataJson();
    },
    getCustomCount() {
        return {
            count: 2,
        };
    },
};

export default GeneralApis;
