import { useTheme } from '@mui/material/styles';
import { styles } from 'styles/style-templates';

export type IconPropType = {
    width: number;
    height: number;
    minWidth: number;
    mr: number;
    ml: number;
    mt: number;
    mb: number;
    my: number;
    mx: number;
    className: string;
    style: any;
    darkBg: boolean;
    isTabIcon: boolean;
    enabledTabIcon: boolean;
    monotone: boolean;
    zoom: number;
    color: string;
};

export function useStyles(props: IconPropType) {
    const theme = useTheme();
    const {
        darkBg = true,
        monotone = false,
        style,
        width,
        height,
        minWidth,
        mt,
        mb,
        mr,
        ml,
        mx,
        my,
        zoom,
        color = null,
        isTabIcon,
        enabledTabIcon,
    } = props;

    const iconStyle: any = {};

    if (width) {
        iconStyle.width = `${width}px`;
    }
    if (height) {
        iconStyle.height = `${height}px`;
    }
    if (minWidth) {
        iconStyle.minWidth = `${minWidth}px`;
    }
    if (mt || my) {
        iconStyle.marginTop = theme.spacing(mt || my);
    }
    if (mb || my) {
        iconStyle.marginBottom = theme.spacing(mb || my);
    }
    if (mr || mx) {
        iconStyle.marginRight = theme.spacing(mr || mx);
    }
    if (ml || mx) {
        iconStyle.marginLeft = theme.spacing(ml || mx);
    }
    if (darkBg) {
        iconStyle['--icon-color'] = styles.color.baseBackground;
    } else {
        iconStyle['--icon-color'] = styles.color.darkShade;
    }
    if (monotone) {
        iconStyle['--icon-color'] = styles.color.greyBackground97;
    }
    if (color) {
        iconStyle['--icon-color'] = color;
    }
    if (zoom) {
        iconStyle.zoom = zoom;
    }

    if (isTabIcon && !enabledTabIcon) {
        iconStyle['--icon-color'] = styles.color.textBoxLabel;
    }

    return {
        ...iconStyle,
        ...style,
    };
}
