// eslint-disable-next-line import/no-unresolved
import DefaultAvailabilityMachinesLoader from './default/availabilitymachinesloader';
import UBSAvailabilityMachinesLoader from './UBS/availabilitymachinesloader';
import MMCAvailabilityMachinesLoader from './MMC/availabilitymachinesloader';
import FMRAvailabilityMachinesLoader from './FMR/availabilitymachinesloader';

const tenantUrl = window.location.hostname;

const getDataLoaders = () => {
    let availabilityMachinesLoader;

    switch (tenantUrl) {
        case 'localhost':
        case 'ubs.tsl-terls.cloud':
        case 'ubs-demo.tessell.com':
        case 'ubs-demo.azure.tessell.com':
        case 'demo.azure.tessell.com':
        case 'msftgsi.azure.tessell.com':
            availabilityMachinesLoader = new UBSAvailabilityMachinesLoader();
            break;
        case 'mmc-demo.tessell.com':
        case 'mmc.tsl-terls.cloud':
        case 'mmc-demo.aws.tessell.com':
        case 'demo.aws.tessell.com':
            availabilityMachinesLoader = new MMCAvailabilityMachinesLoader();
            break;
        case 'fmr-demo.tessell.com':
        case 'fmr.demo.tessell.com':
        case 'fmr.tsl-terls.cloud':
            availabilityMachinesLoader = new FMRAvailabilityMachinesLoader();
            break;
        default:
            availabilityMachinesLoader = new DefaultAvailabilityMachinesLoader();
            break;
    }

    return {
        availabilityMachinesLoader,
    };
};

const { availabilityMachinesLoader } = getDataLoaders();

export default class AvailabilityMachinesData {
    // eslint-disable-next-line class-methods-use-this
    getAvailabilityMachinesServices() {
        return availabilityMachinesLoader.initAvailabilityMachinesServices();
    }

    // eslint-disable-next-line class-methods-use-this
    getAvailabilityMachinesDmmServices(id) {
        return availabilityMachinesLoader.initAvailabilityMachinesDmmServices(id);
    }

    // eslint-disable-next-line class-methods-use-this
    getAvailabilityMachinesSlasServices() {
        return availabilityMachinesLoader.initAvailabilityMachinesSlasServices();
    }

    // eslint-disable-next-line class-methods-use-this
    getAvailabilityMachinesSnapshotsAutomatedFalseServices(id) {
        return availabilityMachinesLoader.initAvailabilityMachinesSnapshotsAutomatedFalseServices(
            id,
        );
    }

    // eslint-disable-next-line class-methods-use-this
    getAvailabilityMachinesSnapshotsManualFalseServices(id) {
        return availabilityMachinesLoader.initAvailabilityMachinesSnapshotsManualFalseServices(id);
    }

    // eslint-disable-next-line class-methods-use-this
    getAvailabilityMachinesSlaServices(id) {
        return availabilityMachinesLoader.initAvailabilityMachinesSlaServices(id);
    }

    // eslint-disable-next-line class-methods-use-this
    getAvailabilityMachinesSanitizationSchedulesServices(id) {
        return availabilityMachinesLoader.initAvailabilityMachinesSanitizationSchedulesServices(id);
    }

    // eslint-disable-next-line class-methods-use-this
    getAvailabilityMachinesSanitizatiedSnapshotsServices(id) {
        return availabilityMachinesLoader.initAvailabilityMachinesSanitizatiedSnapshotsServices(id);
    }

    // eslint-disable-next-line class-methods-use-this
    getAvailabilityMachinesAccessPoliciesServices(id) {
        return availabilityMachinesLoader.initAvailabilityMachinesAccessPoliciesServices(id);
    }

    // eslint-disable-next-line class-methods-use-this
    getAvailabilityMachinesAllSnapshotsServices(id) {
        return availabilityMachinesLoader.initAvailabilityMachinesAllSnapshotsServices(id);
    }

    // eslint-disable-next-line class-methods-use-this
    getAvailabilityMachinesBackupsServices(id) {
        return availabilityMachinesLoader.initAvailabilityMachinesBackupsServices(id);
    }

    // eslint-disable-next-line class-methods-use-this
    getTaskUpdateAvailabilityMachinesServices() {
        return availabilityMachinesLoader.initTaskUpdateAvailabilityMachines();
    }

    // eslint-disable-next-line class-methods-use-this
    getTaskUpdateSanitizedCreateServices() {
        return availabilityMachinesLoader.initTaskUpdateSanitizedCreate();
    }

    // eslint-disable-next-line class-methods-use-this
    getTaskUpdateBackupsCreateServices() {
        return availabilityMachinesLoader.initTaskUpdateBackupsCreate();
    }

    // eslint-disable-next-line class-methods-use-this
    postManualSnapshots(requestBody, id) {
        return availabilityMachinesLoader.createManualSnapshots(requestBody, id);
    }

    // eslint-disable-next-line class-methods-use-this
    postSanitizedSnapshots(requestBody, id) {
        return availabilityMachinesLoader.createSanitizedSnapshots(requestBody, id);
    }

    // eslint-disable-next-line class-methods-use-this
    postBackup(requestBody, id) {
        return availabilityMachinesLoader.createBackups(requestBody, id);
    }

    // eslint-disable-next-line class-methods-use-this
    postAccessPolicy(requestBody, id) {
        return availabilityMachinesLoader.createAccessPolicy(requestBody, id);
    }
}
