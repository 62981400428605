// eslint-disable-next-line import/no-unresolved
import DefaultDataflixLoader from './default/dataflixloader';
import UBSDataflixLoader from './UBS/dataflixloader';
import MMCDataflixLoader from './MMC/dataflixloader';
import FMRDataflixLoader from './FMR/dataflixloader';

const tenantUrl = window.location.hostname;

const getDataLoaders = () => {
    let dataflixloader;

    switch (tenantUrl) {
        case 'localhost':
        case 'ubs.tsl-terls.cloud':
        case 'ubs-demo.tessell.com':
        case 'ubs-demo.azure.tessell.com':
        case 'demo.azure.tessell.com':
        case 'msftgsi.azure.tessell.com':
            dataflixloader = new UBSDataflixLoader();
            break;
        case 'mmc-demo.tessell.com':
        case 'mmc.tsl-terls.cloud':
        case 'mmc-demo.aws.tessell.com':
        case 'demo.aws.tessell.com':
            dataflixloader = new MMCDataflixLoader();
            break;
        case 'fmr-demo.tessell.com':
        case 'fmr.demo.tessell.com':
        case 'fmr.tsl-terls.cloud':
            dataflixloader = new FMRDataflixLoader();
            break;
        default:
            dataflixloader = new DefaultDataflixLoader();
            break;
    }

    return {
        dataflixloader,
    };
};

const { dataflixloader } = getDataLoaders();

export default class DataflixData {
    // eslint-disable-next-line class-methods-use-this
    getDataflix() {
        return dataflixloader.initDataflix();
    }

    // eslint-disable-next-line class-methods-use-this
    getDataflixDetails(id) {
        return dataflixloader.initDataflixDetails(id);
    }

    // eslint-disable-next-line class-methods-use-this
    getDataflixRecoveryInfoDetails(id, recoveryTime, snapshotId) {
        return dataflixloader.initDataflixRecoveryInfo(id, recoveryTime, snapshotId);
    }

    // eslint-disable-next-line class-methods-use-this
    getDataflixBackupDetails(id) {
        return dataflixloader.initDataflixBackup(id);
    }

    // eslint-disable-next-line class-methods-use-this
    getDataflixCatalogDetails(id) {
        return dataflixloader.initDataflixCatalog(id);
    }

    // eslint-disable-next-line class-methods-use-this
    getDataflixSanitizedDetails(id) {
        return dataflixloader.initDataflixSanitized(id);
    }
}
