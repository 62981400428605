/* eslint-disable react/react-in-jsx-scope */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function DBOptionsIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.4989 23.9998H2.49994C1.122 23.9998 0 22.8778 0 21.4989V2.49994C0 1.122 1.122 0 2.49994 0H21.4989C22.878 0 24 1.122 24 2.49994V21.4989C24 22.878 22.878 24 21.4991 24L21.4989 23.9998ZM2.49994 0.999727C1.67293 0.999727 0.999941 1.67271 0.999941 2.49972V21.4987C0.999941 22.3259 1.67293 22.9998 2.49994 22.9998H21.4989C22.3261 22.9998 23.0001 22.3258 23.0001 21.4989V2.4999C23.0001 1.67288 22.326 0.999898 21.4991 0.999898L2.49994 0.999727Z"
                fill="var(--icon-color)"
            />
            <path
                d="M9 5.2C9 5.08954 9.08954 5 9.2 5H19.3C19.4104 5 19.5 5.08954 19.5 5.2V6.80024C19.5 6.9107 19.4104 7.00024 19.3 7.00024H9.2C9.08954 7.00024 9 6.9107 9 6.80024V5.2Z"
                fill="var(--icon-color)"
            />
            <path
                d="M5.00005 8.00011C3.897 8.00011 3 7.10311 3 6.00005C3 4.897 3.897 4 5.00005 4C6.10311 4 7.00011 4.897 7.00011 6.00005C6.99994 7.10311 6.10294 8.00011 5.00005 8.00011ZM5.00005 5.00011C4.44896 5.00011 3.99994 5.44913 3.99994 6.00005C3.99994 6.55098 4.44895 6.99999 4.99988 6.99999C5.55081 6.99999 5.99982 6.55098 5.99982 6.00005C5.99999 5.44913 5.55098 5.00011 5.00005 5.00011Z"
                fill="var(--icon-color)"
            />
            <path
                d="M9 11.2C9 11.0895 9.08954 11 9.2 11H19.3C19.4104 11 19.5 11.0895 19.5 11.2V12.8002C19.5 12.9107 19.4104 13.0002 19.3 13.0002H9.2C9.08954 13.0002 9 12.9107 9 12.8002V11.2Z"
                fill="var(--icon-color)"
            />
            <path
                d="M5.00005 14.0001C3.897 14.0001 3 13.1031 3 12.0001C3 10.897 3.897 10 5.00005 10C6.10311 10 7.00011 10.897 7.00011 12.0001C6.99994 13.1031 6.10294 14.0001 5.00005 14.0001ZM5.00005 11.0001C4.44896 11.0001 3.99994 11.4491 3.99994 12.0001C3.99994 12.551 4.44895 13 4.99988 13C5.55081 13 5.99982 12.551 5.99982 12.0001C5.99999 11.4491 5.55098 11.0001 5.00005 11.0001Z"
                fill="var(--icon-color)"
            />
            <path
                d="M9 17.2C9 17.0895 9.08954 17 9.2 17H19.3C19.4104 17 19.5 17.0895 19.5 17.2V18.8002C19.5 18.9107 19.4104 19.0002 19.3 19.0002H9.2C9.08954 19.0002 9 18.9107 9 18.8002V17.2Z"
                fill="var(--icon-color)"
            />
            <path
                d="M5.00005 20.0001C3.897 20.0001 3 19.1031 3 18.0001C3 16.897 3.897 16 5.00005 16C6.10311 16 7.00011 16.897 7.00011 18.0001C6.99994 19.1031 6.10294 20.0001 5.00005 20.0001ZM5.00005 17.0001C4.44896 17.0001 3.99994 17.4491 3.99994 18.0001C3.99994 18.551 4.44895 19 4.99988 19C5.55081 19 5.99982 18.551 5.99982 18.0001C5.99999 17.4491 5.55098 17.0001 5.00005 17.0001Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
