import clsx from 'clsx';
import { useStyles } from './utils';

export default function PasswordPoliciesIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.74371 0L0 3.65217V12C0 18 5.63006 22 9.7327 24C9.8972 23.8995 10.0651 23.7974 10.2357 23.6935C14.31 21.2145 19.9897 17.7588 20 12V3.65217L9.74371 0ZM9.77576 1.50015L1.25001 4.73384V12.1251C1.25001 16.9817 5.34825 19.5399 8.81881 21.7063C9.14463 21.9097 9.46491 22.1096 9.77576 22.3077C10.1274 22.0902 10.4965 21.8685 10.8762 21.6404C14.3619 19.5466 18.7415 16.9158 18.75 12.1251V4.73384L9.77576 1.50015Z"
                fill="var(--icon-color)"
            />
            <path
                d="M13.3605 8.56409H5.83605C5.37431 8.56409 5 8.95623 5 9.43995V15.571C5 16.0547 5.37431 16.4469 5.83605 16.4469H13.3605C13.8223 16.4469 14.1966 16.0547 14.1966 15.571V9.43995C14.1966 8.95623 13.8223 8.56409 13.3605 8.56409Z"
                stroke="var(--icon-color)"
                strokeWidth="1.1"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.39355 8.55041V6.89676C7.39355 6.70111 7.63747 6.11225 8.07164 5.67808C8.5058 5.24391 9.09466 5 9.70867 5C10.3227 5 10.9115 5.24391 11.3457 5.67808C11.7799 6.11225 12.0238 6.70111 12.0238 6.89676V8.55041"
                stroke="var(--icon-color)"
                strokeWidth="1.1"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.65186 12.0248L9.65186 14.4581"
                stroke="var(--icon-color)"
                strokeWidth="1.1"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="9.60122" cy="11.6935" r="1.25502" fill="var(--icon-color)" />
        </svg>
    );
}
