import { getGenieInfo, getTenantInfo } from 'api-clients/loginServices';
import { ENGINE_TYPES } from 'pages/dbServices/my-services/myServicesList/config/constants';
import {
    EngineFullFormLowercaseEnum,
    FeatureFlagEngineTypeEnum,
    FeatureFlagEnum,
} from 'schemas/enums/featureFlagEnum';
import Cache from 'tools/Cache';
import SupportUtil from 'tools/SupportUtil';
import { v4 as uuidv4 } from 'uuid';
import _head from 'lodash/head';

export const tenantAndGenieApiCall = (resData, headers) => {
    const whoami = resData;
    const hdrs = {
        ...headers,
        'tenant-id': resData.tenantUserAttributes[0].tenantId,
    };
    // tenant api call
    getTenantInfo(hdrs).then((r) => {
        r.json().then((data) => {
            whoami.customerType = data.customerType;
            whoami.billingDisabled = data.billingDisabled;
            Cache.set('enabledClouds', JSON.stringify(data.enabledClouds));
            Cache.set(
                'isAddCrossRegionReplicaEnabled',
                JSON.stringify(data?.isAddCrossRegionReplicaEnabled || false),
            );
            SupportUtil.initAnalytics(whoami, true);
        });
    });
    // genie api call
    getGenieInfo(headers, resData.tenantUserAttributes[0].tenantId).then((r) => {
        r.json().then((data) => {
            Cache.set('genieConfig', JSON.stringify(data));
        });
    });
};

export const setFeatureFlags = ({
    whoamiData,
    updateIsServersAppAvailable,
    updateIsMultiDiskAvailable,
    updateIsTimezoneFeatureAvailable,
    updateIsMssqlHpcAvailable,
}) => {
    const appsArray = whoamiData?.tenantUserAttributes?.[0]?.personas?.[0]?.apps;
    const isServersAppAvailable = appsArray?.find((item) => item?.name === FeatureFlagEnum.Servers);

    const provObj = appsArray?.filter((item) => item?.name === FeatureFlagEnum.Provisioning)[0];
    const myServicesObj = appsArray?.filter((item) => item?.name === FeatureFlagEnum.MyServices)[0];

    let isMultiDiskAvailable = false;
    let isMssqlHpcAvailable = false;
    if (provObj) {
        isMultiDiskAvailable =
            myServicesObj?.features?.find((item) => item?.name === FeatureFlagEnum.MultiDisk) ||
            false;
        isMssqlHpcAvailable =
            provObj?.features?.find((item) => item?.name === FeatureFlagEnum.MssqlHpc) || false;
    }

    const isTimezoneFeatureAvailable = {
        [EngineFullFormLowercaseEnum[FeatureFlagEngineTypeEnum.ORACLE]]: false,
        [EngineFullFormLowercaseEnum[FeatureFlagEngineTypeEnum.MYSQL]]: false,
        [EngineFullFormLowercaseEnum[FeatureFlagEngineTypeEnum.POSTGRES]]: false,
        [EngineFullFormLowercaseEnum[FeatureFlagEngineTypeEnum.MSSQL]]: false,
    };

    provObj?.features
        ?.filter((item) => item?.name?.includes(FeatureFlagEnum.Timezone))
        .forEach((item) => {
            const engineType = Object.values(FeatureFlagEngineTypeEnum).find((type) =>
                item?.name?.includes(type),
            );
            if (engineType) {
                isTimezoneFeatureAvailable[EngineFullFormLowercaseEnum[engineType]] = true;
            }
        });

    updateIsServersAppAvailable(!!isServersAppAvailable);
    updateIsMultiDiskAvailable(!!isMultiDiskAvailable);
    updateIsTimezoneFeatureAvailable({ relationalDB: isTimezoneFeatureAvailable });
    updateIsMssqlHpcAvailable(!!isMssqlHpcAvailable);
};

export const returnProfileData = (response) => {
    const base64Payload = response.credential.split('.')[1]; // value 0 -> header, 1 -> payload, 2 -> VERIFY SIGNATURE
    const tempPayload = Buffer.from(base64Payload, 'base64');
    const userProfile = JSON.parse(tempPayload);

    return userProfile;
};

export const saveGenericData = (e) => {
    const userProfile = returnProfileData(e);
    Cache.set('email', userProfile?.email);
    Cache.set('img', userProfile?.picture);
    Cache.set('user', userProfile?.name);
};

// eslint-disable-next-line no-shadow
export const saveUserData = (response, dispatch, update) => {
    const apps = [];
    let personas = [];
    const features = {};
    response.personas.forEach((p) => {
        personas.push(p.name);
        if (p.name === 'Tessell Operations Administrator') {
            apps.push('Task');
        } else {
            p.apps.forEach((app) => {
                apps.push(app.name);
                features[app.name] = app?.features?.map((f) => f.name);
            });
        }
    });
    personas = Array.from(new Set(personas));
    const ctxData = {
        accessToken: response.accessToken,
        apps,
        personas,
        subscriptions: response?.subscriptions,
    };

    Cache.set('persona', response.personas?.[0]?.name || '');
    Cache.set('subscription', _head(response.subscriptions, []));
    Cache.set('tenant-id', response.tenantId);
    Cache.set('tenantEmail', response.tenantEmail);
    Cache.set('tenantType', response.tenantType);
    Cache.set('tenantServicePlan', response.tenantServicePlan);
    Cache.set('tenant-created-date', response.tenantDateCreated);
    Cache.set('features', JSON.stringify(features));
    Cache.set('session-id', uuidv4());
    dispatch(update(ctxData));
    return ctxData;
};
