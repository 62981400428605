/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function SupportIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 24 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.90909 0C0.860888 0 0 0.861679 0 1.91088V9.55442C0 10.6033 0.860888 11.4653 1.90909 11.4653H2.71023L2.72727 14.7409C2.72789 14.8562 2.76496 14.9684 2.83319 15.0613C2.90142 15.1542 2.99729 15.2232 3.10706 15.2582C3.21683 15.2932 3.33486 15.2926 3.44424 15.2563C3.55361 15.2201 3.64872 15.1501 3.71591 15.0564L6.27273 11.4653H8.18182V15.0139C8.18182 16.0631 9.04271 16.9247 10.0909 16.9247H17.7273L20.2841 20.5161C20.3513 20.6098 20.4464 20.6798 20.5558 20.716C20.6651 20.7523 20.7832 20.7529 20.8929 20.7179C21.0027 20.6829 21.0986 20.614 21.1668 20.521C21.235 20.4281 21.2721 20.3159 21.2727 20.2006L21.2898 16.9247H22.0909C23.1391 16.9247 24 16.0631 24 15.0139V7.37058C24 6.32138 23.1391 5.4597 22.0909 5.4597H15.8182V1.91088C15.8182 0.861679 14.9573 0 13.9091 0H1.90909ZM1.90909 1.09178H13.9091C14.3718 1.09178 14.7273 1.44769 14.7273 1.91088V5.4597H10.0909C9.04271 5.4597 8.18182 6.32138 8.18182 7.37058V10.3733H5.99148C5.90537 10.3726 5.82033 10.3923 5.7433 10.4308C5.66627 10.4693 5.59945 10.5256 5.5483 10.5949L3.80966 13.0347L3.80115 10.9107C3.7989 10.7674 3.74044 10.6307 3.63839 10.5301C3.53634 10.4296 3.39889 10.3732 3.2557 10.3733H1.9091C1.4464 10.3733 1.09092 10.0171 1.09092 9.55442V1.91088C1.09092 1.44769 1.44638 1.09178 1.90909 1.09178ZM10.0909 6.55148H22.0909C22.5536 6.55148 22.9091 6.90712 22.9091 7.37058V15.0139C22.9091 15.4768 22.5536 15.833 22.0909 15.833H20.7443C20.6011 15.8329 20.4637 15.8893 20.3616 15.9898C20.2596 16.0904 20.2011 16.2271 20.1989 16.3704L20.1904 18.4944L18.4517 16.0546C18.4006 15.9853 18.3337 15.929 18.2567 15.8905C18.1797 15.852 18.0946 15.8323 18.0085 15.833H10.0909C9.62822 15.833 9.27274 15.4768 9.27274 15.0139V7.37058C9.27274 6.90712 9.6282 6.55148 10.0909 6.55148Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
