import clsx from 'clsx';
import { useStyles } from './utils';

export default function PartitionKeyLogo(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="20" height="20" rx="10" fill="#F0FCF0" />
            <path
                d="M8.7059 12.353L14.0589 7L14.8824 7.82353L8.7059 14L5 10.2941L5.82353 9.47062L8.7059 12.353Z"
                fill="#4DAC4A"
            />
        </svg>
    );
}
