export const EXTERNAL_APPS = {
    LOGROCKET: 'LOGROCKET',
    MUI: 'MUI',
    USERFLOW_INIT_KEY: 'USERFLOW_INIT_KEY',
    USERFLOW_ID_KEY: 'USERFLOW_ID_KEY',
    INTERCOM_KEY: 'INTERCOM',
    STRIPE_KEY: 'STRIPE_KEY',
};

export const APP_KEY = {
    [EXTERNAL_APPS.LOGROCKET]: {
        appName: EXTERNAL_APPS.LOGROCKET,
        envName: `REACT_APP_${[EXTERNAL_APPS.LOGROCKET]}`,
    },
    [EXTERNAL_APPS.MUI]: {
        appName: EXTERNAL_APPS.MUI,
        envName: `REACT_APP_${[EXTERNAL_APPS.MUI]}`,
    },
    [EXTERNAL_APPS.USERFLOW_INIT_KEY]: {
        appName: EXTERNAL_APPS.USERFLOW_INIT_KEY,
        envName: `REACT_APP_${[EXTERNAL_APPS.USERFLOW_INIT_KEY]}`,
    },
    [EXTERNAL_APPS.USERFLOW_ID_KEY]: {
        appName: EXTERNAL_APPS.USERFLOW_ID_KEY,
        envName: `REACT_APP_${[EXTERNAL_APPS.USERFLOW_ID_KEY]}`,
    },
    [EXTERNAL_APPS.INTERCOM_KEY]: {
        appName: EXTERNAL_APPS.INTERCOM_KEY,
        envName: `REACT_APP_${[EXTERNAL_APPS.INTERCOM_KEY]}`,
    },
    [EXTERNAL_APPS.STRIPE_KEY]: {
        appName: EXTERNAL_APPS.STRIPE_KEY,
        envName: `REACT_APP_${[EXTERNAL_APPS.STRIPE_KEY]}`,
    }
};
