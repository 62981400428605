// For extended perf metrics we have the same format. Only the question mark is replaced by some values.
export const extendedPerfMetricsTableValuesForSQL = () => ({
    'END;': ['END;'],
    'update production_db_accounts set abalance = abalance + ? where aid = ?': [
        'update production_db_accounts set abalance = abalance + 234 where aid = 2096',
        'update production_db_accounts set abalance = abalance + 134 where aid = 9363',
        'update production_db_accounts set abalance = abalance + 56 where aid = 7656',
        'update production_db_accounts set abalance = abalance + 34 where aid = 3456',
        'update production_db_accounts set abalance = abalance + 456 where aid = 6786',
        'update production_db_accounts set abalance = abalance + 87 where aid = 4356',
        'update production_db_accounts set abalance = abalance + 83 where aid = 54776',
        'update production_db_accounts set abalance = abalance + 831 where aid = 2346',
        'update production_db_accounts set abalance = abalance + 842 where aid = 2346',
        'update production_db_accounts set abalance = abalance + 296 where aid = 9646',
    ],
    'update production_db_branches set bbalance = bbalance + ? where bid = ?': [
        'update production_db_branches set bbalance = bbalance + 56 where bid = 34',
        'update production_db_branches set bbalance = bbalance + 456 where bid = 87',
        'update production_db_branches set bbalance = bbalance + 83 where bid = 831',
        'update production_db_branches set bbalance = bbalance + 842 where bid = 56',
        'update production_db_branches set bbalance = bbalance + 34 where bid = 456',
        'update production_db_branches set bbalance = bbalance + 87 where bid = 83',
        'update production_db_branches set bbalance = bbalance + 831 where bid = 842',
    ],
    'vacuum analyze production_db_accounts': ['vacuum analyze production_db_accounts'],
    'update production_db_tellers set tbalance = tbalance + ? where tid = ?': [
        'update production_db_tellers set tbalance = tbalance + 456 where tid = 83',
        'update production_db_tellers set tbalance = tbalance + 842 where tid = 34',
        'update production_db_tellers set tbalance = tbalance + 87 where tid = 831',
        'update production_db_tellers set tbalance = tbalance + 83 where tid = 842',
        'update production_db_tellers set tbalance = tbalance + 34 where tid = 87',
    ],
    'copy production_db_accounts from stdin': ['copy production_db_accounts from stdin'],
    'select abalance from production_db_accounts where aid = ?': [
        'select abalance from production_db_accounts where aid = 2096',
        'select abalance from production_db_accounts where aid = 9363',
        'select abalance from production_db_accounts where aid = 7656',
        'select abalance from production_db_accounts where aid = 3456',
        'select abalance from production_db_accounts where aid = 6786',
        'select abalance from production_db_accounts where aid = 4356',
        'select abalance from production_db_accounts where aid = 54776',
        'select abalance from production_db_accounts where aid = 2346',
    ],
    'alter table production_db_accounts': ['alter table production_db_accounts'],
    'insert into production_db_history(tid, bid, aid, delta, mtime) values (?, ?, ?, ?, current_timestamp())':
        [
            'insert into production_db_history(tid, bid, aid, delta, mtime) values (2096, 56, 456, 83, current_timestamp())',
            'insert into production_db_history(tid, bid, aid, delta, mtime) values (9363, 842, 34, 87, current_timestamp())',
            'insert into production_db_history(tid, bid, aid, delta, mtime) values (7656, 831, 56, 456, current_timestamp())',
            'insert into production_db_history(tid, bid, aid, delta, mtime) values (3456, 83, 842, 34, current_timestamp())',
            'insert into production_db_history(tid, bid, aid, delta, mtime) values (6786, 87, 831, 56, current_timestamp())',
            'insert into production_db_history(tid, bid, aid, delta, mtime) values (4356, 456, 83, 842, current_timestamp())',
            'insert into production_db_history(tid, bid, aid, delta, mtime) values (54776, 34, 87, 831, current_timestamp())',
            'insert into production_db_history(tid, bid, aid, delta, mtime) values (2346, 456, 87, 83, current_timestamp())',
            'insert into production_db_history(tid, bid, aid, delta, mtime) values (2346, 831, 842, 296, current_timestamp())',
        ],
    'BEGIN;': ['BEGIN;'],
});
