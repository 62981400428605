/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function DBServicesIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="8"
                cy="4.31667"
                rx="6.5"
                ry="2.81667"
                stroke="var(--icon-color)"
                strokeWidth="0.866667"
            />
            <path
                d="M1.5 8.8667C1.5 10.3026 4.41015 11.4667 8 11.4667C8.51627 11.4667 9.01848 11.4426 9.5 11.3971"
                stroke="var(--icon-color)"
                strokeWidth="0.866667"
                strokeLinecap="round"
            />
            <path
                d="M1.5 4.53345V13.4428C1.5 14.984 4.41015 16.2335 8 16.2335C8.51627 16.2335 9.01848 16.2076 9.5 16.1588M14.5 4.53345V7.50011"
                stroke="var(--icon-color)"
                strokeWidth="0.866667"
                strokeLinecap="round"
            />
            <path
                d="M13.6538 9.5C13.6538 12.4423 12.6154 13.6538 9.5 13.6538"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.6538 17.8076C13.6538 14.6922 12.6154 13.6538 9.5 13.6538"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.6538 9.5C13.6538 12.6154 14.6922 13.6538 17.8076 13.6538"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.6538 17.8076C13.6538 14.6922 14.6922 13.6538 17.8076 13.6538"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="17.5"
                y1="16"
                x2="17.5"
                y2="18"
                stroke="var(--icon-color)"
                strokeLinecap="round"
            />
            <line
                x1="16.5"
                y1="17"
                x2="18.5"
                y2="17"
                stroke="var(--icon-color)"
                strokeLinecap="round"
            />
            <circle cx="17" cy="9.5" r="0.5" fill="var(--icon-color)" stroke="var(--icon-color)" />
        </svg>
    );
}
