/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2022. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     2/23/2022     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function InvoiceIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            height="20"
            viewBox="0 0 21 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.7369 6.34155C20.7062 6.21969 20.634 6.1118 20.5322 6.03543C20.4942 5.98723 20.4621 5.93693 20.4188 5.89145L15.5983 0.712199C15.1767 0.258127 14.5791 -0.000639854 13.9526 1.18821e-06H2.96194C2.1767 0.000835825 1.42379 0.306751 0.868587 0.850557C0.313384 1.39437 0.00109625 2.13157 5.47618e-06 2.90077L0 23.4335C0 23.7465 0.259058 24 0.5786 24C0.897951 24 1.15698 23.7465 1.15698 23.4335L1.15699 5.05374V2.90077C1.15741 2.43209 1.34787 1.9828 1.68618 1.65143C2.0247 1.32006 2.48337 1.13372 2.96189 1.13309H13.9525C14.2529 1.13247 14.5397 1.25642 14.7418 1.47406L18.8719 5.91125L16.7389 5.91146C16.2611 5.91083 15.8028 5.7247 15.4648 5.39353C15.1269 5.06258 14.9368 4.61371 14.9364 4.14548V3.01863C14.9364 2.70583 14.6774 2.45211 14.3578 2.45211C14.0385 2.45211 13.7794 2.70586 13.7794 3.01863V4.14548C13.7803 4.91403 14.0924 5.65086 14.6471 6.19441C15.2021 6.7378 15.9544 7.04368 16.739 7.04454H19.791C19.8249 7.14679 19.8423 7.25342 19.8432 7.36109V21.1082C19.8426 21.5746 19.6532 22.0216 19.3168 22.3513C18.9802 22.681 18.5236 22.8663 18.0477 22.867H0.578605C0.259254 22.867 0 23.1205 0 23.4335C0 23.7465 0.259058 24 0.5786 24H18.0477C18.8304 23.9992 19.5809 23.6941 20.1342 23.1522C20.6877 22.6101 20.9991 21.8749 21 21.1082V7.36106C20.9989 7.00527 20.9086 6.65518 20.7369 6.34155Z"
                fill="var(--icon-color)"
            />
            <path
                d="M9.70597 7.57801C9.45107 7.25148 8.35301 6.65964 7.17652 6.65964C6.17651 6.5372 4.07059 6.78209 4.11765 8.74128C4.17647 11.1903 7.52957 10.7005 9.00018 11.4964C10.1767 12.1331 10.2356 13.5648 9.58842 14.3608C8.72563 15.2383 6.44702 16.3444 4 13.9934M7.1177 5V17"
                stroke="var(--icon-color)"
                strokeWidth="0.8"
                strokeLinecap="round"
            />
            <path
                d="M13 12L17 12"
                stroke="var(--icon-color)"
                strokeWidth="0.8"
                strokeLinecap="round"
            />
            <path
                d="M13 16L17 16"
                stroke="var(--icon-color)"
                strokeWidth="0.8"
                strokeLinecap="round"
            />
            <path d="M4 20H17" stroke="var(--icon-color)" strokeWidth="0.8" strokeLinecap="round" />
        </svg>
    );
}
