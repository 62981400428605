/* eslint-disable no-param-reassign */
import axios, { AxiosInstance } from 'axios';
import { getDomainUrl } from 'constants/urls';

import Cache from '../../tools/Cache';
import { checkIfUnauthorized } from './utils';
import { InitializeMockData } from './demo-data';

const axiosInstance: AxiosInstance = axios.create({
    baseURL: getDomainUrl(),
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

InitializeMockData(axiosInstance);

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // Add headers, tokens, or perform any other request modification
        const token = Cache.get('accessToken');
        const tenantId = Cache.get('tenant-id');
        if (token) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = token;
            config.headers['tenant-id'] = tenantId;
        }
        return config;
    },
    (error) =>
        // Handle request errors
        Promise.reject(error),
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        // logout in case of Unauthorized
        checkIfUnauthorized(error);

        // This is API status
        const status = error?.response?.status;

        // This is API response
        const errorData = error?.response?.data;
        const errInfo = typeof errorData === 'string' ? { message: errorData } : errorData;
        const rejectResponse = {
            status,
            ...errInfo,
        };

        // Handle request errors
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(rejectResponse);
    },
);

export default axiosInstance;
