/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function NetworkIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.01776 14.4092C5.03024 14.4909 0.585863 13.9699 1.66708 9.56433C1.91911 8.44948 3.25174 6.59667 6.00754 6.4366C6.25709 6.42211 6.46927 6.24137 6.52579 5.99788C6.99574 3.9735 8.89369 0.787743 13 1.01115"
                stroke="var(--icon-color)"
            />
            <path
                d="M12.8301 1.01115C16.9765 0.787679 18.944 3.97489 19.4252 5.99949C19.4829 6.24243 19.6948 6.42313 19.9442 6.43542C22.7298 6.57268 24.0773 8.21055 24.3319 9.32619C25.4236 13.7323 20.8978 14.4928 18.8911 14.411H6.61053"
                stroke="var(--icon-color)"
            />
            <path
                d="M6.49414 14V19.4116C6.49414 19.5479 6.4385 19.6783 6.34009 19.7726L3.49414 22.5"
                stroke="var(--icon-color)"
            />
            <circle cx="2.5" cy="23" r="2" fill="var(--icon-color)" />
            <path
                d="M18.5 14V19.4116C18.5 19.5479 18.5556 19.6783 18.654 19.7726L21.5 22.5"
                stroke="var(--icon-color)"
            />
            <circle r="2" transform="matrix(-1 0 0 1 22.4941 23)" fill="var(--icon-color)" />
            <circle cx="12.5" cy="23" r="2" fill="var(--icon-color)" />
            <path d="M12.5 14V21.5" stroke="var(--icon-color)" />
        </svg>
    );
}
