export default function WhatsNewIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.0688 3.78461C14.9851 3.09817 14.2466 2.70185 13.6283 3.01157L9.79986 4.92927C8.77502 5.44263 7.66955 5.77596 6.53176 5.91472L3.28793 6.31031C2.19148 6.44402 1.41103 7.44126 1.54474 8.53771L1.84738 11.0193C1.98109 12.1158 2.97833 12.8962 4.07478 12.7625L7.31861 12.3669C8.4564 12.2282 9.60959 12.2861 10.7278 12.5381L14.9049 13.4795C15.5795 13.6315 16.2011 13.0693 16.1174 12.3829L15.0688 3.78461Z"
                stroke="white"
                strokeWidth="1.5"
            />
            <line
                x1="5.52134"
                y1="6.03787"
                x2="6.30819"
                y2="12.4901"
                stroke="white"
                strokeWidth="1.5"
            />
            <path
                d="M7.30094 12.3691L8.68365 15.711C8.90264 16.2403 8.63314 16.8451 8.09312 17.0361L7.83183 17.1285C7.32082 17.3093 6.75883 17.05 6.56468 16.544L5.06749 12.6415"
                stroke="white"
                strokeWidth="1.5"
            />
            <path
                d="M18.3509 5.12771C18.1112 4.78992 17.643 4.71041 17.3052 4.95013C16.9674 5.18986 16.8879 5.65803 17.1276 5.99582L18.3509 5.12771ZM17.6116 9.38915C17.5422 9.79751 17.8171 10.1848 18.2254 10.2541C18.6338 10.3235 19.0211 10.0486 19.0904 9.64028L17.6116 9.38915ZM17.1276 5.99582C17.3508 6.31024 17.533 6.84952 17.6241 7.51441C17.7133 8.16463 17.7035 8.84785 17.6116 9.38915L19.0904 9.64028C19.2103 8.93452 19.2181 8.09715 19.1102 7.3106C19.0044 6.53873 18.7748 5.72507 18.3509 5.12771L17.1276 5.99582Z"
                fill="white"
            />
        </svg>
    );
}
