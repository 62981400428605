/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function EditIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.33821 23.2872L24.6534 5.48588C24.8451 5.28873 24.8417 4.97372 24.6456 4.78083L21.161 1.35266C20.9634 1.15821 20.6453 1.16172 20.4519 1.36046L3.11651 19.1826C3.06021 19.2405 3.01709 19.3099 2.99012 19.386L1.31374 24.1151C1.18 24.4923 1.52172 24.866 1.90941 24.7664L7.07611 23.4391C7.17578 23.4135 7.26646 23.361 7.33821 23.2872Z"
                stroke="var(--icon-color)"
                strokeWidth="1.5"
            />
            <path d="M22.0209 8.13043L18.1958 4.2" stroke="var(--icon-color)" strokeWidth="1.5" />
        </svg>
    );
}
