import dbServersListJson from './dbServersList.json';
import dbServerDetailJson from './dbServerDetail.json';
import dbServersSharingEnabledJson from './dbServersSharingEnabled.json';

export default class UBSServersLoader {
    // eslint-disable-next-line class-methods-use-this
    initDbServersList() {
        return dbServersListJson;
    }

    // eslint-disable-next-line class-methods-use-this
    getDbServerDetail(id) {
        return dbServerDetailJson[id];
    }

    // eslint-disable-next-line class-methods-use-this
    getDbServersSharingEnabled() {
        return dbServersSharingEnabledJson;
    }
}
