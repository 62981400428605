import React, { useState, useEffect } from 'react';
import { loadPolicy } from '@open-policy-agent/opa-wasm';
import _keyBy from 'lodash/keyBy';
import _isEmpty from 'lodash/isEmpty';
import OPAPoliciesContext from 'contexts/OPAPoliciesContext';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'constants/app.constants';

const OPAPolicesProvider = (props) => {
    const { children } = props;
    const [opaPolicies, setOPAPolicy] = useState(EMPTY_OBJECT);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const load = async () => {
            const baseUrl = `${window.location.origin}`;
            const policyWasmResp = await fetch(`${baseUrl}/policy.wasm`, {
                cache: 'no-cache',
            });
            const policyWasm = await policyWasmResp.arrayBuffer();
            const policy = await loadPolicy(policyWasm);

            const dataResp = await fetch(`${baseUrl}/data.json`, {
                cache: 'no-cache',
            });

            const data = await dataResp.json();
            policy.setData(data);

            setOPAPolicy(policy);
        };

        setLoading(true);

        load()
            .catch((err) => {
                setError(err);
                // eslint-disable-next-line no-console
                console.error('Error loading OPA policies', err);
            })
            .finally(() => setLoading(false));
    }, EMPTY_ARRAY);

    return (
        <OPAPoliciesContext.Provider value={{ opaPolicies, loading, error }}>
            {children}
        </OPAPoliciesContext.Provider>
    );
};

export default OPAPolicesProvider;
