import Billing from 'api-clients/network-layer/demo-data/REST/Cost/billing';
import { getDomainUrl } from 'constants/urls';
import { regexToString } from '../../utils';

const meterPerDbRegexUrl =
    /\/billing\/meters\?start-date=(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3})&end-date=(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3})&duration=(\w+)&service-id=([a-f\d-]+)&am-details=true/;

const metersApiRegexUrl =
    /\/billing\/meters\?start-date=(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3})&end-date=(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3})&duration=(\w+)&am-details=true/;

export const mockGetMap = {
    [`${getDomainUrl()}/billing/rates?page-size=1000`]: {
        handler: () => {
            const responseData = Billing.getRatesDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/billing/credits`]: {
        handler: () => {
            const responseData = Billing.getTotalCreditsDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/billing/payments/default`]: {
        handler: () => {
            const responseData = Billing.getDefaultPaymentMethodDetails();
            return [200, responseData];
        },
    },
    //--
    [`${getDomainUrl()}/billing/profile`]: {
        handler: () => {
            const responseData = Billing.getBillingProfileDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/billing/invoices`]: {
        handler: () => {
            const responseData = Billing.getInvoicesDetails();
            return [200, responseData];
        },
    },

    // * ---- billing ------
    // * get system generated bills
    [`${getDomainUrl()}/billing/bills?system-generated=true`]: {
        handler: () => {
            const responseData = Billing.getBillsSystemGeneratedTrueDetails();

            return [200, responseData];
        },
    },

    // * get meters per db - billing insights
    [regexToString(meterPerDbRegexUrl)]: {
        handler: (config) => {
            const { url } = config;
            const match = url.match(meterPerDbRegexUrl);
            const startDate = match[1];
            const endDate = match[2];
            const duration = match[3];
            const serviceId = match[4];
            const responseData = Billing.getMetersResShortDurationDetails(
                startDate,
                endDate,
                duration,
                serviceId,
            );
            return [200, responseData];
        },
    },

    // * get meters by range -- all services
    [regexToString(metersApiRegexUrl)]: {
        handler: (config) => {
            const { url } = config;
            const match = url.match(metersApiRegexUrl);

            const startDate = match[1];
            const endDate = match[2];

            const responseData = Billing.getMetersResDetails(startDate, endDate);

            return [200, responseData];
        },
    },
};
