// eslint-disable-next-line import/no-cycle, import/no-unresolved
import SubscriptionsData from './Governance/subscriptions/SubscriptionsData';
import NetworksData from './Governance/networks/NetworksData';
// eslint-disable-next-line import/no-cycle
import SecurityData from './Governance/security/SecurityData';
import ComputesData from './Governance/compute/ComputeData';
import IamData from './Governance/iam/IamData';
import DbGovernanceData from './Governance/dbgovernance/DbGovernanceData';

import MyServicesData from './DbServices/myservices/MyServicesData';
import AvailabilityMachinesData from './DbServices/availabilitymachines/AvailabilityMachinesData';
import DataflixData from './DbServices/dataflix/DataflixData';
import ScriptsLibraryData from './DbServices/scriptlibrary/ScriptLibraryData';
import BenchmarksData from './DbServices/benchmarks/BenchmarksData';

import BillingData from './Cost/billing/BillingData';

import GeneralApisData from './GeneralApis/GeneralApisData';

import TicketsData from './Support/tickets/TicketsData';
import ServersData from './DbServices/servers/ServersData';

const subscriptionsData = new SubscriptionsData();
const networksData = new NetworksData();
const securityData = new SecurityData();
const computesData = new ComputesData();
const iamData = new IamData();
const dbGovernanceData = new DbGovernanceData();

const myServicesData = new MyServicesData();
const availabilityMachinesData = new AvailabilityMachinesData();
const dataflixData = new DataflixData();
const scriptsLibraryData = new ScriptsLibraryData();
const benchmarksData = new BenchmarksData();
const serversData = new ServersData();

const billingData = new BillingData();

const generalApisData = new GeneralApisData();

const ticketsData = new TicketsData();

const MockRepository = {
    getSubscriptionsData() {
        return subscriptionsData;
    },
    getNetworksData() {
        return networksData;
    },
    getSecurityData() {
        return securityData;
    },
    getDbGovernanceData() {
        return dbGovernanceData;
    },
    getComputesData() {
        return computesData;
    },
    getIamData() {
        return iamData;
    },
    getMyServicesData() {
        return myServicesData;
    },
    getAvailabilityMachinesData() {
        return availabilityMachinesData;
    },
    getBenchmarksData() {
        return benchmarksData;
    },
    getServersData() {
        return serversData;
    },
    getBillingData() {
        return billingData;
    },
    getGeneralApisData() {
        return generalApisData;
    },
    getScriptsLibraryData() {
        return scriptsLibraryData;
    },
    getDataflixData() {
        return dataflixData;
    },
    getTicketsData() {
        return ticketsData;
    },
};

export default MockRepository;
