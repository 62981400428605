export default function ResizeComputeIcon() {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="40" height="40" rx="20" fill="#F2FAFE" />
            <rect x="15.2002" y="8" width="1.8" height="24" rx="0.6" fill="#3986C1" />
            <rect x="19.4004" y="8" width="1.8" height="24" rx="0.6" fill="#3986C1" />
            <rect x="23.5996" y="8" width="1.8" height="24" rx="0.6" fill="#3986C1" />
            <rect
                x="32"
                y="14.9004"
                width="1.8"
                height="24"
                rx="0.6"
                transform="rotate(90 32 14.9004)"
                fill="#3986C1"
            />
            <rect
                x="32"
                y="19.1006"
                width="1.8"
                height="24"
                rx="0.6"
                transform="rotate(90 32 19.1006)"
                fill="#3986C1"
            />
            <rect
                x="32"
                y="23.2998"
                width="1.8"
                height="24"
                rx="0.6"
                transform="rotate(90 32 23.2998)"
                fill="#3986C1"
            />
            <rect
                x="11.5"
                y="11.5"
                width="17"
                height="17"
                rx="1.9"
                fill="#F2FAFE"
                stroke="#3986C1"
            />
            <g filter="url(#filter0_d_2699_3161)">
                <rect x="15" y="15" width="10" height="10" rx="1.2" fill="#3986C1" />
            </g>
            <defs>
                <filter
                    id="filter0_d_2699_3161"
                    x="12.6"
                    y="13.2"
                    width="14.8"
                    height="14.8"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="0.6" />
                    <feGaussianBlur stdDeviation="1.2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.215686 0 0 0 0.08 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2699_3161"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2699_3161"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
