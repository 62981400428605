import clsx from 'clsx';
import { useStyles } from './utils';

export default function OverviewIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1544_518)">
                <path
                    d="M11.6918 13.7436H11.0668C10.7353 13.7436 10.4173 13.6119 10.1828 13.3775C9.94842 13.1431 9.81676 12.8251 9.81676 12.4936L10.1829 9.19214C10.1829 9.02639 10.117 8.86739 9.99978 8.75022C9.88261 8.63297 9.72361 8.56714 9.55786 8.56714H8.93286"
                    stroke="var(--icon-color)"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.75 5.9375C8.75 5.41972 9.16975 5 9.6875 5C10.2053 5 10.625 5.41972 10.625 5.9375C10.625 6.45528 10.2053 6.875 9.6875 6.875C9.16975 6.875 8.75 6.45528 8.75 5.9375Z"
                    fill="var(--icon-color)"
                />
                <path
                    d="M10 19.375C15.1777 19.375 19.375 15.1777 19.375 10C19.375 4.82233 15.1777 0.625 10 0.625C4.82233 0.625 0.625 4.82233 0.625 10C0.625 15.1777 4.82233 19.375 10 19.375Z"
                    stroke="var(--icon-color)"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1544_518">
                    <rect width="20" height="20" fill="var(--icon-color)" />
                </clipPath>
            </defs>
        </svg>
    );
}
