/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function ProvisioningIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);
    const iconStyleAdapt = {
        ...iconStyle,
    };

    return (
        // <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        //   <path fillRule="evenodd" clipRule="evenodd" d="M8.1046 0C7.01438 0.0299141 5.35958 0.224607 3.93168 0.722495C3.21797 0.971356 2.5317 1.3059 2.00019 1.75697C1.46274 2.21309 1.06412 2.807 0.99113 3.55542L0.988769 3.57963V3.60395V3.62531C0.988146 3.64826 0.988159 3.67129 0.98877 3.69435L0.988848 10.9888V10.9888L0.988769 14.6493C0.970706 15.3069 1.45359 16.1212 2.52158 16.76C3.60665 17.4091 5.35697 17.9254 8.07737 17.9992L8.07739 18L8.09325 17.9996L8.10536 17.9999L8.10538 17.9993C8.53814 17.9875 8.94667 17.9646 9.33214 17.9317C9.26263 17.6093 9.21652 17.278 9.1957 16.9397C8.85054 16.9681 8.48344 16.9884 8.09317 16.9992C5.48029 16.9271 3.92069 16.4317 3.0349 15.9018C2.12938 15.3602 1.98308 14.8276 1.98846 14.6746L1.98877 14.6658L1.98877 14.657L1.98885 10.9888V10.9888L1.98879 5.45129C2.16016 5.60116 2.35514 5.74736 2.57491 5.88677C3.67115 6.58213 5.41838 7.13825 8.10512 7.20792L8.10956 7.0367L8.11809 6.71671L8.12352 6.91782L8.13104 7.20792C10.8764 7.13673 12.6411 6.5576 13.7325 5.84082C13.9245 5.71469 14.097 5.58309 14.2507 5.44833V10.1256C14.5756 10.0535 14.9096 10.0063 15.2507 9.98591V3.60405V3.57973L15.2483 3.55552C15.1753 2.8071 14.7767 2.21318 14.2393 1.75707C13.7078 1.30599 13.0215 0.971454 12.3078 0.722594C10.8799 0.224706 9.22508 0.0300127 8.13486 9.86755e-05L8.11973 0.551432L8.1046 0ZM14.2507 3.65044V3.63017C14.2038 3.21581 13.9818 2.85016 13.5922 2.51951C13.1897 2.17787 12.6293 1.89375 11.9785 1.66684C10.6819 1.21473 9.14242 1.02911 8.118 1.00002C7.09358 1.02942 5.55607 1.21514 4.26092 1.66674C3.61015 1.89365 3.04981 2.17777 2.64725 2.51941C2.25764 2.85006 2.03566 3.21571 1.98877 3.63007V3.63296V3.63296L1.98877 3.67676C1.99972 3.88772 2.21031 4.47129 3.11055 5.04233C4.00613 5.6104 5.55564 6.13982 8.11808 6.20792C10.737 6.13833 12.2975 5.58689 13.1835 5.00497C14.0881 4.41082 14.2537 3.8197 14.2478 3.65054L14.2507 3.65044Z" fill="var(--icon-color)" />
        //   <path d="M23.4995 16.7875C23.4995 20.5342 20.5893 23.5578 16.7682 23.5578C12.929 23.5578 9.83572 20.5163 9.83572 16.7875C9.83572 13.0478 12.94 9.89403 16.7682 9.89403C20.5786 9.89403 23.4995 13.0296 23.4995 16.7875Z" stroke="var(--icon-color)" />
        //   <path d="M4.1006 12.3716C3.89283 12.2965 3.66355 12.4041 3.58851 12.6119C3.51346 12.8197 3.62106 13.049 3.82884 13.124L4.1006 12.3716ZM8.68517 13.658C8.90374 13.6259 9.05493 13.4227 9.02286 13.2042C8.99079 12.9856 8.7876 12.8344 8.56903 12.8665L8.68517 13.658ZM3.82884 13.124C4.6667 13.4266 5.35391 13.6429 6.10045 13.7397C6.84991 13.837 7.63849 13.8116 8.68517 13.658L8.56903 12.8665C7.56786 13.0134 6.85719 13.0312 6.20339 12.9464C5.54668 12.8612 4.92662 12.6699 4.1006 12.3716L3.82884 13.124Z" fill="var(--icon-color)" />
        //   <path d="M4.1006 9.07939C3.89283 9.00435 3.66355 9.11195 3.58851 9.31972C3.51346 9.5275 3.62106 9.75677 3.82884 9.83182L4.1006 9.07939ZM8.68517 10.3658C8.90374 10.3337 9.05493 10.1305 9.02286 9.91196C8.99079 9.69338 8.7876 9.54219 8.56903 9.57427L8.68517 10.3658ZM3.82884 9.83182C4.6667 10.1344 5.35391 10.3507 6.10045 10.4475C6.84991 10.5448 7.63849 10.5194 8.68517 10.3658L8.56903 9.57427C7.56786 9.72117 6.85719 9.73902 6.20339 9.65418C5.54668 9.56896 4.92662 9.37773 4.1006 9.07939L3.82884 9.83182Z" fill="var(--icon-color)" />
        //   <path fillRule="evenodd" clipRule="evenodd" d="M13.295 15.974H15.5964V13.6335C15.5964 12.324 17.9532 12.324 17.9532 13.6335V15.974H20.2404C21.5918 15.974 21.5918 18.0309 20.2404 18.0309H17.9532V20.1476C17.9532 21.4405 15.5964 21.4405 15.5964 20.1476V18.0309H13.295C11.9263 18.0309 11.9263 15.974 13.295 15.974Z" fill="var(--icon-color)" />
        // </svg>

        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyleAdapt}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2331_337)">
                <ellipse cx="7.75" cy="4" rx="6.75" ry="3" stroke="var(--icon-color)" />
                <path
                    d="M7.75 11.25C4.02208 11.25 1 10.1307 1 8.75"
                    stroke="var(--icon-color)"
                    strokeLinecap="round"
                />
                <path
                    d="M1 4V13.0185C1 14.5532 3.62504 15.817 7 15.9818M14.5 4V7.25"
                    stroke="var(--icon-color)"
                    strokeLinecap="round"
                />
                <path
                    d="M14.25 19C16.8734 19 19 16.8734 19 14.25C19 11.6266 16.8734 9.5 14.25 9.5C11.6266 9.5 9.5 11.6266 9.5 14.25C9.5 16.8734 11.6266 19 14.25 19Z"
                    stroke="var(--icon-color)"
                    strokeWidth="1.02308"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.2501 12.0576V16.4422"
                    stroke="var(--icon-color)"
                    strokeWidth="1.02308"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.0577 14.25H16.4424"
                    stroke="var(--icon-color)"
                    strokeWidth="1.02308"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2331_337">
                    <rect width="20" height="20" fill="var(--icon-color)" />
                </clipPath>
            </defs>
        </svg>
    );
}
