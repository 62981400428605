/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function LicensesFallbackImage(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="49"
            height="80"
            viewBox="0 0 49 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.1925 40.0086C28.3317 40.0086 29.2324 41.405 30.257 41.7475C31.2814 42.0899 32.7961 41.4988 33.6493 42.1269C34.5028 42.7549 34.3917 44.3802 35.0171 45.237C35.6427 46.0938 37.2263 46.4873 37.5674 47.5162C37.9084 48.545 36.8874 49.8416 36.8874 50.9854C36.8874 52.1293 37.9084 53.426 37.5674 54.4548C37.2263 55.4837 35.6427 55.877 35.0171 56.734C34.3917 57.5909 34.5028 59.2159 33.6493 59.8441C32.7961 60.4722 31.2816 59.8811 30.257 60.2235C29.2324 60.566 28.3317 61.9624 27.1925 61.9624C26.0534 61.9624 25.1527 60.566 24.1281 60.2235C23.1036 59.8811 21.589 60.4722 20.7358 59.8441C19.8822 59.2159 19.9934 57.5909 19.3679 56.734C18.7423 55.877 17.1588 55.4837 16.8176 54.455C16.4765 53.426 17.4977 52.1294 17.4977 50.9854C17.4977 49.8416 16.4765 48.545 16.8176 47.5162C17.1588 46.4873 18.7423 46.0938 19.3679 45.237C19.9934 44.3802 19.8822 42.7549 20.7358 42.1269C21.589 41.4988 23.1035 42.0899 24.1281 41.7475C25.1527 41.405 26.0534 40.0086 27.1925 40.0086Z"
                fill="#E5F6FC"
                stroke="#29B5E8"
                strokeWidth="2.2"
                strokeMiterlimit="22.926"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.7479 65.9386L6.6248 65.9377C3.58736 65.9375 1.12512 63.4752 1.12512 60.4377V7.49997C1.12512 4.46241 3.58756 1.99997 6.62512 1.99997H36.4197L47.8749 15.5188V60.4386C47.8749 63.4762 45.4125 65.9386 42.3749 65.9386H33.2479"
                stroke="#29B5E8"
                strokeWidth="2.2"
                strokeMiterlimit="22.926"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.2738 60.0087L33.2739 78.129L27.5111 74.2746L21.7482 78.129L21.7481 60.0087"
                stroke="#29B5E8"
                strokeWidth="2.2"
                strokeMiterlimit="22.926"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.54584 13.4728H28.8031"
                stroke="#29B5E8"
                strokeWidth="2.2"
                strokeMiterlimit="22.926"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.54584 21.1211H36.7226"
                stroke="#29B5E8"
                strokeWidth="2.2"
                strokeMiterlimit="22.926"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                opacity="0.2"
                d="M46.847 16.0087V61.0564C46.847 63.5162 44.8545 65.5087 42.3947 65.5087H34.7479C37.2078 65.5087 40.2479 63.5162 40.2479 61.0564V16.0087C40.2479 13.5487 40.7078 4.50867 38.2479 4.50867L42.3947 10.0087C44.8545 10.0087 46.847 13.5487 46.847 16.0087Z"
                fill="#29B5E8"
                fillOpacity="0.6"
            />
        </svg>
    );
}
