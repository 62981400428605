import clsx from 'clsx';
import { useStyles } from './utils';

export default function SLADetailsIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <g clipPath="url(#clip0_862_3999)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.95035 5.50781H7.96702C8.2259 5.50781 8.43577 5.71768 8.43577 5.97656C8.43577 6.23545 8.2259 6.44531 7.96702 6.44531H4.66425L3.37774 11.1089L8.94512 15.5301C9.00457 15.5773 9.08935 15.5749 9.14609 15.5245L10.0031 14.7627L8.93925 13.8318C8.74442 13.6613 8.72468 13.3651 8.89515 13.1703C9.06563 12.9755 9.36177 12.9557 9.5566 13.1262L11.0127 14.4003L11.6108 14.8788L12.5649 14.0307L11.5011 13.0998C11.3062 12.9293 11.2865 12.6332 11.457 12.4384C11.6274 12.2435 11.9236 12.2238 12.1184 12.3943L13.594 13.6855L14.0589 14.1504L15.1006 13.4212L10.6121 9.08769C10.4258 8.90788 10.4206 8.61113 10.6004 8.42488C10.7802 8.23863 11.077 8.23341 11.2632 8.41323L15.8882 12.8784C16.2417 13.2197 16.1961 13.7987 15.7936 14.0805L14.4892 14.9936C14.1786 15.211 13.7568 15.174 13.4887 14.906L13.2547 14.6719L12.1352 15.6671C11.8507 15.92 11.4253 15.931 11.1281 15.6932L10.7277 15.3729L9.76893 16.2252C9.37175 16.5782 8.77826 16.5947 8.3621 16.2642L2.30908 11.4574L3.95035 5.50781Z"
                    fill="#666666"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.4685 5.04204C8.87757 4.74985 9.36772 4.59277 9.87043 4.59277C10.1109 4.59277 10.3499 4.62872 10.5798 4.69943L15.2656 6.14123L17.7335 11.8996L14.8169 13.1148L10.5209 8.98413C10.2685 8.74135 9.88525 8.6963 9.58334 8.87389L7.8262 9.9075C7.33373 10.1972 6.71238 10.1464 6.27345 9.78066C5.73638 9.3331 5.62853 8.55016 6.0246 7.97406L7.26909 6.16389C7.50604 5.81924 7.80133 5.5186 8.14166 5.2755L8.4685 5.04204Z"
                    fill="#666666"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.75016 4.85188C1.91147 4.26976 2.51414 3.92862 3.09627 4.08993L4.06727 4.35899C4.6494 4.5203 4.99054 5.12297 4.82923 5.7051L3.10683 11.9208C2.94552 12.503 2.34285 12.8441 1.76073 12.6828L0.789723 12.4137C0.207597 12.2524 -0.133542 11.6498 0.0277658 11.0676L1.75016 4.85188Z"
                    fill="#666666"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.1119 4.63128C16.8745 4.07582 16.2318 3.81798 15.6763 4.05538L14.7416 4.45485C14.1862 4.69224 13.9283 5.33498 14.1657 5.89043L16.8549 12.1223C17.0923 12.6777 17.7351 12.9356 18.2905 12.6982L19.2252 12.2987C19.7807 12.0613 20.0385 11.4186 19.8011 10.8631L17.1119 4.63128Z"
                    fill="#666666"
                />
            </g>
            <defs>
                <clipPath id="clip0_862_3999">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
