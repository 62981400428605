import { APP_KEY } from './appKeyConfig';

export const getAppKey = (app, isProd) => {
    if (app === APP_KEY.LOGROCKET.appName) {
        return isProd
            ? process.env[`${APP_KEY.LOGROCKET.envName}_PROD`]
            : process.env[`${APP_KEY.LOGROCKET.envName}_DEV`];
    }
    if (app === APP_KEY.MUI.appName) {
        return process.env[APP_KEY.MUI.envName];
    }
    if (app === APP_KEY.USERFLOW_INIT_KEY.appName) {
        return isProd
            ? process.env[`${APP_KEY.USERFLOW_INIT_KEY.envName}_PROD`]
            : process.env[`${APP_KEY.USERFLOW_INIT_KEY.envName}_DEV`];
    }
    if (app === APP_KEY.USERFLOW_ID_KEY.appName) {
        return isProd
            ? process.env[`${APP_KEY.USERFLOW_ID_KEY.envName}_PROD`]
            : process.env[`${APP_KEY.USERFLOW_ID_KEY.envName}_DEV`];
    }
    if (app === APP_KEY.INTERCOM.appName) {
        return isProd
            ? process.env[`${APP_KEY.INTERCOM.envName}_PROD`]
            : process.env[`${APP_KEY.INTERCOM.envName}_DEV`];
    }
    if (app === APP_KEY.STRIPE_KEY.appName) {
        return isProd
            ? process.env[`${APP_KEY.STRIPE_KEY.envName}_PROD`]
            : process.env[`${APP_KEY.STRIPE_KEY.envName}_DEV`];
    }

    return '';
};
