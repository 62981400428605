// import clsx from 'clsx';
// import { IconPropType } from './utils';

export default function StartScheduleIcon() {
    //   const { className } = props;
    //   const iconStyle = useStyles(props);

    return (
        // <svg className={clsx({ [className]: !!className })} style={iconStyle} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        //   <path d="M8.43743 18.944L1.51743 16.23C1.24727 16.1148 1.01784 15.9212 0.85875 15.6744C0.699661 15.4275 0.618217 15.1386 0.624929 14.845V5.15496C0.617943 4.86172 0.698896 4.5731 0.857364 4.32627C1.01583 4.07943 1.24456 3.88568 1.5141 3.76996L9.09743 0.796631C9.67612 0.567464 10.3204 0.567464 10.8991 0.796631L18.4824 3.76996C18.7526 3.88517 18.982 4.0787 19.1411 4.32557C19.3002 4.57245 19.3816 4.86134 19.3749 5.15496V7.34372M17.4999 16.6166L18.4858 16.23C18.7553 16.1142 18.984 15.9205 19.1425 15.6737C19.2191 15.5543 19.2776 15.4252 19.3168 15.2905" stroke="var(--icon-color)" strokeLinecap="round" strokeLinejoin="round" />
        //   <path d="M9.99992 7.79413L0.919922 4.23413" stroke="var(--icon-color)" strokeLinecap="round" strokeLinejoin="round" />
        //   <path d="M10 7.79413L19.08 4.23413" stroke="var(--icon-color)" strokeLinecap="round" strokeLinejoin="round" />
        //   <path d="M10 12.1875L10 7.8125" stroke="var(--icon-color)" strokeLinecap="round" strokeLinejoin="round" />
        //   <path d="M15.033 15.4207L16.0914 14.3749L16.8247 14.5707C16.9375 14.5971 17.0553 14.5928 17.1659 14.5584C17.2766 14.524 17.376 14.4606 17.4539 14.3749C17.5302 14.293 17.5845 14.1931 17.6114 14.0844C17.6384 13.9758 17.6372 13.8621 17.608 13.754L17.4122 13.0249L17.7539 12.7082L18.4872 12.904C18.6 12.9304 18.7178 12.9262 18.8284 12.8917C18.9391 12.8573 19.0385 12.7939 19.1164 12.7082C19.1928 12.6272 19.2474 12.5281 19.275 12.4203C19.3027 12.3124 19.3026 12.1993 19.2747 12.0915L19.0789 11.3624C19.2629 11.1784 19.3662 10.9288 19.3662 10.6686C19.3662 10.4084 19.2629 10.1589 19.0789 9.97486C18.8949 9.79087 18.6453 9.6875 18.3851 9.6875C18.1249 9.6875 17.8754 9.79087 17.6914 9.97486L13.6455 14.0332C13.0856 13.7953 12.4604 13.7597 11.877 13.9323C11.2936 14.105 10.7885 14.4752 10.4483 14.9795C10.108 15.4839 9.95378 16.0908 10.0121 16.6964C10.0703 17.302 10.3374 17.8684 10.7676 18.2986C11.1978 18.7288 11.7642 18.9959 12.3698 19.0542C12.9754 19.1125 13.5824 18.9582 14.0867 18.618C14.591 18.2777 14.9612 17.7726 15.1339 17.1892C15.3066 16.6059 15.2709 15.9806 15.033 15.4207Z" stroke="var(--icon-color)" strokeLinecap="round" strokeLinejoin="round" />
        // </svg>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24 12.1007C24 18.6725 18.8829 24 12.1646 24C5.44628 24 0 18.6725 0 12.1007C0 5.52891 5.44628 0 12.1646 0C18.8829 0 24 5.52891 24 12.1007Z"
                fill="#E8F9E7"
            />
            <path
                d="M8.50001 17.4924V7.5C8.52705 7.50088 8.55201 7.50384 8.57186 7.50784C8.58112 7.5097 8.5873 7.51139 8.59075 7.51243L17.34 12.5201L8.70413 17.468C8.6776 17.4832 8.65944 17.4915 8.64762 17.4958C8.64293 17.4975 8.63995 17.4983 8.63853 17.4987C8.59565 17.5015 8.55039 17.4997 8.5082 17.4936C8.50542 17.4932 8.50269 17.4928 8.50001 17.4924ZM8.4058 17.4655C8.40582 17.4655 8.40628 17.4657 8.40713 17.4663L8.4058 17.4655Z"
                fill="#4DAC4A"
                stroke="#4DAC4A"
            />
        </svg>
    );
}
