/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

// import clsx from 'clsx';
// import { useStyles } from './utils';

export default function StopIcon(props) {
    // const { className } = props;
    // const iconStyle = useStyles(props);

    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.5 10.0755C18.5 14.7358 14.8785 18.5 10.1234 18.5C5.3504 18.5 1.5 14.7179 1.5 10.0755C1.5 5.42233 5.36135 1.5 10.1234 1.5C14.8678 1.5 18.5 5.40419 18.5 10.0755Z"
                stroke="#F29132"
            />
            <path
                d="M6.74023 7C6.74023 6.72386 6.96409 6.5 7.24023 6.5H13.2402C13.5164 6.5 13.7402 6.72386 13.7402 7V13C13.7402 13.2761 13.5164 13.5 13.2402 13.5H7.24023C6.96409 13.5 6.74023 13.2761 6.74023 13V7Z"
                stroke="#F29132"
            />
        </svg>
    );
}
