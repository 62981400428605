/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './utils';

export default function VisaCardIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 35 30"
            width="46"
            height="46"
            xmlSpace="preserve"
            fill="none"
            // xversion="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <rect x=".5" y=".5" width="33" height="23" rx="3.5" fill="#fff" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.75 15.858H8.69L7.147 9.792c-.073-.279-.229-.525-.458-.642A6.573 6.573 0 0 0 4.8 8.508v-.233h3.318c.458 0 .802.35.859.758l.801 4.376 2.059-5.134h2.002l-3.089 7.583Zm4.234 0H13.04l1.602-7.583h1.945l-1.602 7.583Zm4.119-5.482c.057-.409.4-.642.801-.642a3.535 3.535 0 0 1 1.888.35l.344-1.633a4.8 4.8 0 0 0-1.774-.351c-1.888 0-3.262 1.05-3.262 2.508 0 1.11.973 1.692 1.66 2.042.743.35 1.03.584.972.934 0 .524-.572.758-1.143.758a4.788 4.788 0 0 1-2.003-.467l-.343 1.633c.687.292 1.43.409 2.117.409 2.117.058 3.432-.992 3.432-2.567 0-1.984-2.69-2.1-2.69-2.974Zm9.497 5.482-1.545-7.583h-1.659a.862.862 0 0 0-.8.583l-2.861 7h2.002l.4-1.108h2.46l.23 1.108H28.6Zm-2.917-5.54.571 2.857h-1.602l1.03-2.858Z"
                fill="#172B85"
            />
            <rect x=".5" y=".5" width="33" height="23" rx="3.5" stroke="#D2D2D2" />
        </svg>
    );
}
