import _isNil from 'lodash/isNil';

class CacheClass {
    prefix = 'tessell-';

    reduxPersistKey = 'persist:root';

    constructor() {
        this.ItemsList = Object.keys(localStorage).filter((k) => k.includes(this.prefix));
    }

    get(key) {
        if (!key) {
            return null;
        }
        return localStorage.getItem(this.prefix + key);
    }

    // default value is null, user can pass any value as default value, which will be returned if key is not found, or any error during parsing
    getParsedValue(key, defaultValue = null) {
        if (!key) {
            return null;
        }
        const storedData = localStorage.getItem(this.prefix + key);
        if (_isNil(storedData)) {
            return defaultValue;
        }
        try {
            return JSON.parse(storedData);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Failed to parse data from localStorage:', error);
            return defaultValue;
        }
    }

    set(key, value) {
        if (!key) {
            return null;
        }
        this.ItemsList.push(this.prefix + key);
        return localStorage.setItem(this.prefix + key, value);
    }

    remove(key) {
        if (!key) {
            return null;
        }
        try {
            const ret = localStorage.removeItem(this.prefix + key);
            this.ItemsList.pop(this.ItemsList.indexOf(this.prefix + key));
            return ret;
        } catch (error) {
            return null;
        }
    }

    clear() {
        sessionStorage.clear();
        this.ItemsList.filter((item) => item !== 'redirect-url').forEach((item) => {
            localStorage.removeItem(item);
        });
        localStorage.removeItem(this.reduxPersistKey);
        this.ItemsList = [];
    }

    toJson() {
        const data = {};
        this.ItemsList.forEach((i) => {
            let value = localStorage.getItem(i);
            try {
                value = JSON.parse(value);
            } catch (e) {
                // do nothing
            }
            data[i] = value;
        });
        return data;
    }
}

const Cache = new CacheClass();
export default Cache;
