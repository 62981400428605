import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'userData',
    initialState: {
        value: {},
    },
    reducers: {
        update: (state, action) => {
            // eslint-disable-next-line no-param-reassign
            state.value = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { update } = userSlice.actions;

export default userSlice.reducer;
