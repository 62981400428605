/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

// database config icon
export default function DatabaseIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="8.16667"
                cy="4.41667"
                rx="6.66667"
                ry="2.91667"
                stroke="var(--icon-color)"
            />
            <path
                d="M1.5 9C1.5 10.3807 4.48477 11.5 8.16667 11.5C9.17978 11.5 10.1401 11.4153 11 11.2636"
                stroke="var(--icon-color)"
                strokeLinecap="round"
            />
            <path
                d="M1.5 4.41675V13.618C1.5 15.2097 4.48477 16.5001 8.16667 16.5001M14.8333 4.41675V7.75008"
                stroke="var(--icon-color)"
                strokeLinecap="round"
            />
            <path
                d="M18.7363 11.7096C18.7225 11.6347 18.6931 11.5636 18.6501 11.5008C18.607 11.4381 18.5512 11.3851 18.4863 11.3453L17.0578 12.7738C16.9911 12.843 16.9113 12.898 16.8229 12.9355C16.7345 12.9731 16.6395 12.9924 16.5435 12.9924C16.4475 12.9924 16.3524 12.9731 16.2641 12.9355C16.1757 12.898 16.0958 12.843 16.0292 12.7738L15.4863 12.2881C15.3555 12.1546 15.2822 11.9751 15.2822 11.7881C15.2822 11.6012 15.3555 11.4216 15.4863 11.2881L16.9149 9.85956C16.8838 9.78637 16.8369 9.72092 16.7776 9.66785C16.7184 9.61477 16.6482 9.57536 16.5721 9.55241C16.0736 9.45301 15.5573 9.49556 15.0818 9.67522C14.6064 9.85487 14.1909 10.1644 13.8827 10.5686C13.5746 10.9728 13.3861 11.4554 13.3387 11.9614C13.2914 12.4675 13.3871 12.9766 13.6149 13.431L9.71491 17.2953C9.64688 17.3618 9.59282 17.4412 9.55592 17.5289C9.51901 17.6166 9.5 17.7108 9.5 17.806C9.5 17.9011 9.51901 17.9953 9.55592 18.083C9.59282 18.1707 9.64688 18.2502 9.71491 18.3167L9.97205 18.5738C10.0386 18.6419 10.118 18.6959 10.2057 18.7328C10.2934 18.7697 10.3876 18.7888 10.4828 18.7888C10.5779 18.7888 10.6721 18.7697 10.7598 18.7328C10.8475 18.6959 10.927 18.6419 10.9935 18.5738L14.8935 14.681C15.3479 14.8975 15.8536 14.9837 16.3541 14.9301C16.8546 14.8764 17.3305 14.685 17.7287 14.377C18.1269 14.0691 18.432 13.6568 18.6099 13.1858C18.7877 12.7149 18.8315 12.2039 18.7363 11.7096Z"
                stroke="var(--icon-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
