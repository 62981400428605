/* eslint-disable no-param-reassign */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     9/4/2021     bakul.banthia         Created
 *
 */

import { createSlice } from '@reduxjs/toolkit';

export const cloning = createSlice({
    name: 'cloningData',
    initialState: {
        value: {},
    },
    reducers: {
        launch: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { launch } = cloning.actions;

export default cloning.reducer;
