import VpcsJson from './vpcs.json';
import VpcsSubscriptionNameJson from './vpcs-subscriptionName.json';
import VpcPeeringJson from './vpc-peerings-subscriptionName.json';
import SubnetsSubscriptionName from './subnets-subscriptionName.json';
import byoaMetadataJson from './byoa-metadata.json';
import SampleVirtualNetwork from './SampleVirtualNetwork.json';
import replaceTimestampWithMockDate from 'tools/MockTime';
import registerNetworksSubscriptionName from './register-networks-subscriptionName.json';

// replacing date with Mock Date
VpcsJson = replaceTimestampWithMockDate(VpcsJson);
VpcsSubscriptionNameJson = replaceTimestampWithMockDate(VpcsSubscriptionNameJson);
VpcPeeringJson = replaceTimestampWithMockDate(VpcPeeringJson);
SubnetsSubscriptionName = replaceTimestampWithMockDate(SubnetsSubscriptionName);
byoaMetadataJson = replaceTimestampWithMockDate(byoaMetadataJson);
SampleVirtualNetwork = replaceTimestampWithMockDate(SampleVirtualNetwork);
registerNetworksSubscriptionName = replaceTimestampWithMockDate(registerNetworksSubscriptionName);

const modifyNewNetwork = (requestBody, sampleNetwork) => {
    const newObj = sampleNetwork;

    newObj.cidrBlock = requestBody.cidrBlock;
    newObj.cloudType = requestBody.cloudType;
    newObj.name = requestBody.name;
    newObj.region = requestBody.region;
    newObj.subscriptionName = requestBody.subscriptionName;
    newObj.dateCreated = new Date().toISOString();
    return newObj;
};

export default class UBSNetworkLoader {
    // eslint-disable-next-line class-methods-use-this
    initVpcs() {
        return VpcsJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initByoaMetadata() {
        return byoaMetadataJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initVpcsSubscriptionName(subName) {
        const networkNewlyProvisioned = JSON.parse(sessionStorage.getItem('demo-networks'));

        if (!networkNewlyProvisioned) {
            return VpcsSubscriptionNameJson[subName];
        }

        const filteredNetworks = networkNewlyProvisioned.filter(
            (network) => network.subscriptionName === subName,
        );

        const newNetworkJson = [...VpcsSubscriptionNameJson[subName], ...filteredNetworks];

        return newNetworkJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initVpcPeering(vpcName) {
        return VpcPeeringJson[vpcName];
    }

    // eslint-disable-next-line class-methods-use-this
    initRegisterNetworksSubscriptionName() {
        return registerNetworksSubscriptionName;
    }

    // eslint-disable-next-line class-methods-use-this
    initSubnetsSubscriptionName(subName) {
        return SubnetsSubscriptionName[subName];
    }

    // eslint-disable-next-line class-methods-use-this
    createVirtualNetwork(requestBody) {
        const newNetwork = modifyNewNetwork(JSON.parse(requestBody), SampleVirtualNetwork);

        if (sessionStorage.getItem('demo-networks')) {
            const oldNetwork = JSON.parse(sessionStorage.getItem('demo-networks'));
            sessionStorage.setItem('demo-networks', JSON.stringify([...oldNetwork, newNetwork]));
        } else {
            sessionStorage.setItem('demo-networks', JSON.stringify([newNetwork]));
        }

        return newNetwork;
    }

    // eslint-disable-next-line class-methods-use-this
    updateMaturity(_, url) {
        // network/governance/vpcs/yash/publish?subscriptionName=azure&cloudType=AZURE&region=eastUS
        const splittedUrl = url.split('/');
        const name = splittedUrl?.[3];
        const action = splittedUrl[splittedUrl.length - 1]?.split('?')?.[0];

        const [subscriptionName, cloudType, region] = splittedUrl?.[splittedUrl?.length - 1]
            .split('&')
            .map((el) => el.split('=')[1]);

        if (sessionStorage.getItem('demo-networks')) {
            const oldNetwork = JSON.parse(sessionStorage.getItem('demo-networks'));

            if (oldNetwork) {
                oldNetwork.forEach((networkData, idx) => {
                    if (
                        networkData.name === name &&
                        networkData.subscriptionName === subscriptionName &&
                        networkData.cloudType === cloudType &&
                        networkData.region === region
                    ) {
                        if (action === 'publish') {
                            oldNetwork[idx].maturityStatus = 'PUBLISHED';
                        } else if (action === 'unpublish') {
                            oldNetwork[idx].maturityStatus = 'UNPUBLISHED';
                        } else if (action === 'draft') {
                            oldNetwork[idx].maturityStatus = 'DRAFT';
                        }
                    }
                });
                sessionStorage.setItem('demo-networks', JSON.stringify(oldNetwork));
            }
        }
    }

    // eslint-disable-next-line class-methods-use-this
    deleteVirtualNetwork(_, url) {
        // /network/governance/vpcs/yash-test?subscriptionName=azure&cloudType=AZURE&region=eastUS
        const splittedUrl = url.split('/');
        const name = splittedUrl?.[splittedUrl?.length - 1].split('?')[0];

        const [subscriptionName, cloudType, region] = splittedUrl?.[splittedUrl?.length - 1]
            .split('?')[1]
            .split('&')
            .map((el) => el.split('=')[1]);

        if (sessionStorage.getItem('demo-networks')) {
            const oldNetwork = JSON.parse(sessionStorage.getItem('demo-networks'));

            if (oldNetwork) {
                const setNetwork = oldNetwork.filter(
                    (networkData) =>
                        !(
                            networkData.name === name &&
                            networkData.subscriptionName === subscriptionName &&
                            networkData.cloudType === cloudType &&
                            networkData.region === region
                        ),
                );
                sessionStorage.setItem('demo-networks', JSON.stringify(setNetwork));
            }
        }
    }
}
