import { styles } from 'styles/style-templates';

export default function TessellIcon(props) {
    const { width, height, dark, className } = props;
    const colors = {
        width,
        height,
        '--dark-shade': styles.color.hover,
        '--light-shade': dark ? styles.color.baseBackground : styles.color.darkShade,
    };

    return (
        <svg
            className={className}
            viewBox="0 0 59 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={colors}
        >
            <>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M44.1155 36.7887L28 27.5L44.0932 18.1617L51.8056 22.7385L51.8056 32L44.1155 36.7887Z"
                    fill="#11567F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.9778 6.92578L26.9555 25.5528L10.886 16.2586L10.9864 7.27917L18.943 2.27867L26.9778 6.92578Z"
                    fill="#11567F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.89209 37.7142L26.0061 28.42V47.0084L18.1933 51.411L9.89209 47.0084V37.7142Z"
                    fill="#11567F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.9845 46.1229L28 27.5L44.0763 36.7901L43.9758 45.7695L36.0193 50.77L27.9845 46.1229Z"
                    fill="#29B5E8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.8407 16.2465L26.9325 25.5793L10.8629 34.8735L3.15054 30.2967L3.15054 21L10.8407 16.2465Z"
                    fill="#29B5E8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.106 16.2706L26.9919 25.5648L26.9919 6.97641L34.8048 2.57388L43.106 6.97641L43.106 16.2706Z"
                    fill="#29B5E8"
                />
            </>
        </svg>
    );
}
