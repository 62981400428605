import clsx from 'clsx';
import React from 'react';
import { useStyles } from './utils';

export default function MeterIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
        >
            <path
                d="M16.9832 10.8148C16.9832 10.9265 16.9388 11.0336 16.8598 11.1125C16.7809 11.1915 16.6738 11.2359 16.5621 11.2359C16.4504 11.2359 16.3433 11.1915 16.2644 11.1125C16.1854 11.0336 16.1411 10.9265 16.1411 10.8148C16.1435 9.63961 15.8541 8.48219 15.2989 7.44639L14.7516 7.99376C14.3726 8.38955 13.7579 7.78324 14.1537 7.40429L14.8358 6.71376C14.2182 5.83106 13.4086 5.09973 12.4679 4.57468C11.5272 4.04963 10.4797 3.74449 9.40421 3.68218C9.40421 4.04429 9.58947 4.91166 8.98316 4.91166C8.37684 4.91166 8.59579 4.06955 8.5621 3.68218C7.49163 3.74406 6.44888 4.04648 5.51141 4.56697C4.57395 5.08746 3.7659 5.81262 3.14737 6.6885L3.82947 7.37902C4.22526 7.75797 3.61053 8.36429 3.23158 7.9685L2.68421 7.42113C2.12902 8.45693 1.83966 9.61435 1.84211 10.7896C1.84211 10.9012 1.79774 11.0083 1.71878 11.0873C1.63982 11.1662 1.53272 11.2106 1.42105 11.2106C1.30938 11.2106 1.20229 11.1662 1.12332 11.0873C1.04436 11.0083 1 10.9012 1 10.7896C1 8.66782 1.84285 6.63299 3.34315 5.1327C4.84344 3.63241 6.87827 2.78955 9 2.78955C11.1217 2.78955 13.1566 3.63241 14.6569 5.1327C16.1571 6.63299 17 8.66782 17 10.7896L16.9832 10.8148ZM9.40421 9.1306V7.03025C9.40421 6.91858 9.35985 6.81149 9.28089 6.73252C9.20193 6.65356 9.09483 6.6092 8.98316 6.6092C8.87149 6.6092 8.76439 6.65356 8.68543 6.73252C8.60647 6.81149 8.5621 6.91858 8.5621 7.03025V9.15587C8.1652 9.25835 7.81929 9.50207 7.58922 9.84134C7.35915 10.1806 7.26072 10.5922 7.31237 10.9988C7.36402 11.4055 7.56222 11.7793 7.8698 12.0503C8.17738 12.3213 8.57323 12.4708 8.98316 12.4708C9.39308 12.4708 9.78894 12.3213 10.0965 12.0503C10.4041 11.7793 10.6023 11.4055 10.6539 10.9988C10.7056 10.5922 10.6072 10.1806 10.3771 9.84134C10.147 9.50207 9.80112 9.25835 9.40421 9.15587V9.1306Z"
                fill="#666666"
                stroke="#666666"
                strokeWidth="0.3"
            />
        </svg>
    );
}
