import moment from 'moment';
import {
    decreaseValueXPercent,
    getRandomNumberInRange,
    getUtcStringDifferenceInMilliseconds,
    graphLineType,
    increaseValueXPercent,
    isWeekdayWorkingHours,
    isWeekend,
    personasEnum,
    randomPlusMinusXPercent,
    spikeInGraphTitles,
    valueRangeForUnitMap,
    zeroValueTitles,
} from './myserviceutil';
import Cache from 'tools/Cache';

type GetMonitoringData = {
    customURL: string;
    MetricsJsonWithTime: any;
    serviceId: string;
    MyServicesJson: any;
};

export const getMonitoringData = (props: GetMonitoringData) => {
    const { customURL, MetricsJsonWithTime, serviceId, MyServicesJson } = props;
    const isServiceDataOwner = MyServicesJson[personasEnum.DATA_OWNER].response.some(
        (el) => el.id === serviceId,
    );

    const startTime = customURL.split('start-time=')[1].split('&end-time=')[0];
    const endTime = customURL.split('start-time=')[1].split('&end-time=')[1].split('&step=')[0];
    const differenceInMilliseconds = getUtcStringDifferenceInMilliseconds(startTime, endTime);

    const metricsWithJsonArr = MetricsJsonWithTime;
    const timeNow = moment(endTime).valueOf();
    const startTimeCal = timeNow - differenceInMilliseconds;

    let diffInTime = 30000;

    if (differenceInMilliseconds <= 3600000) {
        diffInTime = 90000;
    } else if (differenceInMilliseconds <= 21600000) {
        diffInTime = 270000;
    } else if (differenceInMilliseconds <= 43200000) {
        diffInTime = 7000000;
    } else if (differenceInMilliseconds <= 86400000) {
        diffInTime = 7000000;
    } else {
        diffInTime = 14000000;
    }

    metricsWithJsonArr.forEach((metricEl, ind) => {
        // get standard range value
        const chartUnit = metricsWithJsonArr[ind].unit;
        let valueRangeForUnit = valueRangeForUnitMap[chartUnit];

        metricEl.metrics.forEach((legend, idx) => {
            const generatedArr = [];
            let timeNow = Date.now();

            // if want to start spikes from one point put 0 instead of random
            let spikeTimer = getRandomNumberInRange(0, 2);
            let datapointValue = valueRangeForUnit;

            // it will go every diffInTime back till it gets startTime (deduced from API)
            while (timeNow >= startTimeCal) {
                if (legend.displayName === 'Availability') {
                    datapointValue = 1;
                } else if (metricEl.title === 'Memory') {
                    datapointValue = randomPlusMinusXPercent(
                        datapointValue,
                        graphLineType.slowIncreasing,
                    );
                } else if (spikeInGraphTitles.includes(metricEl.title)) {
                    // for randomly increasing value to show SPIKE in graph
                    if (spikeTimer % 4 === 0) {
                        datapointValue = increaseValueXPercent(
                            valueRangeForUnit,
                            getRandomNumberInRange(20, 40),
                        );
                    } else datapointValue = randomPlusMinusXPercent(valueRangeForUnit, 10);
                } else if (
                    metricEl.title.includes('Filesystem') ||
                    metricEl.title.includes('Aborted')
                ) {
                    datapointValue = decreaseValueXPercent(
                        valueRangeForUnit,
                        graphLineType.stagnant,
                    );
                } else if (zeroValueTitles.includes(metricEl.title)) {
                    datapointValue = decreaseValueXPercent(0, graphLineType.stagnant);
                } else {
                    datapointValue = randomPlusMinusXPercent(valueRangeForUnit, 1);
                }

                // this changes monitoring data for data owner account and on weekend.
                const isDateWeekend = isWeekend(new Date(timeNow));
                const isWorkingHours = isWeekdayWorkingHours(new Date(timeNow));
                if (isServiceDataOwner && (isDateWeekend || !isWorkingHours)) {
                    datapointValue = 0;
                }

                const newArray = [timeNow / 1000, datapointValue];
                generatedArr.push(newArray);
                timeNow -= diffInTime / 2;
                spikeTimer += 1;
            }

            // setting metricValues with generated array
            metricsWithJsonArr[ind].metrics[idx].metricValues = generatedArr;

            // value from which the next legend should start from.
            valueRangeForUnit = decreaseValueXPercent(
                valueRangeForUnit,
                getRandomNumberInRange(10, 15),
            );
        });
    });

    return metricsWithJsonArr;
};
