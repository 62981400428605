// eslint-disable-next-line import/no-unresolved, import/no-cycle
import DefaultSecurityLoader from './default/securityloader';
import UBSSecurityLoader from './UBS/securityloader';
import MMCSecurityLoader from './MMC/securityloader';
import FMRSecurityLoader from './FMR/securityloader';

const tenantUrl = window.location.hostname;

const getDataLoaders = () => {
    let securityLoader;

    switch (tenantUrl) {
        case 'localhost':
        case 'ubs.tsl-terls.cloud':
        case 'ubs-demo.tessell.com':
        case 'ubs-demo.azure.tessell.com':
        case 'demo.azure.tessell.com':
        case 'msftgsi.azure.tessell.com':
            securityLoader = new UBSSecurityLoader();
            break;
        case 'mmc-demo.tessell.com':
        case 'mmc.tsl-terls.cloud':
        case 'mmc-demo.aws.tessell.com':
        case 'demo.aws.tessell.com':
            securityLoader = new MMCSecurityLoader();
            break;
        case 'fmr-demo.tessell.com':
        case 'fmr.demo.tessell.com':
        case 'fmr.tsl-terls.cloud':
            securityLoader = new FMRSecurityLoader();
            break;
        default:
            securityLoader = new DefaultSecurityLoader();
            break;
    }

    return {
        securityLoader,
    };
};

const { securityLoader } = getDataLoaders();

export default class SecurityData {
    // eslint-disable-next-line class-methods-use-this
    getEligibleEncryptionKeys() {
        return securityLoader.initEligibleEncryptionKeys();
    }

    // eslint-disable-next-line class-methods-use-this
    getEncryptionKeys() {
        return securityLoader.initEncryptionKeys();
    }

    // eslint-disable-next-line class-methods-use-this
    getPasswordPolicies() {
        return securityLoader.initPasswordPolicies();
    }

    // eslint-disable-next-line class-methods-use-this
    getKeyVaultSubscription() {
        return securityLoader.initKeyVaultSubscription();
    }

    // eslint-disable-next-line class-methods-use-this
    createPasswordPolicies(body) {
        return securityLoader.createPasswordPolicies(body);
    }

    // eslint-disable-next-line class-methods-use-this
    createEncryptionKey(body) {
        return securityLoader.createEncryptionKey(body);
    }
}
