import clsx from 'clsx';
import { useStyles } from './utils';

export default function OracleEnterpriseManagerIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
        >
            <style>{'\n\t\t.s0 { fill: #E30613 }\n\t'}</style>

            <path
                d="M13.2388 31.4208C6.78173 31.4208 1.6665 26.2513 1.6665 20C1.6665 13.6275 6.9046 8.57922 13.2388 8.57922H26.7609C33.2179 8.57922 38.3332 13.7487 38.3332 20C38.3332 26.3725 33.0951 31.4208 26.7609 31.4208H13.2388ZM26.5167 27.333C28.5163 27.3213 30.4306 26.5322 31.8445 25.1368C33.2585 23.7413 34.0581 21.8521 34.0699 19.8787C34.0581 17.9053 33.2585 16.0161 31.8445 14.6207C30.4306 13.2253 28.5163 12.4362 26.5167 12.4244H13.6042C11.6046 12.4362 9.69032 13.2253 8.27639 14.6207C6.86246 16.0161 6.06286 17.9053 6.05098 19.8787C6.06286 21.8521 6.86246 23.7413 8.27639 25.1368C9.69032 26.5322 11.6046 27.3213 13.6042 27.333H26.5167Z"
                fill="#E30613"
            />
            <path
                d="M26.0881 17V22.8537H25.019V19.2592C25.019 19.1839 25.0204 19.1032 25.0231 19.0171C25.0284 18.9311 25.0365 18.8437 25.0473 18.7549L23.3731 21.9299C23.2816 22.1047 23.1404 22.1921 22.9495 22.1921H22.78C22.6859 22.1921 22.6025 22.1706 22.5299 22.1275C22.4573 22.0818 22.3995 22.0159 22.3564 21.9299L20.6741 18.7428C20.6822 18.8369 20.6889 18.9284 20.6943 19.0171C20.6997 19.1032 20.7024 19.1839 20.7024 19.2592V22.8537H19.6333V17H20.5531C20.6042 17 20.6499 17.0013 20.6903 17.004C20.7306 17.0067 20.7656 17.0148 20.7952 17.0282C20.8274 17.039 20.8557 17.0578 20.8799 17.0847C20.9068 17.1089 20.9323 17.1439 20.9565 17.1896L22.5944 20.3121C22.6455 20.4063 22.6926 20.5031 22.7356 20.6026C22.7814 20.7021 22.8244 20.8043 22.8647 20.9092C22.9051 20.8016 22.9468 20.6967 22.9898 20.5945C23.0355 20.4923 23.0839 20.3942 23.135 20.3L24.7608 17.1896C24.785 17.1439 24.8106 17.1089 24.8375 17.0847C24.8644 17.0578 24.8926 17.039 24.9222 17.0282C24.9545 17.0148 24.9894 17.0067 25.0271 17.004C25.0674 17.0013 25.1145 17 25.1683 17H26.0881Z"
                fill="#E30613"
            />
            <path
                d="M16.2183 17.948V19.4528H18.1709V20.3686H16.2183V21.9057H18.7276V22.8537H15V17H18.7276V17.948H16.2183Z"
                fill="#E30613"
            />
        </svg>
    );
}
