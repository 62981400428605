import clsx from 'clsx';
import { useStyles } from './utils';

export default function TimeIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 18.5C13.9706 18.5 18 14.4706 18 9.5C18 4.52944 13.9706 0.5 9 0.5C4.02944 0.5 0 4.52944 0 9.5C0 14.4706 4.02944 18.5 9 18.5Z"
                fill="#11567F"
            />
            <path
                d="M9 16.9404C13.1092 16.9404 16.4403 13.6092 16.4403 9.5001C16.4403 5.39094 13.1092 2.05981 9 2.05981C4.89085 2.05981 1.55972 5.39094 1.55972 9.5001C1.55972 13.6092 4.89085 16.9404 9 16.9404Z"
                fill="#F3F3F3"
            />
            <path
                d="M2.29743 10.0479H0.632324V8.9519H2.29743C2.59778 8.9519 2.84544 9.19429 2.84544 9.49991C2.84544 9.80553 2.60305 10.0479 2.29743 10.0479ZM15.7026 10.0479H17.3677V8.9519H15.7026C15.4022 8.9519 15.1546 9.19429 15.1546 9.49991C15.1546 9.80553 15.397 10.0479 15.7026 10.0479Z"
                fill="#11567F"
            />
            <path
                d="M8.452 2.8027V1.13232H9.54801V2.79743C9.54801 3.09778 9.30563 3.34544 9 3.34544C8.69965 3.34544 8.452 3.10305 8.452 2.8027ZM8.452 16.2026V17.8677H9.54801V16.2026C9.54801 15.9022 9.30563 15.6546 9 15.6546C8.69965 15.6546 8.452 15.9022 8.452 16.2026Z"
                fill="#11567F"
            />
            <path
                d="M3.87338 5.15292L2.69305 3.97259L3.46764 3.198L4.64797 4.37833C4.86401 4.59437 4.86401 4.93687 4.64797 5.15292C4.4372 5.36369 4.08942 5.36369 3.87338 5.15292ZM13.3529 14.6272L14.5332 15.8075L15.3078 15.0329L14.1275 13.8526C13.9114 13.6365 13.5689 13.6365 13.3529 13.8526C13.1368 14.0686 13.1368 14.4164 13.3529 14.6272Z"
                fill="#11567F"
            />
            <path
                d="M14.1275 5.15292L15.3078 3.97259L14.5332 3.198L13.3529 4.37833C13.1368 4.59437 13.1368 4.93687 13.3529 5.15292C13.5637 5.36369 13.9114 5.36369 14.1275 5.15292ZM4.64797 14.6272L3.46764 15.8075L2.69305 15.0329L3.87338 13.8526C4.08942 13.6365 4.43193 13.6365 4.64797 13.8526C4.86401 14.0686 4.86401 14.4164 4.64797 14.6272Z"
                fill="#11567F"
            />
            <path
                d="M11.6663 12.809L8.6048 9.74755C8.49941 9.64216 8.44145 9.49989 8.44145 9.35235V6.60176C8.44145 6.29087 8.68911 6.04321 9 6.04321C9.31089 6.04321 9.55855 6.29087 9.55855 6.60176V9.1205L12.4567 12.0186C12.6727 12.2347 12.6727 12.5877 12.4567 12.809C12.2354 13.0251 11.8823 13.0251 11.6663 12.809Z"
                fill="#11567F"
            />
        </svg>
    );
}
