/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function LicensesIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="16"
            height="26"
            viewBox="0 0 16 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.29455 12.4001C9.61359 12.4001 9.86585 12.8383 10.1528 12.9458C10.4397 13.0532 10.8639 12.8677 11.1029 13.0648C11.3419 13.2619 11.3108 13.7719 11.486 14.0408C11.6612 14.3096 12.1047 14.4331 12.2002 14.756C12.2957 15.0788 12.0098 15.4857 12.0098 15.8446C12.0098 16.2035 12.2957 16.6105 12.2002 16.9333C12.1047 17.2562 11.6612 17.3796 11.486 17.6485C11.3108 17.9174 11.3419 18.4273 11.1029 18.6244C10.8639 18.8215 10.4398 18.636 10.1528 18.7435C9.86585 18.851 9.61359 19.2891 9.29455 19.2891C8.9755 19.2891 8.72325 18.851 8.43629 18.7435C8.14937 18.636 7.72516 18.8215 7.4862 18.6244C7.24715 18.4273 7.27828 17.9174 7.10311 17.6485C6.9279 17.3796 6.48439 17.2562 6.38885 16.9333C6.29331 16.6105 6.57931 16.2036 6.57931 15.8446C6.57931 15.4857 6.29331 15.0788 6.38885 14.756C6.48439 14.4331 6.9279 14.3096 7.10311 14.0408C7.27828 13.7719 7.24715 13.2619 7.4862 13.0648C7.72516 12.8677 8.14933 13.0532 8.43629 12.9458C8.72325 12.8383 8.9755 12.4001 9.29455 12.4001Z"
                stroke="var(--icon-color)"
                strokeWidth="1.1"
                strokeMiterlimit="22.926"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.40007 21.0638H1V1.00044L11.8799 1.00006L15.1762 5.24225V21.0638H11.8799"
                stroke="var(--icon-color)"
                strokeWidth="1.2"
                strokeMiterlimit="22.926"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.1336 19.0001L11.1337 25L9.32533 23.7237L7.51693 25L7.51689 19.0001"
                stroke="var(--icon-color)"
                strokeWidth="1.1"
                strokeMiterlimit="22.926"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.13318 4.60022H9.48984"
                stroke="var(--icon-color)"
                strokeWidth="1.2"
                strokeMiterlimit="22.926"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.13318 7.00024H11.975"
                stroke="var(--icon-color)"
                strokeWidth="1.2"
                strokeMiterlimit="22.926"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
