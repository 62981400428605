/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2022. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     2/23/2022     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function BillingProfileIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 20 20"
            fill="none"
            strokeWidth={1.2}
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1"
                y="3"
                width="18"
                height="14"
                rx="2"
                stroke="var(--icon-color)"
                strokeWidth="1.25"
            />
            <path
                d="M1 7H19M6.5 11.5H5M9 11.5H12"
                stroke="var(--icon-color)"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
