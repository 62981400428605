/* eslint-disable no-param-reassign */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/3/2021     bakul.banthia         Created
 *
 */

const MockUtil = {
    getResponse(data, sleep) {
        return this.getPromise(data, sleep);
    },

    getPromise(data, sleep, status) {
        const CONTENT_TYPE_JSON = {
            type: 'application/json',
        };
        const STATUS_OK = {
            status: status || 200,
        };
        const timeout = sleep || 500;
        const blob = new Blob([JSON.stringify(data || {})], CONTENT_TYPE_JSON);
        const response = new Response(blob, STATUS_OK);
        const promise = new Promise((resolve) => {
            setTimeout(() => resolve(response), timeout);
        });
        return promise;
    },
};

export default MockUtil;
