import whoamiJson from './whoami.json';
import buildJson from './build.json';
import appsJson from './apps.json';
import tenantConfigJson from './tenant-config.json';
import rolesIamUserJson from './roles-iam-user.json';
import cloudLocationsJson from './cloud-locations-all=true.json';
import loginDetailsJson from './login.json';
import loginFailedJson from './loginFailed.json';
import ComponentsTsJson from './components-TS.json';
import ApiKeysJson from './api-keys.json';
import EventServiceNotificationsJson from './event-service-notifications.json';
import TemplateMetadataJson from './template-metadata.json';

// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
// eslint-disable-next-line import/no-extraneous-dependencies
import sign from 'jwt-encode';
import replaceTimestampWithMockDate from 'tools/MockTime';
import Cache from 'tools/Cache';
import { lowercaseUsername } from '../util';

// replacing date with Mock Date
whoamiJson = replaceTimestampWithMockDate(whoamiJson);
buildJson = replaceTimestampWithMockDate(buildJson);
appsJson = replaceTimestampWithMockDate(appsJson);
tenantConfigJson = replaceTimestampWithMockDate(tenantConfigJson);
rolesIamUserJson = replaceTimestampWithMockDate(rolesIamUserJson);
cloudLocationsJson = replaceTimestampWithMockDate(cloudLocationsJson);
loginDetailsJson = replaceTimestampWithMockDate(loginDetailsJson);
loginFailedJson = replaceTimestampWithMockDate(loginFailedJson);
ComponentsTsJson = replaceTimestampWithMockDate(ComponentsTsJson);
ApiKeysJson = replaceTimestampWithMockDate(ApiKeysJson);
EventServiceNotificationsJson = replaceTimestampWithMockDate(EventServiceNotificationsJson);
TemplateMetadataJson = replaceTimestampWithMockDate(TemplateMetadataJson);

const profilePictureLinks = {
    bala: 'https://ca.slack-edge.com/T01T032RDAA-U01TKENLA73-1172af7a3aee-512',
    bakul: 'https://ca.slack-edge.com/T01T032RDAA-U022F1MQDCZ-e16c1c5a0f2e-512',
    kamal: 'https://ca.slack-edge.com/T01T032RDAA-U01TD1HK7KN-92a875184dff-512',
};

export default class DefaultGeneralApisLoader {
    // eslint-disable-next-line class-methods-use-this
    initWhoami(emailId) {
        return whoamiJson[lowercaseUsername(emailId)];
    }

    // eslint-disable-next-line class-methods-use-this
    initBuildJson() {
        return buildJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initAppsJson() {
        return appsJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initTenantConfigJson() {
        return tenantConfigJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initRolesIamUserJson() {
        return rolesIamUserJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initCloudLocationsJson() {
        return cloudLocationsJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initLogin(emailId) {
        if (!Object.keys(loginDetailsJson).includes(lowercaseUsername(emailId))) {
            return false;
        }

        // Adding email to the JWT and encoding it again so that can be used in whoami api.
        const { accessToken } = loginDetailsJson[emailId];
        const decodedToken = accessToken && jwt_decode(accessToken);
        if (decodedToken) {
            decodedToken.email = emailId;
            decodedToken.sub = emailId;
        }
        const secret = 'secret';
        const jwt = sign(decodedToken, secret);

        // set profile picture
        Cache.set('img', profilePictureLinks[emailId.split('@')[0]]);

        return { ...loginDetailsJson[emailId], accessToken: jwt, refreshToken: jwt };
    }

    // eslint-disable-next-line class-methods-use-this
    initLoginFailedJson() {
        return loginFailedJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initComponentsTs() {
        return ComponentsTsJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initApiKeysJson() {
        return ApiKeysJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initEventServiceNotificationsJson() {
        return EventServiceNotificationsJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initTemplateMetadataJson() {
        return TemplateMetadataJson;
    }
}
