// eslint-disable-next-line import/no-unresolved
import DefaultGeneralApisLoader from './default/generalapisloader';
import UBSGeneralApisLoader from './UBS/generalapisloader';
import MMCGeneralApisLoader from './MMC/generalapisloader';
import FMRGeneralApisLoader from './FMR/generalapisloader';

const tenantUrl = window.location.hostname;

const getDataLoaders = () => {
    let generalapisloader;

    switch (tenantUrl) {
        case 'localhost':
        case 'ubs.tsl-terls.cloud':
        case 'ubs-demo.tessell.com':
        case 'ubs-demo.azure.tessell.com':
        case 'demo.azure.tessell.com':
        case 'msftgsi.azure.tessell.com':
            generalapisloader = new UBSGeneralApisLoader();
            break;
        case 'mmc-demo.tessell.com':
        case 'mmc.tsl-terls.cloud':
        case 'mmc-demo.aws.tessell.com':
        case 'demo.aws.tessell.com':
            generalapisloader = new MMCGeneralApisLoader();
            break;
        case 'fmr-demo.tessell.com':
        case 'fmr.demo.tessell.com':
        case 'fmr.tsl-terls.cloud':
            generalapisloader = new FMRGeneralApisLoader();
            break;
        default:
            generalapisloader = new DefaultGeneralApisLoader();
            break;
    }

    return {
        generalapisloader,
    };
};

const { generalapisloader } = getDataLoaders();

export default class GeneralApisData {
    // eslint-disable-next-line class-methods-use-this
    getWhoami(emailId) {
        return generalapisloader.initWhoami(emailId);
    }

    // eslint-disable-next-line class-methods-use-this
    getBuildJson() {
        return generalapisloader.initBuildJson();
    }

    // eslint-disable-next-line class-methods-use-this
    getAppsJson() {
        return generalapisloader.initAppsJson();
    }

    // eslint-disable-next-line class-methods-use-this
    getTenantConfigJson() {
        return generalapisloader.initTenantConfigJson();
    }

    // eslint-disable-next-line class-methods-use-this
    getRolesIamUserJson() {
        return generalapisloader.initRolesIamUserJson();
    }

    // eslint-disable-next-line class-methods-use-this
    getCloudLocationsJson() {
        return generalapisloader.initCloudLocationsJson();
    }

    // eslint-disable-next-line class-methods-use-this
    getLoginDetails(emailId) {
        return generalapisloader.initLogin(emailId);
    }

    // eslint-disable-next-line class-methods-use-this
    getLoginFailedDetails() {
        return generalapisloader.initLoginFailedJson();
    }

    // eslint-disable-next-line class-methods-use-this
    getComponentsTsDetails() {
        return generalapisloader.initComponentsTs();
    }

    // eslint-disable-next-line class-methods-use-this
    getApiKeysDetails() {
        return generalapisloader.initApiKeysJson();
    }

    // eslint-disable-next-line class-methods-use-this
    getEventServiceNotificationsDetails() {
        return generalapisloader.initEventServiceNotificationsJson();
    }

    // eslint-disable-next-line class-methods-use-this
    getTemplateMetadataJson() {
        return generalapisloader.initTemplateMetadataJson();
    }
}
