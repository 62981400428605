/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 */

import { MutableRefObject, useEffect, useRef, useState, useCallback } from 'react';

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
    ApiResponseType,
    PostDataCustomUrlType,
    PostDataNoParamsType,
    PostDataWithBodyType,
} from 'types/ApiResponseType.types';
import HttpStatusCodeEnum from 'types/HttpStatusCodeEnum';
import { UseStateType } from 'types/UseState.types';

import { httpMethodMapping } from '../api-clients/network-layer/axiosHttpMethods';
import { GET_METHOD, HTTP_METHODS, POST_METHODS } from 'api-clients/network-layer/constants';
import { SetStateType } from 'types/SetState.types';

type FetchOptions<D, M = HTTP_METHODS> = AxiosRequestConfig<D> & {
    method?: M;
};

type AdditionalOptionsType<T = any> = {
    shouldAbortOngoingCallOnRefetch?: boolean;
    initialResponse?: T;
};

export function useFetch<T = any, D = any>(
    url: string,
    options: AxiosRequestConfig<D> = {},
    initialResponse?: T,
): ApiResponseType<T, PostDataNoParamsType> {
    const [response, setResponse]: UseStateType<T> = useState<T>(initialResponse);
    const [error, setError]: UseStateType<Error> = useState<Error>(null);
    const [isLoading, setIsLoading]: UseStateType<boolean> = useState<boolean>(false);
    const [responseStatus, setResponseStatus]: UseStateType<HttpStatusCodeEnum> =
        useState<HttpStatusCodeEnum>(null);
    const [isInitialLoading, setIsInitialLoading]: UseStateType<boolean> = useState<boolean>(false);
    const loadingCount: MutableRefObject<number> = useRef<number>(0);

    async function postData() {
        setIsLoading(true);

        if (loadingCount.current > 0) {
            setIsInitialLoading(false);
        } else {
            setIsInitialLoading(true);
        }

        loadingCount.current += 1;

        const onRejected = getOnRejectedHandler<T>(setError, setResponse, setResponseStatus);
        const onfulfilled = getOnFulfilledHandler<T>(setResponse, setResponseStatus, setIsLoading);
        const onfinally = getOnFinally(setIsLoading);

        try {
            const getRequestHandler = httpMethodMapping[GET_METHOD.GET];
            const res = await getRequestHandler<T, AxiosResponse<T>, D>(url, options);
            onfulfilled(res);
        } catch (error: unknown) {
            onRejected(error);
        } finally {
            onfinally();
        }
    }

    useEffect(() => {
        postData();
    }, []);

    return {
        postData,
        response,
        responseStatus,
        error,
        isLoading,
        isInitialLoading,
    };
}

export function usePost<T = any, D = BodyInit | any>(
    url: string,
    options?: FetchOptions<D, POST_METHODS | string>,
    additionalOptions: AdditionalOptionsType<T> = {
        initialResponse: null,
        shouldAbortOngoingCallOnRefetch: false,
    },
): ApiResponseType<T, PostDataWithBodyType> {
    const { initialResponse: initialData = null, shouldAbortOngoingCallOnRefetch = false } =
        additionalOptions;
    const [response, setResponse]: UseStateType<T> = useState<T>(initialData ?? null);
    const [error, setError]: UseStateType<Error> = useState<Error>(null);
    const [isLoading, setIsLoading]: UseStateType<boolean> = useState<boolean>(false);
    const [responseStatus, setResponseStatus]: UseStateType<HttpStatusCodeEnum> =
        useState<HttpStatusCodeEnum>(null);
    const [isInitialLoading, setIsInitialLoading]: UseStateType<boolean> = useState<boolean>(false);
    const loadingCount: MutableRefObject<number> = useRef<number>(0);
    const abortController = useRef(undefined);

    async function postData(
        body?: D,
        customUrl?: string,
        moreOptions?: { onSuccess?: (response: T) => void },
    ) {
        if (shouldAbortOngoingCallOnRefetch) {
            abortController.current?.abort();
        }

        abortController.current = new AbortController();
        const { signal } = abortController.current;
        setIsLoading(true);
        if (loadingCount.current > 0) {
            setIsInitialLoading(false);
        } else {
            setIsInitialLoading(true);
        }
        loadingCount.current += 1;

        const onRejected = getOnRejectedHandler<T>(setError, setResponse, setResponseStatus);
        const onfulfilled = getOnFulfilledHandler<T>(setResponse, setResponseStatus, setIsLoading);
        const onfinally = getOnFinally(setIsLoading);

        try {
            const postRequestHandler = httpMethodMapping[options?.method || POST_METHODS.POST];
            const res = await postRequestHandler<T, AxiosResponse<T>, D>(customUrl || url, body, {
                ...options,
                ...(shouldAbortOngoingCallOnRefetch && { signal }),
            });
            onfulfilled(res);
            moreOptions?.onSuccess?.(res);
        } catch (error: unknown) {
            onRejected(error);
        } finally {
            onfinally();
        }
        abortController.current = undefined;
    }

    return {
        postData,
        response,
        responseStatus,
        error,
        isLoading,
        isInitialLoading,
        loadingCount: loadingCount.current,
    };
}

export function useLazyFetch<T = any, D = any>(
    url: string,
    options: FetchOptions<D, HTTP_METHODS | string> = {},
    additionalOptions: AdditionalOptionsType<T> = {
        initialResponse: null,
        shouldAbortOngoingCallOnRefetch: false,
    },
): ApiResponseType<T, PostDataWithBodyType> & { isInitialLoading: boolean } {
    const { initialResponse = null, shouldAbortOngoingCallOnRefetch = false } = additionalOptions;
    const [response, setResponse]: UseStateType<T> = useState<T>(initialResponse);
    const [error, setError]: UseStateType<Error> = useState<Error>(null);
    const [isLoading, setIsLoading]: UseStateType<boolean> = useState<boolean>(false);
    const [responseStatus, setResponseStatus]: UseStateType<HttpStatusCodeEnum> =
        useState<HttpStatusCodeEnum>(null);
    const [isInitialLoading, setIsInitialLoading]: UseStateType<boolean> = useState<boolean>(false);
    const loadingCount: MutableRefObject<number> = useRef<number>(0);
    const abortController = useRef(undefined);

    const postData = useCallback(
        async (body?: D) => {
            if (shouldAbortOngoingCallOnRefetch) {
                abortController.current?.abort();
            }
            abortController.current = new AbortController();
            const { signal } = abortController.current;

            setIsLoading(true);
            if (loadingCount.current > 0) {
                setIsInitialLoading(false);
            } else {
                setIsInitialLoading(true);
            }
            loadingCount.current += 1;

            const onRejected = getOnRejectedHandler<T>(setError, setResponse, setResponseStatus);

            const onfulfilled = getOnFulfilledHandler<T>(
                setResponse,
                setResponseStatus,
                setIsLoading,
            );

            const onfinally = getOnFinally(setIsLoading);

            try {
                let res;
                const isPostMethodType = Object.keys(POST_METHODS)?.includes(options?.method);
                if (isPostMethodType) {
                    const requestHandler = httpMethodMapping[options.method];
                    res = await requestHandler<T, AxiosResponse<T>, D>(url, body, {
                        ...options,
                        ...(shouldAbortOngoingCallOnRefetch && { signal }),
                    });
                } else {
                    const requestHandler = httpMethodMapping.GET;
                    res = await requestHandler<T, AxiosResponse<T>, D>(url, {
                        ...options,
                        ...(shouldAbortOngoingCallOnRefetch && { signal }),
                    });
                }
                onfulfilled(res);
            } catch (error: unknown) {
                onRejected(error);
            } finally {
                onfinally();
            }
            abortController.current = undefined;
        },
        [
            url,
            options,
            shouldAbortOngoingCallOnRefetch,
            getOnRejectedHandler,
            getOnFulfilledHandler,
            getOnFinally,
            setError,
            setResponse,
            setResponseStatus,
            setIsLoading,
            setIsInitialLoading,
        ],
    );

    return {
        postData,
        response,
        responseStatus,
        error,
        isLoading,
        isInitialLoading,
        loadingCount: loadingCount.current,
    };
}

export function useLazyFetch2<T = any, D = any>(
    url: string,
    options: FetchOptions<D, HTTP_METHODS | string>,
    additionalOptions: AdditionalOptionsType<T> = {
        initialResponse: null,
    },
): ApiResponseType<T, PostDataCustomUrlType> {
    const { initialResponse = null } = additionalOptions;
    const [response, setResponse]: UseStateType<T> = useState<T>(initialResponse);
    const [error, setError]: UseStateType<Error> = useState<Error>(null);
    const [isLoading, setIsLoading]: UseStateType<boolean> = useState<boolean>(false);
    const [responseStatus, setResponseStatus]: UseStateType<HttpStatusCodeEnum> =
        useState<HttpStatusCodeEnum>(null);
    const [isInitialLoading, setIsInitialLoading]: UseStateType<boolean> = useState<boolean>(false);
    const loadingCount: MutableRefObject<number> = useRef<number>(0);

    async function postData(customUrl: string = url, body?: D) {
        setIsLoading(true);
        if (loadingCount.current > 0) {
            setIsInitialLoading(false);
        } else {
            setIsInitialLoading(true);
        }
        loadingCount.current += 1;

        const onRejected = getOnRejectedHandler<T>(setError, setResponse, setResponseStatus);
        const onfulfilled = getOnFulfilledHandler<T>(setResponse, setResponseStatus, setIsLoading);
        const onfinally = getOnFinally(setIsLoading);

        try {
            let res;
            const isPostMethodType = Object.keys(POST_METHODS)?.includes(options.method);
            if (isPostMethodType) {
                const requestHandler = httpMethodMapping[options.method];
                res = await requestHandler<T, AxiosResponse<T>, D>(customUrl, body, options);
            } else {
                const requestHandler = httpMethodMapping.GET;
                res = await requestHandler<T, AxiosResponse<T>, D>(customUrl, options);
            }
            onfulfilled(res);
        } catch (error: unknown) {
            onRejected(error);
        } finally {
            onfinally();
        }
    }

    return {
        postData,
        response,
        responseStatus,
        error,
        isLoading,
        isInitialLoading,
        loadingCount: loadingCount.current,
    };
}

const getOnRejectedHandler =
    <T>(
        setError: SetStateType<Error>,
        setResponse: SetStateType<T>,
        setResponseStatus: SetStateType<HttpStatusCodeEnum>,
    ) =>
    (error: any) => {
        const { message, code, status } = error;
        setError(new Error(message || code));
        setResponseStatus(status);

        // for backward compatibility as we have used response object for errors at someplaces
        setResponse(error);
    };

const getOnFulfilledHandler =
    <T>(
        setResponse: SetStateType<T>,
        setResponseStatus: SetStateType<HttpStatusCodeEnum>,
        setIsLoading: SetStateType<boolean>,
    ) =>
    (res: AxiosResponse<T>) => {
        const { data, status } = res;
        setResponseStatus(status);
        setResponse(data);
        setIsLoading?.(false);
    };

const getOnFinally = (setIsLoading: SetStateType<boolean>) => () => {
    setIsLoading(false);
};

export function useLazyFetchDemo(url, options, fetcherFn) {
    const [response, setResponse] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<any>(false);
    const [responseStatus, setResponseStatus] = useState<any>(null);
    const [isInitialLoading, setIsInitialLoading] = useState<any>(false);

    const loadingCount = useRef(0);

    async function postData(body?: any) {
        setIsLoading(true);

        if (loadingCount.current > 0) {
            setIsInitialLoading(false);
        } else {
            setIsInitialLoading(true);
        }

        loadingCount.current += 1;

        try {
            let res: any = {};
            res = await fetcherFn;
            setResponseStatus(200);
            const json = await res.json();
            setResponse(json);
            // loading here false needed to show the loading screen.
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError(error);
        }
    }

    return {
        postData,
        response,
        responseStatus,
        error,
        isLoading,
        isInitialLoading,
    };
}
