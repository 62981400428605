import { v4 as uuidv4 } from 'uuid';
import Cache from 'tools/Cache';
import { personasEnum } from 'api-clients/network-layer/demo-data/repository/DbServices/myservices/common/myserviceutil';

export function isWeekend(date) {
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; // 0 represents Sunday, and 6 represents Saturday
}

export function isDateInCurrentMonth(inputDateParam) {
    const inputDate = new Date(inputDateParam);
    const currentDate = new Date();

    // Check if the year and month of the inputDate match the current year and month
    return (
        inputDate.getFullYear() === currentDate.getFullYear() &&
        inputDate.getMonth() === currentDate.getMonth()
    );
}

export function isWeekdayWorkingHours(date) {
    // Check if it's a weekday (Monday to Friday)
    const dayOfWeek = date.getDay();
    const isWeekday = dayOfWeek >= 1 && dayOfWeek <= 5; // 1-5 represent Monday to Friday

    // Check if the time is between 8:00 AM (8:00) and 5:00 PM (17:00)
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const isWorkingHours = hours >= 8 && hours < 17; // 8:00 AM to 4:59 PM

    return isWeekday && isWorkingHours;
}

export function getMonthInfo(year, monthNumber) {
    if (monthNumber < 1 || monthNumber > 12) {
        throw new Error(
            `Invalid month number: ${monthNumber}. Month number should be between 1 and 12.`,
        );
    }

    const startDate = new Date(year, monthNumber - 1, 1); // Subtract 1 to convert to 0-based month index
    const endDate = new Date(year, monthNumber, 0);

    const monthMappings = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
    };

    const monthName = monthMappings[monthNumber];

    const formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-01 00:00:00`;
    const formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${endDate.getDate()} 23:59:59`;

    return {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        monthName,
    };
}

function generateSubscriptionCosts(groupedDataOnSubs) {
    const subCosts = {};
    let totalCost = 0;
    Object.keys(groupedDataOnSubs).forEach((el) => {
        const azType = filterArrayByProperty(groupedDataOnSubs[el], 'cloudType', 'AZURE');
        const awsType = filterArrayByProperty(groupedDataOnSubs[el], 'cloudType', 'AWS');
        const serviceCost = filterArrayByProperty(groupedDataOnSubs[el], 'entityType', 'SERVICE');
        const amCost = filterArrayByProperty(
            groupedDataOnSubs[el],
            'entityType',
            'AVAILABILITY_MACHINE',
        );
        const mySqlCost = filterArrayByProperty(groupedDataOnSubs[el], 'engineType', 'MYSQL');
        const postgreSqlCost = filterArrayByProperty(
            groupedDataOnSubs[el],
            'engineType',
            'POSTGRESQL',
        );
        const oracleCost = filterArrayByProperty(groupedDataOnSubs[el], 'engineType', 'ORACLE');
        const sqlServerCost = filterArrayByProperty(
            groupedDataOnSubs[el],
            'engineType',
            'SQLSERVER',
        );

        // if aws then aws as well
        const allCosts = azType + awsType;

        totalCost += azType + awsType;

        subCosts[el] = {
            cloudCosts: {
                AZURE: azType,
                AWS: awsType,
            },
            serviceCosts: {
                SERVICE: serviceCost,
                AVAILABILITY_MACHINE: amCost,
            },
            dbEngineCosts: {
                MYSQL: mySqlCost,
                POSTGRESQL: postgreSqlCost,
                ORACLE: oracleCost,
                SQLSERVER: sqlServerCost,
            },
            subscriptionCost: allCosts,
        };
    });

    return { subscriptionCosts: subCosts, totalCost };
}

export function filterArrayByProperty(array, propertyName, value) {
    return array
        .filter((obj) => obj[propertyName] === value) //
        .reduce((accumulator, currentValue) => accumulator + (currentValue.cost ?? 0), 0);
}

export function modifySystemBillSystemGenerated(
    SampleBillSystemGenerated,
    SampleMeterRes,
    myServicesDataJson,
    AvailabilityMachinesSnapshotsAutomatedFalse,
    AvailabilityMachinesBackups,
    AvailabilityMachinesSanitizatiedSnapshots,
    ratesJson,
) {
    const billsSystemGenerated = [];
    const today = new Date();

    for (let i = 0; i < 12; i += 1) {
        const year = today.getFullYear();
        const monthNumber = today.getMonth() + 1; // Add 1 to convert to 1-based month index

        // Move to the previous month
        today.setMonth(today.getMonth() - 1);

        const { startDate, endDate, monthName } = getMonthInfo(year, monthNumber);

        const monthData = JSON.parse(JSON.stringify(SampleBillSystemGenerated));

        monthData.id = uuidv4();
        monthData.name = `Bill ${monthName} ${year}`;
        monthData.startDate = startDate;
        monthData.endDate = endDate;

        const meterModified = modifyMeter(
            SampleMeterRes,
            myServicesDataJson,
            AvailabilityMachinesSnapshotsAutomatedFalse,
            AvailabilityMachinesBackups,
            AvailabilityMachinesSanitizatiedSnapshots,
            ratesJson,
        );

        // Filter the array based on the date range
        const filteredData = meterModified.filter((obj) => {
            const objStartDate = new Date(obj.startDate);
            const objEndDate = new Date(obj.endDate);
            const rangeStartDate = new Date(startDate);
            const rangeEndDate = new Date(endDate);

            return (
                objStartDate >= rangeStartDate && objEndDate <= rangeEndDate
                // &&
                // (obj.entityType === 'AVAILABILITY_MACHINE' || obj.entityType === 'SERVICE')
            );
        });

        // Object to store the grouped data
        const groupedDataOnSubs = {};

        // Group the array based on subscriptionName
        filteredData.forEach((obj) => {
            const { subscriptionName } = obj;

            if (groupedDataOnSubs[subscriptionName]) {
                groupedDataOnSubs[subscriptionName].push(obj);
            } else {
                groupedDataOnSubs[subscriptionName] = [obj];
            }
        });

        const { subscriptionCosts, totalCost } = generateSubscriptionCosts(groupedDataOnSubs);
        monthData.metadata.subscriptionCosts = subscriptionCosts;
        monthData.totalCharges = totalCost;

        billsSystemGenerated.push(monthData);
    }

    return billsSystemGenerated;
}

export function getHoursLeftUntilEndOfDay(startTimeISO, isEnding) {
    const endOfDay = new Date(startTimeISO); // Create a copy of the current date
    endOfDay.setUTCHours(23, 59, 59, 999); // Set the time to 23:59:59.999

    // Calculate the time difference in milliseconds between the current date and the end of the day
    const timeDifference = endOfDay - new Date(startTimeISO);

    // Calculate the number of hours left until the end of the day
    const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60)); // 1000 ms in a second, 60 seconds in a minute, and 60 minutes in an hour

    if (isEnding) {
        const startOfDay = new Date(startTimeISO); // Create a copy of the current date
        startOfDay.setUTCHours(0, 0, 0, 0);
        return {
            hours: hoursLeft,
            startDate: startOfDay.toISOString(),
            endDate: startTimeISO,
        };
    }

    return {
        hours: hoursLeft,
        startDate: startTimeISO,
        endDate: endOfDay.toISOString(),
    };
}

export function generateHourlyIntervals(startDate, endDateParam) {
    const date = new Date(endDateParam);
    const nowUtc = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
    );

    const endDate = new Date(nowUtc);
    const hourlyIntervals = [];

    let currentHour = new Date(startDate);
    while (currentHour <= endDate) {
        const nextHour = new Date(currentHour);
        const currentHourEnding = new Date(currentHour);
        nextHour.setHours(currentHour.getHours() + 1);

        // except for first hour everything will have default 00
        if (hourlyIntervals.length > 0) {
            currentHour.setUTCMinutes(0, 0, 0);
        }
        if (currentHour < endDate) {
            currentHourEnding.setUTCMinutes(59, 59, 59);
        }

        hourlyIntervals.push({
            startDate: currentHour.toISOString(),
            endDate: currentHourEnding.toISOString(),
        });

        currentHour = nextHour;
    }

    // for last hour to have end date
    if (hourlyIntervals.length > 0) {
        hourlyIntervals[hourlyIntervals.length - 1].endDate = endDate.toISOString();
    }

    return hourlyIntervals;
}

export function getHoursPerDayArray(startTimeISO) {
    if (startTimeISO === undefined) {
        return [];
    }

    const startDate = new Date(startTimeISO); // Convert ISO string to a Date object
    const endDate = new Date(); // Current date and time

    // Calculate the time difference in milliseconds between the start and end date
    const timeDifference = endDate - startDate;

    const hoursPerDayArray = [getHoursLeftUntilEndOfDay(startTimeISO)];

    // Calculate the number of days between the two dates
    const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

    // Loop through each day and calculate the hours
    for (let i = 1; i <= daysDifference; i += 1) {
        const currentDay = new Date(startDate);
        currentDay.setUTCHours(0, 0, 0, 0);
        currentDay.setDate(startDate.getDate() + i);

        // Calculate the number of hours for the current day
        const hoursDifference = timeDifference >= 0 ? 24 : 0;
        const endOfDay = new Date(currentDay);
        endOfDay.setUTCHours(23, 59, 59, 999);

        // Not including current date in the array
        if (new Date(currentDay).setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0)) {
            hoursPerDayArray.push({
                hours: hoursDifference,
                startDate: currentDay.toISOString(),
                endDate: endOfDay.toISOString(),
            });
        }
    }

    if (daysDifference > 1) {
        hoursPerDayArray.push(getHoursLeftUntilEndOfDay(new Date().toISOString(), true));
    }

    return hoursPerDayArray;
}

export function isoStringToFormattedUTC(isoString) {
    const date = new Date(isoString);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function isRateNeedToBeCalculated(ownerName, date) {
    // if weekend and owner is bakul(data owner) then no need to
    // calculate as services are assumed to be off
    return !(ownerName === 'bakul@tessell-demo.com' && isWeekend(new Date(date)));
}

export function isRateNeedToBeCalculatedWeekday(ownerName, date) {
    // if weekend and owner is bakul(data owner) then no need to
    // calculate as services are assumed to be off
    return !(ownerName === 'bakul@tessell-demo.com' && isWeekdayWorkingHours(new Date(date)));
}

export const getBackupsMeterRes = (SampleMeterRes, snapshotData, data, serviceData) => {
    const meterResAm = JSON.parse(JSON.stringify(SampleMeterRes));
    meterResAm.id = uuidv4();
    meterResAm.entityType = 'BACKUP';
    meterResAm.name = snapshotData.name;
    meterResAm.startDate = isoStringToFormattedUTC(data.startDate);
    meterResAm.endDate = isoStringToFormattedUTC(data.endDate);

    // from service data
    meterResAm.subscriptionName = serviceData.subscription;
    meterResAm.entityName = serviceData.name;
    meterResAm.serviceName = serviceData.name;
    meterResAm.availabilityMachineId = serviceData.availabilityMachineId;
    meterResAm.availabilityMachineName = serviceData.name;
    meterResAm.engineType = serviceData.engineType;
    meterResAm.userName = serviceData.owner;
    meterResAm.metadata = {
        clone: false,
        backupType: 'snapshotBackup',
        dateCreated: serviceData.dateCreated,
        additionalStorage: 0,
    };
    const size = snapshotData.size / 1073741824;
    meterResAm.usage = size * data.hours;
    meterResAm.meterHours = data.hours;
    meterResAm.cost = meterResAm.usage * 0.000154;

    return meterResAm;
};

export const modifyMeterDailyRes = (
    SampleMeterRes,
    servicesData,
    AvailabilityMachinesSnapshotsAutomatedFalse,
    AvailabilityMachinesBackups,
    AvailabilityMachinesSanitizatiedSnapshots,
    ratesJson,
) => {
    const meterDailyRes = [];

    let totalFreeSpacePerMonth = 3000 * 730; // Gb-hours

    servicesData.forEach((serviceData) =>
        getHoursPerDayArray(serviceData.dateCreated).forEach((data) => {
            if (isRateNeedToBeCalculated(serviceData.owner, data.startDate)) {
                const meterRes = JSON.parse(JSON.stringify(SampleMeterRes));
                meterRes.id = uuidv4();
                meterRes.startDate = isoStringToFormattedUTC(data.startDate);
                meterRes.endDate = isoStringToFormattedUTC(data.endDate);
                meterRes.meterHours = data.hours;
                meterRes.usage = data.hours;

                if (serviceData.owner === 'bakul@tessell-demo.com') {
                    meterRes.meterHours = 9;
                    meterRes.usage = 9;
                }

                // from service data
                meterRes.entityId = serviceData.id;
                meterRes.serviceId = serviceData.id;
                meterRes.subscriptionName = serviceData.subscription;
                meterRes.entityName = serviceData.name;
                meterRes.serviceName = serviceData.name;
                meterRes.availabilityMachineId = serviceData.availabilityMachineId;
                meterRes.availabilityMachineName = serviceData.name;
                meterRes.engineType = serviceData.engineType;
                meterRes.userName = serviceData.owner;
                meterRes.cloudType = serviceData.infrastructure.cloud.toUpperCase();

                meterRes.computeShape = serviceData.infrastructure.computeType;

                meterRes.cost =
                    data.hours *
                        (ratesJson?.filter(
                            (el) =>
                                meterRes.computeShape === el.entityName &&
                                meterRes.cloudType === el.cloudType &&
                                meterRes.engineType === el.engineType,
                        )?.[0]?.[
                            Cache.get('tenantServicePlan') ===
                            'Virtual Private Tessell @ Customer Edition'
                                ? 'costPerUnitBYOC'
                                : 'costPerUnit'
                        ] ?? 0.64) *
                        serviceData?.numOfInstances ?? 1;

                meterDailyRes.push(meterRes);
            }
        }),
    );

    servicesData.forEach((serviceData) => {
        const startDateMapToAM = {};

        // for snapshots.
        const snapshotsData =
            AvailabilityMachinesSnapshotsAutomatedFalse[serviceData.availabilityMachineId]
                ?.snapshots ?? [];

        snapshotsData.forEach((snapshotData) => {
            getHoursPerDayArray(snapshotData.snapshotTime).forEach((data) => {
                if (isRateNeedToBeCalculated(serviceData.owner, data.startDate)) {
                    const meterResAm = getBackupsMeterRes(
                        SampleMeterRes,
                        snapshotData,
                        data,
                        serviceData,
                    );

                    if (totalFreeSpacePerMonth > 0) {
                        totalFreeSpacePerMonth -= meterResAm.cost;
                        meterResAm.cost = 0;
                    }

                    if (startDateMapToAM[meterResAm.startDate]) {
                        startDateMapToAM[meterResAm.startDate].push(meterResAm);
                    } else {
                        startDateMapToAM[meterResAm.startDate] = [meterResAm];
                    }

                    // meterDailyRes.push(meterResAm);
                }
            });
        });

        // for backups.
        const backupsData =
            AvailabilityMachinesBackups[serviceData.availabilityMachineId]?.backups ?? [];

        backupsData.forEach((backupData) => {
            getHoursPerDayArray(backupData.backupTime).forEach((data) => {
                if (isRateNeedToBeCalculated(serviceData.owner, data.startDate)) {
                    const meterResAm = getBackupsMeterRes(
                        SampleMeterRes,
                        backupData,
                        data,
                        serviceData,
                    );

                    if (totalFreeSpacePerMonth > 0) {
                        totalFreeSpacePerMonth -= meterResAm.cost;
                        meterResAm.cost = 0;
                    }

                    if (startDateMapToAM[meterResAm.startDate]) {
                        startDateMapToAM[meterResAm.startDate].push(meterResAm);
                    } else {
                        startDateMapToAM[meterResAm.startDate] = [meterResAm];
                    }

                    // meterDailyRes.push(meterResAm);
                }
            });
        });

        // for sanitized snapshots
        const sanitizedSnapshotsData =
            AvailabilityMachinesSanitizatiedSnapshots[serviceData.availabilityMachineId]
                ?.snapshots ?? [];

        sanitizedSnapshotsData.forEach((sanitizedSnapshotsData) => {
            getHoursPerDayArray(sanitizedSnapshotsData.snapshotTime).forEach((data) => {
                if (isRateNeedToBeCalculated(serviceData.owner, data.startDate)) {
                    const meterResAm = getBackupsMeterRes(
                        SampleMeterRes,
                        sanitizedSnapshotsData,
                        data,
                        serviceData,
                    );

                    if (totalFreeSpacePerMonth > 0) {
                        totalFreeSpacePerMonth -= meterResAm.cost;
                        meterResAm.cost = 0;
                    }

                    if (startDateMapToAM[meterResAm.startDate]) {
                        startDateMapToAM[meterResAm.startDate].push(meterResAm);
                    } else {
                        startDateMapToAM[meterResAm.startDate] = [meterResAm];
                    }

                    // meterDailyRes.push(meterResAm);
                }
            });
        });

        // for AM
        getHoursPerDayArray(serviceData.dateCreated).forEach((data) => {
            if (isRateNeedToBeCalculated(serviceData.owner, data.startDate)) {
                const meterRes = JSON.parse(JSON.stringify(SampleMeterRes));
                meterRes.id = uuidv4();
                meterRes.startDate = isoStringToFormattedUTC(data.startDate);
                meterRes.endDate = isoStringToFormattedUTC(data.endDate);
                meterRes.meterHours = data.hours;
                meterRes.usage = data.hours;
                if (serviceData.owner === 'bakul@tessell-demo.com') {
                    meterRes.meterHours = 9;
                    meterRes.usage = 9;
                }
                meterRes.entityType = 'AVAILABILITY_MACHINE';

                // from service data
                meterRes.subscriptionName = serviceData.subscription;
                meterRes.entityName = serviceData.name;
                meterRes.serviceName = serviceData.name;
                meterRes.availabilityMachineId = serviceData.availabilityMachineId;
                meterRes.availabilityMachineName = serviceData.name;
                meterRes.engineType = serviceData.engineType;
                meterRes.userName = serviceData.owner;
                meterRes.computeShape = serviceData.infrastructure.computeType;

                meterRes.cloudType = serviceData.infrastructure.cloud.toUpperCase();

                meterRes.amDetails = (startDateMapToAM[meterRes.startDate] ?? []).map(
                    (amDetail) => ({
                        name: amDetail.name,
                        entityId: amDetail.id,
                        cloudType: amDetail.cloudType,
                        region: amDetail.region,
                        entityType: amDetail.entityType,
                        userName: amDetail.userName,
                        usage: amDetail.usage,
                        usageUnit: 'per GB per hour',
                        meterHours: amDetail.meterHours,
                        dateCreated: amDetail.dateCreated,
                    }),
                );

                meterRes.usage =
                    startDateMapToAM[meterRes.startDate]?.reduce(
                        (accumulator, currentValue) => accumulator + currentValue.usage,
                        0,
                    ) ?? 0;

                meterRes.cost =
                    startDateMapToAM[meterRes.startDate]?.reduce(
                        (accumulator, currentValue) => accumulator + currentValue.cost,
                        0,
                    ) ?? 0;

                meterDailyRes.push(meterRes);
            }
        });
    });

    return meterDailyRes;
};

export const modifyMeter = (
    SampleMeterRes,
    myServicesDataJson,
    AvailabilityMachinesSnapshotsAutomatedFalse,
    AvailabilityMachinesBackups,
    AvailabilityMachinesSanitizatiedSnapshots,
    ratesJson,
) => {
    const servicesData = myServicesDataJson;

    const meterRes = modifyMeterDailyRes(
        SampleMeterRes,
        [
            ...servicesData[personasEnum.ACCOUNT_OWNER].response,
            ...servicesData[personasEnum.DATA_OWNER].response,
        ],
        AvailabilityMachinesSnapshotsAutomatedFalse,
        AvailabilityMachinesBackups,
        AvailabilityMachinesSanitizatiedSnapshots,
        ratesJson,
    );

    return meterRes;
};
