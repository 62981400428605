import clsx from 'clsx';
import { useStyles } from './utils';

export default function NewVersionIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.40001 14C11.1231 14 14.0308 10.6077 14.0308 2.36926C14.0308 11.0923 16.9385 14 25.6616 14C16.9385 14 14.0308 16.9078 14.0308 25.6308C14.0308 16.9078 11.1231 14 2.40001 14Z"
                fill="#1E7EA1"
                stroke="#1E7EA1"
                strokeWidth="1.4"
                strokeLinejoin="round"
            />
            <path
                d="M18.8461 4.30771C20.3 4.30771 20.7846 3.74232 20.7846 2.36925C20.7846 3.82309 21.2692 4.3077 22.723 4.3077C21.2692 4.3077 20.7846 4.79231 20.7846 6.24615C20.7846 4.79231 20.3 4.30771 18.8461 4.30771Z"
                stroke="#1E7EA1"
                strokeWidth="1.4"
                strokeLinejoin="round"
            />
            <path
                d="M20.7846 21.7539C22.9654 21.7539 23.6923 20.9058 23.6923 18.8462C23.6923 21.0269 24.4192 21.7538 26.6 21.7538C24.4192 21.7538 23.6923 22.4807 23.6923 24.6615C23.6923 22.4807 22.9654 21.7539 20.7846 21.7539Z"
                fill="#1E7EA1"
                stroke="#1E7EA1"
                strokeWidth="1.4"
                strokeLinejoin="round"
            />
        </svg>
    );
}
