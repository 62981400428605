import clsx from 'clsx';
import { useStyles } from './utils';

export default function RemoveCircleOutlinedIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.83341 10C5.83341 10.4584 6.20841 10.8334 6.66675 10.8334H13.3334C13.7917 10.8334 14.1667 10.4584 14.1667 10C14.1667 9.54169 13.7917 9.16669 13.3334 9.16669H6.66675C6.20841 9.16669 5.83341 9.54169 5.83341 10ZM10.0001 1.66669C5.40008 1.66669 1.66675 5.40002 1.66675 10C1.66675 14.6 5.40008 18.3334 10.0001 18.3334C14.6001 18.3334 18.3334 14.6 18.3334 10C18.3334 5.40002 14.6001 1.66669 10.0001 1.66669ZM10.0001 16.6667C6.32508 16.6667 3.33341 13.675 3.33341 10C3.33341 6.32502 6.32508 3.33335 10.0001 3.33335C13.6751 3.33335 16.6667 6.32502 16.6667 10C16.6667 13.675 13.6751 16.6667 10.0001 16.6667Z"
                fill="black"
                fillOpacity="0.54"
            />
        </svg>
    );
}
