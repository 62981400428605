/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './configureStore';
import { billingRatesArray } from 'types/BillingRates.types';

export interface billingRates {
    billingRatesArray: billingRatesArray;
}

const initialState: billingRates = {
    billingRatesArray: [],
};

// slice
export const billingRatesSlice = createSlice({
    name: 'billingRates',
    initialState,
    reducers: {
        updateBillingRates: (state, action: PayloadAction<any>) => {
            state.billingRatesArray = action.payload;
        },
    },
});

// Actions
export const billingRatesActions = {
    updateBillingRates: billingRatesSlice.actions.updateBillingRates,
};

// Selectors
export const selectBillingRates = (state: RootState) => state.billingRatesReducer;

// Reducer
export default billingRatesSlice.reducer;
