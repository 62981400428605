/* eslint-disable no-tabs */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */
import PropTypes from 'prop-types';
import React from 'react';

export default function DatadogIcon(props) {
    const { className } = props;
    return (
        <svg
            className={className}
            width="50"
            viewBox="0 0 800.55 856.85"
            style={{ enableBackground: 'new 0 0 800.55 856.85' }}
        >
            <path
                className="datadog-st0"
                d="M670.38,608.27l-71.24-46.99l-59.43,99.27l-69.12-20.21l-60.86,92.89l3.12,29.24l330.9-60.97l-19.22-206.75
	L670.38,608.27z M361.79,519.13l53.09-7.3c8.59,3.86,14.57,5.33,24.87,7.95c16.04,4.18,34.61,8.19,62.11-5.67
	c6.4-3.17,19.73-15.36,25.12-22.31l217.52-39.46l22.19,268.56l-372.65,67.16L361.79,519.13z M765.85,422.36l-21.47,4.09L703.13,0.27
	L0.27,81.77l86.59,702.68l82.27-11.94c-6.57-9.38-16.8-20.73-34.27-35.26c-24.23-20.13-15.66-54.32-1.37-75.91
	c18.91-36.48,116.34-82.84,110.82-141.15c-1.98-21.2-5.35-48.8-25.03-67.71c-0.74,7.85,0.59,15.41,0.59,15.41
	s-8.08-10.31-12.11-24.37c-4-5.39-7.14-7.11-11.39-14.31c-3.03,8.33-2.63,17.99-2.63,17.99s-6.61-15.62-7.68-28.8
	c-3.92,5.9-4.91,17.11-4.91,17.11s-8.59-24.62-6.63-37.88c-3.92-11.54-15.54-34.44-12.25-86.49c21.45,15.03,68.67,11.46,87.07-15.66
	c6.11-8.98,10.29-33.5-3.05-81.81c-8.57-30.98-29.79-77.11-38.06-94.61l-0.99,0.71c4.36,14.1,13.35,43.66,16.8,57.99
	c10.44,43.47,13.24,58.6,8.34,78.64c-4.17,17.42-14.17,28.82-39.52,41.56c-25.35,12.78-58.99-18.32-61.12-20.04
	c-24.63-19.62-43.68-51.63-45.81-67.18c-2.21-17.02,9.81-27.24,15.87-41.16c-8.67,2.48-18.34,6.88-18.34,6.88
	s11.54-11.94,25.77-22.27c5.89-3.9,9.35-6.38,15.56-11.54c-8.99-0.15-16.29,0.11-16.29,0.11s14.99-8.1,30.53-14
	c-11.37-0.5-22.25-0.08-22.25-0.08s33.45-14.96,59.87-25.94c18.17-7.45,35.92-5.25,45.89,9.17c13.09,18.89,26.84,29.15,55.98,35.51
	c17.89-7.93,23.33-12.01,45.81-18.13c19.79-21.76,35.33-24.58,35.33-24.58s-7.71,7.07-9.77,18.18
	c11.22-8.84,23.52-16.22,23.52-16.22s-4.76,5.88-9.2,15.22l1.03,1.53c13.09-7.85,28.48-14.04,28.48-14.04s-4.4,5.56-9.56,12.76
	c9.87-0.08,29.89,0.42,37.66,1.3c45.87,1.01,55.39-48.99,72.99-55.26c22.04-7.87,31.89-12.63,69.45,24.26
	c32.23,31.67,57.41,88.36,44.91,101.06c-10.48,10.54-31.16-4.11-54.08-32.68c-12.11-15.13-21.27-33.01-25.56-55.74
	c-3.62-19.18-17.71-30.31-17.71-30.31S520,92.95,520,109.01c0,8.77,1.1,41.56,15.16,59.96c-1.39,2.69-2.04,13.31-3.58,15.34
	c-16.36-19.77-51.49-33.92-57.22-38.09c19.39,15.89,63.96,52.39,81.08,87.37c16.19,33.08,6.65,63.4,14.84,71.25
	c2.33,2.25,34.82,42.73,41.07,63.07c10.9,35.45,0.65,72.7-13.62,95.81l-39.85,6.21c-5.83-1.62-9.76-2.43-14.99-5.46
	c2.88-5.1,8.61-17.82,8.67-20.44l-2.25-3.95c-12.4,17.57-33.18,34.63-50.44,44.43c-22.59,12.8-48.63,10.83-65.58,5.58
	c-48.11-14.84-93.6-47.35-104.57-55.89c0,0-0.34,6.82,1.73,8.35c12.13,13.68,39.92,38.43,66.78,55.68l-57.26,6.3l27.07,210.78
	c-12,1.72-13.87,2.56-27.01,4.43c-11.58-40.91-33.73-67.62-57.94-83.18c-21.35-13.72-50.8-16.81-78.99-11.23l-1.81,2.1
	c19.6-2.04,42.74,0.8,66.51,15.85c23.33,14.75,42.13,52.85,49.05,75.79c8.86,29.32,14.99,60.68-8.86,93.92
	c-16.97,23.63-66.51,36.69-106.53,8.44c10.69,17.19,25.14,31.25,44.59,33.9c28.88,3.92,56.29-1.09,75.16-20.46
	c16.11-16.56,24.65-51.19,22.4-87.66l25.49-3.7l9.2,65.46l421.98-50.81L765.85,422.36z M509.12,244.59
	c-1.18,2.69-3.03,4.45-0.25,13.2l0.17,0.5l0.44,1.13l1.16,2.62c5.01,10.24,10.51,19.9,19.7,24.83c2.38-0.4,4.84-0.67,7.39-0.8
	c8.63-0.38,14.08,0.99,17.54,2.85c0.31-1.72,0.38-4.24,0.19-7.95c-0.67-12.97,2.57-35.03-22.36-46.64
	c-9.41-4.37-22.61-3.02-27.01,2.43c0.8,0.1,1.52,0.27,2.08,0.46C514.82,239.55,510.31,241.84,509.12,244.59 M578.99,365.61
	c-3.27-1.8-18.55-1.09-29.29,0.19c-20.46,2.41-42.55,9.51-47.39,13.29c-8.8,6.8-4.8,18.66,1.7,23.53
	c18.23,13.62,34.21,22.75,51.08,20.53c10.36-1.36,19.49-17.76,25.96-32.64C585.48,380.26,585.48,369.2,578.99,365.61 M397.85,260.65
	c5.77-5.48-28.74-12.68-55.52,5.58c-19.75,13.47-20.38,42.35-1.47,58.72c1.89,1.62,3.45,2.77,4.91,3.71
	c5.52-2.6,11.81-5.23,19.05-7.58c12.23-3.97,22.4-6.02,30.76-7.11c4-4.47,8.65-12.34,7.49-26.59
	C401.49,268.05,386.84,271.12,397.85,260.65"
            />
        </svg>
    );
}

DatadogIcon.propTypes = {
    className: PropTypes.string.isRequired,
};
