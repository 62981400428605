// eslint-disable-next-line import/no-unresolved
import DefaultScriptLibraryLoader from './default/scriptloader';

const tenantUrl = '';

const getDataLoaders = () => {
    let scriptlibraryloader;

    switch (tenantUrl) {
        case 'localhost':
        case 'globexUrl':
            // subscriptionLoader = new UBSSubscriptionLoader();
            break;
        default:
            scriptlibraryloader = new DefaultScriptLibraryLoader();
            break;
    }

    return {
        scriptlibraryloader,
    };
};

const { scriptlibraryloader } = getDataLoaders();

export default class ScriptsLibraryData {
    // eslint-disable-next-line class-methods-use-this
    getScripts() {
        return scriptlibraryloader.initScripts();
    }

    // eslint-disable-next-line class-methods-use-this
    getVersions(id) {
        return scriptlibraryloader.initVersions(id);
    }

    // eslint-disable-next-line class-methods-use-this
    getScriptById(id) {
        return scriptlibraryloader.initScriptById(id);
    }
}
