import { styles } from 'styles/style-templates';

export const useStyles = (theme) => ({
    content: {
        [theme.breakpoints.down('laptop')]: {
            width: '300px',
        },
        [theme.breakpoints.up('laptop')]: {
            width: '100%',
            marginTop: 8,
        },
    },
    blank: {
        [theme.breakpoints.down('laptop')]: {
            width: '15%',
        },
        [theme.breakpoints.up('laptop')]: {
            width: '25%',
        },
    },
    // banner: {
    //   [theme.breakpoints.up('laptop')]: {
    //     marginLeft: 12,
    //   },
    // },
    oauthButtons: {
        boxSizing: 'borderBox',
        /* Auto layout */

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 16px',
        gap: '16px',

        height: '36px',

        /* Neutrals/N6 */

        background: '#FFFFFF',
        /* Neutrals/N5 */

        border: '1px solid #EEEEEE',
        /* DS-Base */

        boxShadow: '0px 1px 4px rgba(31, 41, 55, 0.08)',
        borderRadius: '4px',

        /* Inside auto layout */

        flex: 'none',
        order: '0',
        alignSelf: 'stretch',
        flexGrow: '0',
        '&:hover': {
            border: '1px solid #11567F !important',
            color: '#11567F !important',
            background: '#FFFFFF !important',
        },
    },
    form: {
        mx: 'auto',
        background: styles.color.baseBackground,
        width: '520px',
        zIndex: '0',
    },
    login: {
        width: '200px !important',
    },
    input: {
        marginTop: theme.spacing(2),
    },
    progress: {
        color: 'white',
    },
    link: {
        textDecoration: 'inherit',
    },
    body: {
        height: '94vh',
        background: styles.color.baseBackground,
    },
    error: {
        color: 'red',
    },
    icon: {
        width: '100px',
        height: '100px',
        position: 'absolute',
        top: '0',
        mx: 'auto',
        borderRadius: '50%',
        background: 'white',
    },
});
