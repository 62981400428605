// import clsx from 'clsx';
import { useStyles } from './utils';

export default function TessellManagedIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);
    return (
        <svg
            className={className}
            viewBox="0 0 110 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={iconStyle}
        >
            <g filter="url(#filter0_d_1291_411)">
                <circle cx="55" cy="61" r="25" fill="white" />
            </g>
            <path
                d="M39.5388 59.2559L39.5413 54.4849C39.3009 52.5081 38.2069 52.2688 37.5819 52.6318C37.3396 52.7725 37.2463 53.0548 37.1977 53.3307C36.9241 54.8854 35.9721 60.4254 35.7987 63.1895C35.7807 63.4771 35.8338 63.7616 35.9506 64.0251C37.0864 66.5872 38.6311 68.7484 39.2902 69.5394L42.0357 72.3156L47.1192 70.2972C47.1705 68.4281 46.366 65.5682 45.6205 64.5989C45.1027 63.9257 43.156 62.9518 42.5139 61.6976C41.2176 59.1657 40.0811 58.8769 39.5388 59.2559ZM39.5388 59.2559C39.1878 59.8489 39.1155 61.5537 40.4316 63.9726L42.4938 66.6348"
                stroke="#1EB6E9"
            />
            <path
                d="M47.0552 70.9161L41.9126 73.0238C41.5723 73.2141 41.7074 73.8059 41.8174 74.0779L42.5671 77.5464L50.646 75.6399L48.5779 72.4138C48.2827 71.1629 47.4397 70.8941 47.0552 70.9161Z"
                fill="#B2DFFE"
            />
            <path
                d="M41.8766 72.3224V72.3224C41.8766 72.3224 41.1118 73.2291 41.2274 73.8126L42.045 77.9389L51.1174 75.798L48.7456 71.7435C48.6748 71.6225 48.6084 71.4994 48.5318 71.382C48.2942 71.0181 47.6785 70.246 46.8333 70.4455"
                stroke="#1EB6E9"
            />
            <path
                d="M69.4186 59.2831L69.4547 54.5122C69.7111 52.5375 70.8071 52.307 71.4291 52.675C71.6702 52.8177 71.7613 53.1007 71.8076 53.3771C72.0686 54.9339 72.9757 60.4814 73.1266 63.2469C73.1424 63.5346 73.087 63.8187 72.9681 64.0812C71.8115 66.634 70.2493 68.7826 69.5839 69.5683L66.816 72.3221L61.749 70.2626C61.7128 68.3931 62.5404 65.5399 63.2938 64.5766C63.817 63.9076 65.7715 62.9495 66.4238 61.7006C67.7405 59.1793 68.8794 58.8997 69.4186 59.2831ZM69.4186 59.2831C69.7648 59.8789 69.8232 61.5843 68.4876 63.9923L66.4039 66.6378"
                stroke="#1EB6E9"
            />
            <path
                d="M61.8068 70.8777L66.9322 73.027C67.2709 73.2201 67.131 73.8107 67.0188 74.0819L66.241 77.5442L58.1778 75.5723L60.272 72.3631C60.5773 71.1146 61.4224 70.8526 61.8068 70.8777Z"
                fill="#B2DFFE"
            />
            <path
                d="M66.9754 72.3283V72.3283C66.9754 72.3283 67.7328 73.2412 67.6124 73.8237L66.7614 77.9433L57.7067 75.7289L60.1112 71.6938C60.183 71.5733 60.2504 71.4508 60.328 71.334C60.5685 70.9721 61.1905 70.205 62.034 70.4113"
                stroke="#1EB6E9"
            />
            <path
                d="M45.0002 52.1186C45.0002 55.3003 46.675 56.3317 51.0352 55.9104C54.6504 55.561 60.0891 55.9104 62.438 55.9104C64.4513 55.9104 67.3559 55.0369 66.9644 51.3683C66.7237 49.1126 65.2251 47.9306 63.5069 47.5567C62.3713 47.3095 61.3259 46.4743 60.906 45.3907C60.2658 43.7391 58.818 42 55.6679 42C52.1563 42 50.3948 44.594 49.6685 46.686C49.3286 47.6652 48.4376 48.4143 47.4695 48.7846C45.9279 49.3744 45.0002 50.7078 45.0002 52.1186Z"
                fill="#B2DFFE"
            />
            <path
                d="M47.4848 46.5225C48.7989 39.0964 60.4538 39.2011 61.6665 46.5863C69.0621 45.1446 70.7456 57 62.5876 57V55.7175C68.9473 55.7175 67.3595 46.163 61.331 47.9913C60.885 48.1233 60.4294 47.8914 60.4053 47.4321C60.0072 40.6882 49.5131 40.5198 48.7654 47.1272C48.7176 47.532 48.5112 47.9095 47.9886 47.8413C42.1708 47.1184 41.6242 55.7175 47.4323 55.7175L62.5876 55.7175V57L47.4323 57C39.9458 57 40.4205 46.2907 47.4851 46.5229L47.4848 46.5225Z"
                fill="#1EB6E9"
            />
            <defs>
                <filter
                    id="filter0_d_1291_411"
                    x="0"
                    y="0"
                    width="110"
                    height="110"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="-6" />
                    <feGaussianBlur stdDeviation="15" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0313726 0 0 0 0 0.0313726 0 0 0 0 0.0509804 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1291_411"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1291_411"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
