import clsx from 'clsx';
import { useStyles } from './utils';

export default function StandardComputeIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width={40}
            height={40}
            fill="none"
            {...props}
        >
            <g clipPath="url(#a)">
                <path
                    fill="#C3E5F8"
                    stroke="#11567F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M29.14 9.201H10.86c-.918 0-1.662.744-1.662 1.662v4.986c0 .917.744 1.661 1.662 1.661h18.28c.918 0 1.662-.744 1.662-1.662v-4.985c0-.918-.744-1.662-1.662-1.662Z"
                />
                <path
                    stroke="#11567F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M20.831 13.355h5.816"
                />
                <path
                    fill="#C3E5F8"
                    d="M10.86 17.512a1.662 1.662 0 0 0-1.662 1.662v4.985a1.662 1.662 0 0 0 1.662 1.662h18.28a1.662 1.662 0 0 0 1.662-1.662v-4.985a1.662 1.662 0 0 0-1.662-1.662"
                />
                <path
                    stroke="#11567F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M10.86 17.512a1.662 1.662 0 0 0-1.662 1.662v4.985a1.662 1.662 0 0 0 1.662 1.662h18.28a1.662 1.662 0 0 0 1.662-1.662v-4.985a1.662 1.662 0 0 0-1.662-1.662H10.86Z"
                />
                <path
                    stroke="#11567F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M13.768 22.079a.415.415 0 1 1 0-.831M13.768 22.079a.415.415 0 0 0 0-.831M13.768 13.772a.415.415 0 1 1 0-.83M13.768 13.772a.415.415 0 0 0 0-.83M20.831 21.666h5.816M20 25.818v4.986M11.69 30.805H28.31"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M8 8h24v24H8z" />
                </clipPath>
            </defs>
        </svg>
    );
}
