import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFeatureFlags, featureFlagsActions } from 'redux/featureFlags.slice';

export interface relationalDbObj {
    oracle: boolean;
    mysql: boolean;
    postgresql: boolean;
    sqlserver: boolean;
}

export type FeatureFlagsOperators = {
    serversApp: {
        isServersAppAvailable: boolean;
        updateIsServersAppAvailable: (arg: boolean) => void;
    };
    multiDiskProvisioning: {
        isMultiDiskAvailable: boolean;
        updateIsMultiDiskAvailable: (arg: boolean) => void;
    };
    timezone: {
        isTimezoneFeatureAvailable: {
            relationalDB: relationalDbObj;
        };
        updateIsTimezoneFeatureAvailable: (arg: { relationalDB: relationalDbObj }) => void;
    };
    provisioningApp: {
        isMssqlHpcProvisioningAvailable: boolean;
        updateIsMssqlHpcAvailable: (arg: boolean) => void;
    };
};

// ? whoami api call gives feature flag for isServersAppAvailable - key = 'oracle-multi-service-provisioning'

const useFeatureFlags = (): FeatureFlagsOperators => {
    const dispatch = useDispatch();

    const featureFlags = useSelector(selectFeatureFlags);

    const updateIsServersAppAvailable = (isServersAppAvailableArg: boolean) => {
        dispatch(featureFlagsActions.updateIsServerAvailable(isServersAppAvailableArg));
    };

    const updateIsMultiDiskAvailable = (isMultiDiskAvailableArg: boolean) => {
        dispatch(
            featureFlagsActions.updateIsMultiDiskProvisioningAvailable(isMultiDiskAvailableArg),
        );
    };

    const updateIsTimezoneFeatureAvailable = (isTimezoneFeatureAvailableArg: {
        relationalDB: relationalDbObj;
    }) => {
        dispatch(
            featureFlagsActions.updateIsTimezoneFeatureAvailable(isTimezoneFeatureAvailableArg),
        );
    };

    const updateIsMssqlHpcAvailable = (isMssqlHpcProvisioningAvailableArg: boolean) => {
        dispatch(
            featureFlagsActions.updateIsMssqlHpcProvisioningAvailable(
                isMssqlHpcProvisioningAvailableArg,
            ),
        );
    };

    return {
        serversApp: {
            isServersAppAvailable: featureFlags?.isServersAppAvailable,
            updateIsServersAppAvailable,
        },
        multiDiskProvisioning: {
            isMultiDiskAvailable: featureFlags?.isMultiDiskProvisioningAvailable,
            updateIsMultiDiskAvailable,
        },
        timezone: {
            isTimezoneFeatureAvailable: featureFlags?.isTimezoneFeatureAvailable,
            updateIsTimezoneFeatureAvailable,
        },
        provisioningApp: {
            isMssqlHpcProvisioningAvailable: featureFlags?.isMssqlHpcProvisioningAvailable,
            updateIsMssqlHpcAvailable,
        },
    };
};

export default useFeatureFlags;
