import clsx from 'clsx';
import { useStyles } from './utils';

export default function EmptySubnetsIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="65"
            viewBox="0 0 64 65"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52 36.1172H12V54.6292C11.9999 55.0593 12.1385 55.478 12.3952 55.8232C12.6519 56.1683 13.013 56.4215 13.425 56.5452L31.425 61.9452C31.8002 62.0572 32.1998 62.0572 32.575 61.9452L50.575 56.5452C50.987 56.4215 51.3481 56.1683 51.6048 55.8232C51.8615 55.478 52.0001 55.0593 52 54.6292V36.1172Z"
                fill="#11567F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.0003 36.1172H32.0003C32.0003 36.1172 31.8063 62.0292 32.0003 62.0292C32.1943 62.0292 32.3873 62.0012 32.5753 61.9452L50.5753 56.5452C50.9873 56.4215 51.3484 56.1683 51.6051 55.8232C51.8618 55.478 52.0004 55.0593 52.0003 54.6292V36.1172Z"
                fill="#11567F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.0003 42.6062L12.0003 36.1172L6.92529 43.6412C6.83854 43.7696 6.78282 43.9164 6.76252 44.07C6.74222 44.2236 6.75791 44.3799 6.80833 44.5264C6.85876 44.6729 6.94255 44.8057 7.05309 44.9143C7.16363 45.0229 7.29789 45.1044 7.44529 45.1522L25.4403 50.9982C25.6465 51.0652 25.8689 51.0634 26.0739 50.9931C26.279 50.9227 26.4557 50.7877 26.5773 50.6082L32.0003 42.6062Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52 36.1172L32 42.6062L37.423 50.6082C37.5446 50.7877 37.7213 50.9227 37.9264 50.9931C38.1314 51.0634 38.3538 51.0652 38.56 50.9982L56.555 45.1522C56.7024 45.1044 56.8367 45.0229 56.9472 44.9143C57.0577 44.8057 57.1415 44.6729 57.192 44.5264C57.2424 44.3799 57.2581 44.2236 57.2378 44.07C57.2175 43.9164 57.1617 43.7696 57.075 43.6412L52 36.1172Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.1589 22.4859C27.0371 22.307 26.8605 22.1725 26.6556 22.1025C26.4508 22.0325 26.2288 22.0309 26.0229 22.0979L8.02694 27.9449C7.87944 27.9924 7.74503 28.0737 7.63436 28.1821C7.52369 28.2906 7.4398 28.4234 7.38935 28.5699C7.33889 28.7164 7.32324 28.8727 7.34366 29.0263C7.36407 29.1799 7.41997 29.3267 7.50694 29.4549L11.9999 36.1169L31.9999 29.6059L27.1589 22.4859Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.493 29.4549C56.5795 29.3265 56.6351 29.1798 56.6553 29.0263C56.6755 28.8728 56.6597 28.7167 56.6093 28.5703C56.5589 28.4239 56.4752 28.2912 56.3648 28.1827C56.2544 28.0742 56.1202 27.9928 55.973 27.9449L37.977 22.0979C37.7712 22.0309 37.5491 22.0325 37.3443 22.1025C37.1395 22.1725 36.9628 22.307 36.841 22.4859L32 29.6059L52 36.1169L56.493 29.4549Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52 36.1174L32 29.6064L12 36.1174L32 42.6064L52 36.1174Z"
                fill="#29B5E8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32 42.6064V29.6064L12 36.1174L32 42.6064Z"
                fill="#11567F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8664 29.4062C24.5822 29.0232 24.33 28.6175 24.1124 28.1932C23.9921 27.9568 23.7829 27.7778 23.5306 27.6956C23.2784 27.6135 23.0039 27.6349 22.7674 27.7552C22.531 27.8755 22.352 28.0848 22.2699 28.337C22.1877 28.5892 22.2091 28.8638 22.3294 29.1002C22.5834 29.5982 22.8914 30.0972 23.2564 30.5922C23.4157 30.8004 23.6502 30.9377 23.9097 30.9747C24.1692 31.0116 24.4328 30.9452 24.6438 30.7898C24.8548 30.6344 24.9964 30.4023 25.038 30.1435C25.0796 29.8848 25.018 29.62 24.8664 29.4062Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.4036 25.3246C23.4136 24.9216 23.4726 24.5306 23.5806 24.1556C23.6168 24.0293 23.6278 23.8972 23.6129 23.7667C23.598 23.6362 23.5575 23.5099 23.4938 23.395C23.4301 23.2801 23.3444 23.1789 23.2416 23.0972C23.1388 23.0154 23.0209 22.9548 22.8946 22.9186C22.7684 22.8824 22.6362 22.8714 22.5057 22.8863C22.3752 22.9012 22.2489 22.9417 22.1341 23.0054C22.0192 23.0691 21.918 23.1548 21.8363 23.2576C21.7545 23.3604 21.6938 23.4783 21.6576 23.6046C21.503 24.1483 21.4176 24.7094 21.4036 25.2746C21.397 25.5398 21.496 25.7968 21.6789 25.989C21.8617 26.1812 22.1134 26.2929 22.3786 26.2996C22.6438 26.3062 22.9008 26.2072 23.0931 26.0244C23.2853 25.8415 23.397 25.5898 23.4036 25.3246Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.9421 21.9846C25.1991 21.7466 25.4871 21.5266 25.8021 21.3276C25.9132 21.2576 26.0095 21.1663 26.0853 21.0591C26.1611 20.9518 26.2151 20.8307 26.2441 20.7026C26.2731 20.5745 26.2766 20.4419 26.2544 20.3125C26.2322 20.183 26.1847 20.0592 26.1146 19.9481C26.0446 19.837 25.9533 19.7408 25.8461 19.665C25.7388 19.5891 25.6177 19.5352 25.4896 19.5062C25.3615 19.4771 25.229 19.4736 25.0995 19.4959C24.9701 19.5181 24.8462 19.5656 24.7351 19.6356C24.3248 19.8934 23.9391 20.1887 23.5831 20.5176C23.3935 20.699 23.2828 20.9477 23.2749 21.21C23.267 21.4724 23.3625 21.7273 23.5408 21.9198C23.7192 22.1123 23.9661 22.227 24.2282 22.2391C24.4904 22.2513 24.7468 22.1599 24.9421 21.9846Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.6607 20.26C29.1885 20.1636 29.7226 20.1048 30.2587 20.084C30.5174 20.064 30.7583 19.9444 30.9305 19.7502C31.1027 19.5561 31.1928 19.3027 31.1818 19.0434C31.1708 18.7842 31.0595 18.5393 30.8714 18.3605C30.6834 18.1817 30.4332 18.0829 30.1737 18.085C29.5137 18.113 28.8877 18.184 28.2967 18.293C28.0359 18.3413 27.8049 18.4912 27.6546 18.7097C27.5043 18.9283 27.4469 19.1977 27.4952 19.4585C27.5435 19.7193 27.6934 19.9503 27.912 20.1006C28.1305 20.2509 28.3999 20.3083 28.6607 20.26Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.8733 20.201C34.7263 20.211 35.5203 20.181 36.2573 20.116C36.5191 20.0895 36.7598 19.9609 36.9274 19.7581C37.095 19.5553 37.1759 19.2946 37.1526 19.0326C37.1294 18.7705 37.0037 18.5282 36.803 18.3581C36.6023 18.1881 36.3427 18.104 36.0803 18.124C35.4053 18.183 34.6783 18.211 33.8963 18.201C33.6311 18.198 33.3756 18.3004 33.1859 18.4858C32.9962 18.6711 32.8879 18.9243 32.8848 19.1895C32.8818 19.4547 32.9842 19.7103 33.1696 19.9C33.355 20.0897 33.6081 20.198 33.8733 20.201Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.5437 19.5031C40.5337 19.2001 41.3697 18.8121 42.0697 18.3671C42.1805 18.2966 42.2763 18.2048 42.3516 18.0972C42.4269 17.9896 42.4803 17.8682 42.5087 17.74C42.5371 17.6118 42.54 17.4792 42.5172 17.3498C42.4944 17.2205 42.4463 17.0969 42.3757 16.9861C42.3051 16.8754 42.2134 16.7796 42.1058 16.7043C41.9982 16.6289 41.8768 16.5755 41.7486 16.5471C41.6203 16.5187 41.4878 16.5158 41.3584 16.5387C41.2291 16.5615 41.1055 16.6096 40.9947 16.6801C40.3601 17.0737 39.6752 17.3798 38.9587 17.5901C38.705 17.6677 38.4926 17.8429 38.368 18.0771C38.2435 18.3114 38.2171 18.5855 38.2947 18.8391C38.3723 19.0928 38.5475 19.3053 38.7817 19.4298C39.0159 19.5543 39.29 19.5807 39.5437 19.5031Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.6336 15.5679C45.0836 14.6333 45.2923 13.6009 45.2406 12.5649C45.2345 12.4337 45.2026 12.305 45.1468 12.1861C45.091 12.0673 45.0122 11.9606 44.9152 11.8721C44.8181 11.7837 44.7045 11.7152 44.581 11.6707C44.4574 11.6261 44.3263 11.6063 44.1951 11.6124C44.0639 11.6185 43.9352 11.6504 43.8164 11.7062C43.6975 11.7621 43.5908 11.8408 43.5024 11.9379C43.4139 12.035 43.3455 12.1485 43.3009 12.2721C43.2563 12.3956 43.2365 12.5267 43.2426 12.6579C43.2786 13.3615 43.1376 14.0629 42.8326 14.6979C42.7306 14.9348 42.7239 15.202 42.8141 15.4438C42.9043 15.6855 43.0843 15.883 43.3166 15.9953C43.5489 16.1075 43.8155 16.1257 44.0609 16.046C44.3063 15.9664 44.5115 15.7951 44.6336 15.5679Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.9973 9.02909C43.2683 8.04409 42.2793 7.35809 41.2013 7.13709C40.9454 7.09378 40.6828 7.15152 40.4687 7.29812C40.2546 7.44472 40.1058 7.66874 40.0536 7.92293C40.0015 8.17712 40.0501 8.44163 40.1892 8.66067C40.3283 8.87972 40.547 9.03619 40.7993 9.09709C41.4213 9.22409 41.9693 9.65109 42.3903 10.2201C42.4685 10.3256 42.5667 10.4147 42.6793 10.4822C42.792 10.5498 42.9168 10.5945 43.0467 10.6138C43.1766 10.6331 43.309 10.6267 43.4365 10.5948C43.5639 10.5629 43.6838 10.5063 43.7893 10.4281C43.8948 10.3499 43.9839 10.2517 44.0514 10.139C44.119 10.0264 44.1637 9.90158 44.183 9.77167C44.2023 9.64176 44.1959 9.50932 44.164 9.38191C44.1321 9.25449 44.0755 9.13461 43.9973 9.02909Z"
                fill="#1E7EA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.9326 7.3308C32.0146 1.2038 41.3916 1.5998 35.9326 7.3308H30.9326Z"
                fill="#29B5E8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.9336 9.33105C32.0156 15.4571 41.3926 15.0621 35.9336 9.33105H30.9336Z"
                fill="#29B5E8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.843 9.11719H37.539C37.8042 9.11719 38.0586 9.01183 38.2461 8.82429C38.4336 8.63676 38.539 8.3824 38.539 8.11719C38.539 7.85197 38.4336 7.59762 38.2461 7.41008C38.0586 7.22254 37.8042 7.11719 37.539 7.11719H30.843C30.5855 7.12871 30.3424 7.23909 30.1643 7.42537C29.9861 7.61165 29.8867 7.85945 29.8867 8.11719C29.8867 8.37492 29.9861 8.62273 30.1643 8.80901C30.3424 8.99528 30.5855 9.10567 30.843 9.11719Z"
                fill="#1E7EA1"
            />
        </svg>
    );
}
