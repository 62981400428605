import clsx from 'clsx';
import { useStyles } from './utils';

export default function AzureWithCircleIcon(props = {}) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.25" y="0.25" width="47.5" height="47.5" rx="23.75" fill="white" />
            <rect
                x="0.25"
                y="0.25"
                width="47.5"
                height="47.5"
                rx="23.75"
                stroke="#CCCCCC"
                strokeWidth="0.5"
            />
            <path
                d="M19.7419 11H27.501L19.4466 34.8651C19.3638 35.1104 19.2061 35.3235 18.9958 35.4745C18.7855 35.6255 18.5331 35.7067 18.2743 35.7067H12.2359C12.0397 35.7067 11.8464 35.66 11.6719 35.5705C11.4974 35.481 11.3467 35.3512 11.2323 35.1918C11.1179 35.0325 11.0431 34.8482 11.0141 34.6543C10.9851 34.4603 11.0026 34.2622 11.0654 34.0764L18.5695 11.8416C18.6523 11.5963 18.81 11.3832 19.0203 11.2322C19.2306 11.0812 19.483 11 19.7419 11Z"
                fill="url(#paint0_linear_2143_6576)"
            />
            <path
                d="M31.0163 27.0073H18.7123C18.5979 27.0073 18.4862 27.0417 18.3917 27.106C18.2972 27.1704 18.2242 27.2617 18.1822 27.3681C18.1403 27.4745 18.1314 27.591 18.1566 27.7026C18.1818 27.8141 18.24 27.9155 18.3237 27.9935L26.2301 35.3726C26.4602 35.5874 26.7633 35.7069 27.078 35.7069H34.0448L31.0163 27.0073Z"
                fill="#0078D4"
            />
            <path
                d="M19.7426 11C19.4809 10.999 19.2258 11.0817 19.0144 11.2361C18.8031 11.3904 18.6466 11.6082 18.5678 11.8578L11.0754 34.0562C11.0085 34.2427 10.9875 34.4426 11.0142 34.639C11.0408 34.8353 11.1144 35.0224 11.2287 35.1843C11.3429 35.3462 11.4945 35.4782 11.6705 35.5691C11.8466 35.66 12.0419 35.7072 12.2401 35.7067H18.4344C18.6651 35.6655 18.8808 35.5637 19.0594 35.4119C19.2379 35.26 19.373 35.0634 19.4507 34.8422L20.9445 30.4392L26.2816 35.4169C26.5052 35.6019 26.7858 35.7043 27.076 35.7067H34.017L30.9726 27.0071L22.0982 27.0091L27.53 11H19.7426Z"
                fill="url(#paint1_linear_2143_6576)"
            />
            <path
                d="M29.6527 11.8403C29.5701 11.5954 29.4127 11.3826 29.2027 11.2318C28.9928 11.0811 28.7408 11 28.4823 11H19.835C20.0934 11 20.3454 11.0811 20.5554 11.2318C20.7654 11.3825 20.9228 11.5954 21.0055 11.8403L28.5101 34.0763C28.5728 34.2621 28.5904 34.4602 28.5614 34.6542C28.5323 34.8482 28.4575 35.0324 28.3431 35.1918C28.2287 35.3511 28.0781 35.4809 27.9036 35.5704C27.7291 35.66 27.5357 35.7067 27.3396 35.7067H35.987C36.1831 35.7067 36.3764 35.66 36.5509 35.5704C36.7254 35.4809 36.876 35.351 36.9904 35.1917C37.1048 35.0324 37.1796 34.8481 37.2086 34.6542C37.2376 34.4602 37.2201 34.2621 37.1574 34.0763L29.6527 11.8403Z"
                fill="url(#paint2_linear_2143_6576)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2143_6576"
                    x1="22.5699"
                    y1="12.8308"
                    x2="14.5119"
                    y2="36.6362"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#114A8B" />
                    <stop offset="1" stopColor="#0669BC" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2143_6576"
                    x1="25.0879"
                    y1="23.9247"
                    x2="23.2238"
                    y2="24.5549"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopOpacity="0.3" />
                    <stop offset="0.071" stopOpacity="0.2" />
                    <stop offset="0.321" stopOpacity="0.1" />
                    <stop offset="0.623" stopOpacity="0.05" />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_2143_6576"
                    x1="24.0625"
                    y1="12.1363"
                    x2="32.9076"
                    y2="35.702"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3CCBF4" />
                    <stop offset="1" stopColor="#2892DF" />
                </linearGradient>
            </defs>
        </svg>
    );
}
