/* eslint-disable no-param-reassign */
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import { EMPTY_STRING } from 'constants/app.constants';
import Cache from 'tools/Cache';
import _parseInt from 'lodash/parseInt';
import _isNaN from 'lodash/isNaN';
import { v4 as uuidv4 } from 'uuid';
import { billingRatesObj } from 'types/BillingRates.types';

export const tget = (obj, path, defaultValue) => {
    const value = _get(obj, path, defaultValue);
    return _isNil(value) ? defaultValue : value;
};

// function that does nothing but return an empty string (used for default values)
export const getEmptyString = () => EMPTY_STRING;

// * this checks the existence of tessell-whoami in local storage
// * and if it did not have a prev value (i.e., not logged in) and then has a value (i.e., user has logged in) [LOGGING IN FLOW] [populating the cache]
export const isUserLoggedIn = (e) => e.key === 'tessell-whoami' && !e.oldValue && e.newValue;

// * this checks the existence of tessell-accessToken in local storage
// * and if it did have have a prev value (i.e., not logged in) and then does not have a value (i.e., user has logged in) [LOGGING OUT FLOW] [purging the cache]
export const isUserLoggedOut = (e) => e.key === 'tessell-accessToken' && e.oldValue && !e.newValue;

// this function take seconds and returns a string in the format of days, hours, minutes
export const secondsToDhm = (seconds) => {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
    const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
    const mDisplay = m > 0 ? m + (m === 1 ? ' min, ' : ' mins, ') : '';
    const str = dDisplay + hDisplay + mDisplay;

    if (str?.charAt(str.length - 2) === ',') {
        return str?.slice(0, -2);
    }
    return str;
};

export const getTessellAmount = (amount) => {
    const hideBillingCreditsUnit = Cache.get('hideBillingCreditsUnit') === 'true';

    const resolvedOptions = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).resolvedOptions();
    const currencyOptions = {
        minimumFractionDigits: resolvedOptions.minimumFractionDigits,
        maximumFractionDigits: resolvedOptions.maximumFractionDigits,
    };
    const value = hideBillingCreditsUnit
        ? amount.toLocaleString('en-US', currencyOptions)
        : amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

    return hideBillingCreditsUnit ? `${value} Credits` : value;
};

// TODO: check and fix this
export const getTessellAmountWithoutCreditSuffix = (amount) => {
    const hideBillingCreditsUnit = Cache.get('hideBillingCreditsUnit') === 'true';

    const resolvedOptions = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).resolvedOptions();

    const currencyOptions = {
        minimumFractionDigits: resolvedOptions.minimumFractionDigits,
        maximumFractionDigits: resolvedOptions.maximumFractionDigits,
    };

    const value = hideBillingCreditsUnit
        ? amount?.toLocaleString('en-US', currencyOptions)
        : amount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

    return hideBillingCreditsUnit ? `${value}` : value;
};

export const filterUniqueObjectsFromObjectArray = (arr, field) => {
    const obj = {};
    return arr.filter((element) => {
        if (obj[element[field]]) {
            return false;
        }
        obj[element[field]] = 1;
        return true;
    });
};

export const isProdTenant = (customerType) => {
    // Check if its actually a dev env hosted on tessell.com
    let isProdFromMetaData = customerType !== 'INTERNAL';

    if (window.location.hostname.includes('localhost')) {
        isProdFromMetaData = false;
    }

    return isProdFromMetaData;
};

export const isProdEnv = () => {
    if (process.env.REACT_APP_CUSTOMISED_API_URL) {
        return true;
    }

    return window?.location?.hostname?.endsWith('tessell.com');
};

export const isStageEnv = () => {
    const isStage = window?.location?.hostname?.endsWith('stage.com');
    return isStage;
};

export const getCostPerUnit = (isBYOA: boolean, costObj: billingRatesObj) =>
    (isBYOA ? costObj?.costPerUnitBYOC : costObj?.costPerUnit) || 0;

export const getParsedIntegerValue = (value) => {
    if (_isNil(value)) return 0; // handles null or undefined case

    const num = _parseInt(value);

    if (_isNaN(num)) {
        return 0;
    }

    return num;
};

export const getRandomCombinationAlphaNumeric = (length) => {
    const maxUUIDv4length = 36;
    return uuidv4().substring(0, Math.min(length, maxUUIDv4length));
};
