/* eslint-disable react/react-in-jsx-scope */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function DBParametersIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.05"
                y="-0.05"
                width="23.9"
                height="23.9"
                rx="1.95"
                transform="matrix(1 0 0 -1 0 23.9)"
                fill="url(#pattern0)"
                stroke="black"
                strokeWidth="0.1"
            />
            <path
                d="M21.4989 23.9998H2.49994C1.122 23.9998 0 22.8778 0 21.4989V2.49994C0 1.122 1.122 0 2.49994 0H21.4989C22.878 0 24 1.122 24 2.49994V21.4989C24 22.878 22.878 24 21.4991 24L21.4989 23.9998ZM2.49994 0.999727C1.67293 0.999727 0.999941 1.67271 0.999941 2.49972V21.4987C0.999941 22.3259 1.67293 22.9998 2.49994 22.9998H21.4989C22.3261 22.9998 23.0001 22.3258 23.0001 21.4989V2.4999C23.0001 1.67288 22.326 0.999898 21.4991 0.999898L2.49994 0.999727Z"
                fill="var(--icon-color)"
            />
            <path
                d="M5.49991 14.4998L5.49991 4.19978C5.49991 4.08932 5.58945 3.99978 5.69991 3.99978L6.29985 3.99978C6.41031 3.99978 6.49985 4.08932 6.49985 4.19978L6.49985 14.4998L5.49991 14.4998Z"
                fill="var(--icon-color)"
            />
            <path
                d="M5.69991 20C5.58945 20 5.49991 19.9105 5.49991 19.8L5.49991 17L6.49985 17L6.49985 19.8C6.49985 19.9105 6.41031 20 6.29985 20L5.69991 20Z"
                fill="var(--icon-color)"
            />
            <path
                d="M11.6999 20C11.5895 20 11.4999 19.9105 11.4999 19.8L11.4999 13.0002L12.4998 13.0002L12.4998 16.5001L12.4998 19.8C12.4998 19.9105 12.4103 20 12.2998 20L11.6999 20Z"
                fill="var(--icon-color)"
            />
            <path
                d="M11.4999 10.5L11.4999 4.19987C11.4999 4.08941 11.5895 3.99987 11.6999 3.99987L12.2998 3.99987C12.4103 3.99987 12.4998 4.08941 12.4998 4.19987L12.4998 10.5L11.4999 10.5Z"
                fill="var(--icon-color)"
            />
            <path
                d="M8.00014 15.9999C8.00014 17.103 7.10314 18 6.00008 18C4.89703 18 4.00003 17.103 4.00003 15.9999C4.00003 14.8969 4.89703 13.9999 6.00008 13.9999C7.10314 14.0001 8.00014 14.8971 8.00014 15.9999ZM5.00014 15.9999C5.00014 16.551 5.44916 17.0001 6.00008 17.0001C6.55101 17.0001 7.00002 16.551 7.00002 16.0001C7.00002 15.4492 6.55101 15.0002 6.00008 15.0002C5.44916 15 5.00014 15.449 5.00014 15.9999Z"
                fill="var(--icon-color)"
            />
            <path
                d="M17.6999 20C17.5895 20 17.4999 19.9105 17.4999 19.8L17.4999 8.99988L18.4999 8.99988L18.4999 19.8C18.4999 19.9105 18.4103 20 18.2999 20L17.6999 20Z"
                fill="var(--icon-color)"
            />
            <path
                d="M17.4999 6.5L17.4999 4.20006C17.4999 4.08961 17.5895 4.00006 17.6999 4.00006L18.2999 4.00006C18.4103 4.00006 18.4999 4.08961 18.4999 4.20006L18.4999 6.5L17.4999 6.5Z"
                fill="var(--icon-color)"
            />
            <path
                d="M20.0001 8.00019C20.0001 9.10325 19.1031 10.0002 18.0001 10.0002C16.897 10.0002 16 9.10325 16 8.00019C16 6.89714 16.897 6.00014 18.0001 6.00014C19.1031 6.00014 20.0001 6.89714 20.0001 8.00019ZM17.0001 8.00019C17.0001 8.55215 17.4481 9.00013 18.0001 9.00013C18.552 9.00013 19 8.55215 19 8.00019C19 7.44823 18.552 7.00025 18.0001 7.00025C17.4481 7.00008 17.0001 7.44806 17.0001 8.00019Z"
                fill="var(--icon-color)"
            />
            <path
                d="M14.0001 11.9999C14.0001 13.103 13.1031 14 12.0001 14C10.897 14 10 13.103 10 11.9999C10 10.8969 10.897 9.99989 12.0001 9.99989C13.1031 9.99989 14.0001 10.8969 14.0001 11.9999ZM11.0001 11.9999C11.0001 12.5509 11.4492 12.9999 12.0001 12.9999C12.551 12.9999 13 12.5509 13 11.9999C13 11.449 12.551 11 12.0001 11C11.4492 11 11.0001 11.449 11.0001 11.9999Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
