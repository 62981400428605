/* eslint-disable no-param-reassign */

import {
    AvgLoadMetricDatapoint,
    AvgLoadMetricType,
    AvgServiceLoadResponseType,
} from 'pages/dbServices/my-services/monitoring/components/avgDbLoad/types/AvgServiceLoadTypes';
import {
    decreaseValueXPercent,
    getRandomNumberInRange,
    getUtcStringDifferenceInMilliseconds,
    graphLineType,
    increaseValueXPercent,
    isWeekdayWorkingHours,
    isWeekend,
    personasEnum,
    randomPlusMinusXPercent,
    spikeInGraphTitles,
    valueRangeForUnitMap,
    zeroValueTitles,
} from './myserviceutil';
import { MockChartTypeData, avgDbLoadLegendLabelGetter } from './avgDbLoadSlicedEntityName';
import moment from 'moment';

type GetAvgDbLoadDataProps = {
    requestBody: Partial<AvgServiceLoadResponseType>;
};

export const getAvgDbLoadData = (props: GetAvgDbLoadDataProps): AvgServiceLoadResponseType => {
    const { requestBody } = props;
    const { startTime, endTime, serviceInstanceId, entityId, sliceBy } = requestBody;
    const { metricType } = sliceBy;

    const differenceInMilliseconds = getUtcStringDifferenceInMilliseconds(startTime, endTime);

    const timeNow = moment(endTime).valueOf();
    const startTimeCal = timeNow - differenceInMilliseconds;

    let diffInTime = 30000;

    if (differenceInMilliseconds <= 3600000) {
        diffInTime = 90000;
    } else if (differenceInMilliseconds <= 21600000) {
        diffInTime = 270000;
    } else if (differenceInMilliseconds <= 43200000) {
        diffInTime = 7000000;
    } else if (differenceInMilliseconds <= 86400000) {
        diffInTime = 7000000;
    } else {
        diffInTime = 14000000;
    }

    let valueRangeForUnit = valueRangeForUnitMap.count;

    const metricDistribution = avgDbLoadLegendLabelGetter[metricType]().map((entity) => {
        const { entityName, chartType, initialValue } = entity;
        valueRangeForUnit =
            chartType === MockChartTypeData.ZigZag
                ? decreaseValueXPercent(initialValue, getRandomNumberInRange(70, 80))
                : initialValue;

        return {
            slicedEntityName: entityName,
            metricValues: getMetricValues({
                timeNow,
                valueRangeForUnit,
                startTime: startTimeCal,
                diffInTime,
                chartType,
            }),
        } as AvgLoadMetricType;
    });

    const totalDistribution = metricDistribution?.reduce(
        (agg, curr, currIdx) => {
            if (!currIdx) return agg;

            agg.metricValues = curr?.metricValues?.map((dp, idx) => ({
                timestamp: dp.timestamp,
                value: agg.metricValues[idx].value + dp.value,
            }));

            return agg;
        },
        {
            slicedEntityName: 'Total',
            metricValues: metricDistribution?.[0]?.metricValues,
        },
    );
    metricDistribution.unshift(totalDistribution);
    return {
        startTime,
        endTime,
        serviceInstanceId,
        entityId,
        sliceBy,
        metricDistribution,
    };
};

const getMetricValues = (props: {
    timeNow: number;
    valueRangeForUnit: number;
    startTime: number;
    diffInTime: number;
    chartType?: MockChartTypeData;
}) => {
    const { timeNow, valueRangeForUnit, startTime, diffInTime, chartType } = props;
    const generatedArr: { timestamp: number; value: number }[] = [];

    // if want to start spikes from one point put 0 instead of random
    // let spikeTimer = getRandomNumberInRange(0, 2);
    let datapointValue = valueRangeForUnit;

    let dpTime = timeNow;

    const totalDp = ((timeNow - startTime) * 2) / diffInTime + 1;
    let currentDpNum = 1;
    let spikeTimer = getRandomNumberInRange(0, 3);

    // it will go every diffInTime back till it gets startTime (deduced from API)
    while (dpTime >= startTime) {
        if (chartType === MockChartTypeData.ReachMaxima) {
            // Calculate the slope value based on the position in the dataset
            const slope = Math.cos((currentDpNum / totalDp) * Math.PI);
            const incrementValue = (slope * Math.PI * Math.E) / 3;
            datapointValue = increaseValueXPercent(datapointValue, incrementValue);
            currentDpNum += 1;
        } else if (chartType === MockChartTypeData.AlmostStagnant) {
            // for randomly increasing value to show SPIKE in graph
            if (spikeTimer % 20 === 0) {
                datapointValue = increaseValueXPercent(
                    datapointValue,
                    getRandomNumberInRange(1, 2),
                );
            } else datapointValue = randomPlusMinusXPercent(datapointValue, 1);
        } else {
            datapointValue = randomPlusMinusXPercent(valueRangeForUnit, 10);
        }

        const newDatapoint = { timestamp: dpTime / 1000, value: datapointValue };
        generatedArr.push(newDatapoint);
        dpTime -= diffInTime / 2;

        spikeTimer += 1;
    }

    return generatedArr;
};
