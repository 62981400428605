// eslint-disable-next-line import/no-unresolved
import DefaultComputeLoader from './default/computeloader';
import UBSComputeLoader from './UBS/computeloader';
import MMCComputeLoader from './MMC/computeloader';
import FMRComputeLoader from './FMR/computeloader';

const tenantUrl = window.location.hostname;

const getDataLoaders = () => {
    let computeloader;

    switch (tenantUrl) {
        case 'localhost':
        case 'ubs.tsl-terls.cloud':
        case 'ubs-demo.tessell.com':
        case 'ubs-demo.azure.tessell.com':
        case 'demo.azure.tessell.com':
        case 'msftgsi.azure.tessell.com':
            computeloader = new UBSComputeLoader();
            break;
        case 'mmc-demo.tessell.com':
        case 'mmc.tsl-terls.cloud':
        case 'mmc-demo.aws.tessell.com':
        case 'demo.aws.tessell.com':
            computeloader = new MMCComputeLoader();
            break;
        case 'fmr-demo.tessell.com':
        case 'fmr.demo.tessell.com':
        case 'fmr.tsl-terls.cloud':
            computeloader = new FMRComputeLoader();
            break;
        default:
            computeloader = new DefaultComputeLoader();
            break;
    }

    return {
        computeloader,
    };
};

const { computeloader } = getDataLoaders();

export default class ComputeData {
    // eslint-disable-next-line class-methods-use-this
    getComputes() {
        // page-size=100&include-availability=true
        return computeloader.initComputes();
    }
}
