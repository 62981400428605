import { createSlice } from '@reduxjs/toolkit';

/**
 * catalogList: Array of catalog
 * benchmarks: List of Benchmarks
 */

export const benchmarksStore = createSlice({
    name: 'benchmarksStore',
    initialState: {
        value: {},
    },
    reducers: {
        update: (state, action) => {
            // eslint-disable-next-line no-param-reassign
            state.value = { ...state.value, ...action.payload };
        },
    },
});

// Action creators are generated for each case reducer function
export const { update } = benchmarksStore.actions;

export default benchmarksStore.reducer;
