import clsx from 'clsx';
import { useStyles } from './utils';

export default function FailedServiceIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2363_4557)">
                <ellipse
                    cx="5.6001"
                    cy="2.62498"
                    rx="5.25"
                    ry="2.275"
                    stroke="#EC373C"
                    strokeWidth="0.7"
                />
                <path
                    d="M0.350098 6.29999C0.350098 7.36485 2.33155 8.24459 4.9001 8.38148"
                    stroke="#EC373C"
                    strokeWidth="0.7"
                    strokeLinecap="round"
                />
                <path
                    d="M0.350098 2.79999V9.99599C0.350098 11.1389 2.33155 12.0832 4.9001 12.2301M10.8501 2.79999V4.89999"
                    stroke="#EC373C"
                    strokeWidth="0.7"
                    strokeLinecap="round"
                />
                <path
                    d="M9.79981 6.29999C7.86681 6.29999 6.29981 7.86694 6.29981 9.79999C6.29981 11.733 7.86681 13.3 9.79981 13.3C11.7328 13.3 13.2998 11.733 13.2998 9.79999C13.2998 7.86694 11.7328 6.29999 9.79981 6.29999Z"
                    stroke="#EC373C"
                    strokeWidth="0.7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.7998 8.04999V9.79999"
                    stroke="#EC373C"
                    strokeWidth="0.7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.7998 11.1125C9.92061 11.1125 10.0186 11.2104 10.0186 11.3312C10.0186 11.452 9.92061 11.55 9.7998 11.55"
                    stroke="#EC373C"
                    strokeWidth="0.7"
                />
                <path
                    d="M9.7998 11.1125C9.679 11.1125 9.58105 11.2104 9.58105 11.3312C9.58105 11.452 9.679 11.55 9.7998 11.55"
                    stroke="#EC373C"
                    strokeWidth="0.7"
                />
            </g>
            <defs>
                <clipPath id="clip0_2363_4557">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
