export default function ExclamationWarningIconWithCircleContainer() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_137_8187)">
                <path
                    d="M8 11.5C7.86193 11.5 7.75 11.3881 7.75 11.25C7.75 11.1119 7.86193 11 8 11"
                    stroke="#F29132"
                />
                <path
                    d="M8 11.5C8.13807 11.5 8.25 11.3881 8.25 11.25C8.25 11.1119 8.13807 11 8 11"
                    stroke="#F29132"
                />
                <path d="M8 9V3.5" stroke="#F29132" strokeMiterlimit="10" strokeLinecap="round" />
                <path
                    d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85787 12.1421 0.5 8 0.5C3.85787 0.5 0.5 3.85787 0.5 8C0.5 12.1421 3.85787 15.5 8 15.5Z"
                    stroke="#F29132"
                    strokeMiterlimit="10"
                />
            </g>
            <defs>
                <clipPath id="clip0_137_8187">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
