export const ORACLE = 'ORACLE';
export const POSTGRESQL = 'POSTGRESQL';
export const MYSQL = 'MYSQL';
export const SQLSERVER = 'SQLSERVER';
export const MONGODB = 'MONGODB';
export const MILVUS = 'MILVUS';

export const DATABASE_TYPE = {
    Oracle: ORACLE,
    Postgresql: POSTGRESQL,
    MySQL: MYSQL,
    SQLServer: SQLSERVER,
    MongoDB: MONGODB,
    MILVUS,
};

export const DATABASE_EDITION = Object.freeze({
    STANDARD: 'STANDARD',
    ENTERPRISE: 'ENTERPRISE',
    COMMUNITY: 'COMMUNITY',
    EVALUATION: 'EVALUATION',
    DEVELOPER: 'DEVELOPER',
    EXPRESS: 'EXPRESS',
});

export const DATABASE_EDITION_TO_LABEL_MAP = {
    [DATABASE_EDITION.STANDARD]: 'Standard',
    [DATABASE_EDITION.ENTERPRISE]: 'Enterprise',
    [DATABASE_EDITION.COMMUNITY]: 'Community',
    [DATABASE_EDITION.EVALUATION]: 'Evaluation',
    [DATABASE_EDITION.DEVELOPER]: 'Developer',
    [DATABASE_EDITION.EXPRESS]: 'Express',
};


export const SQL_SERVER_EDITIONS = Object.freeze([
    DATABASE_EDITION.EVALUATION,
    DATABASE_EDITION.STANDARD,
    DATABASE_EDITION.ENTERPRISE,
    DATABASE_EDITION.DEVELOPER,
]);

export const ORACLE_EDITIONS = Object.freeze([
    DATABASE_EDITION.STANDARD,
    DATABASE_EDITION.ENTERPRISE,
]);

export const RELATIONAL_DATABASES = Object.freeze([
    DATABASE_TYPE.Oracle,
    DATABASE_TYPE.Postgresql,
    DATABASE_TYPE.MySQL,
    DATABASE_TYPE.SQLServer,
]);

// Vector DB constants
export const VECTOR_DATABASES = Object.freeze([MILVUS]);

export const VECTOR_DATABASE_TYPE = {
    MILVUS,
};

export const MILVUS_EDITIONS = Object.freeze({
    COMMUNITY: 'COMMUNITY',
    ENTERPRISE: 'ENTERPRISE',
});

export const COMPUTE_TYPES = Object.freeze({
    PERFORMANCE: 'High Performance',
    STANDARD: 'Standard',
});

//  NoSql DB constants
export const NOSQL_DATABASES = Object.freeze([MONGODB]);
export const NOSQL_DATABASE_TYPE = {
    MONGODB,
};

const RELATIONAL_DB_FAMILY = 'relationalDatabase';
const VECTOR_DB_FAMILY = 'vectorDatabase';
const NOSQL_DB_FAMILY = 'nosqlDatabase';

export const ENGINE_FAMILIES = {
    ...RELATIONAL_DATABASES?.reduce((acc, ele) => {
        acc[ele] = RELATIONAL_DB_FAMILY;
        return acc;
    }, {}),

    ...VECTOR_DATABASES?.reduce((acc, ele) => {
        acc[ele] = VECTOR_DB_FAMILY;
        return acc;
    }, {}),
    ...NOSQL_DATABASES?.reduce((acc, ele) => {
        acc[ele] = NOSQL_DB_FAMILY;
        return acc;
    }, {}),
};
