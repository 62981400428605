import ratesJson from './rates.json';
import totalCreditsJson from './totalcredits.json';
import defaultPaymentMethodJson from './paymentmethod-default.json';
import billingProfileJson from './billingprofile.json';

import myServicesDataJson from '../../../DbServices/myservices/FMR/myservices.json';
import AvailabilityMachinesSnapshotsAutomatedFalse from '../../../DbServices/availabilitymachines/FMR/snapshots-automated=false.json';
import AvailabilityMachinesBackups from '../../../DbServices/availabilitymachines/FMR/availabilitymachine-backups-automated=false.json';
import AvailabilityMachinesSanitizatiedSnapshots from '../../../DbServices/availabilitymachines/FMR/availabilitymachines-sanitized-snapshots.json';

import { v4 as uuidv4 } from 'uuid';

import SampleBillSystemGenerated from './SampleBillSystemGenerated.json';
import SampleMeterRes from './SampleMeterRes.json';
import SampleInvoiceGenerator from './SampleInvoiceGenerator.json';
import {
    generateHourlyIntervals,
    isDateInCurrentMonth,
    isRateNeedToBeCalculated,
    isRateNeedToBeCalculatedWeekday,
    isoStringToFormattedUTC,
    modifyMeter,
    modifySystemBillSystemGenerated,
} from '../utils';
import { personasEnum } from 'api-clients/network-layer/demo-data/repository/DbServices/myservices/common/myserviceutil';

export default class FMRBillingLoader {
    // eslint-disable-next-line class-methods-use-this
    initRates() {
        return ratesJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initTotalCredits() {
        return totalCreditsJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initGetMeterRes(start, end) {
        const meterModified = modifyMeter(
            SampleMeterRes,
            myServicesDataJson,
            AvailabilityMachinesSnapshotsAutomatedFalse,
            AvailabilityMachinesBackups,
            AvailabilityMachinesSanitizatiedSnapshots,
            ratesJson,
        );
        const filteredData = meterModified.filter((obj) => {
            const objStartDate = new Date(obj.startDate);
            const objEndDate = new Date(obj.endDate);
            const rangeStartDate = new Date(start);
            const rangeEndDate = new Date(end);

            return objStartDate >= rangeStartDate && objEndDate <= rangeEndDate;
        });

        return filteredData;
    }

    // eslint-disable-next-line class-methods-use-this
    initGetMeterResShortDuration(start, end, dbId, cycle) {
        const meterModified = modifyMeter(
            SampleMeterRes,
            myServicesDataJson,
            AvailabilityMachinesSnapshotsAutomatedFalse,
            AvailabilityMachinesBackups,
            AvailabilityMachinesSanitizatiedSnapshots,
            ratesJson,
        );

        if (cycle === 'HOURLY') {
            const meterDailyRes = [];

            const serviceData = myServicesDataJson;
            const servicesData = [
                ...serviceData[personasEnum.ACCOUNT_OWNER].response,
                ...serviceData[personasEnum.DATA_OWNER].response,
            ];

            servicesData
                .filter((serviceData) => serviceData.id === dbId)
                .forEach((serviceData) => {
                    generateHourlyIntervals(start, end).forEach((hourlyData) => {
                        if (
                            isRateNeedToBeCalculated(serviceData.owner, hourlyData.startDate) &&
                            isRateNeedToBeCalculatedWeekday(
                                serviceData.owner,
                                new Date(hourlyData.startDate),
                            )
                        ) {
                            const meterRes = JSON.parse(JSON.stringify(SampleMeterRes));
                            meterRes.id = uuidv4();
                            meterRes.startDate = isoStringToFormattedUTC(hourlyData.startDate);
                            meterRes.endDate = isoStringToFormattedUTC(hourlyData.endDate);
                            meterRes.cost = 1 * 0.64 * serviceData?.numOfInstances ?? 1;
                            meterRes.meterHour = 1;
                            meterRes.duration = 'HOURLY';
                            // from service data
                            meterRes.entityId = serviceData.id;
                            meterRes.serviceId = serviceData.id;
                            meterRes.subscriptionName = serviceData.subscription;
                            meterRes.entityName = serviceData.name;
                            meterRes.serviceName = serviceData.name;
                            meterRes.availabilityMachineId = serviceData.availabilityMachineId;
                            meterRes.availabilityMachineName = serviceData.name;
                            meterRes.engineType = serviceData.engineType;
                            meterRes.userName = serviceData.owner;

                            meterDailyRes.push(meterRes);
                        }
                    });
                });

            return meterDailyRes;
        }
        if (cycle === 'DAILY') {
            const filteredData = meterModified.filter((obj) => {
                const objStartDate = new Date(obj.startDate);
                const objEndDate = new Date(obj.endDate);
                const rangeStartDate = new Date(start);
                const rangeEndDate = new Date(end);

                return (
                    objStartDate >= rangeStartDate &&
                    objEndDate <= rangeEndDate &&
                    dbId === obj.entityId &&
                    isRateNeedToBeCalculated(obj.userName, obj.startDate)
                );
            });

            return filteredData;
        }

        return meterModified;
    }

    // eslint-disable-next-line class-methods-use-this
    initDefaultPaymentMethod() {
        return defaultPaymentMethodJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initBillsSystemGeneratedTrue() {
        const billModified = modifySystemBillSystemGenerated(
            SampleBillSystemGenerated,
            SampleMeterRes,
            myServicesDataJson,
            AvailabilityMachinesSnapshotsAutomatedFalse,
            AvailabilityMachinesBackups,
            AvailabilityMachinesSanitizatiedSnapshots,
            ratesJson,
        );
        return billModified;
    }

    // eslint-disable-next-line class-methods-use-this
    initBillingProfileJson() {
        return billingProfileJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initInvoicesJson() {
        const billModified = modifySystemBillSystemGenerated(
            SampleBillSystemGenerated,
            SampleMeterRes,
            myServicesDataJson,
            AvailabilityMachinesSnapshotsAutomatedFalse,
            AvailabilityMachinesBackups,
            AvailabilityMachinesSanitizatiedSnapshots,
            ratesJson,
        );

        const generatedInvoices = billModified
            .filter((bill) => bill.totalCharges > 0 && !isDateInCurrentMonth(bill.startDate))
            .map((bill, idx) => {
                const sampleInvoice = JSON.parse(JSON.stringify(SampleInvoiceGenerator));
                sampleInvoice.id = uuidv4();
                sampleInvoice.number += idx;
                sampleInvoice.subTotal = bill.totalCharges;
                sampleInvoice.total = bill.totalCharges / 2;
                sampleInvoice.discountAmount = bill.totalCharges / 2;
                sampleInvoice.amountDue = bill.totalCharges / 2;
                sampleInvoice.discountPercentage = 50;
                sampleInvoice.dateCreated = bill.startDate;
                sampleInvoice.metadata.billStartDate = bill.startDate;
                sampleInvoice.metadata.billEndDate = bill.endDate;
                return sampleInvoice;
            });

        return generatedInvoices;
    }
}
