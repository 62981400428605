import clsx from 'clsx';
import { useStyles } from './utils';

export default function StopServiceIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="8" height="8" rx="2" fill="#F29132" />
        </svg>
    );
}
