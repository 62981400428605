import clsx from 'clsx';
import { useStyles } from './utils';

export default function SummaryIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            width="18"
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx({ [className]: !!className })}
            style={iconStyle}
        >
            <g id="Billing Summary">
                <path
                    id="Vector 445"
                    d="M11.8913 0.785156H2.5C1.67157 0.785156 1 1.45673 1 2.28516V19.7568C1 20.5853 1.67157 21.2568 2.5 21.2568H15.7067C16.5352 21.2568 17.2067 20.5853 17.2067 19.7568V5.87586C17.2067 5.61951 17.1083 5.37296 16.9317 5.18711L13.3413 1.40766C12.9637 1.0102 12.4395 0.785156 11.8913 0.785156Z"
                    stroke="var(--icon-color)"
                />
                <path
                    id="Vector 456"
                    d="M3.55908 11.0212L4.75899 9.69635C4.79491 9.65669 4.85568 9.65233 4.89689 9.68646L6.43268 10.9583C6.47483 10.9932 6.5372 10.9877 6.57265 10.946L8.8111 8.31272C8.84908 8.26805 8.91716 8.26548 8.95839 8.30717L10.6491 10.0166C10.6891 10.057 10.7547 10.056 10.7935 10.0143L14.616 5.90332M14.616 5.90332H11.9866M14.616 5.90332L14.6479 8.22965"
                    stroke="var(--icon-color)"
                    strokeWidth="0.7"
                    strokeLinecap="round"
                />
                <g id="Group 417">
                    <path
                        id="Rectangle 350"
                        d="M3.55908 13.7934C3.55908 13.7381 3.60385 13.6934 3.65908 13.6934H5.0432C5.09843 13.6934 5.1432 13.7381 5.1432 13.7934V18.5975C5.1432 18.6528 5.09843 18.6975 5.0432 18.6975H3.65908C3.60385 18.6975 3.55908 18.6528 3.55908 18.5975L3.55908 13.7934Z"
                        fill="var(--icon-color)"
                    />
                    <path
                        id="Rectangle 351"
                        d="M5.93506 15.1586C5.93506 15.1034 5.97983 15.0586 6.03506 15.0586H7.41918C7.47441 15.0586 7.51918 15.1034 7.51918 15.1586V18.598C7.51918 18.6532 7.47441 18.698 7.41918 18.698H6.03506C5.97983 18.698 5.93506 18.6532 5.93506 18.598V15.1586Z"
                        fill="var(--icon-color)"
                    />
                    <path
                        id="Rectangle 352"
                        d="M8.31152 12.8842C8.31152 12.829 8.35629 12.7842 8.41152 12.7842H9.79564C9.85087 12.7842 9.89564 12.829 9.89564 12.8842V18.5983C9.89564 18.6536 9.85087 18.6983 9.79564 18.6983H8.41152C8.35629 18.6983 8.31152 18.6536 8.31152 18.5983V12.8842Z"
                        fill="var(--icon-color)"
                    />
                    <rect
                        id="Rectangle 353"
                        x="10.688"
                        y="15.0586"
                        width="1.58412"
                        height="3.63941"
                        rx="0.1"
                        fill="var(--icon-color)"
                    />
                    <path
                        id="Rectangle 354"
                        d="M13.064 11.974C13.064 11.9188 13.1087 11.874 13.164 11.874H14.5481C14.6033 11.874 14.6481 11.9188 14.6481 11.974V18.5979C14.6481 18.6531 14.6033 18.6979 14.5481 18.6979H13.164C13.1087 18.6979 13.064 18.6531 13.064 18.5979V11.974Z"
                        fill="var(--icon-color)"
                    />
                </g>
            </g>
        </svg>
    );
}
