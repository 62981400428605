/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './utils';

export default function ExclamationIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 18 18"
            width="16"
            height="16"
            xmlSpace="preserve"
            fill="none"
            // xversion="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M10 6.889V10m0 3.111h.008M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                stroke="#B91C1C"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
