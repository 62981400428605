/* eslint-disable react/react-in-jsx-scope */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function DBIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Computer Database" clipPath="url(#clip0_11_37)">
                <g id="Group 877">
                    <path
                        id="Vector"
                        d="M9.91072 7.45536C15.0391 7.45536 19.1964 5.85637 19.1964 3.88393C19.1964 1.91148 15.0391 0.3125 9.91072 0.3125C4.78236 0.3125 0.625 1.91148 0.625 3.88393C0.625 5.85637 4.78236 7.45536 9.91072 7.45536Z"
                        fill="#1E7EA1"
                    />
                    <path
                        id="Subtract"
                        d="M19.1965 5.22141C19.1965 7.19385 15.0391 8.79283 9.91074 8.79283C4.78237 8.79283 0.625 7.19385 0.625 5.22141V9.68748C0.625 11.6589 4.78215 13.2589 9.91074 13.2589C15.0393 13.2589 19.1965 11.6589 19.1965 9.68748V5.22141Z"
                        fill="#1E7EA1"
                    />
                    <path
                        id="Subtract_2"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.1964 10.9375C19.1964 12.909 15.0393 14.509 9.91072 14.509C4.78214 14.509 0.625 12.909 0.625 10.9375V16.0268C0.625 17.9982 4.78214 19.5982 9.91072 19.5982C15.0393 19.5982 19.1964 17.9982 19.1964 16.0268V10.9375Z"
                        fill="#1E7EA1"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_11_37">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
