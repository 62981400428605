import MockRepository from 'api-clients/network-layer/demo-data/repository/MockRepository';

const computesData = MockRepository.getComputesData();

const Computes = {
    getComputesData() {
        return computesData.getComputes();
    },
};

export default Computes;
