/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function MaintenanceIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.3444 0.949597L3.02513 0.713549L2.72272 0.971394L0.704259 2.69239L0.429389 2.92675L0.565158 3.26118L1.92306 6.60603L2.01494 6.83236L2.25004 6.89877L4.29329 7.47589L7.71005 11.2116L16.8379 23.0369L16.9163 23.1384L17.0338 23.1896C18.4861 23.8223 20.8452 22.7712 21.0833 20.0297L21.1042 19.7887L20.9283 19.6229L10.1008 9.41647L6.72048 5.16903L6.42911 3.40562L6.3961 3.2058L6.23329 3.08543L3.3444 0.949597ZM1.62753 3.21873L3.06871 1.98994L5.47551 3.76935L5.75502 5.46101L5.77619 5.58909L5.857 5.69064L9.34039 10.0675L9.36261 10.0955L9.38858 10.12L20.0597 20.179C19.7859 22.0415 18.2994 22.5371 17.5422 22.312L8.48912 10.5836L8.47638 10.5671L8.46231 10.5517L4.93553 6.69572L4.83959 6.59082L4.70275 6.55217L2.75778 6.0028L1.62753 3.21873Z"
                fill="var(--icon-color)"
            />
            <path
                d="M19.1255 8.31321L23.2007 5.01557C23.4872 4.78371 23.9172 4.93659 23.9686 5.30247C24.7571 10.9175 19.3209 13.5869 15.6802 11.8713C15.4785 11.7762 15.2364 11.8056 15.0778 11.9615L4.44345 22.4166C4.38017 22.4788 4.301 22.5243 4.21371 22.5408C2.32394 22.8977 0.827729 20.725 1.30674 19.1311C1.33055 19.0519 1.37933 18.9831 1.44146 18.9286L12.7062 9.05011C12.829 8.9424 12.8901 8.77612 12.8743 8.61316C12.1957 1.58919 17.0667 0.437231 20.0088 1.19021C20.3495 1.27739 20.443 1.69202 20.2028 1.94775L16.7321 5.64354C16.5556 5.83148 16.5517 6.12348 16.7231 6.31728L18.4365 8.25461C18.6141 8.45546 18.9178 8.48129 19.1255 8.31321Z"
                stroke="var(--icon-color)"
                strokeLinecap="round"
            />
        </svg>
    );
}
