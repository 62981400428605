import clsx from 'clsx';
import { useStyles } from './utils';

export default function RefreshIconWithBackground(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect width="40" height="40" fill="#F2FAFE" rx="20" />
            <path
                fill="#fff"
                fillOpacity="0.01"
                d="M0 0H24V24H0z"
                transform="translate(8 8)"
                style={{ mixBlendMode: 'multiply' }}
            />
            <path
                fill="#1E7EA1"
                d="M17 15.5h-3.915A8.25 8.25 0 0128.25 20h1.5a9.75 9.75 0 00-17.25-6.24V11H11v6h6v-1.5zM23 24.5h3.915A8.25 8.25 0 0111.75 20h-1.5a9.75 9.75 0 0017.25 6.24V29H29v-6h-6v1.5z"
            />
        </svg>
    );
}
