import moment from 'moment';
import MyServicesJson from './myservices.json';
import ComputeServicesJson from './computeservices.json';
import FetchComputesMilvusJson from './fetchComputesMilvus.json';
import CollectionListJson from './collectionList.json';
import CollectionDetailJson from './collectionDetails.json';
import ComputeServiceIncludesTrueJson from './computeservices-includetrue.json';
import DatabaseServicesJson from './databaseservice.json';
import TaskProgressJson from './taskprogress.json';
import SampleCreateService from './samplecreateservice.json';
import TimeLineServices from './timelineservices.json';
import StartStopSchedule from './startstopschedule.json';
import EligibleUsersDbSharing from './eligibleusersdbsharing.json';
import EligibleUsersAmSharing from './eligibleusers-amsharing.json';
import SoftwareImagesJson from './softwareimages.json';
import ParameterProfileJson from './parametersprofile.json';
import OptionsProfileJson from './optionsprofile.json';
import FeaturesConfigJson from './feature-config.json';
import ConnectionPoolJson from './connection-pools.json';
import SampleDatasetsJson from './sample-datasets.json';
import SslCredsJson from './ssl-creds.json';
import VpcDetailsMyService from './vpcdetails-myservice.json';
import IntegrationTypesJson from './integration-types.json';
import GovernanceIntegration from './governance-integrations.json';
import MetricsJsonWithTime from './metrics.json';
import LogfilesJson from './logfiles.json';
import StreamJson from './stream.json';
import MaintainceWindowsJson from './maintenance-windows.json';

import Cache from 'tools/Cache';
import replaceTimestampWithMockDate from 'tools/MockTime';

import {
    personasEnum,
    modifyTaskProgress,
    modifyNewProvisionedService,
} from '../common/myserviceutil';
import { getMonitoringData } from '../common/getMonitoringData';
import { getAvgDbLoadData } from '../common/getAvgDbLoadData';
import { getExtendedPerfMetricsData, getTopDimensionsData } from '../common/getTopDimensionsData';

// replacing date with Mock Date
MyServicesJson = replaceTimestampWithMockDate(MyServicesJson);
ComputeServicesJson = replaceTimestampWithMockDate(ComputeServicesJson);
ComputeServiceIncludesTrueJson = replaceTimestampWithMockDate(ComputeServiceIncludesTrueJson);
DatabaseServicesJson = replaceTimestampWithMockDate(DatabaseServicesJson);
TaskProgressJson = replaceTimestampWithMockDate(TaskProgressJson);
SampleCreateService = replaceTimestampWithMockDate(SampleCreateService);
TimeLineServices = replaceTimestampWithMockDate(TimeLineServices);
StartStopSchedule = replaceTimestampWithMockDate(StartStopSchedule);
EligibleUsersDbSharing = replaceTimestampWithMockDate(EligibleUsersDbSharing);
EligibleUsersAmSharing = replaceTimestampWithMockDate(EligibleUsersAmSharing);
SoftwareImagesJson = replaceTimestampWithMockDate(SoftwareImagesJson);
ParameterProfileJson = replaceTimestampWithMockDate(ParameterProfileJson);
OptionsProfileJson = replaceTimestampWithMockDate(OptionsProfileJson);
FeaturesConfigJson = replaceTimestampWithMockDate(FeaturesConfigJson);
ConnectionPoolJson = replaceTimestampWithMockDate(ConnectionPoolJson);
SampleDatasetsJson = replaceTimestampWithMockDate(SampleDatasetsJson);
SslCredsJson = replaceTimestampWithMockDate(SslCredsJson);
VpcDetailsMyService = replaceTimestampWithMockDate(VpcDetailsMyService);
IntegrationTypesJson = replaceTimestampWithMockDate(IntegrationTypesJson);
GovernanceIntegration = replaceTimestampWithMockDate(GovernanceIntegration);
MetricsJsonWithTime = replaceTimestampWithMockDate(MetricsJsonWithTime);
LogfilesJson = replaceTimestampWithMockDate(LogfilesJson);
StreamJson = replaceTimestampWithMockDate(StreamJson);

export default class UBSMyServicesLoader {
    // eslint-disable-next-line class-methods-use-this
    initMyServices() {
        const myServicesNewlyProvisioned = JSON.parse(sessionStorage.getItem('demo-my-services'));

        let myServicesJsonData = MyServicesJson[personasEnum.ACCOUNT_OWNER];
        const persona = Cache.get('persona');

        if (persona === personasEnum.DATA_OWNER) {
            myServicesJsonData = MyServicesJson[personasEnum.DATA_OWNER];
        }

        const myServicesJsonResp =
            persona === personasEnum.DATA_OWNER
                ? myServicesJsonData.response
                : [
                      ...myServicesJsonData.response,
                      ...MyServicesJson[personasEnum.DATA_OWNER].response,
                  ];

        if (!myServicesNewlyProvisioned) {
            return { ...myServicesJsonData, response: [...myServicesJsonResp] };
        }

        const newMyServicesJson = {
            ...myServicesJsonData,
            response: [...myServicesJsonResp, ...myServicesNewlyProvisioned],
        };

        return newMyServicesJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initComputeServices() {
        return ComputeServicesJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initFetchComputesMilvus() {
        return FetchComputesMilvusJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initSoftwareImages() {
        return SoftwareImagesJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initComputeServicesIncludeTrue() {
        return ComputeServiceIncludesTrueJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initDatabaseServices(id) {
        const myServicesNewlyProvisioned = JSON.parse(sessionStorage.getItem('demo-my-services'));

        if (myServicesNewlyProvisioned) {
            const newMyServiceProvisioned = myServicesNewlyProvisioned.filter((el) => el.id === id);
            if (newMyServiceProvisioned.length > 0) {
                return newMyServiceProvisioned?.[0];
            }
        }

        // returning data for respective id
        return DatabaseServicesJson[id];
    }

    // eslint-disable-next-line class-methods-use-this
    initTaskProgressServices(id) {
        const modifiedTaskProgress = modifyTaskProgress(id, TaskProgressJson);
        return modifiedTaskProgress;
    }

    // eslint-disable-next-line class-methods-use-this
    initTimeLineForServices(id) {
        return TimeLineServices[id];
    }

    // eslint-disable-next-line class-methods-use-this
    initStartStopScheduleServices(id) {
        return StartStopSchedule[id];
    }

    // eslint-disable-next-line class-methods-use-this
    initEligibleUsersDbServices() {
        return EligibleUsersDbSharing;
    }

    // eslint-disable-next-line class-methods-use-this
    initEligibleUsersAmServices() {
        return EligibleUsersAmSharing;
    }

    // eslint-disable-next-line class-methods-use-this
    initParameterProfileServices() {
        return ParameterProfileJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initOptionsProfileServices() {
        return OptionsProfileJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initFeaturesConfigServices() {
        return FeaturesConfigJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initConnectionPoolsServices() {
        return ConnectionPoolJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initSampleDatasetsServices() {
        return SampleDatasetsJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initIntegrationTypesServices() {
        return IntegrationTypesJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initGovernanceIntegrationServices() {
        return GovernanceIntegration;
    }

    // eslint-disable-next-line class-methods-use-this
    initLogfilesForServices(url) {
        const id = url.split('entity-id=')[1].split('&service-instance-id=')[0];

        // Get the current date
        const currentDate = moment();

        const dummyLogFiles = [];
        // Loop for each day, going back n months
        for (let i = 0; i < 7; i += 1) {
            const dataForLogFile = JSON.parse(JSON.stringify(LogfilesJson[id][0]));

            dataForLogFile.fileName = `${dataForLogFile?.fileName}_${currentDate.format(
                'YYYY-MM-DD_HH-mm-ss',
            )}.${'log'}`;

            dummyLogFiles.push(dataForLogFile);

            // Subtract one day from the current date
            currentDate.subtract(1, 'days');
        }

        return dummyLogFiles;
    }

    // eslint-disable-next-line class-methods-use-this
    initStreamForServices(id) {
        return StreamJson[id];
    }

    // eslint-disable-next-line class-methods-use-this
    initCollectionListServices(serviceId, databaseId) {
        // no use of databaseId as of now
        return CollectionListJson[serviceId];
    }

    // eslint-disable-next-line class-methods-use-this
    initCollectionDetailServices(collectionId) {
        return CollectionDetailJson[collectionId];
    }

    // eslint-disable-next-line class-methods-use-this
    initMonitoringServices(customURL, serviceId) {
        return getMonitoringData({ customURL, MetricsJsonWithTime, serviceId, MyServicesJson });
    }

    // eslint-disable-next-line class-methods-use-this
    initMonitoringAvgDbLoad(requestBody) {
        const avgDbLoadRequestBody = JSON.parse(requestBody);
        const response = getAvgDbLoadData({ requestBody: avgDbLoadRequestBody });
        return response;
    }

    // eslint-disable-next-line class-methods-use-this
    initMonitoringTopDimensions(requestBody) {
        const topDimensionsRequestBody = JSON.parse(requestBody);
        const response = getTopDimensionsData({ requestBody: topDimensionsRequestBody });
        return response;
    }

    // eslint-disable-next-line class-methods-use-this
    initMonitoringExtendedPerfMetrics(requestBody) {
        const extendedPerfMetricsRequestBody = JSON.parse(requestBody);
        const response = getExtendedPerfMetricsData({
            requestBody: extendedPerfMetricsRequestBody,
        });
        return response;
    }

    // eslint-disable-next-line class-methods-use-this
    initVpcDetailsMyServiceServices(vpcName) {
        return VpcDetailsMyService[vpcName];
    }

    // eslint-disable-next-line class-methods-use-this
    initMaintainanceWindows() {
        return MaintainceWindowsJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initSslCredsServices() {
        const SslCreds = JSON.parse(JSON.stringify(SslCredsJson));
        // Get the current date
        const currentDate = new Date();

        // Add milliseconds to the current date
        const millisecondsToAdd = 90000000;

        const futureDate = new Date(currentDate.getTime() + millisecondsToAdd);

        // Convert the future date to a string
        const futureDateString = futureDate.toISOString();

        SslCreds[0].certExpiryDate = futureDateString;

        return SslCreds;
    }

    // eslint-disable-next-line class-methods-use-this
    provisionDatabase(requestBody) {
        const newProvisionedService = modifyNewProvisionedService(requestBody, SampleCreateService);
        if (sessionStorage.getItem('demo-my-services')) {
            const oldProvisioningService = JSON.parse(sessionStorage.getItem('demo-my-services'));
            sessionStorage.setItem(
                'demo-my-services',
                JSON.stringify([...oldProvisioningService, newProvisionedService]),
            );
        } else {
            sessionStorage.setItem('demo-my-services', JSON.stringify([newProvisionedService]));
        }
        return newProvisionedService;
    }
}
