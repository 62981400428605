import clsx from 'clsx';
import { useStyles } from './utils';

export default function AzureActiveDirectory(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 120 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.779 0.25708L0.256836 21.2981L17.779 35.7323L35.3012 21.2981L17.779 0.25708Z"
                fill="#2483C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.3535 14.4849C18.7545 14.16 19.1157 13.8664 19.4331 13.5863L24.5068 19.4843C24.3822 19.8014 24.3137 20.1467 24.3137 20.5081C24.3137 20.7494 24.3443 20.9836 24.4017 21.207L18.8413 24.8993C18.6846 24.8449 18.5215 24.804 18.3535 24.7781V14.4849ZM20.2338 25.9638C20.5424 26.414 20.723 26.9588 20.723 27.5458C20.723 29.0925 19.4691 30.3464 17.9223 30.3464C16.3756 30.3464 15.1217 29.0925 15.1217 27.5458C15.1217 26.838 15.3842 26.1916 15.8172 25.6986L10.2103 22.5559C9.70989 23.0229 9.03817 23.3087 8.2997 23.3087C6.75293 23.3087 5.49902 22.0548 5.49902 20.5081C5.49902 18.9613 6.75293 17.7074 8.2997 17.7074C8.77477 17.7074 9.2222 17.8257 9.61417 18.0344L15.3918 12.5268C15.0428 11.983 14.8345 11.3909 14.8345 10.7415C14.8345 9.03608 16.217 7.65356 17.9224 7.65356C19.6279 7.65356 21.0104 9.03608 21.0104 10.7415C21.0104 11.3953 20.8415 11.9172 20.524 12.4012L25.4196 18.2782C25.8901 17.92 26.4774 17.7074 27.1144 17.7074C28.6612 17.7074 29.9151 18.9613 29.9151 20.5081C29.9151 22.0548 28.6612 23.3087 27.1144 23.3087C26.3011 23.3087 25.5688 22.9621 25.0571 22.4084L20.2338 25.9638ZM16.827 24.9674L10.9522 21.4094C11.0483 21.1266 11.1004 20.8234 11.1004 20.5081C11.1004 19.9909 10.9602 19.5065 10.7158 19.0907L16.5372 13.8067C16.7043 13.9521 16.8796 14.0935 17.0609 14.2312V24.8801C16.9813 24.9058 16.9033 24.9349 16.827 24.9674Z"
                fill="white"
            />
            <path
                d="M47.7277 15.7209H46.7041L45.8676 13.5086H42.5216L41.7347 15.7209H40.7056L43.7323 7.82935H44.6899L47.7277 15.7209ZM45.5649 12.6777L44.3267 9.31521C44.2863 9.20515 44.246 9.02904 44.2056 8.7869H44.1836C44.1469 9.0107 44.1047 9.1868 44.057 9.31521L42.8298 12.6777H45.5649Z"
                fill="#2483C5"
            />
            <path
                d="M52.7025 10.3443L49.3676 14.9505H52.6695V15.7209H48.0413V15.4403L51.3763 10.8561H48.355V10.0857H52.7025V10.3443Z"
                fill="#2483C5"
            />
            <path
                d="M58.4259 15.7209H57.5233V14.8294H57.5013C57.1271 15.5118 56.5474 15.853 55.7623 15.853C54.4195 15.853 53.7482 15.0532 53.7482 13.4536V10.0857H54.6452V13.3105C54.6452 14.4992 55.1001 15.0936 56.01 15.0936C56.4502 15.0936 56.8116 14.9321 57.0941 14.6093C57.3803 14.2828 57.5233 13.8572 57.5233 13.3325V10.0857H58.4259V15.7209Z"
                fill="#2483C5"
            />
            <path
                d="M63.1916 10.9992C63.0339 10.8781 62.8064 10.8176 62.5092 10.8176C62.124 10.8176 61.8012 10.9992 61.5407 11.3624C61.2839 11.7256 61.1555 12.2209 61.1555 12.8483V15.7209H60.2529V10.0857H61.1555V11.2468H61.1775C61.3059 10.8506 61.5022 10.5424 61.7663 10.3223C62.0305 10.0985 62.3258 9.9866 62.6523 9.9866C62.8871 9.9866 63.0669 10.0123 63.1916 10.0636V10.9992Z"
                fill="#2483C5"
            />
            <path
                d="M68.5463 13.1289H64.5674C64.5821 13.7563 64.7509 14.2406 65.0737 14.5818C65.3966 14.923 65.8405 15.0936 66.4055 15.0936C67.0402 15.0936 67.6236 14.8844 68.1555 14.4662V15.3137C67.6602 15.6732 67.0054 15.853 66.1909 15.853C65.3948 15.853 64.7692 15.598 64.3143 15.0881C63.8594 14.5744 63.6319 13.8535 63.6319 12.9253C63.6319 12.0485 63.8795 11.3349 64.3748 10.7846C64.8738 10.2306 65.492 9.95358 66.2294 9.95358C66.9668 9.95358 67.5373 10.1921 67.9409 10.669C68.3445 11.1459 68.5463 11.8082 68.5463 12.6557V13.1289ZM67.6217 12.364C67.6181 11.843 67.4915 11.4376 67.242 11.1478C66.9962 10.8579 66.6532 10.713 66.2129 10.713C65.7873 10.713 65.4259 10.8653 65.1288 11.1698C64.8316 11.4743 64.6482 11.8724 64.5784 12.364H67.6217Z"
                fill="#2483C5"
            />
            <path
                d="M47.7277 28.4003H46.7041L45.8676 26.188H42.5216L41.7347 28.4003H40.7056L43.7323 20.5087H44.6899L47.7277 28.4003ZM45.5649 25.357L44.3267 21.9946C44.2863 21.8845 44.246 21.7084 44.2056 21.4663H44.1836C44.1469 21.6901 44.1047 21.8662 44.057 21.9946L42.8298 25.357H45.5649Z"
                fill="#2483C5"
            />
            <path
                d="M52.6145 28.1417C52.1816 28.4021 51.6679 28.5324 51.0736 28.5324C50.2701 28.5324 49.6208 28.2719 49.1255 27.7509C48.6338 27.2263 48.388 26.5476 48.388 25.7147C48.388 24.7865 48.654 24.0418 49.186 23.4804C49.718 22.9155 50.4279 22.633 51.3157 22.633C51.811 22.633 52.2476 22.7247 52.6255 22.9081V23.8327C52.2073 23.5392 51.7597 23.3924 51.2827 23.3924C50.7067 23.3924 50.2334 23.5997 49.8629 24.0143C49.496 24.4252 49.3126 24.9663 49.3126 25.6377C49.3126 26.2981 49.485 26.8191 49.8299 27.2006C50.1784 27.5822 50.6443 27.7729 51.2277 27.7729C51.7193 27.7729 52.1816 27.6097 52.6145 27.2832V28.1417Z"
                fill="#2483C5"
            />
            <path
                d="M56.5933 28.3453C56.3805 28.4627 56.0999 28.5214 55.7513 28.5214C54.7644 28.5214 54.271 27.9711 54.271 26.8704V23.5355H53.3024V22.765H54.271V21.3892L55.1735 21.0976V22.765H56.5933V23.5355H55.1735V26.7108C55.1735 27.0887 55.2377 27.3584 55.3661 27.5198C55.4945 27.6812 55.7073 27.7619 56.0045 27.7619C56.2319 27.7619 56.4282 27.6996 56.5933 27.5748V28.3453Z"
                fill="#2483C5"
            />
            <path
                d="M58.2608 21.3342C58.0993 21.3342 57.9618 21.2792 57.848 21.1691C57.7343 21.059 57.6774 20.9196 57.6774 20.7509C57.6774 20.5821 57.7343 20.4427 57.848 20.3326C57.9618 20.2189 58.0993 20.162 58.2608 20.162C58.4259 20.162 58.5653 20.2189 58.679 20.3326C58.7964 20.4427 58.8551 20.5821 58.8551 20.7509C58.8551 20.9123 58.7964 21.0499 58.679 21.1636C58.5653 21.2773 58.4259 21.3342 58.2608 21.3342ZM58.701 28.4003H57.7985V22.765H58.701V28.4003Z"
                fill="#2483C5"
            />
            <path
                d="M64.9582 22.765L62.7129 28.4003H61.8268L59.6916 22.765H60.6822L62.113 26.8594C62.2194 27.1603 62.2854 27.4226 62.3111 27.6464H62.3331C62.3698 27.3639 62.4285 27.1089 62.5092 26.8814L64.0061 22.765H64.9582Z"
                fill="#2483C5"
            />
            <path
                d="M70.3953 25.8083H66.4165C66.4312 26.4357 66.6 26.92 66.9228 27.2611C67.2457 27.6023 67.6896 27.7729 68.2546 27.7729C68.8893 27.7729 69.4726 27.5638 70.0046 27.1456V27.9931C69.5093 28.3526 68.8544 28.5324 68.04 28.5324C67.2438 28.5324 66.6183 28.2774 66.1634 27.7674C65.7084 27.2538 65.481 26.5329 65.481 25.6047C65.481 24.7278 65.7286 24.0143 66.2239 23.4639C66.7229 22.91 67.3411 22.633 68.0785 22.633C68.8159 22.633 69.3864 22.8714 69.79 23.3484C70.1935 23.8253 70.3953 24.4875 70.3953 25.335V25.8083ZM69.4708 25.0434C69.4671 24.5224 69.3406 24.117 69.0911 23.8272C68.8453 23.5373 68.5022 23.3924 68.062 23.3924C67.6364 23.3924 67.275 23.5447 66.9778 23.8492C66.6807 24.1537 66.4972 24.5517 66.4275 25.0434H69.4708Z"
                fill="#2483C5"
            />
            <path
                d="M74.9795 28.4003V20.5087H77.1588C79.9397 20.5087 81.3302 21.791 81.3302 24.3555C81.3302 25.5735 80.9431 26.5531 80.169 27.2942C79.3986 28.0316 78.3658 28.4003 77.0707 28.4003H74.9795ZM75.904 21.3452V27.5638H77.0817C78.1163 27.5638 78.9216 27.2868 79.4976 26.7328C80.0736 26.1789 80.3616 25.3937 80.3616 24.3775C80.3616 22.356 79.2867 21.3452 77.1368 21.3452H75.904Z"
                fill="#2483C5"
            />
            <path
                d="M83.2233 21.3342C83.0619 21.3342 82.9243 21.2792 82.8106 21.1691C82.6968 21.059 82.64 20.9196 82.64 20.7509C82.64 20.5821 82.6968 20.4427 82.8106 20.3326C82.9243 20.2189 83.0619 20.162 83.2233 20.162C83.3884 20.162 83.5278 20.2189 83.6415 20.3326C83.7589 20.4427 83.8176 20.5821 83.8176 20.7509C83.8176 20.9123 83.7589 21.0499 83.6415 21.1636C83.5278 21.2773 83.3884 21.3342 83.2233 21.3342ZM83.6635 28.4003H82.761V22.765H83.6635V28.4003Z"
                fill="#2483C5"
            />
            <path
                d="M88.4293 23.6786C88.2716 23.5575 88.0441 23.497 87.7469 23.497C87.3617 23.497 87.0388 23.6786 86.7784 24.0418C86.5215 24.405 86.3931 24.9003 86.3931 25.5276V28.4003H85.4906V22.765H86.3931V23.9262H86.4151C86.5436 23.53 86.7398 23.2218 87.004 23.0017C87.2681 22.7779 87.5635 22.666 87.89 22.666C88.1248 22.666 88.3046 22.6917 88.4293 22.743V23.6786Z"
                fill="#2483C5"
            />
            <path
                d="M93.7839 25.8083H89.8051C89.8198 26.4357 89.9886 26.92 90.3114 27.2611C90.6343 27.6023 91.0782 27.7729 91.6432 27.7729C92.2779 27.7729 92.8612 27.5638 93.3932 27.1456V27.9931C92.8979 28.3526 92.243 28.5324 91.4286 28.5324C90.6324 28.5324 90.0069 28.2774 89.552 27.7674C89.097 27.2538 88.8696 26.5329 88.8696 25.6047C88.8696 24.7278 89.1172 24.0143 89.6125 23.4639C90.1115 22.91 90.7297 22.633 91.4671 22.633C92.2045 22.633 92.775 22.8714 93.1786 23.3484C93.5821 23.8253 93.7839 24.4875 93.7839 25.335V25.8083ZM92.8594 25.0434C92.8557 24.5224 92.7292 24.117 92.4797 23.8272C92.2339 23.5373 91.8908 23.3924 91.4506 23.3924C91.025 23.3924 90.6636 23.5447 90.3664 23.8492C90.0693 24.1537 89.8858 24.5517 89.8161 25.0434H92.8594Z"
                fill="#2483C5"
            />
            <path
                d="M98.9955 28.1417C98.5625 28.4021 98.0489 28.5324 97.4546 28.5324C96.6511 28.5324 96.0017 28.2719 95.5064 27.7509C95.0148 27.2263 94.769 26.5476 94.769 25.7147C94.769 24.7865 95.035 24.0418 95.567 23.4804C96.0989 22.9155 96.8089 22.633 97.6967 22.633C98.192 22.633 98.6286 22.7247 99.0065 22.9081V23.8327C98.5882 23.5392 98.1406 23.3924 97.6637 23.3924C97.0877 23.3924 96.6144 23.5997 96.2439 24.0143C95.877 24.4252 95.6935 24.9663 95.6935 25.6377C95.6935 26.2981 95.866 26.8191 96.2108 27.2006C96.5594 27.5822 97.0253 27.7729 97.6087 27.7729C98.1003 27.7729 98.5625 27.6097 98.9955 27.2832V28.1417Z"
                fill="#2483C5"
            />
            <path
                d="M102.974 28.3453C102.761 28.4627 102.481 28.5214 102.132 28.5214C101.145 28.5214 100.652 27.9711 100.652 26.8704V23.5355H99.6834V22.765H100.652V21.3892L101.554 21.0976V22.765H102.974V23.5355H101.554V26.7108C101.554 27.0887 101.619 27.3584 101.747 27.5198C101.875 27.6812 102.088 27.7619 102.385 27.7619C102.613 27.7619 102.809 27.6996 102.974 27.5748V28.3453Z"
                fill="#2483C5"
            />
            <path
                d="M106.447 28.5324C105.614 28.5324 104.948 28.2701 104.449 27.7454C103.954 27.2171 103.706 26.5182 103.706 25.6487C103.706 24.7022 103.965 23.9629 104.482 23.4309C104.999 22.8989 105.698 22.633 106.579 22.633C107.419 22.633 108.074 22.8916 108.544 23.4089C109.017 23.9262 109.253 24.6435 109.253 25.5607C109.253 26.4595 108.998 27.1804 108.488 27.7234C107.982 28.2627 107.302 28.5324 106.447 28.5324ZM106.513 23.3924C105.933 23.3924 105.475 23.5905 105.137 23.9867C104.8 24.3793 104.631 24.9223 104.631 25.6157C104.631 26.2834 104.801 26.8099 105.143 27.1951C105.484 27.5803 105.941 27.7729 106.513 27.7729C107.096 27.7729 107.544 27.584 107.856 27.2061C108.171 26.8282 108.329 26.2908 108.329 25.5937C108.329 24.8893 108.171 24.3463 107.856 23.9647C107.544 23.5832 107.096 23.3924 106.513 23.3924Z"
                fill="#2483C5"
            />
            <path
                d="M113.634 23.6786C113.476 23.5575 113.249 23.497 112.952 23.497C112.566 23.497 112.244 23.6786 111.983 24.0418C111.726 24.405 111.598 24.9003 111.598 25.5276V28.4003H110.695V22.765H111.598V23.9262H111.62C111.748 23.53 111.944 23.2218 112.209 23.0017C112.473 22.7779 112.768 22.666 113.095 22.666C113.329 22.666 113.509 22.6917 113.634 22.743V23.6786Z"
                fill="#2483C5"
            />
            <path
                d="M119.55 22.765L116.958 29.3028C116.496 30.4695 115.846 31.0529 115.01 31.0529C114.775 31.0529 114.579 31.029 114.421 30.9813V30.1723C114.615 30.2384 114.793 30.2714 114.955 30.2714C115.41 30.2714 115.751 29.9999 115.978 29.4569L116.43 28.3893L114.228 22.765H115.23L116.754 27.1016C116.773 27.1566 116.811 27.2997 116.87 27.5308H116.903C116.921 27.4428 116.958 27.3033 117.013 27.1126L118.614 22.765H119.55Z"
                fill="#2483C5"
            />
        </svg>
    );
}
