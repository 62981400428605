/* eslint-disable react/react-in-jsx-scope */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function RestoreServiceIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <g clipPath="url(#clip0_7205_2927)">
                <path
                    d="M7.17862 14.7272C6.30988 13.799 5.85767 13.2278 5.85767 13.0611C5.85767 12.8707 6.27418 12.3471 7.13102 11.4665C7.83315 10.7405 8.49958 10.0979 8.61858 10.0384C8.77329 9.95512 8.91609 10.0146 9.24931 10.3002C9.79673 10.7763 9.78483 11.0857 9.1898 11.6569C8.92799 11.9068 8.71378 12.1686 8.71378 12.2162C8.71378 12.3828 11.0225 12.3233 11.6413 12.1329C12.4029 11.9068 13.1289 11.288 13.4859 10.562C13.7239 10.0622 13.7596 9.81231 13.7596 8.50326C13.7596 7.1347 13.7358 6.96809 13.4502 6.42067C13.1051 5.76614 12.8195 5.46863 12.0816 5.00451C11.4866 4.6356 11.3319 4.24288 11.6175 3.80256C11.915 3.35034 12.2958 3.31464 12.998 3.69546C13.8191 4.14768 14.6997 5.13542 15.0329 5.98035C15.4019 6.90859 15.4971 9.30059 15.2115 10.4192C14.8544 11.7759 13.9262 12.8945 12.6528 13.4777C12.0578 13.7514 11.8079 13.799 10.3561 13.8347C9.45162 13.8585 8.71378 13.918 8.71378 13.9656C8.71378 14.0132 8.92799 14.2631 9.1898 14.513C9.74913 15.0604 9.78483 15.3341 9.34451 15.7864C8.80899 16.3457 8.61858 16.2505 7.17862 14.7272Z"
                    fill="#1E7EA1"
                />
                <path
                    d="M3.16812 13.3825C2.03757 12.8232 1.15693 11.7521 0.799919 10.4907C0.502407 9.45532 0.597611 6.92051 0.966526 5.98037C1.33544 5.02833 2.22798 4.0882 3.21572 3.60028C3.97735 3.21946 4.06065 3.20756 5.70292 3.17186L7.40469 3.12426L6.86917 2.52923C6.57166 2.19602 6.33365 1.8628 6.33365 1.7795C6.33365 1.49389 6.94057 0.886963 7.22618 0.886963C7.5356 0.886963 10.2608 3.61218 10.2608 3.93349C10.2608 4.1358 7.77361 6.75391 7.38089 6.96811C7.22618 7.05142 7.07148 6.99192 6.75016 6.7063C6.52406 6.504 6.33365 6.24218 6.33365 6.13508C6.33365 6.02798 6.58356 5.65906 6.89297 5.30205L7.45229 4.65942L5.96473 4.71892C4.60808 4.76652 4.44147 4.79032 3.84644 5.11164C3.14432 5.49245 2.59689 6.14698 2.39458 6.82531C2.32318 7.07522 2.28748 7.89635 2.31128 8.75319C2.34698 10.1812 2.35888 10.2765 2.704 10.8358C2.95391 11.2285 3.26332 11.5379 3.67984 11.7759C4.57237 12.2995 4.76278 12.8113 4.25106 13.323C3.90595 13.68 3.78694 13.6919 3.16812 13.3825Z"
                    fill="#1E7EA1"
                />
            </g>
            <defs>
                <clipPath id="clip0_7205_2927">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}
