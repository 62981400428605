import clsx from 'clsx';
import React from 'react';
import { useStyles } from './utils';

export default function GenerativeOutlinedIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="36" height="36" rx="18" fill="#F2FAFE" />

            <g clipPath="url(#clip0_48_19)">
                <path
                    d="M15.5443 19.7585H9.61635C9.23119 19.7585 8.91895 20.0708 8.91895 20.4559V26.3839C8.91895 26.769 9.23119 27.0813 9.61635 27.0813H15.5443C15.9295 27.0813 16.2417 26.769 16.2417 26.3839V20.4559C16.2417 20.0708 15.9295 19.7585 15.5443 19.7585Z"
                    stroke="#1E7EA1"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M26.3837 19.7585H20.4557C20.0705 19.7585 19.7583 20.0708 19.7583 20.4559V26.3839C19.7583 26.769 20.0705 27.0813 20.4557 27.0813H26.3837C26.7689 27.0813 27.081 26.769 27.081 26.3839V20.4559C27.081 20.0708 26.7689 19.7585 26.3837 19.7585Z"
                    stroke="#1E7EA1"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.5443 8.9187H9.61635C9.23119 8.9187 8.91895 9.23094 8.91895 9.61611V15.5441C8.91895 15.9292 9.23119 16.2415 9.61635 16.2415H15.5443C15.9295 16.2415 16.2417 15.9292 16.2417 15.5441V9.61611C16.2417 9.23094 15.9295 8.9187 15.5443 8.9187Z"
                    stroke="#1E7EA1"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.6365 13.1206C19.2071 13.0459 19.2071 12.4294 19.6365 12.3547C21.1922 12.0841 22.4295 10.8992 22.7672 9.35667L22.7931 9.23843C22.8859 8.81403 23.4902 8.81139 23.5868 9.23496L23.6182 9.37275C23.9685 10.908 25.2061 12.0829 26.7575 12.3528C27.1891 12.4279 27.1891 13.0475 26.7575 13.1225C25.2061 13.3924 23.9685 14.5674 23.6182 16.1026L23.5868 16.2404C23.4902 16.664 22.8859 16.6613 22.7931 16.2369L22.7672 16.1187C22.4295 14.5762 21.1922 13.3912 19.6365 13.1206Z"
                    stroke="#1E7EA1"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_48_19">
                    <rect width="20" height="20" fill="white" transform="translate(8 8)" />
                </clipPath>
            </defs>
        </svg>
    );
}
