import clsx from 'clsx';
import React from 'react';
import { useStyles } from './utils';

export default function BackingUpIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);
    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="7.99899"
                cy="5.44521"
                rx="3.55514"
                ry="1.44424"
                stroke="var(--icon-color)"
            />
            <path
                d="M4.44385 5.55664V10.4633C4.44385 11.3121 6.03554 12.0002 7.99899 12.0002C9.96244 12.0002 11.5541 11.3121 11.5541 10.4633V5.55664"
                stroke="var(--icon-color)"
            />
            <path
                d="M11.6108 1.5H13.7778C14.1767 1.5 14.5001 1.82339 14.5001 2.22232V4.38915"
                stroke="var(--icon-color)"
                strokeLinecap="round"
            />
            <path
                d="M4.38916 1.5H2.22224C1.82332 1.5 1.49993 1.82339 1.49993 2.22232V4.38915"
                stroke="var(--icon-color)"
                strokeLinecap="round"
            />
            <path
                d="M11.6108 14.501H13.7778C14.1767 14.501 14.5001 14.1776 14.5001 13.7787V11.6118"
                stroke="var(--icon-color)"
                strokeLinecap="round"
            />
            <path
                d="M4.38916 14.501H2.22224C1.82332 14.501 1.49993 14.1776 1.49993 13.7787V11.6118"
                stroke="var(--icon-color)"
                strokeLinecap="round"
            />
        </svg>
    );
}
