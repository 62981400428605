import Security from 'api-clients/network-layer/demo-data/REST/Governance/security';
import { getDomainUrl } from 'constants/urls';
import { regexToString } from '../../utils';

export const mockGetMap = {
    [`${getDomainUrl()}/encryption-keys/eligible?page-size=100&pageOffset=0`]: {
        handler: () => {
            const responseData = Security.getEligibleEncryptionKeysDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/encryption-keys/governance/eligible?page-size=100&pageOffset=0`]: {
        handler: () => {
            const responseData = Security.getEligibleEncryptionKeysDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/encryption-keys/governance?page-size=1000&include-shared-with-users=true`]:
        {
            handler: () => {
                const responseData = Security.getEncryptionKeysDetails();
                return [200, responseData];
            },
        },
    [`${getDomainUrl()}/security/password-policies`]: {
        handler: () => {
            const responseData = Security.getPasswordPoliciesDetails();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/security/key-vaults?subscription-name=${subscriptionName}&region=${region}`
    [regexToString(/\/security\/key-vaults\?subscription-name=([\w\s-]+)&region=([\w-]+)/)]: {
        handler: (config) => {
            const responseData = Security.getKeyVaultSubscriptionDetails();
            return [200, responseData];
        },
    },
};

// Define the mock data mapping for POST requests
export const mockPostMap = {
    [`${getDomainUrl()}/encryption-keys/governance`]: {
        handler: (config) => {
            const responseData = Security.createEncryptionKey(config.data);

            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/encryption-keys/register`]: {
        handler: (config) => {
            const responseData = Security.registerEncryptionKey();
            return [200, responseData];
        },
    },
};

// Define the mock data mapping for PATCH requests
export const mockPatchMap = {
    [`${getDomainUrl()}/security/password-policies`]: {
        handler: (config) => {
            const responseData = Security.createPasswordPolicies(config.data);

            return [200, responseData];
        },
    },
};
