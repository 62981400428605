import { createSlice } from '@reduxjs/toolkit';

export const restoreDBInfo = createSlice({
    name: 'restoreInfo',
    initialState: {
        value: {},
    },
    reducers: {
        update: (state, action) => {
            // eslint-disable-next-line no-param-reassign
            state.value = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { update } = restoreDBInfo.actions;

export default restoreDBInfo.reducer;
