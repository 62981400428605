/*
 *
 * Copyright © Tessell Inc, 2022. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     2/23/2022     bakul.banthia        Get customer type
 *
 */

import { useMemo } from 'react';
import { getHeaders, getDomainUrl } from 'constants/urls';
import { useFetch, useLazyFetch, useLazyFetch2, usePost } from 'tools/useFetch';
import Cache from 'tools/Cache';
import { EMPTY_OBJECT } from 'constants/app.constants';

export const useLogin = () => {
    const url = `${getDomainUrl()}/iam/users/login`;
    const login = usePost(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return login;
};

export const useLogout = () => {
    const url = `${getDomainUrl()}/iam/users/logout`;
    const logout = usePost(url, {
        method: 'POST',
        headers: getHeaders(),
    });
    return logout;
};

const iamUrl = () => {
    const IAMUrl = `${getDomainUrl()}/iam`;
    return IAMUrl;
};

export const getUsers = () => {
    const url = `${iamUrl()}/users?load-personas=true&load-subscriptions=true`;
    return fetch(url, { headers: getHeaders() });
};

export const useGetUsers = () => {
    const url = `${iamUrl()}/users?load-personas=true&load-subscriptions=true`;
    const usersRes = useLazyFetch(url, { headers: getHeaders() });

    return {
        ...usersRes,
        response: usersRes?.response || null,
    };
};

export const useGetPrivileges = () => {
    const url = `${iamUrl()}/privileges`;
    const privilegeRes = useFetch(url, { headers: getHeaders() });
    return {
        ...privilegeRes,
        response: privilegeRes?.response || null,
    };
};
export const addIamUser = (data) => {
    const url = `${iamUrl()}/users/`;
    return fetch(url, {
        headers: getHeaders(),
        method: 'POST',
        body: JSON.stringify(data),
    });
};

export const getPersonas = () => {
    const url = `${iamUrl()}/personas?load-apps=true&page-size=100&pageOffset=0`;
    return fetch(url, {
        headers: getHeaders(),
    });
};

export const useReset = () => {
    const url = `${getDomainUrl()}/iam/users/change-password`;
    const resetRes = usePost(url, {
        method: 'POST',
        headers: getHeaders(),
    });
    resetRes.response = useMemo(() => resetRes.response || {}, [resetRes.response]);
    return resetRes;
};

export const useResetByAdmin = () => {
    const url = `${getDomainUrl()}/iam/users/force-reset-password/`;
    const resetByAdmin = usePost(url, {
        method: 'POST',
        headers: getHeaders(),
    });
    return resetByAdmin;
};

export const toggleIAMUserStatus = (email, data) => {
    const url = `${iamUrl()}/users/${email}/change-status`;
    return fetch(url, {
        headers: getHeaders(),
        method: 'POST',
        body: JSON.stringify(data),
    });
};

export const updateSubscription = (email, data) => {
    const url = `${iamUrl()}/users/${email}/enable-subscriptions`;
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: getHeaders(),
    });
};

export const useCreatePersona = () => {
    const url = `${getDomainUrl()}/iam/personas`;
    const login = usePost(url, {
        method: 'POST',
        headers: getHeaders(),
    });
    return login;
};

export const useGetApps = () => {
    const url = `${getDomainUrl()}/apps?load-privileges=true&load-features=true`;
    const usersRes = useFetch(url, { headers: getHeaders() });

    return {
        ...usersRes,
        response: usersRes?.response || null,
    };
};

export const checkWhoami = (headers) => {
    const url = `${getDomainUrl()}/iam/users/whoami`;
    return fetch(url, {
        method: 'GET',
        headers,
    });
};

export const useInvitedSignUp = () => {
    const url = `${getDomainUrl()}/iam/users/accept-invite`;
    const signup = usePost(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return signup;
};

export const inviteIamUser = (data) => {
    const url = `${iamUrl()}/users/invite-user`;
    return fetch(url, {
        headers: getHeaders(),
        method: 'POST',
        body: JSON.stringify(data),
    });
};

export const useGetServicePlan = () => {
    const url = `${getDomainUrl()}/service-plans`;
    const servicePlanRes = useLazyFetch(url, { headers: getHeaders() });

    return {
        ...servicePlanRes,
        response: servicePlanRes?.response || null,
    };
};

export const useUpdateServicePlan = () => {
    const url = `${getDomainUrl()}/service-plan`;
    const servicePlanRes = useLazyFetch(url, {
        headers: getHeaders(),
        method: 'PATCH',
    });

    return {
        ...servicePlanRes,
        response: servicePlanRes?.response || null,
    };
};

export const getTenantInfo = (headers) => {
    const url = `${getDomainUrl()}/tenant-config`;
    return fetch(url, {
        method: 'GET',
        headers,
    });
};

export const getGenieInfo = (headers, id) => {
    const url = `${getDomainUrl()}/vm-access/genie-config/${id}`;
    return fetch(url, {
        method: 'GET',
        headers,
    });
};

export const getMSlogin = () => {
    const url = `${getDomainUrl()}/iam/login/saml`;
    return fetch(url, {
        method: 'GET',
        redirect: 'manual',
    });
};

export const useGetLoginOptions = () => {
    const url = `${iamUrl()}/idps/configured-idps`;
    const loginOptionsRes = useFetch(url);

    return loginOptionsRes;
};

export const useSAMLWhoami = (nonce) => {
    const url = `${iamUrl()}/login/saml/whoami?nonce=${nonce}`;
    const whoamiRes = useFetch(url);

    return whoamiRes;
};

export const useGetUpgradablePersona = () => {
    const url = `${iamUrl()}/personas/upgradable-personas`;
    const res = useFetch(url, { headers: getHeaders() });
    // res.response = useMemo(() => res.response || {}, [res.response]);
    return res;
};

export const getUpgradablePersona = () => {
    const url = `${iamUrl()}/personas/upgradable-personas`;
    return fetch(url, {
        headers: getHeaders(),
    });
};

export const updateUserPersona = (emailId, body) => {
    const updateIdpRes = fetch(`${getDomainUrl()}/iam/users/${emailId}`, {
        method: 'PATCH',
        headers: getHeaders(),
        body,
    });
    return updateIdpRes;
};

export const useUpdateUserPersona = () => {
    const res = useLazyFetch2('', { method: 'PATCH' });
    return res;
};

export const useGetIDPs = () => {
    const url = `${getDomainUrl()}/iam/sso`;
    const idps = useLazyFetch(url, { headers: getHeaders() });
    return idps;
};

export const useUpdatePersona = (name) => {
    const url = `${getDomainUrl()}/iam/personas/${name}`;
    const res = useLazyFetch(url, { headers: getHeaders(), method: 'PATCH' });
    return res;
};

export const useCreateIdpConfig = () => {
    const url = `${getDomainUrl()}/iam/idps`;
    const idpConfig = usePost(url, {
        method: 'POST',
        headers: getHeaders(),
    });
    return idpConfig;
};

export const updateIdpConfig = (id, body) => {
    const updateIdpRes = fetch(`${getDomainUrl()}/iam/idps/${id}`, {
        method: 'PUT',
        headers: getHeaders(),
        body,
    });
    return updateIdpRes;
};

export const deleteIdpConfig = (id) => {
    const deleteIdpRes = fetch(`${getDomainUrl()}/iam/idps/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
    });
    return deleteIdpRes;
};

export const revokeAPIKey = (label) => {
    const res = fetch(`${getDomainUrl()}/iam/api-keys/${label}`, {
        method: 'DELETE',
        headers: getHeaders(),
    });
    return res;
};

export const useGetAPIKeys = () => {
    const url = `${getDomainUrl()}/iam/api-keys?page-size=1000`;
    const res = useLazyFetch(url, { headers: getHeaders() });
    return res;
};

export const useGetCredits = () => {
    const url = `${getDomainUrl()}/billing/credits`;
    const res = useLazyFetch(url, { headers: getHeaders() });
    return res;
};

export const useCreateAPIKey = () => {
    const url = `${getDomainUrl()}/iam/api-keys`;
    const res = usePost(url, {
        method: 'POST',
        headers: getHeaders(),
    });
    return res;
};

export const useUpdateUsername = () => {
    const url = `${getDomainUrl()}/iam/users/profile`;
    const res = useLazyFetch(url, {
        headers: getHeaders(),
        method: 'PATCH',
    });
    return res;
};

export const forgotPassword = (email) => {
    const url = `${iamUrl()}/users/forgot-password/${encodeURIComponent(email)}`;
    return fetch(url, {
        // headers: getHeaders(),
        method: 'POST',
    });
};

export const useSetNewPassword = () => {
    const url = `${iamUrl()}/users/reset-password`;
    const res = usePost(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
    return res;
};

export const deletePersona = (name) => {
    const url = `${iamUrl()}/personas/${encodeURIComponent(name)}`;
    return fetch(url, {
        headers: getHeaders(),
        method: 'DELETE',
    });
};

export const useUpdateExpiredPassword = () => {
    const url = `${getDomainUrl()}/iam/users/reset-password-on-expiry`;
    const resetRes = usePost(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
    resetRes.response = resetRes.response || EMPTY_OBJECT;
    return resetRes;
};
export const getAccessToken = (tenantId) => {
    const url = `${iamUrl()}/users/refresh-token`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Authorization: Cache.get('refreshToken'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'tenant-id': tenantId,
        },
    });
};
