import clsx from 'clsx';
import { useStyles } from './utils';

export default function SumologicIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);
    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <style>{'\n\t\t.s0 { fill: #FEFEFE }\n\t'}</style>

            <path d="M0 40H40V0H0V40Z" fill="#000099" />
            <path
                d="M15.5682 10.1191L14.3303 11.6221C13.2688 10.791 12.5968 10.5613 11.6241 10.5613C10.8625 10.5613 10.3195 10.8034 10.1549 11.1986V11.7102C10.2581 11.9283 10.51 12.0644 10.8819 12.1703C11.1294 12.2415 11.5708 12.3474 12.2079 12.4889C14.1801 12.9268 15.198 13.4082 15.5558 14.3827C15.5558 14.711 15.5576 15.9988 15.5576 16.3004C15.1259 17.5428 13.7903 18.325 11.8359 18.325C10.121 18.325 8.90102 17.8828 7.46826 16.6093L8.79421 15.124C9.44918 15.6543 9.97963 16.0076 10.4219 16.1847C10.8642 16.3796 11.3412 16.4678 11.8894 16.4678C12.6395 16.4678 13.2127 16.2444 13.3987 15.8261V15.2103C13.2652 14.9237 12.9394 14.7546 12.5612 14.6461C12.3148 14.5936 11.8715 14.4877 11.2353 14.3453C9.30592 13.9225 8.4035 13.4607 8.07066 12.5824V10.653C8.49969 9.52466 9.77493 8.70496 11.6775 8.70496C13.2331 8.70494 14.3126 9.07603 15.5682 10.1191Z"
                fill="#FEFEFE"
            />
            <path
                d="M32.1972 9.005V18.0422H30.0917V17.0864C29.6148 17.8651 28.7301 18.3252 27.4398 18.3252C25.3005 18.3252 24.2378 17.211 24.2378 15.4596V9.005H26.5374V14.8234C26.5374 15.7605 27.0499 16.3264 28.0413 16.3264C29.1894 16.3264 29.8977 15.6723 29.8977 14.4523V9.005H32.1972Z"
                fill="#FEFEFE"
            />
            <path
                d="M21.0165 24.6968V31.0117H18.717V25.3873C18.717 24.3087 18.2746 23.6537 17.2842 23.6537C16.2768 23.6537 15.693 24.3977 15.693 25.4585V31.0117H13.3934V25.3873C13.3934 24.2375 12.9164 23.6537 11.9616 23.6537C10.9532 23.6537 10.3695 24.3977 10.3695 25.4585V31.0117H8.0708V21.9736H10.2111V22.9828C10.7414 22.116 11.6074 21.6746 12.7927 21.6746C13.9416 21.6746 14.8085 22.1516 15.3032 22.9988C15.9226 22.116 16.825 21.6746 18.0094 21.6746C19.9193 21.6746 21.0165 22.8404 21.0165 24.6968Z"
                fill="#FEFEFE"
            />
            <path
                d="M26.0266 27.8331C26.3861 28.787 27.1532 29.3494 28.1713 29.3494C29.1769 29.3494 29.9404 28.787 30.3 27.8331C30.3 27.3774 30.3 25.6332 30.3 25.1527C29.9423 24.2004 29.1786 23.6184 28.1713 23.6184C27.1532 23.6184 26.3843 24.2004 26.0266 25.1527C26.0266 25.6047 26.0266 27.4201 26.0266 27.8331ZM32.5301 28.3883C31.8626 30.1753 30.2359 31.2949 28.1713 31.2949C26.0942 31.2949 24.4639 30.1753 23.7964 28.3883V24.5795C24.4639 22.7908 26.0942 21.6748 28.1713 21.6748C30.2359 21.6748 31.8626 22.7908 32.5301 24.5795V28.3883Z"
                fill="#FEFEFE"
            />
        </svg>
    );
}
