/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function StartIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.5 12.1007C23.5 18.404 18.5992 23.5 12.1646 23.5C5.71197 23.5 0.5 18.386 0.5 12.1007C0.5 5.80455 5.72292 0.5 12.1646 0.5C18.5885 0.5 23.5 5.78642 23.5 12.1007Z"
                stroke="var(--icon-color)"
            />
            <path
                d="M8.79606 18.4964C8.68686 18.5066 8.57734 18.4941 8.50001 18.4715V6.505C8.52319 6.50191 8.55211 6.49985 8.5849 6.50001C8.62984 6.50024 8.67282 6.5046 8.70788 6.51153C8.73546 6.51698 8.74967 6.52233 8.75307 6.52361L19.3975 12.5238L8.87071 18.4637C8.84514 18.4781 8.82634 18.4866 8.81294 18.4915C8.80199 18.4955 8.7967 18.4963 8.79606 18.4964ZM8.45728 6.5136C8.45727 6.51359 8.4577 6.51343 8.45859 6.51315L8.45728 6.5136Z"
                stroke="var(--icon-color)"
            />
        </svg>
    );
}
