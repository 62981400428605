import clsx from 'clsx';
import { useStyles } from './utils';

export default function AccessIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <circle cx="8" cy="8.5" r="7" fill="var(--icon-color)" stroke="var(--icon-color)" />
            <mask
                id="mask0_7084_9590"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="16"
                height="15"
            >
                <circle cx="8" cy="7.5" r="7" fill="#D9D9D9" stroke="black" />
            </mask>
            <g mask="url(#mask0_7084_9590)">
                <circle cx="8" cy="14" r="4" fill="white" stroke="white" />
            </g>
            <circle cx="8" cy="6.5" r="2" fill="white" stroke="white" />
        </svg>
    );
}
