/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function DataflixIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 24 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.4951 14.5997C9.46265 14.6008 9.42981 14.5986 9.40001 14.5937V6.4002C9.42124 6.40088 9.44075 6.40323 9.45613 6.40638C9.46261 6.40771 9.46707 6.40892 9.46972 6.40971L16.4854 10.5165L9.58718 14.5586C9.55613 14.5768 9.53343 14.5876 9.51653 14.5938C9.50032 14.5998 9.49409 14.5997 9.4951 14.5997Z"
                stroke="var(--icon-color)"
                strokeWidth="0.8"
            />
            <path
                d="M1.23895 3.66116H23.5109"
                stroke="var(--icon-color)"
                strokeWidth="0.8"
                strokeLinecap="round"
            />
            <path
                d="M23.6 1V17.0238C23.6 17.3552 23.3314 17.6238 23 17.6238H1.34375C1.01238 17.6238 0.74375 17.3552 0.74375 17.0238V1C0.74375 0.66863 1.01238 0.4 1.34375 0.4H12.1719H23C23.3314 0.4 23.6 0.668629 23.6 1Z"
                stroke="var(--icon-color)"
                strokeWidth="0.8"
            />
        </svg>
    );
}
