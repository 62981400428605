/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function LogsIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 18 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.1564 5.65962C17.1564 5.44605 17.08 5.23248 16.9273 5.0723L12.6509 0.266963C12.4982 0.080089 12.2691 0 12.04 0H1.34909C0.610909 0 0 0.640712 0 1.41491V22.5851C0 23.3593 0.610909 24 1.34909 24H15.8327C16.5709 24 17.1818 23.3593 17.1818 22.5851L17.1564 5.65962ZM15.8073 22.9321H1.34909C1.17091 22.9321 1.01818 22.772 1.01818 22.5851V1.38821C1.01818 1.20133 1.17091 1.04116 1.34909 1.04116H11.8618V4.24472C11.8618 5.099 12.5236 5.7931 13.3382 5.7931H16.1636V22.5851C16.1382 22.772 15.9855 22.9321 15.8073 22.9321Z"
                fill="var(--icon-color)"
            />
            <path
                d="M4.66261 17.9404H3.14976V14.3974C3.14976 14.1324 3.02873 14 2.78668 14H2.36308C2.12103 14 2 14.1324 2 14.3974V18.6026C2 18.8676 2.12103 19 2.36308 19H4.63235C4.8744 19 4.99543 18.8676 4.99543 18.6026V18.3377C5.02569 18.0728 4.90466 17.9404 4.66261 17.9404Z"
                fill="var(--icon-color)"
            />
            <path
                d="M7.5 14C6.01266 14 5 15.0897 5 16.4679C5 17.8782 6.01266 19 7.5 19C8.98734 19 10 17.9103 10 16.4679C10 15.0577 8.98734 14 7.5 14ZM7.5 17.8782C6.74051 17.8782 6.20253 17.2692 6.20253 16.4359C6.20253 15.6346 6.74051 15.0577 7.5 15.0577C8.25949 15.0577 8.79747 15.6346 8.79747 16.4359C8.79747 17.2692 8.25949 17.8782 7.5 17.8782Z"
                fill="var(--icon-color)"
            />
            <path
                d="M14.6927 16.1795H13.8384C13.618 16.1795 13.5077 16.3077 13.5077 16.5641V16.8205C13.5077 17.0769 13.618 17.2051 13.8384 17.2051H14.114V17.5256C14.114 17.5256 13.866 17.9103 13.2597 17.9103C12.6259 17.9103 12.0747 17.3654 12.0747 16.468C12.0747 15.7308 12.433 15.0897 13.2597 15.0897C13.618 15.0897 13.8384 15.2179 14.0313 15.3141C14.2242 15.4103 14.362 15.4103 14.4722 15.2179L14.61 14.9295C14.7203 14.7051 14.6927 14.5769 14.5274 14.4167C14.362 14.2885 13.8384 14 13.177 14C11.9094 14 11 15.0577 11 16.5321C11 18.0385 11.9645 19 13.0393 19C13.7833 19 14.1416 18.5513 14.1416 18.5513C14.1416 18.5513 14.1416 18.5833 14.1416 18.6154C14.1416 18.7756 14.2518 18.9038 14.4722 18.9038H14.6651C14.8856 18.9038 14.9958 18.7756 14.9958 18.5192V16.5321C15.0234 16.3077 14.9132 16.1795 14.6927 16.1795Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
