import clsx from 'clsx';
import { useStyles } from './utils';

export default function ParameterProfileUpdating(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_4686_21706)">
                <path
                    d="M13.2344 14.9999H2.15166C1.34786 14.9999 0.693359 14.3454 0.693359 13.541V2.4583C0.693359 1.6545 1.34786 1 2.15166 1H13.2344C14.0389 1 14.6934 1.6545 14.6934 2.4583V13.541C14.6934 14.3455 14.0388 14.9999 13.2344 14.9999ZM2.15166 1.58317C1.66923 1.58317 1.27666 1.97575 1.27666 2.45817V13.5409C1.27666 14.0234 1.66923 14.4166 2.15166 14.4166H13.2344C13.7169 14.4166 14.1101 14.0234 14.1101 13.541V2.45827C14.1101 1.97585 13.7169 1.58327 13.2345 1.58327L2.15166 1.58317Z"
                    fill="var(--icon-color)"
                />
                <path
                    d="M3.90234 9.45898L3.90234 3.534C3.90234 3.42354 3.99189 3.334 4.10234 3.334L4.28564 3.334C4.3961 3.334 4.48564 3.42354 4.48564 3.534L4.48564 9.45898L3.90234 9.45898Z"
                    fill="var(--icon-color)"
                />
                <path
                    d="M4.10234 12.666C3.99189 12.666 3.90234 12.5765 3.90234 12.466L3.90234 10.916L4.48564 10.916L4.48564 12.466C4.48564 12.5765 4.3961 12.666 4.28564 12.666L4.10234 12.666Z"
                    fill="var(--icon-color)"
                />
                <path
                    d="M7.60234 12.666C7.49189 12.666 7.40234 12.5765 7.40234 12.466L7.40234 8.58277L7.98564 8.58277L7.98564 10.6244L7.98564 12.466C7.98564 12.5765 7.8961 12.666 7.78564 12.666L7.60234 12.666Z"
                    fill="var(--icon-color)"
                />
                <path
                    d="M7.40234 7.125L7.40234 3.53326C7.40234 3.4228 7.49189 3.33326 7.60234 3.33326L7.78564 3.33326C7.8961 3.33326 7.98564 3.4228 7.98564 3.53326L7.98564 7.125L7.40234 7.125Z"
                    fill="var(--icon-color)"
                />
                <path
                    d="M5.36074 10.3333C5.36074 10.9768 4.83749 11.5 4.19404 11.5C3.55059 11.5 3.02734 10.9768 3.02734 10.3333C3.02734 9.68985 3.55059 9.1666 4.19404 9.1666C4.83749 9.1667 5.36074 9.68995 5.36074 10.3333ZM3.61074 10.3333C3.61074 10.6548 3.87267 10.9167 4.19404 10.9167C4.51542 10.9167 4.77734 10.6548 4.77734 10.3334C4.77734 10.012 4.51542 9.7501 4.19404 9.7501C3.87267 9.75001 3.61074 10.0119 3.61074 10.3333Z"
                    fill="var(--icon-color)"
                />
                <path
                    d="M11.1023 12.666C10.9919 12.666 10.9023 12.5765 10.9023 12.466L10.9023 6.24928L11.4856 6.24928L11.4856 12.466C11.4856 12.5765 11.3961 12.666 11.2856 12.666L11.1023 12.666Z"
                    fill="var(--icon-color)"
                />
                <path
                    d="M10.9023 4.79102L10.9023 3.53272C10.9023 3.42226 10.9919 3.33272 11.1023 3.33272L11.2856 3.33272C11.3961 3.33272 11.4856 3.42226 11.4856 3.53272L11.4856 4.79102L10.9023 4.79102Z"
                    fill="var(--icon-color)"
                />
                <path
                    d="M12.3607 5.66729C12.3607 6.31074 11.8375 6.83398 11.194 6.83398C10.5506 6.83398 10.0273 6.31074 10.0273 5.66729C10.0273 5.02384 10.5506 4.50059 11.194 4.50059C11.8375 4.50059 12.3607 5.02384 12.3607 5.66729ZM10.6107 5.66729C10.6107 5.98926 10.8721 6.25059 11.194 6.25059C11.516 6.25059 11.7773 5.98926 11.7773 5.66729C11.7773 5.34531 11.516 5.08399 11.194 5.08399C10.8721 5.08389 10.6107 5.34521 10.6107 5.66729Z"
                    fill="var(--icon-color)"
                />
                <path
                    d="M8.86074 7.99932C8.86074 8.64277 8.33749 9.16602 7.69404 9.16602C7.05059 9.16602 6.52734 8.64277 6.52734 7.99932C6.52734 7.35587 7.05059 6.83262 7.69404 6.83262C8.33749 6.83262 8.86074 7.35587 8.86074 7.99932ZM7.11074 7.99932C7.11074 8.32069 7.37267 8.58262 7.69404 8.58262C8.01542 8.58262 8.27734 8.32069 8.27734 7.99932C8.27734 7.67794 8.01542 7.41602 7.69404 7.41602C7.37267 7.41602 7.11074 7.67794 7.11074 7.99932Z"
                    fill="var(--icon-color)"
                />
                <circle cx="11" cy="10" r="6" fill="white" />
                <g clipPath="url(#clip1_4686_21706)">
                    <mask
                        id="mask0_4686_21706"
                        maskUnits="userSpaceOnUse"
                        x="6"
                        y="5"
                        width="11"
                        height="11"
                    >
                        <path
                            d="M11.0695 14.8607C13.7159 14.8607 15.8612 12.7154 15.8612 10.069C15.8612 7.42257 13.7159 5.27734 11.0695 5.27734C8.42306 5.27734 6.27783 7.42257 6.27783 10.069C6.27783 12.7154 8.42306 14.8607 11.0695 14.8607Z"
                            fill="white"
                            stroke="white"
                            strokeWidth="0.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M13.3559 10.7884C13.0507 11.7604 12.1426 12.4655 11.0698 12.4655C9.99718 12.4655 9.08892 11.7604 8.78369 10.7884V12.2259M13.3559 7.91341V9.35091C13.0507 8.37892 12.1426 7.67383 11.0698 7.67383C9.99718 7.67383 9.08892 8.37892 8.78369 9.35091"
                            stroke="black"
                            strokeWidth="0.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </mask>
                    <g mask="url(#mask0_4686_21706)">
                        <path
                            d="M5.31934 4.31836H16.8193V15.8184H5.31934V4.31836Z"
                            fill="var(--icon-color)"
                        />
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_4686_21706">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
                <clipPath id="clip1_4686_21706">
                    <rect width="11.5" height="11.5" fill="white" transform="translate(5 4)" />
                </clipPath>
            </defs>
        </svg>
    );
}
