// eslint-disable-next-line import/no-unresolved
import DefaultDbGovernanceLoader from './default/dbgovernanceloader';
import UBSDbGovernanceLoader from './UBS/dbgovernanceloader';
import MMCDbGovernanceLoader from './MMC/dbgovernanceloader';
import FMRDbGovernanceLoader from './FMR/dbgovernanceloader';

const tenantUrl = window.location.hostname;

const getDataLoaders = () => {
    let dbgovernanceloader;

    switch (tenantUrl) {
        case 'localhost':
        case 'ubs.tsl-terls.cloud':
        case 'ubs-demo.tessell.com':
        case 'ubs-demo.azure.tessell.com':
        case 'demo.azure.tessell.com':
        case 'msftgsi.azure.tessell.com':
            dbgovernanceloader = new UBSDbGovernanceLoader();
            break;
        case 'mmc-demo.tessell.com':
        case 'mmc.tsl-terls.cloud':
        case 'mmc-demo.aws.tessell.com':
        case 'demo.aws.tessell.com':
            dbgovernanceloader = new MMCDbGovernanceLoader();
            break;
        case 'fmr-demo.tessell.com':
        case 'fmr.demo.tessell.com':
        case 'fmr.tsl-terls.cloud':
            dbgovernanceloader = new FMRDbGovernanceLoader();
            break;
        default:
            dbgovernanceloader = new DefaultDbGovernanceLoader();
            break;
    }

    return {
        dbgovernanceloader,
    };
};

const { dbgovernanceloader } = getDataLoaders();

export default class DbGovernanceData {
    // eslint-disable-next-line class-methods-use-this
    getSoftwareImages() {
        return dbgovernanceloader.initSoftwareImages();
    }

    // eslint-disable-next-line class-methods-use-this
    getParameterProfiles() {
        return dbgovernanceloader.initParameterProfiles();
    }

    // eslint-disable-next-line class-methods-use-this
    getParameterProfilesValidations() {
        return dbgovernanceloader.initParameterProfilesValidations();
    }

    // eslint-disable-next-line class-methods-use-this
    getOptionProfiles() {
        return dbgovernanceloader.initOptionsProfiles();
    }

    // eslint-disable-next-line class-methods-use-this
    getOptionsProfilesLoadAclsTrue() {
        return dbgovernanceloader.initOptionsProfilesLoadAclsTrue();
    }

    // eslint-disable-next-line class-methods-use-this
    getSLAs() {
        return dbgovernanceloader.initSLAs();
    }

    // eslint-disable-next-line class-methods-use-this
    getADDomains() {
        return dbgovernanceloader.initAdDomains();
    }

    // eslint-disable-next-line class-methods-use-this
    getLicenses() {
        return dbgovernanceloader.initLicenses();
    }

    // eslint-disable-next-line class-methods-use-this
    createCustomSLA(body) {
        return dbgovernanceloader.createCustomSLA(body);
    }
}
