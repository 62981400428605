import Tickets from 'api-clients/network-layer/demo-data/REST/Support/tickets';
import { getDomainUrl } from 'constants/urls';
import { regexToString } from '../../utils';

// Define the mock data mapping for GET requests
export const mockGetMap = {
    [`${getDomainUrl()}/tickets`]: {
        handler: (config) => {
            const responseData = Tickets.getTicketsDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/tickets/user/assignable/search?project=TS`]: {
        handler: () => [200, []],
    },
    // `${getDomainUrl()}/tickets/${id}/conversations`
    [regexToString(/\/tickets\/([a-zA-Z0-9_-]+)\/conversations/)]: {
        handler: (config) => {
            const { url } = config;
            const regex = /\/tickets\/([a-zA-Z0-9_-]+)\/conversations/;
            const matches = url.match(regex);
            const ticketId = matches?.[1];

            const responseData = Tickets.getConversationByTicketIdDetails(ticketId);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/tickets/${id}`
    [regexToString(/\/tickets\/([a-zA-Z0-9_-]+)/)]: {
        handler: (config) => {
            const { url } = config;
            const regex = /\/tickets\/([a-zA-Z0-9_-]+)/;
            const matches = url.match(regex);
            const ticketId = matches?.[1];

            const responseData = Tickets.getTicketByIdDetails(ticketId);
            return [200, responseData];
        },
    },
};
