import Subscriptions from 'api-clients/network-layer/demo-data/REST/Governance/subscriptions';
import { getDomainUrl } from 'constants/urls';
import { regexToString } from '../../utils';

export const mockGetMap = {
    [`${getDomainUrl()}/subscriptions?page-size=100`]: {
        handler: () => {
            const responseData = Subscriptions.getSubscriptions();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/subscriptions?page-size=1000`]: {
        handler: (config) => {
            const responseData = Subscriptions.getSubscriptionAsConsumer();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/subscription-service/governance/subscriptions?page-size=1000`]: {
        handler: () => {
            const responseData = Subscriptions.getSubscriptions();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/subscription-service/governance/subscriptions/${name}/users`
    [regexToString(/\/subscription-service\/governance\/subscriptions/)]: {
        handler: (config) => {
            const responseData = Subscriptions.getSubscriptionUsers();
            return [200, responseData];
        },
    },
};

// Define the mock data mapping for POST requests
export const mockPostMap = {
    [`${getDomainUrl()}/subscription-service/governance/subscriptions`]: {
        // Mock response function
        handler: (config) => {
            // Execute Subscriptions.createSubscription with request body
            const responseData = Subscriptions.createSubscription(config.data);

            return [200, responseData];
        },
    },
};

export const mockPutMap = {
    //  `${baseUrl}/subscription-service/governance/subscriptions/${name}/${action}` , for all actions on subscriptions related to PUT
    [regexToString(/\/subscription-service\/governance\/subscriptions\/.*/)]: {
        handler: () => [200, {}],
    },
};

export const mockPatchMap = {
    // `${baseUrl}/subscription-service/governance/subscriptions/${subName}/cloud-type/${cloud.toLowerCase()}/${action.toLowerCase()}`
    // for all actions on subscriptions related to PATCH
    [regexToString(/\/subscription-service\/governance\/subscriptions\/.*/)]: {
        handler: () => [200, {}],
    },
};
