export default function ScheduleDurationIcon() {
    return (
        <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.75 0.5C0.75 0.223858 0.973858 0 1.25 0H12.75C13.0261 0 13.25 0.223858 13.25 0.5C13.25 0.776142 13.0261 1 12.75 1H12V3.5C12 5.4791 10.8501 7.18964 9.18206 8C10.8501 8.81036 12 10.5209 12 12.5V15H12.75C13.0261 15 13.25 15.2239 13.25 15.5C13.25 15.7761 13.0261 16 12.75 16H1.25C0.973858 16 0.75 15.7761 0.75 15.5C0.75 15.2239 0.973858 15 1.25 15H2V12.5C2 10.5209 3.14985 8.81036 4.81794 8C3.14985 7.18964 2 5.4791 2 3.5V1H1.25C0.973858 1 0.75 0.776142 0.75 0.5ZM11 14.3839V12.5C11 10.2909 9.20914 8.5 7 8.5C4.79086 8.5 3 10.2909 3 12.5V14.3839L4.11973 13.1397C5.65905 11.4294 8.34095 11.4294 9.88026 13.1397L11 14.3839ZM10.5455 15L9.32279 13.6414C8.08141 12.2621 5.91859 12.2621 4.67721 13.6415L3.45451 15H10.5455ZM3 1H11V3.5C11 3.93653 10.9301 4.35673 10.8008 4.75H3.19919C3.06993 4.35673 3 3.93653 3 3.5V1ZM7 7.5C5.51944 7.5 4.22675 6.6956 3.53513 5.5H10.4649C9.77325 6.6956 8.48056 7.5 7 7.5Z"
                fill="#11567F"
            />
        </svg>
    );
}
