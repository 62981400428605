import clsx from 'clsx';
import { useStyles } from './utils';

export default function ShimmerRow(props) {
    const { className } = props;
    const iconStyle = useStyles(props);
    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
            width="852"
            height="62"
            fill="none"
            viewBox="0 0 852 62"
        >
            <g opacity="0.3">
                <rect width="840" height="62" fill="#EEE" rx="4" />
                <rect
                    width="354"
                    height="23"
                    x="16"
                    y="19.5"
                    fill="url(#paint0_linear_412_1702)"
                    rx="11.5"
                />
                <rect
                    width="81"
                    height="16"
                    x="755"
                    y="12"
                    fill="url(#paint1_linear_412_1702)"
                    rx="8"
                />
                <rect
                    width="135"
                    height="16"
                    x="701"
                    y="34"
                    fill="url(#paint2_linear_412_1702)"
                    rx="8"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_412_1702"
                    x1="16"
                    x2="370"
                    y1="31"
                    y2="31"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#B8B7B7" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_412_1702"
                    x1="755"
                    x2="836"
                    y1="20"
                    y2="20"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#B8B7B7" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_412_1702"
                    x1="701"
                    x2="836"
                    y1="42"
                    y2="42"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#B8B7B7" />
                </linearGradient>
            </defs>
        </svg>
    );
}
