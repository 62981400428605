/* eslint-disable react/react-in-jsx-scope */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function ActivityAppIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.7983 9.99992H13.1316L11.4649 14.1666L9.79827 5.83325L8.1316 9.99992H6.46494"
                stroke="white"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.13159 9.99994C3.13159 6.46411 3.13159 4.69661 4.22992 3.59827C5.32826 2.49994 7.09659 2.49994 10.6316 2.49994C14.1674 2.49994 15.9349 2.49994 17.0333 3.59827C18.1316 4.69661 18.1316 6.46494 18.1316 9.99994C18.1316 13.5358 18.1316 15.3033 17.0333 16.4016C15.9349 17.4999 14.1666 17.4999 10.6316 17.4999C7.09576 17.4999 5.32826 17.4999 4.22992 16.4016C3.13159 15.3033 3.13159 13.5349 3.13159 9.99994Z"
                stroke="white"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
