// eslint-disable-next-line import/no-unresolved
import DefaultTicketsLoader from './default/ticketsloader';

const tenantUrl = '';

const getDataLoaders = () => {
    let ticketsloader;

    switch (tenantUrl) {
        case 'localhost':
        case 'globexUrl':
            // subscriptionLoader = new UBSSubscriptionLoader();
            break;
        default:
            ticketsloader = new DefaultTicketsLoader();
            break;
    }

    return {
        ticketsloader,
    };
};

const { ticketsloader } = getDataLoaders();

export default class TicketsData {
    // eslint-disable-next-line class-methods-use-this
    getTickets() {
        return ticketsloader.initTickets();
    }

    // eslint-disable-next-line class-methods-use-this
    getConversationByTicketId(id) {
        return ticketsloader.initConversationByTicketId(id);
    }

    // eslint-disable-next-line class-methods-use-this
    getTicketById(id) {
        return ticketsloader.initTicketById(id);
    }
}
