import clsx from 'clsx';
import { useStyles } from './utils';

export default function EditionIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="14"
            height="14"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.0131 1.42717C10.3412 1.09924 10.7862 0.915039 11.2501 0.915039C11.714 0.915039 12.1589 1.09924 12.4871 1.42717L13.5731 2.51317C13.901 2.84132 14.0852 3.28625 14.0852 3.75017C14.0852 4.21408 13.901 4.65901 13.5731 4.98717L4.9631 13.5972C4.7531 13.8072 4.4931 13.9612 4.2071 14.0422L0.956095 14.9722C0.827418 15.0089 0.691244 15.0106 0.561701 14.977C0.432158 14.9434 0.313965 14.8758 0.219384 14.7811C0.124803 14.6864 0.0572802 14.5681 0.0238199 14.4386C-0.00964046 14.309 -0.00781899 14.1728 0.0290952 14.0442L0.958095 10.7942C1.0391 10.5082 1.1931 10.2472 1.4031 10.0362L10.0131 1.42617V1.42717ZM10.1891 6.25017L8.7501 4.81017L2.4641 11.0972C2.43403 11.1272 2.41202 11.1644 2.4001 11.2052L1.8421 13.1582L3.7951 12.6002C3.8359 12.5882 3.87304 12.5662 3.9031 12.5362L10.1891 6.25017ZM11.4271 2.48717C11.4039 2.46388 11.3763 2.44541 11.3459 2.43281C11.3155 2.42021 11.283 2.41372 11.2501 2.41372C11.2172 2.41372 11.1847 2.42021 11.1543 2.43281C11.1239 2.44541 11.0963 2.46388 11.0731 2.48717L9.8111 3.75017L11.2501 5.19017L12.5131 3.92717C12.5364 3.90394 12.5548 3.87635 12.5675 3.84598C12.5801 3.81561 12.5865 3.78305 12.5865 3.75017C12.5865 3.71728 12.5801 3.68472 12.5675 3.65435C12.5548 3.62398 12.5364 3.59639 12.5131 3.57317L11.4271 2.48717Z"
                fill="#11567F"
            />
        </svg>
    );
}
