import {
    VECTOR_DATABASE_TYPE,
    MILVUS_EDITIONS,
    DATABASE_TYPE,
    DATABASE_EDITION,
} from 'constants/db.constants';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'constants/app.constants';
import Cache from 'tools/Cache';

const milvusProvisioningFlag = 'milvus-provisioning';
const postgresProvisioningFlag = 'postgres-provisioning';
const mysqlProvisioningFlag = 'mysql-provisioning';
const oracleProvisioningFlag = 'oracle-provisioning';
const mssqlProvisioningFlag = 'mssql-provisioning';
const mongoProvisioningFlag = 'mongodb-provisioning';

export function getDefaultDBEditionForSelectedEngine(engineType) {
    switch (engineType) {
        case DATABASE_TYPE.MILVUS:
            return MILVUS_EDITIONS.COMMUNITY;
        case 'ORACLE':
            return DATABASE_EDITION.ENTERPRISE;
        case DATABASE_TYPE.Postgresql:
            return DATABASE_EDITION.COMMUNITY;
        case DATABASE_TYPE.MySQL:
            return DATABASE_EDITION.COMMUNITY;
        case DATABASE_TYPE.MongoDB:
            return DATABASE_EDITION.COMMUNITY;
        case DATABASE_TYPE.SQLServer:
            return DATABASE_EDITION.EVALUATION;
        default:
            return '';
    }
}

export const getDefaultPortForSelectedEngine = (engineType) => {
    switch (engineType) {
        case DATABASE_TYPE.MILVUS:
            return '19530';
        case DATABASE_TYPE.Oracle:
            return '1521';
        case DATABASE_TYPE.Postgresql:
            return '5432';
        case DATABASE_TYPE.MySQL:
            return '3306';
        case DATABASE_TYPE.MongoDB:
            return '27017';
        case DATABASE_TYPE.SQLServer:
            return '1433';
        default:
            return '';
    }
};

export const getDefaultMasterPwdForSelectedEngine = (engineType) => {
    switch (engineType) {
        case DATABASE_TYPE.MILVUS:
            return 'Tessell123ZX@$';
        case DATABASE_TYPE.Oracle:
            return 'Tessell123ZX@$';
        case DATABASE_TYPE.Postgresql:
            return 'Tessell123ZX$';
        case DATABASE_TYPE.MySQL:
            return 'Tessell123ZX@$';
        case DATABASE_TYPE.MongoDB:
            return 'Tessell123';
        case DATABASE_TYPE.SQLServer:
            return 'Tessell123ZX@$';
        default:
            return '';
    }
};

export const getEnabledEnginesForRelationalDB = () => {
    const features = Cache.getParsedValue('features', EMPTY_OBJECT)?.Provisioning || EMPTY_ARRAY;
    const availableEngineTypes = [];
    if (features.find((f) => f === mysqlProvisioningFlag)) {
        availableEngineTypes.push(DATABASE_TYPE.MySQL);
    }
    if (features.find((f) => f === postgresProvisioningFlag)) {
        availableEngineTypes.push(DATABASE_TYPE.Postgresql);
    }
    if (features.find((f) => f === oracleProvisioningFlag)) {
        availableEngineTypes.push(DATABASE_TYPE.Oracle);
    }
    if (features.find((f) => f === mssqlProvisioningFlag)) {
        availableEngineTypes.push(DATABASE_TYPE.SQLServer);
    }
    return availableEngineTypes;
};

export const isMilvusProvisioningEnabled = () => {
    const features = Cache.getParsedValue('features', EMPTY_OBJECT)?.Provisioning || EMPTY_ARRAY;
    return Boolean(features.find((f) => f === milvusProvisioningFlag));
};

export const getEnabledEnginesForVectorDB = () => {
    const availableEngineTypes = [];
    if (isMilvusProvisioningEnabled()) {
        availableEngineTypes.push(VECTOR_DATABASE_TYPE.MILVUS);
    }
    return availableEngineTypes;
};

export const isMongoDBProvisioningEnabled = () => {
    const features = Cache.getParsedValue('features', EMPTY_OBJECT)?.Provisioning || EMPTY_ARRAY;
    return Boolean(features.find((f) => f === mongoProvisioningFlag));
};

export const getEnabledEnginesForNoSQLDB = () => {
    const availableEngineTypes = [];
    if (isMongoDBProvisioningEnabled()) {
        availableEngineTypes.push(DATABASE_TYPE.MongoDB);
    }
    return availableEngineTypes;
};

export const isVectorDBProvisioningEnabled = () => isMilvusProvisioningEnabled();
