import PersonasJson from './personas.json';
import UsersJson from './users.json';
import UpgradablePersonasJson from './upgradable-personas.json';
import SsoJson from './sso.json';
import SamplePersona from './SamplePersona.json';
import SampleUser from './SampleUser.json';

import { v4 as uuidv4 } from 'uuid';
import replaceTimestampWithMockDate from 'tools/MockTime';

// replacing date with Mock Date
PersonasJson = replaceTimestampWithMockDate(PersonasJson);
UsersJson = replaceTimestampWithMockDate(UsersJson);
UpgradablePersonasJson = replaceTimestampWithMockDate(UpgradablePersonasJson);
SsoJson = replaceTimestampWithMockDate(SsoJson);
SamplePersona = replaceTimestampWithMockDate(SamplePersona);
SampleUser = replaceTimestampWithMockDate(SampleUser);

const personaMappingSections = {
    Tickets: 'TICKETS',
    'My Services': 'MY_SERVICES',
    Provisioning: 'PROVISIONING',
    'Availability Machines': 'AVAILABILITY_MACHINES',
    Dataflix: 'DATAFLIX',
    'Script Library': 'SCRIPT_LIBRARY',
    Benchmarks: 'BENCHMARKS',
    IAM: 'IAM',
    Subscriptions: 'SUBSCRIPTIONS',
    Compute: 'COMPUTE',
    Networks: 'NETWORKS',
    'DB Governance': 'DB_GOVERNANCE',
    Security: 'SECURITY',
    Utilization: 'UTILIZATION',
    Billing: 'BILLING',
    Payments: 'PAYMENTS',
};

const getFilteredPrivileges = (reqBody, samplePersona) => {
    const filteredPrivileges = reqBody.apps.map((app) => {
        const modifedPrivilegeObj = samplePersona.privileges.filter(
            (privilege) => personaMappingSections[app] === privilege.name,
        )[0];
        modifedPrivilegeObj.id = uuidv4();
        return modifedPrivilegeObj;
    });
    return filteredPrivileges;
};

const getFilteredApps = (reqBody, samplePersona) => {
    const filteredPrivileges = reqBody.apps.map((app) => {
        const modifiedPersonaObj = samplePersona.apps.filter((appData) => app === appData.name)[0];
        modifiedPersonaObj.id = uuidv4();

        modifiedPersonaObj.privileges[0].id = uuidv4();

        return modifiedPersonaObj;
    });
    return filteredPrivileges;
};

const modifyPersonaRequest = (reqBody, samplePersona) => {
    const newObj = samplePersona;
    newObj.name = reqBody.name;
    newObj.description = reqBody.description;
    newObj.tenantId = reqBody.tenantId;
    newObj.id = uuidv4();
    newObj.dateCreated = new Date().toISOString();
    newObj.dateModified = new Date().toISOString();
    newObj.privileges = getFilteredPrivileges(reqBody, samplePersona);
    newObj.apps = getFilteredApps(reqBody, samplePersona);

    return newObj;
};

const modifyUserInviteRequest = (reqBody, sampleUser) => {
    const newObj = sampleUser;

    newObj.emailId = reqBody.emailId;
    newObj.tenantUserAttributes[0].subscriptions = reqBody.subscriptions;
    newObj.tenantUserAttributes[0].personas = [
        {
            apps: null,
            name: reqBody.personas[0],
        },
    ];
    newObj.personas = [
        {
            apps: null,
            name: reqBody.personas[0],
        },
    ];

    return newObj;
};

export default class UBSIamLoader {
    // eslint-disable-next-line class-methods-use-this
    initPersonas() {
        const personaNewlyProvisioned = JSON.parse(sessionStorage.getItem('demo-personas'));

        if (!personaNewlyProvisioned) {
            return PersonasJson;
        }

        return {
            ...PersonasJson,
            response: [...PersonasJson.response, ...personaNewlyProvisioned],
        };

        // return PersonasJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initUsers() {
        const userNewlyProvisioned = JSON.parse(sessionStorage.getItem('demo-users'));

        if (!userNewlyProvisioned) {
            return UsersJson;
        }

        return {
            ...UsersJson,
            response: [...UsersJson.response, ...userNewlyProvisioned],
        };

        // return UsersJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initUpgradablePersonas() {
        return UpgradablePersonasJson;
    }

    // eslint-disable-next-line class-methods-use-this
    initSso() {
        return SsoJson;
    }

    // eslint-disable-next-line class-methods-use-this
    createPersona(reqBody) {
        const modifiedPersona = modifyPersonaRequest(JSON.parse(reqBody), SamplePersona);

        if (sessionStorage.getItem('demo-personas')) {
            const oldPersonas = JSON.parse(sessionStorage.getItem('demo-personas'));
            sessionStorage.setItem(
                'demo-personas',
                JSON.stringify([...oldPersonas, modifiedPersona]),
            );
        } else {
            sessionStorage.setItem('demo-personas', JSON.stringify([modifiedPersona]));
        }

        return modifiedPersona;
    }

    // eslint-disable-next-line class-methods-use-this
    createUser(reqBody) {
        const modifiedUser = modifyUserInviteRequest(JSON.parse(reqBody), SampleUser);

        if (sessionStorage.getItem('demo-users')) {
            const oldUsers = JSON.parse(sessionStorage.getItem('demo-users'));
            sessionStorage.setItem('demo-users', JSON.stringify([...oldUsers, modifiedUser]));
        } else {
            sessionStorage.setItem('demo-users', JSON.stringify([modifiedUser]));
        }

        return modifiedUser;
    }
}
