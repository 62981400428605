/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */
import clsx from 'clsx';
import { useStyles } from './utils';

export default function InstancesIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);
    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.125 3.33325C11.125 3.05711 10.9011 2.83325 10.625 2.83325H6.25C5.45435 2.83325 4.69129 3.14932 4.12868 3.71193C3.56607 4.27454 3.25 5.0376 3.25 5.83325V11.4583C3.25 11.7344 3.47386 11.9583 3.75 11.9583C4.02614 11.9583 4.25 11.7344 4.25 11.4583V5.83325C4.25 5.30282 4.46071 4.79411 4.83579 4.41904C5.21086 4.04397 5.71957 3.83325 6.25 3.83325H10.625C10.9011 3.83325 11.125 3.60939 11.125 3.33325Z"
                fill="var(--icon-color)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.25 8.45825C15.9739 8.45825 15.75 8.68211 15.75 8.95825V14.5833C15.75 15.1137 15.5393 15.6224 15.1642 15.9975C14.7891 16.3725 14.2804 16.5833 13.75 16.5833H9.375C9.09886 16.5833 8.875 16.8071 8.875 17.0833C8.875 17.3594 9.09886 17.5833 9.375 17.5833H13.75C14.5456 17.5833 15.3087 17.2672 15.8713 16.7046C16.4339 16.142 16.75 15.3789 16.75 14.5833V8.95825C16.75 8.68211 16.5261 8.45825 16.25 8.45825Z"
                fill="var(--icon-color)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.22855 7.97726C7.03329 7.782 6.71671 7.782 6.52145 7.97726L3.75 10.7487L0.978553 7.97726C0.783291 7.782 0.466708 7.782 0.271447 7.97726C0.0761843 8.17252 0.0761843 8.4891 0.271447 8.68436L3.39645 11.8094C3.59171 12.0046 3.90829 12.0046 4.10355 11.8094L7.22855 8.68436C7.42382 8.4891 7.42382 8.17252 7.22855 7.97726Z"
                fill="var(--icon-color)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.6036 8.60226C16.4083 8.407 16.0917 8.407 15.8964 8.60226L12.7714 11.7273C12.5762 11.9225 12.5762 12.2391 12.7714 12.4344C12.9667 12.6296 13.2833 12.6296 13.4786 12.4344L16.25 9.66292L19.0214 12.4344C19.2167 12.6296 19.5333 12.6296 19.7286 12.4344C19.9238 12.2391 19.9238 11.9225 19.7286 11.7273L16.6036 8.60226Z"
                fill="var(--icon-color)"
            />
            <path
                d="M18.75 2.5C18.75 1.80964 18.1904 1.25 17.5 1.25H15C14.3096 1.25 13.75 1.80964 13.75 2.5V5C13.75 5.69036 14.3096 6.25 15 6.25H17.5C18.1904 6.25 18.75 5.69036 18.75 5V2.5Z"
                fill="var(--icon-color)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 2.25H17.5C17.6381 2.25 17.75 2.36193 17.75 2.5V5C17.75 5.13807 17.6381 5.25 17.5 5.25H15C14.8619 5.25 14.75 5.13807 14.75 5V2.5C14.75 2.36193 14.8619 2.25 15 2.25ZM17.5 1.25C18.1904 1.25 18.75 1.80964 18.75 2.5V5C18.75 5.69036 18.1904 6.25 17.5 6.25H15C14.3096 6.25 13.75 5.69036 13.75 5V2.5C13.75 1.80964 14.3096 1.25 15 1.25H17.5Z"
                fill="var(--icon-color)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.75 15C6.75 14.0335 5.9665 13.25 5 13.25H2.5C1.5335 13.25 0.75 14.0335 0.75 15V17.5C0.75 18.4665 1.5335 19.25 2.5 19.25H5C5.9665 19.25 6.75 18.4665 6.75 17.5V15ZM5 14.25C5.41421 14.25 5.75 14.5858 5.75 15V17.5C5.75 17.9142 5.41421 18.25 5 18.25H2.5C2.08579 18.25 1.75 17.9142 1.75 17.5V15C1.75 14.5858 2.08579 14.25 2.5 14.25H5Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
