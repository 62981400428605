/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */
import clsx from 'clsx';
import { useStyles } from './utils';

export default function TagIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.4977 0H6.39124C5.99061 0 5.61502 0.150235 5.33959 0.450704L0.507042 5.25822C-0.169014 5.93427 -0.169014 7.06103 0.507042 7.73709L4.23787 11.493C4.91393 12.169 6.04069 12.169 6.71674 11.493L11.5493 6.66041C11.8247 6.38498 12 6.00939 12 5.60876V1.50235C12 0.676056 11.3239 0 10.4977 0ZM10.9984 5.58373C10.9984 5.70892 10.9484 5.83412 10.8482 5.93427L6.01565 10.7919C5.71518 11.0923 5.23944 11.0923 4.96401 10.7919L1.20814 7.06103C0.907668 6.76056 0.907668 6.28482 1.20814 6.00939L6.04069 1.17684C6.14085 1.07668 6.26604 1.0266 6.39124 1.0266H10.4977C10.7731 1.0266 10.9984 1.25196 10.9984 1.52739V5.58373Z"
                fill="var(--icon-color)"
            />
            <path
                d="M8.74531 2.50391C8.31965 2.50391 7.99414 2.82941 7.99414 3.25508C7.99414 3.68075 8.31965 4.00625 8.74531 4.00625C9.17098 4.00625 9.49649 3.68075 9.49649 3.25508C9.49649 2.82941 9.17098 2.50391 8.74531 2.50391Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
