/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function SoftwareImageIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.908203 3.86428H23.5038"
                stroke="var(--icon-color)"
                strokeWidth="0.8"
                strokeLinecap="round"
            />
            <path
                d="M23.6 1V18.0238C23.6 18.3552 23.3314 18.6238 23 18.6238H1C0.668629 18.6238 0.4 18.3552 0.4 18.0238V1C0.4 0.668629 0.668629 0.4 1 0.4H12H23C23.3314 0.4 23.6 0.668629 23.6 1Z"
                stroke="var(--icon-color)"
                strokeWidth="0.8"
            />
            <path
                d="M7 7L4.22798 10.6217C4.09289 10.7982 4.09053 11.0426 4.22219 11.2217L7 15"
                stroke="var(--icon-color)"
                strokeLinecap="round"
            />
            <path
                d="M17 7L19.772 10.6217C19.9071 10.7982 19.9095 11.0426 19.7778 11.2217L17 15"
                stroke="var(--icon-color)"
                strokeLinecap="round"
            />
            <path d="M13 7L12 15" stroke="var(--icon-color)" strokeLinecap="round" />
        </svg>
    );
}
