import clsx from 'clsx';
import { useStyles } from './utils';

export default function ControlPanelIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
        >
            {/* <rect width="40" height="40" fill="#F2FAFE" /> */}
            <path
                d="M27.9158 29.9999H12.0833C10.935 29.9999 10 29.0649 10 27.9158V12.0833C10 10.935 10.935 10 12.0833 10H27.9158C29.065 10 30 10.935 30 12.0833V27.9158C30 29.065 29.0649 29.9999 27.9158 29.9999ZM12.0833 10.8331C11.3941 10.8331 10.8333 11.3939 10.8333 12.0831V27.9156C10.8333 28.6049 11.3941 29.1665 12.0833 29.1665H27.9158C28.6051 29.1665 29.1667 28.6049 29.1667 27.9157V12.0832C29.1667 11.3941 28.605 10.8332 27.9159 10.8332L12.0833 10.8331Z"
                fill="var(--icon-color)"
            />
            <path
                d="M14.5833 22.084L14.5833 13.534C14.5833 13.4235 14.6728 13.334 14.7833 13.334L15.2165 13.334C15.327 13.334 15.4165 13.4235 15.4165 13.534L15.4165 22.084L14.5833 22.084Z"
                fill="var(--icon-color)"
            />
            <path
                d="M14.7833 26.666C14.6728 26.666 14.5833 26.5765 14.5833 26.466L14.5833 24.166L15.4165 24.166L15.4165 26.466C15.4165 26.5765 15.327 26.666 15.2165 26.666L14.7833 26.666Z"
                fill="var(--icon-color)"
            />
            <path
                d="M19.7833 26.666C19.6728 26.666 19.5833 26.5765 19.5833 26.466L19.5833 20.8328L20.4165 20.8328L20.4165 23.7494L20.4165 26.466C20.4165 26.5765 20.327 26.666 20.2165 26.666L19.7833 26.666Z"
                fill="var(--icon-color)"
            />
            <path
                d="M19.5833 18.75L19.5833 13.5332C19.5833 13.4228 19.6728 13.3332 19.7833 13.3332L20.2165 13.3332C20.327 13.3332 20.4165 13.4228 20.4165 13.5332L20.4165 18.75L19.5833 18.75Z"
                fill="var(--icon-color)"
            />
            <path
                d="M16.6667 23.3333C16.6667 24.2525 15.9192 25 15 25C14.0808 25 13.3333 24.2525 13.3333 23.3333C13.3333 22.4141 14.0808 21.6666 15 21.6666C15.9192 21.6667 16.6667 22.4142 16.6667 23.3333ZM14.1667 23.3333C14.1667 23.7925 14.5409 24.1667 15 24.1667C15.4591 24.1667 15.8332 23.7925 15.8332 23.3334C15.8332 22.8743 15.4591 22.5001 15 22.5001C14.5409 22.5 14.1667 22.8742 14.1667 23.3333Z"
                fill="var(--icon-color)"
            />
            <path
                d="M24.7833 26.666C24.6728 26.666 24.5833 26.5765 24.5833 26.466L24.5833 17.4992L25.4165 17.4992L25.4165 26.466C25.4165 26.5765 25.327 26.666 25.2165 26.666L24.7833 26.666Z"
                fill="var(--icon-color)"
            />
            <path
                d="M24.5833 15.416L24.5833 13.5327C24.5833 13.4223 24.6728 13.3327 24.7833 13.3327L25.2165 13.3327C25.327 13.3327 25.4165 13.4223 25.4165 13.5327L25.4165 15.416L24.5833 15.416Z"
                fill="var(--icon-color)"
            />
            <path
                d="M26.6667 16.6673C26.6667 17.5865 25.9192 18.334 25 18.334C24.0808 18.334 23.3333 17.5865 23.3333 16.6673C23.3333 15.7481 24.0808 15.0006 25 15.0006C25.9192 15.0006 26.6667 15.7481 26.6667 16.6673ZM24.1667 16.6673C24.1667 17.1272 24.54 17.5006 25 17.5006C25.4599 17.5006 25.8332 17.1272 25.8332 16.6673C25.8332 16.2073 25.4599 15.834 25 15.834C24.54 15.8338 24.1667 16.2072 24.1667 16.6673Z"
                fill="var(--icon-color)"
            />
            <path
                d="M21.6667 19.9993C21.6667 20.9185 20.9192 21.666 20 21.666C19.0808 21.666 18.3333 20.9185 18.3333 19.9993C18.3333 19.0801 19.0808 18.3326 20 18.3326C20.9192 18.3326 21.6667 19.0801 21.6667 19.9993ZM19.1667 19.9993C19.1667 20.4584 19.5409 20.8326 20 20.8326C20.4591 20.8326 20.8332 20.4584 20.8332 19.9993C20.8332 19.5402 20.4591 19.166 20 19.166C19.5409 19.166 19.1667 19.5402 19.1667 19.9993Z"
                fill="var(--icon-color)"
            />
        </svg>
    );
}
