/* eslint-disable no-unused-vars */

export default function CircularStopIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_690_2614)">
                <path
                    d="M7.99986 15.4287C12.1025 15.4287 15.4284 12.1028 15.4284 8.0001C15.4284 3.89742 12.1025 0.571533 7.99986 0.571533C3.89717 0.571533 0.571289 3.89742 0.571289 8.0001C0.571289 12.1028 3.89717 15.4287 7.99986 15.4287Z"
                    stroke="#11567F"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.71401 5.14307H6.28544C5.65425 5.14307 5.14258 5.65474 5.14258 6.28592V9.7145C5.14258 10.3457 5.65425 10.8574 6.28544 10.8574H9.71401C10.3452 10.8574 10.8569 10.3457 10.8569 9.7145V6.28592C10.8569 5.65474 10.3452 5.14307 9.71401 5.14307Z"
                    fill="#11567F"
                    stroke="#11567F"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_690_2614">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
