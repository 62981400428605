import Cache from 'tools/Cache';
import SupportUtil from 'tools/SupportUtil';
import { EnvUrls } from './EnvUrls';
import { validateEmail } from 'utils/validateEmail';
import { CloudTypes } from 'common-components/utils/CloudTypes';

const envUrl = EnvUrls.ErrorPropagation_002001;

let shouldUseNewApiUrlPattern = false;

// we are using this api to check if the control plane is on azure or not,
// if it is on azure then we will use the new api url pattern 'https://uidev008.api.tsl-terls.cloud'
// else we will use the old api url pattern 'https://api.uidev008.tsl-terls.cloud'
// if cp is on azure then then there will be a file 'cloud-type.json' with build files
// if cp is not on azure then there will be no file 'cloud-type.json' with build files
// this is a temporary solution, we will remove once we will have same url pattern for AWS as well

await fetch(`${window.location.origin}/cloud-type.json`)
    .then((response) => response.json())
    .then((json) => {
        shouldUseNewApiUrlPattern = true;
        const cloudType = json.cloud_type;
        if (cloudType?.toUpperCase() === CloudTypes.AZURE) {
            shouldUseNewApiUrlPattern = true;
        }
    })
    .catch((err) => {});

export const getHeaders = () => {
    const headers = {
        Authorization: Cache.get('accessToken'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'tenant-id': Cache.get('tenant-id'),
    };
    if (validateEmail(Cache.get('tenant-id')) || Cache.get('tenant-id') === 'undefined') {
        Cache.clear();
        Cache.set('reload', true);
        SupportUtil.reset();
        window.location.replace(window.location.origin);
    }
    return headers;
};

export const getLoginUrl = () =>
    window.location.hostname.includes('localhost')
        ? 'http://localhost:3000'
        : window.location.origin;

export const getDomainUrl = () => {
    // if configure url is provided in env then use that
    if (process.env.REACT_APP_CUSTOMISED_API_URL) {
        return process.env.REACT_APP_CUSTOMISED_API_URL;
    }

    const envURLToBeConsumed = window.location.hostname.includes('localhost')
        ? envUrl
        : window.location.host;

    if (shouldUseNewApiUrlPattern) {
        const [tenant, ...rest] = envURLToBeConsumed?.split('.') || [];
        return `https://${tenant}.api.${rest?.join('.')}`;
    }
    return `https://api.${envURLToBeConsumed}`;
};

export const graphQLuri = `${getDomainUrl()}/graphql`;
