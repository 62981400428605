import Networks from 'api-clients/network-layer/demo-data/REST/Governance/networks';
import { getDomainUrl } from 'constants/urls';
import { regexToString } from '../../utils';

export const mockGetMap = {
    [`${getDomainUrl()}/network/vpcs?page-size=500`]: {
        handler: () => {
            const responseData = Networks.getVpcsDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/network/vpcs?page-size=500&include-subnets=true`]: {
        handler: () => {
            const responseData = Networks.getVpcsDetails();
            return [200, responseData];
        },
    },
    [`${getDomainUrl()}/subscription-service/governance/subscription-metadata/byoa`]: {
        handler: () => {
            const responseData = Networks.getByoaMetadata();
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/network/governance/vpcs?subscriptionName=${subscription}&page-size=1000` , has an extra & while URL generation
    [regexToString(/\/network\/governance\/vpcs\?subscriptionName=([\w\s-]+)&&page-size=(\d+)/)]: {
        handler: (config) => {
            const { url } = config;
            const regex =
                /\/network\/governance\/vpcs\?subscriptionName=([\w\s-]+)&&page-size=(\d+)/;
            const matches = url.match(regex);
            const subscription = matches?.[1];

            const responseData = Networks.getVpcsSubscriptionName(subscription);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/network/governance/vpcs?subscriptionName=${subscription}&page-size=1000`
    [regexToString(/\/network\/governance\/vpcs\?subscriptionName=([\w\s-]+)&page-size=(\d+)/)]: {
        handler: (config) => {
            const { url } = config;
            const regex =
                /\/network\/governance\/vpcs\?subscriptionName=([\w\s-]+)&page-size=(\d+)/;
            const matches = url.match(regex);
            const subscription = matches?.[1];

            const responseData = Networks.getVpcsSubscriptionName(subscription);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/network/governance/vpcs/register?subscriptionName=${subscription}&cloudType=${cloud}&region=${region}`
    [regexToString(
        /\/network\/governance\/vpcs\/register\?subscriptionName=([\w\s-]+)&cloudType=([\w-]+)&region=([\w-]+)/,
    )]: {
        handler: () => {
            const responseData = Networks.getRegisterNetworksSubscriptionName();

            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/network/governance/vpc/${vpcName}/vpc-peerings?subscriptionName=${subscription}&cloudType=${cloud}&region=${region}&page-size=100`
    [regexToString(
        /\/network\/governance\/vpc\/([\w\s-]+)\/vpc-peerings\?subscriptionName=([\w\s-]+)&cloudType=([\w-]+)&region=([\w-]+)&page-size=(\d+)/,
    )]: {
        handler: (config) => {
            const { url } = config;
            const regex =
                /\/network\/governance\/vpc\/([\w\s-]+)\/vpc-peerings\?subscriptionName=([\w\s-]+)&cloudType=([\w-]+)&region=([\w-]+)&page-size=(\d+)/;
            const matches = url.match(regex);
            const vpcName = matches?.[1];

            const responseData = Networks.getVpcPeering(vpcName);
            return [200, responseData];
        },
    },
    // `${getDomainUrl()}/network/governance/vpc/${vpcName}/subnets?subscriptionName=${subscription}&cloudType=${cloud}&region=${region}&page-size=100`
    [regexToString(
        /\/network\/governance\/vpc\/([\w\s-]+)\/subnets\?subscriptionName=([\w\s-]+)&cloudType=([\w-]+)&region=([\w-]+)&page-size=(\d+)/,
    )]: {
        handler: (config) => {
            const { url } = config;
            const regex =
                /\/network\/governance\/vpc\/([\w\s-]+)\/subnets\?subscriptionName=([\w\s-]+)&cloudType=([\w-]+)&region=([\w-]+)&page-size=(\d+)/;
            const matches = url.match(regex);
            const vpcName = matches?.[1];

            const responseData = Networks.getSubnetsSubscriptionName(vpcName);
            return [200, responseData];
        },
    },
};

// Define the mock data mapping for POST requests
export const mockPostMap = {
    [`${getDomainUrl()}/network/governance/vpcs`]: {
        handler: (config) => {
            const responseData = Networks.createVirtualNetwork(config.data);

            return [200, responseData];
        },
    },
};
