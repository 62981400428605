// eslint-disable-next-line import/no-unresolved
import DefaultBillingLoader from './default/billingloader';
import UBSBillingLoader from './UBS/billingloader';
import MMCBillingLoader from './MMC/billingloader';
import FMRBillingLoader from './FMR/billingloader';

const tenantUrl = window.location.hostname;

const getDataLoaders = () => {
    let billingloader;

    switch (tenantUrl) {
        case 'localhost':
        case 'ubs.tsl-terls.cloud':
        case 'ubs-demo.tessell.com':
        case 'ubs-demo.azure.tessell.com':
        case 'demo.azure.tessell.com':
        case 'msftgsi.azure.tessell.com':
            billingloader = new UBSBillingLoader();
            break;
        case 'mmc-demo.tessell.com':
        case 'mmc.tsl-terls.cloud':
        case 'mmc-demo.aws.tessell.com':
        case 'demo.aws.tessell.com':
            billingloader = new MMCBillingLoader();
            break;
        case 'fmr-demo.tessell.com':
        case 'fmr.demo.tessell.com':
        case 'fmr.tsl-terls.cloud':
            billingloader = new FMRBillingLoader();
            break;
        default:
            billingloader = new DefaultBillingLoader();
            break;
    }

    return {
        billingloader,
    };
};

const { billingloader } = getDataLoaders();

export default class BillingData {
    // eslint-disable-next-line class-methods-use-this
    getRates() {
        return billingloader.initRates();
    }

    // eslint-disable-next-line class-methods-use-this
    getTotalCredits() {
        return billingloader.initTotalCredits();
    }

    // eslint-disable-next-line class-methods-use-this
    getMetersRes(start, end) {
        return billingloader.initGetMeterRes(start, end);
    }

    // eslint-disable-next-line class-methods-use-this
    getMetersResShortDuration(start, end, dbId, cycle) {
        return billingloader.initGetMeterResShortDuration(start, end, dbId, cycle);
    }

    // eslint-disable-next-line class-methods-use-this
    getDefaultPaymentMethod() {
        return billingloader.initDefaultPaymentMethod();
    }

    // eslint-disable-next-line class-methods-use-this
    getBillsSystemGeneratedTrue() {
        return billingloader.initBillsSystemGeneratedTrue();
    }

    // eslint-disable-next-line class-methods-use-this
    getBillingProfile() {
        return billingloader.initBillingProfileJson();
    }

    // eslint-disable-next-line class-methods-use-this
    getInvoices() {
        return billingloader.initInvoicesJson();
    }
}
