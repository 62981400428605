import clsx from 'clsx';
import { useStyles } from './utils';

export default function PITRIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill="var(--icon-color)"
                fillOpacity="0.01"
                style={{ mixBlendMode: 'multiply' }}
                d="M0 0H24V24H0z"
            />
            <path
                fill="var(--icon-color)"
                d="M5.25 6a.75.75 0 100-1.5.75.75 0 000 1.5zM5.25 12a.75.75 0 100-1.5.75.75 0 000 1.5zM5.25 18a.75.75 0 100-1.5.75.75 0 000 1.5z"
            />
            <path
                fill="var(--icon-color)"
                d="M9 19.5H3V15h6v-1.5H3V9h13.5V3A1.5 1.5 0 0015 1.5H3A1.5 1.5 0 001.5 3v16.5A1.5 1.5 0 003 21h6v-1.5zM3 3h12v4.5H3V3z"
            />
            <path
                fill="var(--icon-color)"
                d="M21 12.75v1.81a5.248 5.248 0 10-4.5 7.94V21a3.75 3.75 0 113.432-5.25H18v1.5h4.5v-4.5H21z"
            />
        </svg>
    );
}
