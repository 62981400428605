// eslint-disable-next-line import/no-unresolved
import DefaultBenchmarksLoader from './default/benchmarksloader';

const tenantUrl = '';

const getDataLoaders = () => {
    let benchmarksloader;

    switch (tenantUrl) {
        case 'localhost':
        case 'globexUrl':
            // subscriptionLoader = new UBSSubscriptionLoader();
            break;
        default:
            benchmarksloader = new DefaultBenchmarksLoader();
            break;
    }

    return {
        benchmarksloader,
    };
};

const { benchmarksloader } = getDataLoaders();

export default class BenchmarksData {
    // eslint-disable-next-line class-methods-use-this
    getBenchmarksCatalog() {
        return benchmarksloader.initBenchmarksCatalog();
    }

    // eslint-disable-next-line class-methods-use-this
    getBenchmarks() {
        return benchmarksloader.initBenchmarksJson();
    }
}
