/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/2/2021     bakul.banthia         Created
 *
 */

import React from 'react';
import ReactDOM from 'react-dom';
import {
    ApolloProvider,
    ApolloClient,
    HttpLink,
    ApolloLink,
    InMemoryCache,
    concat,
} from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enUS from 'date-fns/locale/en-US';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { BrowserRouter } from 'react-router-dom';
import 'animate.css';
import './styles/main.scss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from './reportWebVitals';
import Tessell from './Tessell';
// import store from './redux/store';
import { graphQLuri, getHeaders } from './constants/urls';
import { store, persistor } from './redux/configureStore';
import './i18n';
import { getAppKey } from 'tools/getAppKey';
import { EXTERNAL_APPS } from 'tools/appKeyConfig';

LicenseInfo.setLicenseKey(getAppKey(EXTERNAL_APPS.MUI));

const httpLink = new HttpLink({ uri: graphQLuri });

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            ...getHeaders(),
        },
    }));

    return forward(operation);
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
});

ReactDOM.render(
    <React.StrictMode>
        {/* <GoogleOAuthProvider clientId="845494904126-oic4uoi34hif60557tfp8hvs5os1nl6a.apps.googleusercontent.com"> */}
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
            <ApolloProvider client={client}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        {/* <Provider store={store}> */}
                        <BrowserRouter>
                            <Tessell />
                        </BrowserRouter>
                    </PersistGate>
                </Provider>
            </ApolloProvider>
        </LocalizationProvider>
        {/* </GoogleOAuthProvider> */}
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
