export default function LockIcon() {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1396_1864)">
                <path
                    d="M19.8334 9.33268V6.88268C19.8334 5.36653 19.2188 3.91247 18.1248 2.84039C17.0308 1.76831 15.5471 1.16602 14 1.16602C12.4529 1.16602 10.9692 1.76831 9.87523 2.84039C8.78127 3.91247 8.16669 5.36653 8.16669 6.88268V9.33268"
                    stroke="#979797"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6 8.75C4.75736 8.75 3.75 9.75736 3.75 11V24C3.75 25.2426 4.75736 26.25 6 26.25H22C23.2426 26.25 24.25 25.2426 24.25 24V11C24.25 9.75736 23.2426 8.75 22 8.75H6Z"
                    fill="#F8DA9F"
                    stroke="white"
                    strokeWidth="0.5"
                />
                <path
                    d="M14 11.499C14 10.9467 14.4477 10.499 15 10.499H20.1667C21.2713 10.499 22.1667 11.3945 22.1667 12.499V22.499C22.1667 23.6036 21.2713 24.499 20.1667 24.499H15C14.4477 24.499 14 24.0513 14 23.499V11.499Z"
                    fill="#F7C164"
                />
                <g filter="url(#filter0_dd_1396_1864)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20 17.5C20 20.8137 17.3137 23.5 14 23.5C10.6863 23.5 8 20.8137 8 17.5C8 14.1863 10.6863 11.5 14 11.5C17.3137 11.5 20 14.1863 20 17.5ZM16.8125 15.8355C16.975 15.6122 16.9259 15.2995 16.7026 15.1369C16.4794 14.9744 16.1667 15.0236 16.0041 15.2468L13.3381 18.9078L12.0717 17.6653C11.8746 17.4719 11.558 17.475 11.3646 17.6721C11.1713 17.8692 11.1743 18.1858 11.3714 18.3792L12.7475 19.7293C12.8361 19.8178 12.9432 19.8858 13.0611 19.9283C13.1792 19.9709 13.3053 19.9869 13.4303 19.9751C13.5533 19.9642 13.6726 19.9272 13.7802 19.8665C13.8886 19.8054 13.9827 19.7216 14.0558 19.6209L14.0561 19.6206L16.8125 15.8355Z"
                        fill="white"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_dd_1396_1864"
                    x="2"
                    y="6.5"
                    width="24"
                    height="24"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.215686 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1396_1864"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.215686 0 0 0 0.06 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_1396_1864"
                        result="effect2_dropShadow_1396_1864"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1396_1864"
                        result="shape"
                    />
                </filter>
                <clipPath id="clip0_1396_1864">
                    <rect width="28" height="28" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
