/*
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     sandeeptessell         Created
 *
 */
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './utils';

export default function NothingRegisterIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="65"
            height="79"
            viewBox="0 0 65 79"
            xmlSpace="preserve"
            fill="none"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M51.3232 5.28674V65.3923C51.3232 67.8512 49.3299 69.8445 46.871 69.8445H5.2865C2.82753 69.8445 0.834229 67.8512 0.834229 65.3923V5.28674C0.834229 2.82778 2.82753 0.834473 5.2865 0.834473H46.8711C49.3299 0.834473 51.3232 2.82792 51.3232 5.28674Z"
                fill="#FEFDFE"
            />
            <path
                opacity="0.2"
                d="M51.326 5.28698V65.3925C51.326 67.8523 49.3335 69.8448 46.8737 69.8448H39.2269C41.6868 69.8448 43.6792 67.8523 43.6792 65.3925V5.28698C43.6792 2.82701 41.6868 0.834717 39.2269 0.834717H46.8737C49.3335 0.834717 51.326 2.82701 51.326 5.28698Z"
                fill="#29B5E8"
                fillOpacity="0.6"
            />
            <path
                d="M28.1544 64.1952C28.1544 65.3422 27.2301 66.2729 26.0831 66.2729C24.9361 66.2729 24.0052 65.3422 24.0052 64.1952C24.0052 63.0482 24.936 62.1174 26.0831 62.1174C27.2301 62.1174 28.1544 63.0482 28.1544 64.1952Z"
                fill="#B8DAFF"
            />
            <path
                d="M30.0421 16.4472C30.0421 18.6349 28.2621 20.4054 26.0744 20.4054C23.8866 20.4054 22.116 18.6349 22.116 16.4472C22.116 14.2594 23.8865 12.4795 26.0744 12.4795C28.2621 12.4795 30.0421 14.2593 30.0421 16.4472Z"
                fill="#E4F4FB"
            />
            <path
                d="M18.5682 32.5235C18.5682 27.2888 21.9244 23.0303 26.0713 23.0303C30.2183 23.0303 33.5919 27.2888 33.5919 32.5235H18.5682Z"
                fill="#EFF1F2"
            />
            <path
                opacity="0.2"
                d="M30.044 16.4509C30.044 18.6325 28.2631 20.4022 26.0704 20.4022C25.614 20.4022 25.18 20.3243 24.7793 20.1797C26.3265 19.6567 27.4506 18.1761 27.4506 16.451C27.4506 14.7147 26.3265 13.2342 24.7793 12.7C25.18 12.5553 25.614 12.4775 26.0704 12.4775C28.263 12.4772 30.044 14.2582 30.044 16.4509Z"
                fill="#29B5E8"
            />
            <path
                opacity="0.2"
                d="M33.5942 32.5236H30.0435C30.0435 28.0602 27.5948 24.3091 24.3 23.2962C24.8678 23.1181 25.4577 23.0291 26.0699 23.0291C30.2216 23.0291 33.5942 27.2921 33.5942 32.5236Z"
                fill="#29B5E8"
            />
            <path
                d="M26.0831 61.2827C24.4765 61.2827 23.17 62.5893 23.17 64.1948C23.17 65.8013 24.4767 67.1078 26.0831 67.1078C27.6853 67.1078 28.9892 65.8012 28.9892 64.1948C28.9892 62.5893 27.6853 61.2827 26.0831 61.2827ZM26.0831 65.4382C25.3973 65.4382 24.8395 64.8806 24.8395 64.1946C24.8395 63.5098 25.3971 62.9522 26.0831 62.9522C26.7646 62.9522 27.3196 63.5098 27.3196 64.1946C27.3196 64.8806 26.7647 65.4382 26.0831 65.4382Z"
                fill="#29B5E8"
            />
            <path
                d="M26.0739 11.6448C23.4309 11.6448 21.2809 13.7991 21.2809 16.447C21.2809 19.0906 23.4309 21.2406 26.0739 21.2406C28.7224 21.2406 30.8767 19.0906 30.8767 16.447C30.8767 13.7991 28.7222 11.6448 26.0739 11.6448ZM26.0739 19.571C24.3515 19.571 22.9505 18.1699 22.9505 16.447C22.9505 14.7199 24.3517 13.3144 26.0739 13.3144C27.8016 13.3144 29.2071 14.7199 29.2071 16.447C29.2071 18.1699 27.8016 19.571 26.0739 19.571Z"
                fill="#29B5E8"
            />
            <path
                d="M17.7321 32.5237C17.7321 32.9845 18.1059 33.3584 18.5668 33.3584H33.5905H33.6127C34.0736 33.3584 34.4475 32.9845 34.4475 32.5237C34.4475 32.4551 34.4394 32.3889 34.4236 32.3247C34.3377 26.7214 30.6235 22.1963 26.0702 22.1963C21.4723 22.1963 17.7321 26.829 17.7321 32.5237ZM32.7248 31.6888H19.4326C19.7566 27.305 22.6098 23.8658 26.0703 23.8658C29.5394 23.8659 32.3998 27.305 32.7248 31.6888Z"
                fill="#29B5E8"
            />
            <path
                d="M10.6577 40.2356H41.5014C41.9622 40.2356 42.3361 39.8618 42.3361 39.4009C42.3361 38.94 41.9622 38.5662 41.5014 38.5662H10.6577C10.1969 38.5662 9.823 38.94 9.823 39.4009C9.823 39.8616 10.1969 40.2356 10.6577 40.2356Z"
                fill="#29B5E8"
            />
            <path
                d="M10.6577 47.8899H41.5014C41.9622 47.8899 42.3361 47.5161 42.3361 47.0552C42.3361 46.5943 41.9622 46.2205 41.5014 46.2205H10.6577C10.1969 46.2205 9.823 46.5943 9.823 47.0552C9.823 47.5161 10.1969 47.8899 10.6577 47.8899Z"
                fill="#29B5E8"
            />
            <path
                d="M33.7261 53.876H18.4307C17.9698 53.876 17.5959 54.2498 17.5959 54.7107C17.5959 55.1716 17.9698 55.5455 18.4307 55.5455H33.7261C34.187 55.5455 34.5609 55.1716 34.5609 54.7107C34.561 54.25 34.187 53.876 33.7261 53.876Z"
                fill="#29B5E8"
            />
            <path
                d="M0 5.287V65.3927C0 68.3079 2.37179 70.6797 5.287 70.6797H46.871C49.7863 70.6797 52.158 68.3079 52.158 65.3927V26.7582C52.158 26.7582 52.158 22.6256 52.158 18.1186C52.158 13.6168 52.158 9.4898 52.158 9.4898V5.287C52.158 2.37179 49.7863 0 46.871 0H5.287C2.37179 0 0 2.37179 0 5.287ZM1.66962 5.287C1.66962 3.2924 3.29254 1.66947 5.28715 1.66947H46.8712C48.8658 1.66947 50.4887 3.2924 50.4887 5.287V9.49023C50.4887 9.49023 50.4887 13.6204 50.4887 18.1185C50.4887 22.6218 50.4887 26.7576 50.4887 26.7576V65.3927C50.4887 67.3873 48.8658 69.0102 46.8712 69.0102H5.287C3.2924 69.0102 1.66947 67.3873 1.66947 65.3927L1.66962 5.287Z"
                fill="#29B5E8"
            />
            <path
                d="M64.25 67.4919C64.25 73.4444 59.4431 78.25 53.5082 78.25C47.5562 78.25 42.75 73.4437 42.75 67.4919C42.75 61.5569 47.5555 56.75 53.5082 56.75C59.444 56.75 64.25 61.5562 64.25 67.4919Z"
                fill="#E4F4FB"
                stroke="#29B5E8"
                strokeWidth="1.5"
            />
            <path
                d="M55.1681 62.344V66.92C55.1681 67.4107 55.1387 67.8987 55.0801 68.384C55.0214 68.864 54.9441 69.3547 54.8481 69.856H53.1681C53.0721 69.3547 52.9947 68.864 52.9361 68.384C52.8774 67.8987 52.8481 67.4107 52.8481 66.92V62.344H55.1681ZM52.5761 72.744C52.5761 72.552 52.6107 72.3733 52.6801 72.208C52.7547 72.0427 52.8534 71.8987 52.9761 71.776C53.1041 71.6533 53.2534 71.5573 53.4241 71.488C53.5947 71.4133 53.7814 71.376 53.9841 71.376C54.1814 71.376 54.3654 71.4133 54.5361 71.488C54.7067 71.5573 54.8561 71.6533 54.9841 71.776C55.1121 71.8987 55.2107 72.0427 55.2801 72.208C55.3547 72.3733 55.3921 72.552 55.3921 72.744C55.3921 72.936 55.3547 73.1173 55.2801 73.288C55.2107 73.4533 55.1121 73.5973 54.9841 73.72C54.8561 73.8427 54.7067 73.9387 54.5361 74.008C54.3654 74.0773 54.1814 74.112 53.9841 74.112C53.7814 74.112 53.5947 74.0773 53.4241 74.008C53.2534 73.9387 53.1041 73.8427 52.9761 73.72C52.8534 73.5973 52.7547 73.4533 52.6801 73.288C52.6107 73.1173 52.5761 72.936 52.5761 72.744Z"
                fill="#29B5E8"
            />
            <path
                opacity="0.1"
                d="M64 67.4925C64 73.3053 59.2985 78 53.4926 78C52.6268 78 51.7911 77.8957 51 77.687C55.597 76.5842 58.9999 72.4406 58.9999 67.4924C58.9999 62.5592 55.5968 58.416 51 57.313C51.7911 57.1041 52.6268 57 53.4926 57C59.2985 57.0002 64 61.6951 64 67.4925Z"
                fill="#29B5E8"
            />
        </svg>
    );
}
