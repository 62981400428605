/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function MigrationIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            viewBox="0 0 25 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.2906 5.5V1.5C13.2906 1.22386 13.0668 1 12.7906 1H1.5C1.22386 1 1 1.22386 1 1.5V13.7941C1 14.0703 1.22386 14.2941 1.5 14.2941H19.6156M19.6156 14.2941L16.9562 11.6118M19.6156 14.2941L16.9562 17.2M11.8531 17V20.5C11.8531 20.7761 12.077 21 12.3531 21H23.5C23.7761 21 24 20.7761 24 20.5V8.68431C24 8.40817 23.7761 8.18431 23.5 8.18431H5.09688M5.09688 8.18431L8.5 5M5.09688 8.18431L8.5 11.5"
                stroke="var(--icon-color)"
                strokeLinecap="round"
            />
        </svg>
    );
}
