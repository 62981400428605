export default function DetailsArrowIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_490_291)">
                <path
                    d="M14.8 9.7998V13.7537C14.8 14.0311 14.6898 14.2972 14.4936 14.4934C14.2974 14.6896 14.0314 14.7998 13.7539 14.7998H2.2462C1.96875 14.7998 1.70265 14.6896 1.50646 14.4934C1.31027 14.2972 1.20005 14.0311 1.20005 13.7537V2.24596C1.20005 1.9685 1.31027 1.70241 1.50646 1.50622C1.70265 1.31002 1.96875 1.1998 2.2462 1.1998H6.20005"
                    stroke="#11567F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.95555 1.20042H14.8V6.04492"
                    stroke="#11567F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.8 1.20034L6.5 9.5"
                    stroke="#11567F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_490_291">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(16 16) rotate(-180)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
